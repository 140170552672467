import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChronometerDisciplinesListPage } from './chronometer-disciplines-list.page';
import { SharedComponentsModule } from '@app/components/shared-components.module';
import { StudyRegisterModalPageModule } from '../study-register-modal/study-register-modal.module';

@NgModule({
  imports: [
    StudyRegisterModalPageModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [ChronometerDisciplinesListPage]
})
export class ChronometerDisciplinesListPageModule {}

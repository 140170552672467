<section id="see-advantages">
    <h2 class="text-center ion-padding-vertical">
        <img src="assets/imgs/plans-compare/coroa_high.png" />
        Vantagens de ser Premium
    </h2>
    <ion-grid id="see-content" *ngFor="let benefit of benefits">
        <ion-row id="see-content-row">
            <ion-col size="2">
                <ion-icon size="large" [name]="benefit.icon"></ion-icon>
            </ion-col>
            <ion-col>
                <h3>{{benefit.title}}</h3>
                <p>{{benefit.text}}</p>
            </ion-col>
        </ion-row>
    </ion-grid>
</section>
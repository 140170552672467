<ion-progress-bar
[hidden]="!isLoading"
color="orange"
type="indeterminate"
></ion-progress-bar>
<app-time-charts
  [statistics]="statistics"
  [period]="period"
  [tipo]="tipo"
  *ngIf="isTimeTabSelected"
></app-time-charts>
<app-exercise-charts
  [statistics]="statistics"
  [period]="period"
  [tipo]="tipo"
  *ngIf="isExercisesTabSelected"
></app-exercise-charts>
<app-page-charts [statistics]="statistics" [period]="period" [tipo]="tipo" *ngIf="isPagesTabSelected"></app-page-charts>
<app-efficiency-charts
  [statistics]="statistics"
  [period]="period"
  [tipo]="tipo"
  *ngIf="isEfficiencyTabSelected"
></app-efficiency-charts>

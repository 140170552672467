export class Guid {
   static newGuid(): string {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
         return v.toString(16);
      });
   }

   static _getRandomString(length) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randS = '';

      while (length > 0) {
         randS += chars.charAt(Math.floor(Math.random() * chars.length));
         length--;
      }
      return randS;
   }

   static generateLocalObjectId(userId = '') {
      return `_l${userId}${Guid._getRandomString(9)}`;
   }
}

<app-common-header
  title="Editar Senha"
  [isDark]="true"
  (leftIconTap)="dismiss()"
  [hasFilter]="false"
  [hasQuestionTutorial]="false"
></app-common-header>

<ion-content class="ion-padding">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <ion-item lines="none" class="ion-margin-top">
      <ion-icon name="lock-closed" slot="start"></ion-icon>
      <ion-input autocomplete="off" formControlName="password" required [type]="passwordInputType" placeholder="Nova Senha"> </ion-input>
      <ion-icon
        *ngIf="passwordInputType === 'password'"
        name="eye"
        slot="end"
        (click)="onShowPassword()"
        class="ion-margin-top"
      >
      </ion-icon>
      <ion-icon
        *ngIf="passwordInputType === 'text'"
        name="eye-off"
        slot="end"
        (click)="onShowPassword()"
        class="ion-margin-top"
      ></ion-icon>
    </ion-item>
    <ion-item lines="none" class="ion-margin-top">
      <ion-icon name="lock-closed" slot="start"></ion-icon>
      <ion-input autocomplete="off" formControlName="passwordRepeat" required [type]="passwordInputType" placeholder="Repetir Nova senha">
      </ion-input>
      <ion-icon
        *ngIf="passwordInputType === 'password'"
        name="eye"
        slot="end"
        (click)="onShowPassword()"
        class="ion-margin-top"
      >
      </ion-icon>
      <ion-icon
        *ngIf="passwordInputType === 'text'"
        name="eye-off"
        slot="end"
        (click)="onShowPassword()"
        class="ion-margin-top"
      ></ion-icon>
    </ion-item>
    <ion-grid class="ion-margin">
      <ion-row>
        <ion-col>
          <ion-button type="submit" color="success" shape="round" expand="full">SALVAR</ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button color="light" fill="clear" shape="round" expand="full" (click)="dismiss()">CANCELAR</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { StudyRegisterModalPage } from './study-register-modal.page';
import { SharedComponentsModule } from '@app/components/shared-components.module';
import { CustomDateTimeModule } from '@app/directives/custom-date-time/custom-date-time.module';
import { ChooseStudyTypeModalPageModule } from './choose-study-type-modal/choose-study-type-modal.module';
import { TimeRegisterSummaryPageModule } from '../time-register-summary/time-register-summary.module';
import { AppIntegerInputDirective } from '@app/directives/app-integer-input/app-integer-input.directive';
@NgModule({
  imports: [
    TimeRegisterSummaryPageModule,
    ChooseStudyTypeModalPageModule,
    SharedComponentsModule,
    CustomDateTimeModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [StudyRegisterModalPage, AppIntegerInputDirective]
})
export class StudyRegisterModalPageModule {}

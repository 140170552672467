import { Injectable } from "@angular/core";
import { AlertController, Platform } from "@ionic/angular";
import { UserStoreService } from "@services/api/user-store/user-store.service";
import OneSignal from 'onesignal-cordova-plugin';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  constructor(
    private platform: Platform,
    private alertCtrl: AlertController,
    private userStoreService: UserStoreService
  ) {}

  init() {
    if (this.platform.is('cordova')) {
      OneSignal.setAppId('aa06a153-119b-4501-96a3-b875db6f6d17');
      //google id '1018418760703'
      //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

      OneSignal.setNotificationOpenedHandler((data) => {
        const msg = data.notification.body;
        const title = data.notification.title;
        this.showAlert({ header: title, message: msg });
      });
    }
  }

  async showAlert(args: {
    header: string;
    subHeader?: string;
    message?: string;
    buttons?: any;
  }): Promise<HTMLIonAlertElement> {
    const { header, subHeader, message, buttons } = args;
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: buttons || ['OK']
    });

    await alert.present();

    return alert;
  }

  async logout() {
  
    OneSignal.removeExternalUserId();
  }
}

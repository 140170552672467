import { Injectable } from '@angular/core';
import { CachedItem } from '@models/cached-item.interface';
import { Color } from '@models/Color';
import { SubjectItem, UserSubject } from '@models/Subject';
import { ParseService } from '../parse/parse.service';

@Injectable({
   providedIn: 'root'
})
export class DisciplinasService {
   constructor(private parseService: ParseService) {}

   // Colors
   changeColorDisciplinaUsuario(cor: Color, discipline: SubjectItem): Promise<any> {
      return this.parseService.run('changeColorDisciplinaUsuario', { cor, discipline });
   }

   getCoresPadrao(): Promise<any> {
      return this.parseService.run('getCoresPadrao');
   }

   syncColors(lastUpdate: Date): Promise<any> {
      return this.parseService.run('syncColors', {
         searchDto: {
            lastUpdate: lastUpdate
         }
      });
   }

   // Disciplinas
   getDisciplinas(concurso): Promise<any> {
      return this.parseService.run('getDisciplinas', concurso);
   }

   // Disciplinas Gerais
   getDisciplinasGerais(): Promise<any> {
      return this.parseService.run('getDisciplinasGerais');
   }

   async syncGeneralSubjects(lastUpdate: Date): Promise<any> {
      return this.parseService.run('syncGeneralSubjects', {
         searchDto: {
            lastUpdate: lastUpdate
         }
      });
   }

   // Disciplinas Usuario
   deleteDisciplinaUsuario(disciplineId: string): Promise<any> {
      return this.parseService.run('deleteDisciplinaUsuario', { disciplineId });
   }

   getDisciplinasUsuario(userId: string): Promise<any> {
      return this.parseService.run('getDisciplinasUsuario', { userId });
   }

   syncUserSubjects(
      userId: string,
      lastUpdate: Date,
      subjectsToDelete: UserSubject[],
      subjectsToCreate: UserSubject[],
      data: CachedItem[]
   ) {
      return this.parseService.run('syncUserSubjectsOffline_v2', {
         searchDto: {
            userId,
            lastUpdate
         },
         data,
         subjectsToDelete,
         subjectsToCreate
      });
   }

   createDisciplinaUsuario(discipline: UserSubject): Promise<any> {
      return this.parseService.run('createDisciplinaUsuario', { discipline });
   }

   getUserSubjectLastUpdatedAt(userId: string) {
      return this.parseService.run('getUserSubjectLastUpdatedAt', { userId });
   }
}

<ion-header>
  <ion-toolbar>
    <ion-title mode="ios">
      <div class="title-img">
        <img class="img-logo" src="assets/imgs/estudaqui_logo.png" />
        <span>estudaqui</span>
      </div>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="container-info">
    <h2>Quase lá!</h2>
    <span>
      Olá {{currentUserFullname}},
      <br />
      <br />
      <span *ngIf="!isPaymentViaCreditCard"><b>ATENÇÃO:</b> o boleto vence HOJE</span>
      <br />
      <br />
      Sua assinatura do <b class="bold">Plano {{this.nomePlano}}</b>, com duração de
      <b class="bold">{{this.duracaoPlano}} dias</b> e vencimento em <b class="bold">{{this.expirationPlano}}</b>, foi
      solicitada!
      <br />
      <br />
      Quando o status do pagamento for atualizado você <b>receberá um e-mail</b>

      <span *ngIf="isPaymentViaCreditCard">
        <br />
        <br />
        <b class="alert-important bold">Importante:</b> <b>caso tenha problemas</b> no pagamento com cartão, contate o
        emissor antes de refazer a compra. As falhas normalmente são bloqueios feitos pelo banco.
      </span>

      <br />
      <br />
      Quer rever tudo que o Estudaqui te oferece? Visite <span class="link" (click)="navigate()">estudaqui.com</span>.
    </span>
  </div>
  <ion-button color="orange" shape="round" expand="block" (click)="openPaymentSlip()" *ngIf="!isPaymentViaCreditCard">
    <div class="button-content">
      <ion-icon class="icon" name="barcode" color="white"></ion-icon>
      <span>VISUALIZAR BOLETO</span>
    </div>
  </ion-button>
  <ion-button fill="clear" (click)="dismiss()">
    Voltar para a tela Principal
  </ion-button>
  <div class="footer">
    <span class="support">
      Tem um problema? Nos avise em
      <a class="anchor" href="mailto:suporte@estudaqui.com?subject=Problema%20ao%20renovar%20plano - Versão {{appVersion}} - {{userEmailEncoded}}">
        suporte@estudaqui.com
      </a>
    </span>
    <span class="logged-as">Logado como: {{currentUserEmail}}</span>
  </div>
</ion-content>

import { Injectable } from "@angular/core";
import { CachingUtils } from "@app/util/caching-utils";
import { Statistic } from "@models/Statistic";
import { SyncResult } from "@models/sync-result.interface";
import {
  StatisticDto,
  EstudoService,
} from "@app/@services/api/estudo/estudo.service";
import { OfflineStorageService } from "@app/@services/api/offline-storage/offline-storage.service";
import { SyncService } from "@app/@services/api/sync/sync.service";
import { UserStoreService } from "@app/@services/api/user-store/user-store.service";
import { interval, BehaviorSubject } from "rxjs";
import { ClearCachingService } from "../clear-caching/clear-caching.service";
import { TopicCachingService } from "../topic-caching/topic-caching.service";
import { UserSubjectCachingService } from "../user-subject-caching/user-subject-caching.service";
import { auditTime } from "rxjs/operators";
import { ErrorReportService } from "@services/error-report/error-report.service";
import { Severity } from "@sentry/angular";

@Injectable({
  providedIn: "root",
})
export class StatisticCachingService {
  entityName = "cachedStatistic";
  cachedData: Array<Statistic> = [];
  lastUpdate: Date = null;
  syncTimer = interval(60000);
  statisticDto: StatisticDto = {
    page: 0,
    email: "",
    start: `${new Date().getFullYear()}-01-01`,
    end: `${new Date().getFullYear()}-12-31`,
  };

  private statisticsRefresh$: BehaviorSubject<boolean>;

  constructor(
    private userStore: UserStoreService,
    private studyService: EstudoService,
    private clearCaching: ClearCachingService<Statistic>,
    private offlineStorage: OfflineStorageService,
    private userSubjectCachingService: UserSubjectCachingService,
    private topicCachingService: TopicCachingService,
    private syncService: SyncService,
    private errorReportService: ErrorReportService
  ) {
    this.statisticsRefresh$ = new BehaviorSubject(false);
  }

  subscribeRefresh() {
    return this.statisticsRefresh$.pipe(auditTime(30));
  }

  publishRefresh() {
    this.statisticsRefresh$.next(true);
  }

  private clearResult(syncResult: SyncResult<Statistic>) {
    syncResult.doUpdateOrCreate.forEach((r) => {
      delete r.user;
      if (r.disciplina_usuario) delete r.disciplina_usuario.user;
    });
  }

  private async getAll(): Promise<Array<Statistic>> {
    const data = await this.offlineStorage.get<Array<Statistic>>(
      this.entityName
    );
    return data ? data : [];
  }

  private async updateSubjectOfStatistic(
    statistics: Statistic[],
    subjectIds: { objectId: string; localObjectId: string }[]
  ) {
    const localObjectIds = subjectIds
      .filter((obj) => obj.localObjectId)
      .map((obj) => obj.localObjectId);
    const objectIds = subjectIds
      .filter((obj) => obj.objectId)
      .map((obj) => obj.objectId);
    const userSubjects = (await this.userSubjectCachingService.read()).filter(
      (subject) =>
        localObjectIds.includes(subject.localObjectId) ||
        objectIds.includes(subject.objectId)
    );
      
    // Esse código foi adicionado pra "CORRIGIR" um bug e evitar o travamento da sincronização.
    // Basicamente ,existe estatisticas que são criadas sem disciplina_usuário, gerando bug no sync.
    // o codigo procura disciplinas pela descricao da estatistica.
    const statisticsBug = statistics.filter((s) => {
      const { disciplina_usuario } = s;
      if (!disciplina_usuario) {
        return true;
      }
    });
    
    if (statisticsBug.length > 0) {
      this.errorReportService.sendReport({
        sentry: {
          err: new Error('BUG_STATISTIC_MISSING_DISCIPLINA_USUARIO'),
          tags: {fn: 'updateSubjectOfStatistic'},
          level: Severity.Debug
        }
      });
      const userSubjects = this.userSubjectCachingService.cachedData;
      for (const s of statisticsBug) {
        const { curso } = s;
        const result = await this.userSubjectCachingService.findUserSubjectByDescription(curso, userSubjects);
        if (result) {
          s.disciplina_usuario = result;
        }
      }
    }
    statistics = statistics.filter((s) => {
      const { disciplina_usuario } = s;
      if (!disciplina_usuario) {
        return false;
      }
      return true;
    });    
    // fim.

    statistics.forEach((statistic) => {
      if (
        localObjectIds.includes(statistic.disciplina_usuario.localObjectId) ||
        objectIds.includes(statistic.disciplina_usuario.localObjectId)
      ) {
        const newSubject = userSubjects.find(
          (subject) =>
            (subject.localObjectId &&
              statistic.disciplina_usuario.localObjectId &&
              subject.localObjectId ===
                statistic.disciplina_usuario.localObjectId) ||
            (subject.objectId &&
              statistic.disciplina_usuario.objectId &&
              subject.objectId === statistic.disciplina_usuario.objectId)
        );
        if (newSubject) {
          statistic.disciplina_usuario = newSubject;
          statistic.objectIdDisciplina = newSubject.objectId;
        }
      }
    });
  }

  private async fetchStatisticsToWrite() {
    const statisticsToWrite: Statistic[] = this.cachedData.filter(
      (block) => block.hasPendingWrite
    );
    const subjectIds: { objectId: string; localObjectId: string }[] =
      statisticsToWrite
        .filter((s) => s.disciplina_usuario)
        .map((block) => ({
          localObjectId: block.disciplina_usuario.localObjectId,
          objectId: block.disciplina_usuario.objectId,
        }));

    const topicsMap = new Map();
    const topics = await this.topicCachingService.read();
    topics.forEach((obj) => topicsMap.set(obj.localObjectId, obj));
    if (subjectIds.length > 0)
      await this.updateSubjectOfStatistic(statisticsToWrite, subjectIds);
    statisticsToWrite.forEach((statistic) => {
      if (statistic.topic)
        statistic.topic = topicsMap.get(statistic.topic.localObjectId);
    });
    return statisticsToWrite;
  }

  private async fetchStatisticsToDestroy(): Promise<Statistic[]> {
    const userSubjects_ = this.userSubjectCachingService.read();
    const data_ = this.offlineStorage.get<Statistic[]>(this.entityName);
    const [_, data] = await Promise.all([userSubjects_, data_]);

    const statisticsToDelete: Statistic[] = data
      ? data.filter((block) => block.hasPendingDelete)
      : [];
    return statisticsToDelete.map((statistic) => {
      delete statistic.hasPendingDelete;
      return statistic;
    });
  }

  async read(): Promise<Array<Statistic>> {
    let data = await this.offlineStorage.get<Array<Statistic>>(this.entityName);
    data = data ? data : [];
    const response = data ? data.filter((s) => !s.hasPendingDelete) : [];
    return response;
  }

  async delete(object: Partial<Statistic>) {
    await this.offlineStorage.delete(this.entityName, object);
    this.syncService.setSyncStatus(true);
    this.publishRefresh();
  }

  async update(object: Partial<Statistic>) {
    await this.offlineStorage.update(this.entityName, object);
    this.syncService.setSyncStatus(true);
    this.publishRefresh();
  }

  async create(object: Statistic) {
    await this.offlineStorage.create<Statistic>(this.entityName, object);
    this.syncService.setSyncStatus(true);
    this.publishRefresh();
  }

  async clear() {
    return this.clearCaching.clear(this);
  }

  async sync(): Promise<void> {
    try {
      let all = await this.getAll();
      const lastUpdate: Date = (await this.offlineStorage.get(
        `${this.entityName}LastUpdate`
      )) as Date;
      this.cachedData = all.filter((s) => !s.hasPendingDelete);
      this.statisticDto.email = this.userStore.user.username;
      this.statisticDto.lastUpdate = lastUpdate;
      this.statisticDto.toWrite = await this.fetchStatisticsToWrite().then((statistics)=> {
        return statistics.map((statistic)=> {
          const { total_exerc, num_acertos, pag_estudadas } = statistic;
          
          if (total_exerc && typeof total_exerc === 'number') {
            statistic.total_exerc = total_exerc + '';
          }

          if (num_acertos && typeof num_acertos === 'number') {
            statistic.num_acertos = num_acertos + '';
          }

          if (pag_estudadas && typeof pag_estudadas === 'number') {
            statistic.pag_estudadas = pag_estudadas + '';
          }


          return statistic
        })
      });
     
      this.statisticDto.deleted = await this.fetchStatisticsToDestroy();
  
      const syncResult = (await this.studyService.syncStatistics(
        this.statisticDto
      )) as SyncResult<Statistic>;
      const syncDate = syncResult.syncDate;
      this.clearResult(syncResult);
      all = CachingUtils.mergeWithSync(all, syncResult);
      await this.offlineStorage.set(this.entityName, all);
      this.cachedData = all;
      await this.offlineStorage.set(`${this.entityName}LastUpdate`, syncDate);
      this.publishRefresh();
    } catch (error) {
      console.log(error);
    }
  }

  async needSync(): Promise<boolean> {
    try {
      const data = await this.getAll();
      const lastUpdatedLocal_ = this.getLastUpdated(data).then((result) =>
        result ? result.updatedAt : null
      );
      const lastUpdatedAtServer_ = this.studyService
        .getLastUpdatedAtStatistic(this.userStore.user.email)
        .then((result) => (result ? result.lastUpdatedAt : null));

      const hasPending_ = Promise.resolve(data).then((values: any[]) => {
        if (values && values.length > 0) {
          const value = values.find((v) => {
            const { hasPendingDelete, hasPendingWrite } = v;

            if (hasPendingDelete || hasPendingWrite) {
              return true;
            }
            return false;
          });
          if (value) {
            return true;
          }
          return false;
        }
        return false;
      });

      const hasPending = await hasPending_;

      if (hasPending) {
        return true;
      }

      const [lastUpdatedLocal, lastUpdatedAtServer] = await Promise.all([
        lastUpdatedLocal_,
        lastUpdatedAtServer_,
      ]);

      return lastUpdatedLocal !== lastUpdatedAtServer;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private async getLastUpdated(data): Promise<Statistic | null> {
    const dataSorted = CachingUtils.orderByUpdatedAt<Statistic>(data);

    if (dataSorted.length > 0) {
      return dataSorted[0];
    }

    return null;
  }

  async set(items: Statistic[]) {
    this.cachedData = items;
    await this.save();
    this.publishRefresh();
  }

  async save(lastUpdate?: Date) {
    await this.offlineStorage.set(this.entityName, this.cachedData);
    if (lastUpdate)
      await this.offlineStorage.set(`${this.entityName}LastUpdate`, lastUpdate);
  }
}

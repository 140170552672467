import { Injectable } from '@angular/core';
import { CachedItem } from '@models/cached-item.interface';
import { Review } from '@models/Review';
import { SyncResult } from '@models/sync-result.interface';
import { User } from '@models/User';
import { ParseService } from '@app/@services/api/parse/parse.service';
import moment from 'moment';
import { TopicsService } from '../topics/topics.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  constructor(private parseService: ParseService, private topicsService: TopicsService) {}

  syncReviews(toWrite: Review[], toDelete: Review[], lastUpdate: Date, user: User, data: CachedItem[]) {
    return this.parseService
      .run('syncReviews_v2', {
        toWrite,
        toDelete,
        lastUpdate,
        user,
        data
      })
      .then(async (syncResult: SyncResult<Review>) => {
        const topicsMap = await this.topicsService.getTopicsAsMapWithObjectIdKey();

        syncResult.doUpdateOrCreate.forEach((review: Review) => {
          if (review.reviewDate && review.reviewDate.iso) {
            const dt = new Date(review.reviewDate.iso);
            review.reviewDate = moment(dt).startOf('day').toDate();
          }

          if (review.completedAt && review.completedAt.iso) {
            const dt = new Date(review.completedAt.iso);
            review.completedAt = moment(dt).startOf('day').toDate();
          }

          if (review.topicPointer) {
            review.topic = topicsMap.get(review.topicPointer.objectId);
          }
        });

        return syncResult;
      });
  }

  getLastUpdatedAt(userId: string): Promise<Date | null> {
    return this.parseService.run('getReviewLastUpdatedAt', { userId }).then((result) => result.lastUpdatedAt);
  }

  async syncReviewsV3(reviewDTO: ReviewDto): Promise<SyncResult<any>> {
    const topicsMap = await this.topicsService.getTopicsAsMapWithObjectIdKey();

    const syncResult: SyncResult<any> = await this.parseService.run('syncReviews_v3', { reviewDTO });

    syncResult.doUpdateOrCreate.forEach((review: Review) => {
      if (review.reviewDate && review.reviewDate.iso) {
        const dt = new Date(review.reviewDate.iso);
        review.reviewDate = moment(dt).startOf('day').toDate();
      }

      if (review.completedAt && review.completedAt.iso) {
        const dt = new Date(review.completedAt.iso);
        review.completedAt = moment(dt).startOf('day').toDate();
      }

      if (review.topicPointer) {
        review.topic = topicsMap.get(review.topicPointer.objectId);
      }
    });

    return syncResult;
  }
}

interface ReviewDto {
  lastUpdate?: Date;
  toWrite?: Review[];
  deleted?: string[];
}

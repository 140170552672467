import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AnalyticsEventEnum } from '@models/AnalyticsEventEnum';
import { User } from '@models/User';
import { ParseService } from '@services/api/parse/parse.service';
import { EventService } from '@services/event-service/event.service';
import { BasePageController } from '../BasePage';
import { LoginService } from '../login/shared/login.service';

@Component({
  selector: 'app-session-expired-modal',
  templateUrl: './session-expired-modal.page.html',
  styleUrls: ['./session-expired-modal.page.scss']
})
export class SessionExpiredModal extends BasePageController implements OnInit {
  passwordInputType: string = "password";
  submitted: boolean = false;
  form: FormGroup;
  user: User;

  constructor(
    private eventService: EventService,
    public loginService: LoginService,
    private parseService: ParseService) {
    super();
  }

  async ngOnInit() {
    this.user = await this.userStoreService.getUser();
    
    this.form = new FormGroup({
      email: new FormControl(this.user.email, [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]),
      password: new FormControl('', [Validators.required])
    });
  }

  async ionViewWillEnter() {
    this.analyticsService.logEvent(AnalyticsEventEnum.SC_SESSION_EXPIRED);
    this.parseService.userLogout().catch(e=> console.error(e));
  }


  async handleLoginForm() {
    this.submitted = true;
    if (this.form.invalid) { return; }

    const { email, password } = this.form.value;

    const isUsernameCorrect = this.isUsernameCorrect(email);
    if(!isUsernameCorrect) {
      this.showToastMessage(`Usuário deve ser ${this.user.email}`);
      return;
    } 

    const loading = await this.showLoading();

    try {

      await this.userService.logIn({
        user: { email, password }
      });

      await loading.dismiss();
      await this.handleSuccess();
    } catch (error) {
      const { code, message } = error;
      if (code === 101 && message === 'Invalid username/password.') {
        this.showToastMessage('Usuário ou senha incorretos.');
      } else {
        this.showToastMessage('Um erro ocorreu, contate o suporte.');
      }
      await loading.dismiss();
    }
  }

  async handleLoginWithSocialProvider(provider: 'Facebook' | 'Google') {
    try {

     const user = await this.userService.fetchSessionWithSocialProvider(provider);

     if(!this.isUsernameCorrect(user.email)) {
      this.showToastMessage(`Usuário deve ser ${this.user.email}`);
      return;
     } 

     const sessionToken = user.sessionToken;
     await this.userService.become(sessionToken);

      await this.handleSuccess();
    } catch (error) {
      this.showToastMessage('Um erro ocorreu, contate o suporte!');
    }
  }
  

  async handleSuccess() {
    this.eventService.publish('doSync');
    await this.modalCtrl.dismiss();
  }

  async onShowPassword() {
    this.passwordInputType =
      this.passwordInputType === "password" ? "text" : "password";
  }

  private isUsernameCorrect(email: string) : boolean {
    return email === this.user.email;
  }

}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { BotModule } from "@app/bot/bot.module";
import { SharedModule } from "@app/shared/shared.module";
import { SharedComponentsModule } from "@app/components/shared-components.module";

import { MyExamEditSubjectPage } from "./my-exam-edit-subject.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BotModule,
    SharedComponentsModule,
    SharedModule
  ],
  declarations: [MyExamEditSubjectPage],
})
export class MyExamEditSubjectPageModule {}

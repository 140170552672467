import { Injectable } from '@angular/core';
import { AmplitudeService } from './amplitude.service';
import { FirebaseAnalyticsService } from './firebase-analytics.service';
import { Callback } from 'amplitude-js';
import { AnalyticsEventEnum } from '@models/AnalyticsEventEnum';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private amplitudeService: AmplitudeService, private firebaseAnalyticsService: FirebaseAnalyticsService) {}

  setUserId(userId: string) {
    this.amplitudeService.setUserId(userId);
    this.firebaseAnalyticsService.setUserId(userId);
  }

  setUserObjective(objective: string) {
    this.amplitudeService.setUserObjective(objective);
    this.firebaseAnalyticsService.setUserObjective(objective);
  }

  setUserPlan(planId: string, expired: boolean, planExp: Date, planName: string) {
    this.amplitudeService.setUserPlan(planId, expired, planExp, planName);
  }

  logEvent(event: AnalyticsEventEnum | string, data?: any, cb?: Callback) {
    this.amplitudeService.logEvent(event, data, cb);
    this.firebaseAnalyticsService.logEvent(event, data);
  }

  logEventBtnInfo(origin: string) {
    this.logEvent(AnalyticsEventEnum.BT_INFO, { origin });
  }

  logPageEvent(view) {
    try {
      const pageName = view.instance.constructor.name;
      const pageConfig = PageNameEventParams[pageName];
      if (pageConfig) {
        const { eventType, context } = pageConfig;
        this.logEvent(eventType, context);
      }
    } catch (e) {
      console.log(e);
    }
  }

  logEventReviewSegment(index: number) {
    const eventType = SegmentsAmplitudeEventType[index];
    this.logEvent(eventType);
  }

  logBtnEventChronometer(type: AnalyticsEventEnum, subjectShortName: string, freeSubject: boolean) {
    const recordType = freeSubject === true ? 'Estudo livre' : 'Estudo planejado';
    this.logEvent(type, {
      subject: subjectShortName,
      record_type: recordType
    });
  }
}

const PageNameEventParams = {
  CardsPage: { eventType: AnalyticsEventEnum.SC_HISTORY },
  CreateCyclePage: { eventType: AnalyticsEventEnum.SC_CYCLE_CREATE },
  CreateAutomaticCyclePage: { eventType: AnalyticsEventEnum.SC_CYCLE_CREATE_AUTOMATIC },
  ReviewListPage: { eventType: AnalyticsEventEnum.SC_REVIEW },
  ChronometerDisciplinesListPage: { eventType: AnalyticsEventEnum.SC_STUDY_RECORD },
  CronometroModalPage: { eventType: AnalyticsEventEnum.SC_STUDY_RECORD_INFO },
  TimeRegisterSummaryPage: { eventType: AnalyticsEventEnum.SC_STUDY_SUMMARY },
  TopicListPage: { eventType: AnalyticsEventEnum.SC_MY_SUBJECTS_TOPICS },
  PlanosPage: { eventType: AnalyticsEventEnum.SC_SUBSCRIPTION_PLANS },
  TutorialAutomaticCyclePage: { eventType: AnalyticsEventEnum.BT_INFO, context: { origin: 'create_automatic_cycle' } },
  TutorialEstatisticaPage: { eventType: AnalyticsEventEnum.BT_INFO, context: { origin: 'statistic' } },
  TutorialHistoricoPage: { eventType: AnalyticsEventEnum.BT_INFO, context: { origin: 'historic' } },
  TutorialCronometroPage: { eventType: AnalyticsEventEnum.BT_INFO, context: { origing: 'study_record' } },
  StatisticHistoryTabPage: { eventType: AnalyticsEventEnum.SC_HISTORY },
  ChronometerPage: { eventType: AnalyticsEventEnum.SC_CHRONOMETER }
};

const SegmentsAmplitudeEventType = {
  0: AnalyticsEventEnum.TAB_REVIEW_DONE,
  1: AnalyticsEventEnum.TAB_REVIEW_DUE,
  2: AnalyticsEventEnum.TAB_REVIEW_TODAY,
  3: AnalyticsEventEnum.TAB_REVIEW_FUTURE
};

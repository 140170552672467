import { Component, OnInit } from "@angular/core";
import { BasePageController } from "@app/pages/BasePage";
import DateUtils from "@app/util/DateUtils";
import { NavParams } from "@ionic/angular";
import { MetasService } from "@services/api/metas/metas.service";
import moment from "moment";
import * as _ from "underscore";
declare let require: any;
const Hchart = require("highcharts");

@Component({
  selector: "app-statistic-day-detail-dialog",
  templateUrl: "./statistic-day-detail-dialog.page.html",
  styleUrls: ["./statistic-day-detail-dialog.page.scss"],
})
export class StatisticDayDetailDialogPage
  extends BasePageController
  implements OnInit
{
  info: any;
  categorias: any = [];
  disciplinas: any = [];
  chartCategorias: any = [];
  metas: any = [0, 0, 0, 0, 0, 0, 0];
  metasChart: any = [];
  isIphoneX = false;

  iphoneModels = ["iphone10,3", "iphone10,6", "x86_64", "iphone11,8"];
  constructor(public navParams: NavParams, private metasService: MetasService) {
    super();
    this.info = navParams.get("data");
  }

  ngOnInit() {}

  ionViewWillEnter() {
    const groups = _.groupBy(this.info.disciplinas, "materia");
    _.each(groups, (discp, i) => {
      let total = 0;
      let totalParadoPorDisciplina = 0;
      _.each(discp, (e, z) => {
        total += e.tempo;
        totalParadoPorDisciplina += e.tempoParado;
      });
      const obj = {
        name: i,
        cor: discp[0].cor,
        color: discp[0].cor,
        data: [total],
        tempoParado: totalParadoPorDisciplina,
      };
      this.disciplinas.push(obj);
    });

    this.chartCategorias.push(moment(this.info.createAt).format("YYYY-MM-DD"));

    const metaAtual = this.metasService.readMetas();

    if (metaAtual) {
      this.metas = [
        metaAtual.monday,
        metaAtual.tuesday,
        metaAtual.wednesday,
        metaAtual.thursday,
        metaAtual.friday,
        metaAtual.saturday,
        metaAtual.sunday,
      ];
    } else {
      this.metas = [0, 0, 0, 0, 0, 0, 0];
    }

    const indexDia = moment(this.info.createAt).isoWeekday();
    const meta = this.metas[indexDia - 1];
    this.metasChart.push(meta);
    this.carregaGrafico();
  }

  carregaGrafico() {
    const me = this;
    const grafico = {
      chart: {
        animation: false,
        renderTo: "graficoDia",
        type: "column",
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: "Estudo do dia",
      },
      xAxis: {
        categories: this.chartCategorias,
        labels: {
          enabled: true,
          formatter: function () {
            return DateUtils.convertDatetoDDMM(this.value);
          },
          style: {
            fontWeight: "bold",
          },
        },
      },
      yAxis: {
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        min: 0,
        title: {
          text: "Tempos de estudo",
        },
        labels: {
          enabled: false,
          formatter: function () {
            return me.formataHoraTotal(this);
          },
          style: {
            fontWeight: "bold",
          },
        },
        plotLines: [
          {
            color: "#FF0000",
            label: {
              text: "Meta",
              style: {
                color: "#FF0000",
                fontWeight: "bold",
              },
              zIndex: 99999,
            },
            value: this.info.meta,
            width: 3,
          },
        ],
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        formatter: function () {
          return me.formataHoraTooltip(this);
        },
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            color: "#000",
            style: {
              fontWeight: "bold",
              fontSize: "10",
            },
            formatter: function () {
              return me.formataHora(this);
            },
            inside: true,
          },
          pointPadding: 0.1,
          pointWidth: 100,
          groupPadding: 0,
        },
      },
      series: this.disciplinas,
    };

    new Hchart.Chart(grafico);
  }

  tempoTotal(): string {
    let totalHours = 0,
      totalMinutes = 0;
    this.info.disciplinas.forEach((duration) => {
      const h = moment.duration(duration.estudado).get("hours");
      const m = moment.duration(duration.estudado).get("minutes");

      totalHours += h;
      totalMinutes += m;
    });

    const h = Math.floor(totalMinutes / 60);
    totalHours += h;
    totalMinutes -= h * 60;

    let tempoTotal: string =
      totalHours < 10 ? `0${totalHours}` : totalHours.toString();
    tempoTotal +=
      ":" + (totalMinutes < 10 ? `0${totalMinutes}` : totalMinutes.toString());
    return tempoTotal;
  }

  tempoDisperdicado(): string {
    const tempoDisperdicado = moment(this.info.tempo_desperdicado, "HH:mm:ss");
    return tempoDisperdicado.format("HH:mm");
  }

  formataHoraTooltip(point) {
    const hora = this.getHora(point.y);
    return `${point.series.name}: ${hora}<br/>Total: ${this.getHora(
      point.total
    )}`;
  }

  formataHoraTotal(point): string {
    return this.getHora(point.total);
  }

  formataHora(point): string {
    return this.getHora(point.y);
  }

  getHora(hora): string {
    return moment.utc(hora).format("HH:mm");
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-close-popup',
  templateUrl: './close-popup.component.html',
  styleUrls: ['./close-popup.component.scss']
})
export class ClosePopupComponent {
  constructor(private modalController: ModalController) {}

  close() {
    this.modalController.dismiss();
  }
}

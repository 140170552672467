import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";
import { DeleteAccountModalComponent } from "../delete-account-modal/delete-account-modal.component";
import { SharedComponentsModule } from "@app/components/shared-components.module";


@NgModule({
  imports: [SharedComponentsModule, CommonModule, FormsModule, IonicModule],
  declarations: [DeleteAccountModalComponent],
})
export class DeleteAccountModalModule {}

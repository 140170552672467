import ChartUtils from '@app/util/ChartUtils';

export const getChart1 = (data: Array<any>, height: number) => {
  return {
    chart: {
      animation: false,
      inverted: true,
      height: '100%',
      renderTo: 'chart-1',
      type: 'column'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Eficiência (horas líquidas/total)',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    subtitle: {
      text: '(Somente estudos cronometrados)',
      style: {
        fontSize: '0.8rem',
      }
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif',
        }
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      labels: {
        enabled: true,
        format: '{value:.0f}%'
      },
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '{point.y:.0f}%',
          style: {
            fontSize: '0.62rem',
            fontFamily: 'Comfortaa, sans-serif',
          }
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        }
      }
    },
    series: [
      {
        data: data
      }
    ]
  };
};

export const getChart2 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-2',
      type: 'column'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Eficiência por disciplina/assunto',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif',
        }
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      labels: {
        enabled: true,
        format: '{value:.0f}%'
      },
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '{point.y:.0f}%',
          style: {
            fontSize: '0.62rem',
            fontFamily: 'Comfortaa, sans-serif',
          }
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        },
      }
    },
    series: [
      {
        type: undefined,
        name: 'Eficiência por disciplina',
        colorByPoint: true,
        data: data
      }
    ]
  };
};

export const getChart3 = (categories: Array<string | number>, data: Array<any>) => {
  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-3',
      type: 'column',
      height: 500,
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Eficiência por dia da semana',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.72rem',
          fontFamily: 'Comfortaa, sans-serif',
        }
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      labels: {
        enabled: true,
        format: '{value:.0f}%'
      },
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '{point.y:.0f}%',
          style: {
            fontSize: '0.8rem',
            fontFamily: 'Comfortaa, sans-serif',
          }
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        }
      }
    },
    series: [
      {
        data: data
      }
    ]
  };
};

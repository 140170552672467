import { Component } from '@angular/core';

@Component({
  selector: 'app-compare-table',
  templateUrl: './compare-table.component.html',
  styleUrls: ['./compare-table.component.scss']
})
export class CompareTableComponent {
  compareTable = [
    {
      text: 'Anotação de Estudo',
      iconFree: 'checkmark',
      class: 'even'
    },
    {
      text: 'Histórico',
      iconFree: 'checkmark',
      class: 'odd'
    },
    {
      text: 'Versão Web',
      iconFree: '',
      class: 'even'
    },
    {
      text: 'Uso offline',
      iconFree: '',
      class: 'odd'
    },
    {
      text: 'Plano de Estudo Manual',
      iconFree: '',
      class: 'even'
    },
    {
      text: 'Plano de Estudo Otimizado',
      iconFree: '',
      class: 'odd'
    },
    {
      text: 'Estatísticas Avançadas',
      iconFree: '',
      class: 'even'
    },
    {
      text: 'Controle de Revisão',
      iconFree: '',
      class: 'odd'
    },
    {
      text: 'Calendário de Metas',
      iconFree: '',
      class: 'even'
    },
    {
      text: 'Sem anúncios',
      iconFree: '',
      class: 'odd'
    },
  ];
}

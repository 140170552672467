import { Animation, createAnimation } from '@ionic/core';

export function appLeaveAnimation(baseEl: HTMLElement): Animation {

    const baseAnimation = createAnimation().addElement(baseEl);
  
    const backdropAnimation = createAnimation().addElement(baseEl.querySelector('ion-backdrop'));
  
    const wrapperEl = baseEl.querySelector('.modal-wrapper');
    const wrapperAnimation = createAnimation().addElement(wrapperEl);

    wrapperAnimation.addElement(wrapperEl);
  
    wrapperAnimation
      .fromTo('transform', 'scaleX(1) scaleY(1)', 'scaleX(0.1) scaleY(0.1)')
      .fromTo('opacity', 1, 0);
  
    backdropAnimation.fromTo('opacity', 0.4, 0.0);
  
    return baseAnimation
      .easing('ease-out')
      .duration(400)
      .addAnimation(backdropAnimation)
      .addAnimation(wrapperAnimation);

}
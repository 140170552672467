import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { PlansComparePage } from './plans-compare.page';
import { CompareTableComponent } from './compare-table/compare-table.component';
import { TalkingAboutComponent } from './talking-about/talking-about.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { ExpirationAlertComponent } from './expiration-alert/expiration-alert.component';
import { FooterActionComponent } from './footer-action/footer-action.component';
import { PopoverListComponent } from './footer-action/popover-list/popover-list.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [
    PopoverListComponent,
    FooterActionComponent,
    BenefitsComponent,
    ExpirationAlertComponent,
    TalkingAboutComponent,
    CompareTableComponent,
    PlansComparePage
  ]
})
export class PlansComparePageModule {}

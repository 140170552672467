import { Injectable } from '@angular/core';
import { Guid } from '@app/util/guid';

import { ParseService } from '../parse/parse.service';
import { CachingUtils } from '@app/util/caching-utils';
import { OfflineStorageService } from '../offline-storage/offline-storage.service';
import { IMyExam } from '@models/MyExam';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  private readonly lastUpdateKey: string = 'cachedMyExamsLastUpdate';
  private readonly entityKey: string = 'cachedMyExams';

  constructor(private parseService: ParseService, private storage: OfflineStorageService) {}

  private async fetchMyExams(lastUpdate?: Date): Promise<IMyExam[]> {
    return this.parseService.run('fetchMyExams', { lastUpdate });
  }

  async loadMyExams() {
    await this.syncMyExams();
    return this.loadExamsFromStorage();
  }

  async disableMyExam(objectId: string) {
    return this.parseService.run('disableMyExam', { objectId });
  }

  async createOrUpdateMyExam(args: {
    objectId?: string;
    localObjectId?: string
    cargo: string;
    nicho: string;
    pesosDiferentes: boolean;
    subjects: { objectId: string; peso: number; quantidadeQuestoes: number }[];
  }) {
    const { objectId, localObjectId, cargo, nicho, subjects, pesosDiferentes } = args;
    const localObjectIdR = localObjectId || Guid.generateLocalObjectId();

    const id = await this.parseService.run('createOrUpdateMyExam', {
      cargo,
      nicho,
      subjects,
      pesosDiferentes,
      localObjectId: localObjectIdR,
      objectId
    });

    await this.syncMyExams();

    return { objectId: id, cargo, localObjectId, subjects };
  }

  async hasExams() {
    const exams = await this.loadMyExams();
    return exams.length > 0;
  }

  private async syncMyExams() {
    const lastDateSync = await this.getLastDateSync();
    const updatedExams = await this.fetchMyExams(lastDateSync);

    await this.storage.set(this.entityKey, updatedExams);
    await this.storage.set(this.lastUpdateKey, new Date(0));
  }

  private getLastDateSync(): Promise<Date> {
    return this.storage.get<Date>(this.lastUpdateKey);
  }

  private async loadExamsFromStorage(): Promise<IMyExam[]> {
    const response = await this.storage.get<IMyExam[]>(this.entityKey);
    return response || [];
  }
}

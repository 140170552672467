import { Injectable } from '@angular/core';
import { UserSubject, SubjectItem } from '@models/Subject';
import { UserSubjectCachingService } from '../caching/user-subject-caching/user-subject-caching.service';
import { SubjectService } from '../subject/subject.service';
import { UserStoreService } from '../user-store/user-store.service';
import StringUtils from '../../../util/StringUtils';

@Injectable({
   providedIn: 'root'
})
export class DisciplineService {
   constructor(
      private userStore: UserStoreService,
      private subjectService: SubjectService,
      private userSubjectCachingService: UserSubjectCachingService
   ) {}

   async findUserDisciplineByDescription(description: string): Promise<UserSubject> {
      const all = await this.userSubjectCachingService.read();
      return all.find((discipline) => {
         return (
            StringUtils.normalizeToUpperCase(description) === StringUtils.normalizeToUpperCase(discipline.descricao)
         );
      });
   }

   async findByLocalOrObjectId(localObjectId:string, objectId?:string) {
      const disciplinesMap = await this.getDisciplinesAsMap();

      let response = disciplinesMap.get(localObjectId);

      if(!response && objectId) {
         response = disciplinesMap.get(objectId);
      }

      return response
   }

   async getDisciplinesAsMap(): Promise<Map<string, UserSubject>> {
      const map = new Map<string, UserSubject>();
      const all = await this.userSubjectCachingService.read();
      all.forEach((discipline) => {
         let key: string = discipline.objectId;
         if (discipline.localObjectId) key = discipline.localObjectId;
         map.set(key, discipline);
      });
      return map;
   }

   async update(object: Partial<UserSubject>) {
      return this.userSubjectCachingService.update(object);
   }

   async getDisciplinesFromGeneralAndUser(
      objectId: string,
      localObjectId: string,
      isUserSubject: boolean
   ): Promise<UserSubject[]> {
      return (await this.userSubjectCachingService.read()).filter((subject) => {
         if (objectId) {
            if (!isUserSubject)
               return subject && subject.disciplina_geral && subject.disciplina_geral.objectId === objectId;
            return subject && subject.objectId === objectId;
         }
         return subject && subject.localObjectId === localObjectId;
      });
   }

   async createUserSubjectGeneralSubjectItem(subject: SubjectItem): Promise<UserSubject> {
      const newDiscipline: UserSubject = {
         cor: subject.color,
         abreviatura: subject.shortName,
         descricao: subject.name,
         tipo: subject.type,
         user: this.userStore.user,
         disciplinaGeral: subject.userSubject ? undefined : { objectId: subject.objectId },
         localObjectId: subject.localObjectId
      };

      const existentSubject: UserSubject = await this.findUserDisciplineByDescription(subject.name);

      if (existentSubject) {
         newDiscipline.objectId = existentSubject.objectId;
         newDiscipline.cor = existentSubject.color;
         newDiscipline.excluida = false;
         await this.userSubjectCachingService.update(newDiscipline);
      } else {
         await this.userSubjectCachingService.create(newDiscipline);
      }

      await this.subjectService.loadSubjectItems();
      return newDiscipline;
   }
}

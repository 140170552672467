<div class="container-metas-total">
  <div class="metas-total">
    <div class="container-info">
      <div class="container-img item-col-1">
        <img class="img-1" src="assets/imgs/icon-paginas.png" />
        <app-percentage-circle [percent]="100"></app-percentage-circle>
      </div>
    </div>
    <div class="container-time">
      <span>Páginas</span>
      <span>{{ totalPages }}</span>
    </div>
  </div>
  <div class="metas-total">
    <div class="container-info">
      <div class="container-img item-col-2">
        <img class="img-2" src="assets/imgs/icon-ampulheta-2x.png" />
        <app-percentage-circle [percent]="100"></app-percentage-circle>
      </div>
    </div>
    <div class="container-time">
      <span>Velocidade</span>
      <span>{{ pagesRatio }} / pág</span>
    </div>
  </div>
</div>
<div class="slides-time-chart">
  <ion-slides
    [ngClass]="{ 'has-charts': hasStatistics }"
    pager
    (ionSlidesDidLoad)="slideChanged($event)"
    (ionSlideDidChange)="slideChanged($event)"
  >
    <ion-slide class="ion-no-padding ion-padding-top" [ngClass]="{ 'has-chart': hasStatistics }">
      <div class="chart-container" style="width: 100%; height: 56vh; overflow: scroll;" *ngIf="hasStatistics">        
        <div class="chart" id="chart-1"></div>
      </div>
      <div class="empty-grafico ion-padding" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve marcação de páginas lidas nesse período.<br /><span
            class="small"
            >(exemplo do gráfico abaixo)</span
          >
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/paginas-1.png" alt="" />
        </figure>
      </div>
    </ion-slide>

    <ion-slide class="ion-no-padding ion-padding-top" [ngClass]="{ 'has-chart': hasStatistics }">
      <div class="chart-container" style="width: 100%; height: 56vh; overflow: scroll;" *ngIf="hasStatistics">        
        <div class="chart" id="chart-2"></div>
      </div>
      <div class="ion-padding empty-grafico" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve marcação de páginas lidas nesse período.<br /><span
            class="small"
            >(exemplo do gráfico abaixo)</span
          >
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/paginas-2.png" alt="" />
        </figure>
      </div>
    </ion-slide>

    <ion-slide class="ion-no-padding ion-padding-top" [ngClass]="{ 'has-chart': hasStatistics }">
      <div class="chart-container" style="width: 100%; height: 56vh; overflow: scroll;" *ngIf="hasStatistics">       
        <div class="chart" id="chart-3"></div>  
        <ion-button color="medium" fill="clear" (click)="expand()">
          <ion-icon slot="start" name="contract-outline"></ion-icon>
          Expandir gráfico
        </ion-button>
      </div>
      <div class="ion-padding empty-grafico" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve marcação de páginas lidas nesse período.<br /><span
            class="small"
            >(exemplo do gráfico abaixo)</span
          >
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/paginas-3.png" alt="" />
        </figure>
      </div>
    </ion-slide>
  </ion-slides>
</div>

<app-common-header
  title="Minha Assinatura"
  [isDark]="true"
  [hasFilter]="false"
  [hasTutorial]="false"
  [hasQuestionTutorial]="false"
  (leftIconTap)="dismiss()"
></app-common-header>

<ion-content>
  <ion-list>
    <ion-item button lines="full" detail="true" (click)="onMySubscription()">
      <ion-icon slot="start" name="ribbon"></ion-icon>
      <ion-label>
        <h2><b>Meu Plano</b></h2>
        <h3>Detalhes sobre o plano atual</h3>
      </ion-label>
    </ion-item>
    <ion-item button lines="full" detail="true" (click)="onSelectPlan()">
      <ion-icon slot="start" name="cart"></ion-icon>
      <ion-label>
        <h2><b>Todos os Planos</b></h2>
        <h3>Todos Planos oferecidos</h3>
      </ion-label>
    </ion-item>
    <!-- <ion-item button lines="full" detail="true" (click)="onPersonalData()">
      <ion-icon slot="start" name="person"></ion-icon>
      <ion-label>
        <h2><b>Dados Pessoais </b></h2>
        <h3>Meus dados pessoais</h3>
      </ion-label>
    </ion-item> -->
  </ion-list>
</ion-content>

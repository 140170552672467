import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TagsService } from '@services/api/tags/tags.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { UserService } from '@services/api/user/user.service';
import { LoadingService } from '@services/loading/loading.service';
import { LogoutService } from '@services/logout/logout.service';
import { StorageService } from '@services/storage/storage.service';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-delete-data-modal',
  templateUrl: './delete-data-modal.page.html',
  styleUrls: ['./delete-data-modal.page.scss']
})
export class DeleteDataModalPage {
  deleteFieldValue: string;
  constructor(private modalController: ModalController, private toastService: ToastService) {}

  async clickDelete() {
    if (this.deleteFieldValue !== 'LIMPAR DADOS') {
      await this.toastService.showWarning('Digite LIMPAR DADOS', null, null);
      return;
    }

    this.modalController.dismiss({delete: true});
  }

  clickCancel() {
    this.modalController.dismiss({ delete: false });
  }
}

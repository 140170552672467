import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { tap }  from 'rxjs/operators';
import { ReviewUtils } from '@app/util/review-utils';
import { Review } from '@models/Review';
import { ReviewCachingService } from '@services/api/caching/review-caching/review-caching.service';
import { LocalNotificationService } from '@services/local-notifcations/local-notifications.service';
import { StorageService } from '@services/storage/storage.service';
import { ILocalNotification } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Platform } from '@ionic/angular';

export const KEY_LAST_REVIEW_NOTIFICATION_DATE = 'LAST_REVIEW_NOTIFICATION_DATE';
const TIME_INTERVAL_MS = 12 * 60 * 60 * 1000; //12hrs
const KEY_REVIEW_NOTIFICATION_SCHEDULE = 'REVIEW_NOTIFICATION_SCHEDULE';
@Injectable({
  providedIn: 'root'
})
export class ReviewNotificationService {
  constructor(
    private storageService: StorageService,
    private reviewUtils: ReviewUtils,
    private reviewCachingService: ReviewCachingService,
    private localNotifcationService: LocalNotificationService,
    private platform: Platform
  ) {
    this.once();
  }

  private once() {
    this.localNotifcationService
      .on('cancelall')
      .pipe(tap(() => this.forceRefreshScheduledNotifications()))
      .subscribe();

    this.cancelAllScheduledNotifications();
  }

  async resolveShowReviewNotificationModal() {
    const lastNotifcationIsMoreThanADay = await this.lastNotifcationIsMoreThanADay();
    if (lastNotifcationIsMoreThanADay) {
      await this.bumpLastUpdate();
      this.reviewUtils.showReviewWarning();
    }
  }

  private readLastNotificationInMillis(): Promise<null | number> {
    return this.storageService.get(KEY_LAST_REVIEW_NOTIFICATION_DATE);
  }

  private bumpLastUpdate() {
    return this.storageService.set(KEY_LAST_REVIEW_NOTIFICATION_DATE, Date.now());
  }

  private async lastNotifcationIsMoreThanADay(): Promise<boolean> {
    const lastNotificationMillis = await this.readLastNotificationInMillis();
    if (!lastNotificationMillis) {
      return true;
    }

    const resultNowMinusLast = Date.now() - lastNotificationMillis;

    return resultNowMinusLast > TIME_INTERVAL_MS;
  }

  public async refreshLocalNotifcations(reviewsAux?: Review[]) {
    const settings: any = await this.storageService.get('notificationSettings');
    if (settings && !settings.notifyAboutReviews) {
      return;
    }

    const today = new Date();
    const reviews = reviewsAux || (await this.reviewCachingService.read());
    const isAndroid = this.platform.is('android');

    // filter reviews after today and create a standard notifaction date
    const datesToReview: { notifactionAt: Date; subjectDescription: string; topic: string }[] = reviews
      .filter((r) => r.status === 'active' && today < new Date(r.reviewDate))
      .map((r) => {
        const topic = r.topic?.description ? r.topic.description : r.topic;
        return {
          notifactionAt: new Date(new Date(r.reviewDate).setHours(8, 0, 0)),
          topic: topic && typeof topic === 'string' ? topic.split(' ')[0] : '', //get first word of a topic
          subjectDescription: r.userSubject.descricao || ''
        };
      });

    // group reviews by the notification date
    const groupedReviews = groupBy(datesToReview, 'notifactionAt');
    const groupedReviewsKey = Object.keys(groupedReviews);

    // create all the notificaiton objects to be registered
    const schedules: ILocalNotification[] = groupedReviewsKey.map((key) => {
      const at = new Date(key);
      const reviewInformation = groupedReviews[key];

      const text: any[] = [{}];

      reviewInformation.forEach((r) => {
        const { topic, subjectDescription } = r;
        const message = `${subjectDescription} - ${topic}`;
        text.push({ person: 'Estudaqui', message });
      });


      const schedule: ILocalNotification = {
        id: at.getTime(),
        title: isAndroid ? 'Hoje tem revisão! Clique aqui para revisar!': 'Hoje tem revisão',
        text: isAndroid ? text : 'Clique aqui para revisar!',
        trigger: { at }
      };

      return schedule;
    });

    schedules.sort((a, b)=> new Date(a.trigger.at).getTime() - new Date(b.trigger.at).getTime());

    await this.storageService.set(KEY_REVIEW_NOTIFICATION_SCHEDULE, schedules);
    this.cancelAllScheduledNotifications();
  }

  async forceRefreshScheduledNotifications() {    
    const schedules: ILocalNotification[] = await this.storageService.get(KEY_REVIEW_NOTIFICATION_SCHEDULE);

    if (schedules && schedules.length > 0) {
      this.localNotifcationService.schedule(schedules);
    }
  }

    cancelAllScheduledNotifications() {
    return this.localNotifcationService.cancelAll().catch((e)=>{console.error(e)});
  }

  async clear() {
    this.storageService.remove(KEY_LAST_REVIEW_NOTIFICATION_DATE);
    this.storageService.remove(KEY_REVIEW_NOTIFICATION_SCHEDULE);
    this.cancelAllScheduledNotifications();
  }
}

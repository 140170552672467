import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColorFromStatistic } from './ColorFromStatistic';
import { FilterTypeSubject } from './filterSubjects.pipe';
import { GetColorEventDayPipe } from './get-color-event-day.pipe';
import { GetIconDayEvent } from './GetIconDayEvent';
import { MomentUTC } from './MomentUTC';
import { ReverseArrayPipe } from './reverseArray.pipe';
import { WrapFnPipe } from './WrapFn.pipe';
import { DescricaoFromUserSubject } from './DescricaoFromUserSubject';
import { CorCodeFromUserSubject } from './CorCodeFromUserSubject';
import { FilterExamsByTypePipe } from './FilterExamsByType';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CorCodeFromUserSubject,
    DescricaoFromUserSubject,
    ReverseArrayPipe,
    GetIconDayEvent,
    GetColorEventDayPipe,
    FilterTypeSubject,
    ColorFromStatistic,
    MomentUTC,
    FilterExamsByTypePipe,
    WrapFnPipe
  ],
  exports: [
    CorCodeFromUserSubject,
    DescricaoFromUserSubject,
    ReverseArrayPipe,
    GetIconDayEvent,
    GetColorEventDayPipe,
    FilterTypeSubject,
    ColorFromStatistic,
    MomentUTC,
    FilterExamsByTypePipe,
    WrapFnPipe
  ]
})
export class PipesModule {}

<ion-app>
  <ion-split-pane contentId="main-content" when="false">
    <ion-menu contentId="main-content" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list id="inbox-list">
          <ion-menu-toggle auto-hide="false">
       
              <ion-item lines="none" detail="false">
                <ion-icon (click)="doSync()" slot="start" *ngIf="(syncStatus | async) === false" color="success" name="cloud-done"></ion-icon>
                <ion-icon (click)="doSync()" slot="start" *ngIf="syncStatus | async" color="warning" name="sync-circle"></ion-icon>
                <ion-label (click)="doSync()">{{ !(syncStatus | async) ? 'Sincronizado': 'Sincronizar' }}</ion-label>
                
                <ion-button fill="clear" (click)="this.sideMenuService.onHelpSync()">
                  <ion-icon slot="icon-only" size="large" color="primary" src="/assets/svg/help-circle.svg"></ion-icon>
                </ion-button>

              </ion-item>
        
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openWebVersion()">
              <ion-icon slot="start" name="desktop-outline"></ion-icon>
              <ion-label>Versão Web</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <div class="hr spacer"></div>
          <ion-list-header>Estudo</ion-list-header>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onGoalsClick()">
              <ion-icon slot="start" name="ribbon"></ion-icon>
              <ion-label>Metas de Estudo</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onCycleClick()">
              <ion-icon slot="start" name="pie-chart"></ion-icon>
              <ion-label>Meus Planos de Estudo </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openChoosePlanTypeModal()">
              <ion-icon slot="start" name="add"></ion-icon>
              <ion-label class="crown-label">Criar Novo Plano <img src="assets/imgs/plans-compare/coroa_high.png"
                  class="crown-img">
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onMyExam()">
              <ion-icon slot="start" name="document"></ion-icon>
              <ion-label class="crown-label">Mapear Novas Provas<img src="assets/imgs/plans-compare/coroa_high.png"
                class="crown-img"></ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onSubjectsAndTopicsClick()">
              <ion-icon slot="start" name="book"></ion-icon>
              <ion-label>Disciplinas e Assuntos</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onPerformanceClick()">
              <ion-icon slot="start" name="stats-chart"></ion-icon>
              <ion-label class="crown-label">Desempenho <img src="assets/imgs/plans-compare/coroa_high.png"
                  class="crown-img"></ion-label>

            </ion-item>
          </ion-menu-toggle>
      
          <div class="hr spacer"></div>
          <!-- Conta -->
          <ion-list-header>Conta</ion-list-header>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.appService.openMySubscription()">
              <ion-icon slot="start" name="card"></ion-icon>
              <ion-label>Minha Assinatura</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <div>
            <ion-item button lines="none" detail="false" class="ion-margin-left"
              (click)="this.sideMenuService.onShowSubmenu()">
              <ion-icon slot="start" name="person-circle-outline"></ion-icon>
              <ion-icon *ngIf="!this.sideMenuService.showSubmenu" slot="end" name="chevron-forward"></ion-icon>
              <ion-icon *ngIf="this.sideMenuService.showSubmenu" slot="end" name="chevron-down"></ion-icon>
              <ion-label>Gerenciar Conta</ion-label>
            </ion-item>
            <ion-list *ngIf="this.sideMenuService.showSubmenu" class="ion-margin-start">
              <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onChangePassword()">
                <ion-icon slot="start" name="key"></ion-icon>
                <ion-label>
                  <p>Alterar Senha</p>
                </ion-label>
              </ion-item>
              <ion-item button lines="none" detail="false" (click)="openDeleteHistory()">
                <ion-icon slot="start" name="archive"></ion-icon>
                <ion-label>
                  <p>Limpar todos os dados</p>
                </ion-label>
              </ion-item>
              <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openDeleteAccount()">
                <ion-icon slot="start" name="warning"></ion-icon>
                <ion-label>
                  <p>Excluir Conta</p>
                </ion-label>
              </ion-item>
            </ion-list>
          </div>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onNotificationsClick()">
              <ion-icon slot="start" name="notifications"></ion-icon>
              <ion-label>Notificações</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle hidden="true" auto-hide="false">
            <ion-item button lines="none" detail="false"
              (click)="this.sideMenuService.openExternalLink('import-history')">
              <ion-icon slot="start" name="download"></ion-icon>
              <ion-label>Importar Histórico</ion-label>
            </ion-item>
          </ion-menu-toggle>

          <div class="hr spacer"></div>

          <!-- FeedBack -->
          <ion-list-header>Feedback</ion-list-header>
          <ion-menu-toggle *ngIf="isAndroid" auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="onModalRateClick()">
              <ion-icon slot="start" name="star"></ion-icon>
              <ion-label>Avaliar o Aplicativo</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onImprovementsSurvey()">
              <ion-icon slot="start" name="pencil"></ion-icon>
              <ion-label>Pesquisa de Melhorias</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <div class="hr spacer"></div>
          <!-- Ajuda -->
          <ion-list-header>Ajuda</ion-list-header>
            <div>
              <ion-item button lines="none" detail="false" class="ion-margin-left"
                (click)="this.sideMenuService.onShowSubmenu2()">
                <ion-icon slot="start" name="mail"></ion-icon>
                <ion-icon *ngIf="!this.sideMenuService.showSubmenu2" slot="end" name="chevron-forward"></ion-icon>
                <ion-icon *ngIf="this.sideMenuService.showSubmenu2" slot="end" name="chevron-down"></ion-icon>
                <ion-label>Suporte</ion-label>
              </ion-item>
              <ion-list *ngIf="this.sideMenuService.showSubmenu2" class="ion-margin-start">
                <ion-item button lines="none" detail="false"  href="mailto:financeiro@estudaqui.com?subject=Contato via aplicativo - Versão {{appVersion}} - {{userEmailEncoded}}"
                (click)="this.sideMenuService.openContact()">
                  <ion-icon slot="start" name="cash"></ion-icon>
                  <ion-label>
                    <p>Financeiro</p>
                  </ion-label>
                </ion-item>
                <ion-item button lines="none" detail="false" (click)="this.sideMenuService.onReportBug()">
                  <ion-icon slot="start" name="chatbubbles"></ion-icon>
                  <ion-label>
                    <p>Demais Assuntos</p>
                  </ion-label>
                </ion-item>
              </ion-list>
            </div>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openExternalLink('tutorial')">
              <ion-icon slot="start" name="videocam"></ion-icon>
              <ion-label>Tutoriais</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openExternalLink('FAQ')">
              <ion-icon slot="start" name="chatbubbles"></ion-icon>
              <ion-label>FAQ</ion-label>
            </ion-item>
          </ion-menu-toggle>
  
          <div class="hr spacer"></div>
          <!-- A Estudaqui -->
          <ion-list-header>A Estudaqui</ion-list-header>
          <ion-menu-toggle *ngIf="!isIOS" auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openExternalLink('app-details')">
              <ion-icon slot="start" name="information"></ion-icon>
              <ion-label>Detalhes do App</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle *ngIf="!isIOS" auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openExternalLink('about-us')">
              <ion-icon slot="start" name="people"></ion-icon>
              <ion-label>Quem Somos</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openExternalLink('blog')">
              <ion-icon slot="start" name="logo-rss"></ion-icon>
              <ion-label>Dicas Gratuitas (Blog)</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openExternalLink('instagram')">
              <ion-icon slot="start" name="logo-instagram"></ion-icon>
              <ion-label>Instagram</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item button lines="none" detail="false" (click)="this.sideMenuService.openExternalLink('youtube')">
              <ion-icon slot="start" name="logo-youtube"></ion-icon>
              <ion-label>Youtube</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <div class="hr"></div>
          <ion-menu-toggle auto-hide="false">
            <ion-item lines="none" detail="false" (click)="handleLogout()">
              <ion-icon slot="start" name="exit"></ion-icon>
              <ion-label>Logout</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <app-menu-footer></app-menu-footer>


      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
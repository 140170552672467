<app-common-header
  (leftIconTap)="close()"
  title="Mapear Prova"
  [isDark]="true"
  [hasLogo]="false"
  [hasDrawer]="false"
  [hasFilter]="false"
  [hasTutorial]="false"
></app-common-header>

<ion-toolbar style="padding-bottom: 5px;">
  <ion-item class="ion-margin-horizontal" color="orange" lines="none">
    <ion-label position="floating">Filtro por Objetivo</ion-label>
    <ion-select [(ngModel)]="goalSelectedValue" [compareWith]="compareWith">
      <ion-select-option value="Concursos Públicos">Concursos Públicos</ion-select-option>
      <ion-select-option value="Exames Classistas (OAB, CFC etc)">Exames Classistas (OAB, CFC, etc)</ion-select-option>
      <ion-select-option value="ENEM/Vestibulares">ENEM/Vestibulares</ion-select-option>
      <ion-select-option value="Carreiras Militares">Carreiras Militares</ion-select-option>
      <ion-select-option value="Carreiras Médicas">Carreiras Médicas</ion-select-option>
      <ion-select-option value="Outros">Outros</ion-select-option>
    </ion-select>
  </ion-item>
  
  <ion-item class="ion-margin-horizontal" lines="none" color="lightblue">
    <ion-label position="floating">Nome da Prova</ion-label>
    <ion-input [(ngModel)]="examNameInput" required="true"></ion-input>
  </ion-item>


  <ion-item class="ion-no-padding" lines="none">
    <ion-toggle item-start mode="md" [(ngModel)]="pesosDiferentesToggle" (ionChange)="onPesoDiferenteToggle($event)"></ion-toggle>
    <ion-label>Pesos diferentes</ion-label>
    <ion-button class="toolbar-icon-button" color="primary" fill="clear" (click)="myExamService.openHelpPeso()">
      <ion-icon slot="icon-only" src="/assets/svg/help-circle.svg"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-button class="ion-padding-horizontal" style="margin-bottom: 8px;" color="primary" expand="block" (click)="onAddSubject()">
    <ion-icon slot="start" size="large" name="add"></ion-icon>
    Disciplina
  </ion-button>
  <ion-label class="ion-margin-horizontal">Total de questões: {{ calculateTotalQuestoes }}</ion-label>
</ion-toolbar>

<ion-content class=" ion-text-center">

  <div class="items ion-padding">
    <div *ngFor="let subject of subjects; let i = index;" class="item no-left-radius">
      <div class="info" [style.borderLeftColor]="subject.objectId | corCodeFromUserSubject:userSubjects | async">
        <h2 class="title">{{ subject.objectId | descricaoFromUserSubject:userSubjects | async }}</h2>
        <h3 class="subtitle" style="margin-top: 5px;">{{ subject.quantidadeQuestoes ? 'Q: ' + subject.quantidadeQuestoes : '' }}  {{ pesosDiferentesToggle ? subject.peso ? '| P: ' + subject.peso : '' : ''    }} </h3>

      </div>
      <div class="actions" style="margin-right: 5px;">
        <ion-button class="ion-no-padding"  color="light"  slot="icon-only" fill="clear" style="margin-right: 10px;" (click)="onEditSubject(subject, i)">
          <ion-icon style="font-size: 22px;" name="pencil"></ion-icon>
        </ion-button>
        <ion-button class="ion-no-padding" color="light" slot="icon-only" fill="clear" (click)="onRemoveSubject(i)">
          <ion-icon style="font-size: 22px;"  name="trash"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>

 <p>Adicione disciplinas para essa prova</p>
</ion-content>

<ion-footer class="ion-no-padding">
  <ion-button color="primary" shape="round" expand="block" (click)="onSave()">
    Salvar
  </ion-button>
</ion-footer>
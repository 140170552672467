<app-simple-header></app-simple-header>

<ion-content class="ion-padding ion-text-center">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-icon size="large" color="danger" name="warning-outline"></ion-icon>
        <h1><b>Excluir Conta</b></h1>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="splash-info">
          <div class="splash-intro">
            Essa ação irá EXCLUIR completamente o seu usuário e todos os dados vinculados a ele, inclusive o seu login e eventuais planos já pagos.
          </div>
        </div>
      </ion-col>
    </ion-row>
    <br />
    <ion-row>
      <ion-col>
          <ion-chip style="font-size: small;" color="danger">ATENÇÃO: <br/> isso não poderá ser desfeito!</ion-chip> 
      </ion-col>
    </ion-row>
    <br />

    <ion-row>
      <ion-col>
        <ion-item class="box">
          <ion-input [(ngModel)]="deleteFieldValue" autocapitalize="characters" type="text" placeholder="Digite EXCLUIR TUDO"></ion-input>
        </ion-item>
     </ion-col>
    </ion-row>

    <br />
    <ion-row>
      <ion-col>
        <ion-button expand="block" color="danger" (click)="clickDelete()">
          Excluir Conta
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button  expand="block" (click)="clickCancel()">
          Cancelar
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
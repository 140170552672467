import { Injectable } from "@angular/core";
import { UserStoreService } from "@services/api/user-store/user-store.service";
import { environment } from "../../environments/environment";
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { Severity } from "@sentry/angular";


@Injectable({ providedIn: "root" })
export class SentryService {
  initialized = false;

  constructor(private userStore: UserStoreService) {}

  async initialize() {
    try {
      Sentry.init({
        environment: environment.production ? 'production' : 'HOMOLOG',
        dsn: 'https://0961a0c542604da2b5b375b39b3a6410@o382474.ingest.sentry.io/5211584',
        // this assumes your build process sets "npm_package_version" in the env
        release: `estudaqui@${environment.appVersion}`,
        integrations: [
          new BrowserTracing({
            tracingOrigins: ['localhost'],
            routingInstrumentation: Sentry.routingInstrumentation
          })
        ],
        tracesSampleRate: 0
      });

      if (this.userStore.user)
        this.setUser({
          email: this.userStore.user.email,
          id: this.userStore.user.objectId,
        });
      this.initialized = true;
    } catch (err) {
      console.error(err);
    }
  }

  setUser(sentryUserData: {
    email: string;
    id: string;
    username?: string;
    ip_address?: string;
  }) {
    Sentry.setUser({ ...sentryUserData });
  }

  withScope(tags: any, err: any, level?: Severity, data?: any) {
    Sentry.withScope(function (scope) {
      Object.keys(tags).forEach((key) => {
        scope.setTag(key, tags[key]);
      });
      if(data) scope.setContext(data, data);
      if (level) scope.setLevel(level);
      Sentry.captureException(err);
    });
  }

  captureException(error: any) {
    Sentry.captureException(error.originalError || error);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPeriod } from '@models/IPeriod';
import moment from 'moment';

@Component({
  selector: 'app-select-periodo',
  templateUrl: './select-periodo.component.html',
  styleUrls: ['./select-periodo.component.scss']
})
export class SelectPeriodoComponent implements OnInit {
  @Input() tipo: string;
  @Output() Voltar: EventEmitter<IPeriod> = new EventEmitter();
  @Output() Avancar: EventEmitter<IPeriod> = new EventEmitter();

  periodoSemana: string;
  periodoMes: string;
  periodoAno: string;

  month: moment.Moment;
  week: moment.Moment;
  year: moment.Moment;
  constructor() {}

  ngOnInit() {
    this.week = moment().startOf('week');
    this.month = moment().startOf('month');
    this.year = moment().startOf('year');
    this.periodoSemana = this.formatWeek();
    this.periodoMes = this.month.format('MM/YYYY');
    this.periodoAno = this.year.format('YYYY');
  }

  voltar() {
    let changePeriod: IPeriod;

    if (this.tipo === 'semana') {
      this.week.subtract(1, 'week');
      this.periodoSemana = this.formatWeek();
      changePeriod = this.getWeekPeriod();
    } else if (this.tipo === 'mes') {
      this.month.subtract(1, 'month');
      this.periodoMes = this.month.format('MM/YYYY');
      changePeriod = this.getMonthPeriod();
    } else if (this.tipo === 'ano') {
      this.year.subtract(1, 'year');
      this.periodoAno = this.year.format('YYYY');
      changePeriod = this.getYearPeriod();
    }

    this.Voltar.emit(changePeriod);
  }

  avancar() {
    let changePeriod: IPeriod;

    if (this.tipo === 'semana') {
      this.week.add(1, 'week');
      this.periodoSemana = this.formatWeek();
      changePeriod = this.getWeekPeriod();
    } else if (this.tipo === 'mes') {
      this.month.add(1, 'month');
      this.periodoMes = this.month.format('MM/YYYY');
      changePeriod = this.getMonthPeriod();
    } else if (this.tipo === 'ano') {
      this.year.add(1, 'year');
      this.periodoAno = this.year.format('YYYY');
      changePeriod = this.getYearPeriod();
    }

    this.Avancar.emit(changePeriod);
  }

  getWeekPeriod(): IPeriod {
    return {
      start: this.getStartOfWeek(),
      end: this.getEndOfWeek()
    };
  }

  getMonthPeriod(): IPeriod {
    return {
      start: this.month.clone().startOf('month'),
      end: this.month.clone().endOf('month')
    };
  }

  getYearPeriod(): IPeriod {
    return {
      start: this.year.clone().startOf('year'),
      end: this.year.clone().endOf('year')
    };
  }

  private formatWeek(): string {
    return `${this.getStartOfWeek().format('DD/MM/YY')} - ${this.getEndOfWeek().format('DD/MM/YY')}`;
  }

  private getStartOfWeek(): moment.Moment {
    return this.week.clone().startOf('week').add(1, 'day');
  }

  private getEndOfWeek(): moment.Moment {
    return this.week.clone().endOf('week').add(1, 'day');
  }
}

<ion-footer>
  <div class="menu-infos">
    <ion-chip class="ion-margin-bottom" *ngIf="isStaging" color="warning">
      <ion-label color="warning">Homologação</ion-label>
    </ion-chip>
    <span class="menu-info-mb"> {{ appVersion }} </span>
    <span class="menu-info-mb">{{ currentUserEmail || "" }}</span>
    <span class="menu-info-mb">{{ currentUserPlan || "" }}</span>
    <span class="menu-info-mb">{{ deviceName || "N/A" }}</span> 
    <span *ngIf="hasUser && !userStoreService.userIsFreemium()">Validade: {{ currentUserExpiryDate | date:'dd/MM/yyyy' || "" }}</span>
    <br />
    <a *ngIf="canTransfer" (click)="this.sideMenuService.transferData()" href="javascript:void(0)">Transfer</a>
    
    <a
      *ngIf="
        currentUserEmail && currentUserEmail === '2039129309093@3123010302.com'
      "
      (click)="this.sideMenuService.restoreData()"
      href="javascript:void(0)"
    >
      Restaurar
    </a>
  </div>
</ion-footer>
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AdMob, RewardedAd, InterstitialAd } from '@admob-plus/ionic/ngx';
import { Events } from 'plugins/admob-plus-cordova/esm';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WebUtils } from '@app/util/WebUtils';

@Injectable({
  providedIn: 'root'
})
export class AdsService {
  private rewardedAd: RewardedAd;
  private interstitialAd: InterstitialAd;
  private isStart: boolean = false;

  private adUnitId: string = '';
  private interstitialAdId: string = '';

  constructor(private admob: AdMob, private platform: Platform) {}

  async init() {
    try {
      await this.platform.ready();

      if(WebUtils.isWeb()) {
        return;
      }


      const isIOS = this.platform.is('ios');

      this.adUnitId = isIOS ? environment.adUnitIdIOS : environment.adUnitId;
      this.interstitialAdId = isIOS ? environment.interstitialAdIOS : environment.interstitialAd;

      await this.admob.start().catch((e) => {});
    } catch (e) {
      console.error(e);
    }
  }

  async resolvePrefetch() {
    try {
      if (!this.isStart) {
        this.initSubscribe();
        this.rewardedAd = new this.admob.RewardedAd({
          adUnitId: this.adUnitId
        });

        this.interstitialAd = new this.admob.InterstitialAd({
          adUnitId: this.interstitialAdId
        });

        this.isStart = true;
        this.loadRewardedAd();
        this.loadInterstitialAd();
      }
    } catch (e) {
      console.error(e);
    }
  }

  private initSubscribe() {
    this.admob.on(Events.rewardedReward).subscribe((_) => {
      this.loadRewardedAd();
    });

    this.admob.on(Events.interstitialShow).subscribe((_) => {
      this.loadInterstitialAd();
    });
  }

  getRewarded() {
    return this.rewardedAd;
  }

  async loadRewardedAd() {
    const isLoaded = await this.rewardedAd.isLoaded();

    if (!isLoaded) {
      await this.rewardedAd.load();
    }

    return this.rewardedAd;
  }

  async loadInterstitialAd() {
    const isLoaded = await this.interstitialAd.isLoaded();

    if (!isLoaded) {
      await this.interstitialAd.load();
    }

    return this.interstitialAd;
  }
}

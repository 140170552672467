<div class="interview-container">
  <div class="interview-progress">
    <img class="avatar" src="{{avatar}}" />
    <div class="interview-progress-numbers" *ngIf="hasProgress">
      <div class="horizontal-line"></div>
      <div [ngClass]="first ? 'interview-progress-number-container orange-bg' : 'interview-progress-number-container'">
        <span *ngIf="first">
          <img src="assets/svg/white-check.svg" />
        </span>
        <span *ngIf="!first">1</span>
      </div>
      <div
        [ngClass]="second ? 'interview-progress-number-container interview-progress-ml orange-bg' : 'interview-progress-number-container interview-progress-ml'"
      >
        <span *ngIf="second">
          <img src="assets/svg/white-check.svg" />
        </span>
        <span *ngIf="!second">2</span>
      </div>
      <div
        [ngClass]="third ? 'interview-progress-number-container interview-progress-ml orange-bg' : 'interview-progress-number-container interview-progress-ml'"
      >
        <span *ngIf="third">
          <img src="assets/svg/white-check.svg" />
        </span>
        <span *ngIf="!third">3</span>
      </div>
    </div>
    <div class="title" *ngIf="!hasProgress">
      {{title}}
    </div>
  </div>
</div>

<div class="container-lista">
  <div>
    <p class="white-text" *ngIf="!pageParams.canSelectDiscipline">
      Informe a descrição do assunto para a disciplina
      <strong>{{ discipline?.descricao }}</strong>
    </p>
    <div [formGroup]="formTopic">
      <ion-item color="light" class="subject-abbreviation">
        <ion-input
          #inputDescription
          type="text"
          [value]="topic?.description"
          formControlName="description"
          placeholder="Descrição *"
        >
        </ion-input>
      </ion-item>

      <div class="flex-row ion-margin-bottom ion-margin-top">
        <ion-label class="color-label" style="color: white; margin-top: 0"
          >Selecione uma cor</ion-label
        >
      </div>
      <div class="flex-row">
        <ion-item
          [ngStyle]="{
            'border-left-color': selectedColor?.codigo,
            'border-left-style': 'solid',
            'border-left-width': '15px'
          }"
          class="color-picker"
          (click)="chooseColor()"
          lines="none"
        >
          <ion-label> {{ selectedColor?.nome }} </ion-label>
        </ion-item>
      </div>
    </div>
  </div>
</div>

<div class="footer ion-margin-top">
  <ion-button expand="block" shape="round" (click)="save()">Salvar</ion-button>
</div>

import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage/storage.service';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private syncNeeded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private syncState$: BehaviorSubject<{status: 'empty' | 'running' | 'done', data?: any}>;
  private lastSync: Date;

  
  constructor(private storage: StorageService) {
    this.syncNeeded.next(false);
    this.syncState$ = new BehaviorSubject({status: 'empty'});
  }

  async init() {
    const syncIsNeededLastState: boolean = await this.readSyncIsNeededState();
    this.syncNeeded.next(syncIsNeededLastState);
  }

  publishSyncingStatus(status: 'empty' | 'running' | 'done', data?: any) {
    this.syncState$.next({status, data});
  }

  get syncState() {
    return this.syncState$;
  }
    
  getSyncStatus(): BehaviorSubject<boolean> {
    return this.syncNeeded;
  }

  setSyncStatus(value: boolean, from = 'unknown'): void {
    this.saveSyncIsNeededState(value);
    this.syncNeeded.next(value);
  }

  updateLastSync(date: Date = new Date()) {
    this.lastSync = date;
    return this.storage.set('lastSync', date)
  }

  async getlLastSync() : Promise<Date|undefined> {
    if(!this.lastSync) {
      this.lastSync = await this.storage.get('lastSync');
    }
    return this.lastSync
  }

  async lastSyncIsMoreThan(days: number) {
    const lastSync = await this.getlLastSync();
    if (lastSync) {
      const momentLastSync = moment(lastSync);
      const elapsedDays = moment().diff(momentLastSync, 'days');
      return elapsedDays >= days;
    }
    return false;
  }

  async clear() {
    return this.storage.remove('lastSync');
  }

  async saveSyncIsNeededState(value:boolean) {
    this.storage.set('SYNC_IS_NEEDED', value);
  }

  async readSyncIsNeededState() : Promise<boolean> {
    const result = await this.storage.get('SYNC_IS_NEEDED');
    return !!result
  }

}

import { Injectable } from "@angular/core";
import { Ciclo } from "@models/Ciclo";
import { SyncResult } from "@models/sync-result.interface";
import { CycleService } from "@app/@services/api/cycle/cycle.service";
import { OfflineStorageService } from "@app/@services/api/offline-storage/offline-storage.service";
import { SyncService } from "@app/@services/api/sync/sync.service";
import { UserStoreService } from "@app/@services/api/user-store/user-store.service";
import { interval } from "rxjs";
import { CachingUtils } from "src/app/util/caching-utils";
import { ClearCachingService } from "../clear-caching/clear-caching.service";

@Injectable({
  providedIn: "root",
})
export class CycleCachingService {
  entityName = "cachedCycle";
  entityLastUpdate = "cachedCycleLastUpdate";
  cachedData: Array<Ciclo> = [];
  lastUpdate: Date = null;
  syncTimer = interval(60000);

  constructor(
    private clearCaching: ClearCachingService<Ciclo>,
    private offlineStorage: OfflineStorageService,
    private userStore: UserStoreService,
    private cycleProvider: CycleService,
    private syncProvider: SyncService
  ) {}

  private async getAll(): Promise<Ciclo[]> {
    const data = await this.offlineStorage.get<Ciclo[]>(this.entityName);
    return data ? data : [];
  }

  async clear(): Promise<void> {
    await this.clearCaching.clear(this);
  }

  async sync(): Promise<void> {
    let all: Ciclo[] = await this.getAll();
    const tracking = CachingUtils.buildTrackingToSync(all);
    const lastUpdate = await this.offlineStorage.get<Date>(
      this.entityLastUpdate
    );
    const syncResult = (await this.cycleProvider.syncCycles({
      email: this.userStore.user.username,
      lastUpdate: lastUpdate ? lastUpdate : null,
      toWrite: (await this.read()).filter((obj) => obj.hasPendingWrite),
      data: tracking,
    })) as SyncResult<Ciclo>;

    all = CachingUtils.mergeWithSync(all, syncResult);
    await this.offlineStorage.set(this.entityName, all);
  }

  async read(): Promise<Ciclo[]> {
    let data = await this.offlineStorage.get<Ciclo[]>(this.entityName);
    data = data ? data : [];
    return data ? data.filter((cycle) => !cycle.hasPendingDelete) : [];
  }

  async set(items: Ciclo[]) {
    await this.offlineStorage.set(this.entityName, items);
    this.syncProvider.setSyncStatus(true);
  }

  async create(object: Ciclo): Promise<void> {
    await this.offlineStorage.create<Ciclo>(this.entityName, object);
    this.syncProvider.setSyncStatus(true);
  }

  async update(object: Partial<Ciclo>): Promise<void> {
    await this.offlineStorage.update(this.entityName, object);
    this.syncProvider.setSyncStatus(true);
  }

  private async getLastUpdated(data): Promise<Ciclo | null> {
    const dataSorted = CachingUtils.orderByUpdatedAt<Ciclo>(data);

    if (dataSorted.length > 0) {
      return dataSorted[0];
    }

    return null;
  }

  async needSync(): Promise<boolean> {
    try {
      const data = await this.getAll();
      const lastUpdatedLocal_ = this.getLastUpdated(data).then((result) =>
        result ? result.updatedAt : null
      );
      const lastUpdatedAtServer_ = this.cycleProvider
        .getLastUpdatedAt(this.userStore.user.email)
        .then((result) => (result ? result.lastUpdatedAt : null));

      const hasPending_ = this.offlineStorage
        .get(this.entityName)
        .then((values: any[]) => {
          if (values && values.length > 0) {
            const value = values.find((v) => {
              const { hasPendingDelete, hasPendingWrite } = v;

              if (hasPendingDelete || hasPendingWrite) {
                return true;
              }
              return false;
            });
            if (value) {
              return true;
            }
            return false;
          }
          return false;
        });

      const hasPending = await hasPending_;

      if (hasPending) {
        return true;
      }

      const [lastUpdatedLocal, lastUpdatedAtServer] = await Promise.all([
        lastUpdatedLocal_,
        lastUpdatedAtServer_,
      ]);

      return lastUpdatedLocal !== lastUpdatedAtServer;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async find(objectId?:string) {
    const result = await this.read();
    return result.find((c)=> c.objectId === objectId);
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { CreateReviewPage } from "./create-review.page";
import { CustomDateTimeModule } from "@app/directives/custom-date-time/custom-date-time.module";
import { SharedComponentsModule } from "@app/components/shared-components.module";

@NgModule({
  imports: [
    CustomDateTimeModule,
    SharedComponentsModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [CreateReviewPage],
})
export class CreateReviewPageModule {}

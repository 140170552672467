<div class="item-select-periodo" *ngIf="tipo !== 'dia'">
  <ion-button fill="clear" (click)="voltar()">
    <ion-icon size="large" slot="icon-only" name="chevron-back-outline"></ion-icon>
  </ion-button>

  <div class="label-periodo">
    <span [hidden]="tipo != 'semana'">{{ periodoSemana }}</span>
    <span [hidden]="tipo != 'mes'">{{ periodoMes }}</span>
    <span [hidden]="tipo != 'ano'">{{ periodoAno }}</span>
  </div>

  <ion-button fill="clear" (click)="avancar()">
    <ion-icon size="large" slot="icon-only" name="chevron-forward-outline"></ion-icon>
  </ion-button>
</div>

<section id="compare-table">
    <h2 class="text-center ion-padding-vertical">
        <img width="35px" src="assets/imgs/plans-compare/coroa_high.png" />
        Conheça as vantagens
    </h2>
    <ion-grid>
        <ion-row>
            <ion-col size="6"></ion-col>
            <ion-col class="text-center">Gratuito</ion-col>
            <ion-col class="text-center premium-check">Premium</ion-col>
        </ion-row>
        <div *ngFor="let item of compareTable">
            <ion-row [class]="item.class">
                <ion-col class="text-font-custom" size="6">{{item.text}}</ion-col>
                <ion-col class="text-center">
                    <ion-icon name="{{item.iconFree}}"></ion-icon>
                </ion-col>
                <ion-col class="text-center premium-check">
                    <ion-icon name="checkmark-sharp"></ion-icon>
                </ion-col>
            </ion-row>
        </div>
    </ion-grid>
</section>
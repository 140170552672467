<app-common-header
  (leftIconTap)="dismiss()"
  (rightIconTap)="openTutorialTab()"
  [isDark]="true"
  [hasLogo]="true"
  [hasFilter]="false"
  [hasTutorial]="true"
  [enableScreenshot]="true"
>
</app-common-header>

<ion-content scrollY="false" style="max-width:100%;">

    <ion-segment mode="md" (ionChange)="onSegmentChange($event)" [(ngModel)]="tipo">
      <ion-segment-button value="dia">Dia</ion-segment-button>
      <ion-segment-button value="semana"> <ion-label>Semana        <img  src="assets/imgs/plans-compare/coroa_high.png"
        class="crown-img-two">
          </ion-label>
    
      </ion-segment-button>
      <ion-segment-button value="mes"><ion-label> Mês <img src="assets/imgs/plans-compare/coroa_high.png" class="crown-img">
        </ion-label></ion-segment-button>
      <ion-segment-button value="ano"><ion-label>Ano <img src="assets/imgs/plans-compare/coroa_high.png" class="crown-img">
        </ion-label></ion-segment-button>
    </ion-segment>
 
  <app-select-periodo
    [tipo]="tipo"
    (Voltar)="onVoltar($event)"
    (Avancar)="onAvancar($event)"
  >
  </app-select-periodo>
  <div class="container-elements" [ngSwitch]="tipo">
    <app-statistic-tab-day *ngSwitchCase="'dia'"></app-statistic-tab-day>

    <app-statistic-tab-week
      #week
      *ngSwitchCase="'semana'"
      [statistics]="statistics"
      [period]="period"
      [tipo]="tipo"
      [isTimeTabSelected]="isTimeTabSelected"
      [isExercisesTabSelected]="isExercisesTabSelected"
      [isPagesTabSelected]="isPagesTabSelected"
      [isEfficiencyTabSelected]="isEfficiencyTabSelected"
    ></app-statistic-tab-week>
    <app-statistic-tab-month
      #month
      *ngSwitchCase="'mes'"
      [statistics]="statistics"
      [period]="period"
      [tipo]="tipo"
      [isTimeTabSelected]="isTimeTabSelected"
      [isExercisesTabSelected]="isExercisesTabSelected"
      [isPagesTabSelected]="isPagesTabSelected"
      [isEfficiencyTabSelected]="isEfficiencyTabSelected"
    ></app-statistic-tab-month>
    
    <app-statistic-tab-year
      #year
      *ngSwitchCase="'ano'"
      [statistics]="statistics"
      [period]="period"
      [tipo]="tipo"
      [isTimeTabSelected]="isTimeTabSelected"
      [isExercisesTabSelected]="isExercisesTabSelected"
      [isPagesTabSelected]="isPagesTabSelected"
      [isEfficiencyTabSelected]="isEfficiencyTabSelected"
    ></app-statistic-tab-year>
    <ion-footer *ngIf="tipo === 'dia'" style="position: absolute; bottom: 0;">
    
      <ion-button (click)="onMoreGraphic()" color="primary" expand="block" shape="round"
        style="text-transform: none; max-width: 600px; margin: auto" strong>
        <ion-label style="margin-right: 5px;"> Ver mais gráficos</ion-label>
        <img src="assets/imgs/plans-compare/coroa_high.png" width="20px">
      </ion-button>
    
    </ion-footer>
  </div>
</ion-content>

<footer class="tabs" *ngIf="tipo !== 'dia'">
  <button
    class="tabs__button"
    [class.tabs__button--active]="isTimeTabSelected"
    (click)="onChangeTab(chartType.TEMPO)"
  >
    Tempo
  </button>
  <button
    class="tabs__button"
    [class.tabs__button--active]="isExercisesTabSelected"
    (click)="onChangeTab(chartType.EXERCICIOS)"
  >
    Exercícios
  </button>
  <button
    class="tabs__button"
    [class.tabs__button--active]="isPagesTabSelected"
    (click)="onChangeTab(chartType.PAGINAS)"
  >
    Páginas
  </button>
  <button
    class="tabs__button"
    [class.tabs__button--active]="isEfficiencyTabSelected"
    (click)="onChangeTab(chartType.EFICIENCIA)"
  >
    Eficiência
  </button>
</footer>

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController, NavParams } from "@ionic/angular";
import { PaymentMethod } from "@models/CreditCard";
import { GENERATED_BANKSLIP } from "@models/Tags";
import { InAppBrowserService } from "@services/api/in-app-browser/in-app-browser.service";
import { TagsService } from "@services/api/tags/tags.service";
import { UserStoreService } from "@services/api/user-store/user-store.service";
import { EventService } from "@services/event-service/event.service";
import { ModalService } from "@services/modal/modal.service";
import { StorageService } from "@services/storage/storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-post-payment",
  templateUrl: "./post-payment.page.html",
  styleUrls: ["./post-payment.page.scss"],
})
export class PostPaymentPage implements OnInit {
  duracaoPlano: string;
  expirationPlano: string;
  firstBuy: boolean;
  nomePlano: string;
  paymentMethod: PaymentMethod;
  subscription: any;
  constructor(
    private events: EventService,
    private inAppBrowser: InAppBrowserService,
    public navParams: NavParams,
    private storage: StorageService,
    private userStore: UserStoreService,
    private tagsService: TagsService,
    private modalCtrl: ModalController,
    private modalService: ModalService
  ) {
    this.duracaoPlano = this.navParams.get("duracaoPlano");
    this.expirationPlano = this.navParams.get("expirationPlano");
    this.firstBuy = this.navParams.get("firstBuy");
    this.nomePlano = this.navParams.get("nomePlano");
    this.paymentMethod = this.navParams.get("paymentMethod");
    this.subscription = this.navParams.get("subscription");
  }

  ngOnInit() {}

  async dismiss() {
    if (this.firstBuy) {
      let ratingData = await this.storage.get("ratingData");
      if (!ratingData || typeof ratingData.needRate === "undefined") {
        ratingData = {
          ratedAt: null,
          count: 0,
          purchasedAt: null,
          needRate: true,
          lastMark: null,
        };
      }

      if (ratingData.needRate) {
        ratingData.purchasedAt = new Date();
        await this.storage.set("ratingData", ratingData);
      }

      this.events.publish("update:statusAssinatura");
    }

    this.modalService.closeAll();
    await this.modalCtrl.dismiss();
  }

  ionViewDidEnter() {
    if (!this.isPaymentViaCreditCard) {
      this.tagsService.addTagsToUser(
        [
          {
            tag: GENERATED_BANKSLIP,
            unidade_1: this.subscription.current_transaction
              ? this.subscription.current_transaction.boleto_url.boleto_url
              : this.subscription.createdTransaction.boleto_url,
          },
        ],
        this.userStore.user.objectId
      );
    }
  }

  openPaymentSlip() {
    if (this.paymentMethod === PaymentMethod.BANK_PAYMENT_SLIP) {
      let link = "";
      if (this.subscription.current_transaction)
        link = this.subscription.current_transaction.boleto_url;
      else link = this.subscription.createdTransaction.boleto_url;
      this.inAppBrowser.openLinkOnBrowser(link);
    }
  }

  navigate() {
    this.inAppBrowser.openLinkOnBrowser("https://www.estudaqui.com/");
  }

  get currentUserEmail() {
    return this.userStore && this.userStore.user
      ? this.userStore.user.username
      : "";
  }

  get currentUserFullname() {
    return this.userStore && this.userStore.user
      ? this.userStore.user.fullname
      : "";
  }

  get isPaymentViaCreditCard(): boolean {
    return this.paymentMethod === PaymentMethod.CREDIT_CARD;
  }

  get appVersion() {
    return environment.appVersion
  }

  get userEmailEncoded() {
    return this.userStore && this.userStore.user.objectId ? encodeURIComponent(this.userStore.user.email) : ""
  }
}

import { Injectable } from "@angular/core";
import { GeneralSubject } from "@models/Subject";
import { StorageService } from "@app/@services/storage/storage.service";
import { interval } from "rxjs";
import { CachingUtils } from "src/app/util/caching-utils";
import { ClearCachingService } from "../caching/clear-caching/clear-caching.service";
import { DisciplinasService } from "../disciplinas/disciplinas.service";
import { OfflineStorageService } from "../offline-storage/offline-storage.service";
import StringUtils from "@app/util/StringUtils";

@Injectable({
  providedIn: 'root'
})
export class GeneralSubjectCachingService {
  entityName = 'cachedGeneralSubjects';
  lastUpdate: Date = null;
  cachedData: Array<GeneralSubject> = [];
  syncTimer = interval(60000);

  constructor(
    private clearCaching: ClearCachingService<GeneralSubject>,
    private subjectService: DisciplinasService,
    private storage: StorageService,
    private offlineStorage: OfflineStorageService
  ) {}

  async clear() {
    return this.clearCaching.clear(this);
  }

  private async getAll(): Promise<GeneralSubject[]> {
    const data = await this.offlineStorage.get<Array<GeneralSubject>>(this.entityName);
    return data ? data : [];
  }

  async sync(): Promise<void> {
    try {
      let all: GeneralSubject[] = await this.getAll();
      const [, lastUpdate] = await Promise.all([this.read(), this.getLastUpdate()]);
      await this.read();
      const result = (await this.subjectService.syncGeneralSubjects(lastUpdate)) as Array<GeneralSubject>;
      this.lastUpdate = new Date();
      all = CachingUtils.mergeWithSync(all, {
        doUpdateOrCreate: result,
        doDelete: [],
        syncDate: new Date()
      });

      await this.storage.set(this.entityName, all);
      await this.storage.set(`${this.entityName}LastUpdate`, this.lastUpdate);
    } catch (err) {
      console.log(err);
    }
  }

  async read(): Promise<Array<GeneralSubject>> {
    const data = await this.offlineStorage.get<Array<GeneralSubject>>(this.entityName);
    return data ? data.filter((s) => !s.hasPendingDelete && s.descricao) : [];
  }

  private async getLastUpdate() {
    return this.lastUpdate || (await this.storage.get(`${this.entityName}LastUpdate`));
  }

  async findGeneralSubject(where: {
    objectId?: string,
    descricaoBusca?: string
  },  generalSubjects?: GeneralSubject[]): Promise<GeneralSubject | null> {
    const collection = generalSubjects || (await this.read());
    let response: GeneralSubject | null = null;
    
    if(where.objectId) {
      response = await this.findGeneralSubjectByObjectId(where.objectId, collection); 
    }

    if(!response && where.descricaoBusca) {
      response = await this.findGeneralSubjectByObjectId(where.descricaoBusca, collection);
    }

    return response;
  }

  async findGeneralSubjectByObjectId(
    objectId: string,
    generalSubjects?: GeneralSubject[]
  ): Promise<GeneralSubject | null> {
    const collection = generalSubjects || (await this.read());
    return collection.find((generalSubject) => generalSubject.objectId === objectId);
  }

  async findGeneralByDescricaoBusca(descricaoBusca : string, generalSubjects?: GeneralSubject[]) : Promise<GeneralSubject | null> {
    const collection = generalSubjects || (await this.read());
    descricaoBusca = StringUtils.normalize(descricaoBusca);
    return collection.find((generalSubject)=> StringUtils.normalize(generalSubject.Busca_disciplina) === descricaoBusca);
  }

}

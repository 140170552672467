import { Injectable } from "@angular/core";
import { HammerGestureConfig } from "@angular/platform-browser";

// custom configuration Hammerjs
@Injectable()
export class HammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    if (window) {
      const mc = new (<any>window).Hammer(element);

      for (const eventName in this.overrides) {
        if (eventName) {
          mc.get(eventName).set(this.overrides[eventName]);
        }
      }
      return mc;
    }
  }
}

import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { StatisticHistoryTabPage } from "./statistic-history-tab.page";
import { SharedComponentsModule } from "@app/components/shared-components.module";
import { CustomDateTimeModule } from "@app/directives/custom-date-time/custom-date-time.module";
import { HistoryCardComponent } from "./history-card/history-card.component";
import { HistoryFilterModalComponent } from "./history-filter-modal/history-filter-modal.component";
import { HistoryChipFiltersComponent } from "./history-chip-filters/history-chip-filters.component";
import { SearchSubjectModalComponent } from "./history-filter-modal/search-subject-modal/search-subject-modal.component";
import { SharedModule } from "@app/shared/shared.module";
import { PipesModule } from "src/theme/pipes/pipes.module";

@NgModule({
  imports: [
    PipesModule,
    CustomDateTimeModule,
    SharedComponentsModule,
    SharedModule,
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [SearchSubjectModalComponent, HistoryChipFiltersComponent, HistoryFilterModalComponent, HistoryCardComponent, StatisticHistoryTabPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class StatisticHistoryTabPageModule {}

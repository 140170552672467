import { Injectable } from '@angular/core';
import { GeneralSubject, SubjectItem, UserSubject } from '@models/Subject';
import { BehaviorSubject } from 'rxjs';
import { UserSubjectCachingService } from '../caching/user-subject-caching/user-subject-caching.service';
import { GeneralSubjectCachingService } from '../general-subject-caching/general-subject-caching.service';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {
  private _subjectItems: BehaviorSubject<Array<SubjectItem>> = new BehaviorSubject([]);
  private _userSubjectItems: BehaviorSubject<Array<SubjectItem>> = new BehaviorSubject([]);

  constructor(
    private userSubjectCachingService: UserSubjectCachingService,
    private generalSubjectCachingService: GeneralSubjectCachingService
  ) {}

  get subjectItems(): Array<SubjectItem> {
    return this._subjectItems.getValue();
  }

  get userSubjectItems(): Array<SubjectItem> {
    return this._userSubjectItems.getValue();
  }

  async loadSubjectItems() {
    await this.fetchGeneralSubjects();
  }

  async loadOnlyDisciplinasGerais() {
    await this.fetchOnlyDisciplinasGerais();
  }

  async reset() {
    await this.fetchGeneralSubjects();
  }

  private async fetchGeneralSubjects() {
    try {
      const generalSubjects = await this.generalSubjectCachingService.read();
      const userSubjects: Array<UserSubject> = (await this.userSubjectCachingService.getAll());
      const userSubjectItems: Array<SubjectItem> = [];

      if (userSubjects) {
        for (let i = 0; i < userSubjects.length; i++) {
          const subject = userSubjects[i];
          userSubjectItems.push({
            objectId: subject.objectId,
            localObjectId: subject.localObjectId,
            id: -1,
            time: '00:00',
            excluida: subject.excluida || subject.hasPendingDelete,
            subjectSearch: subject.descricao_busca,
            finished: false,
            color: subject.cor,
            shortName: subject.abreviatura,
            name: subject.descricao,
            type: subject.tipo,
            freeSubject: true,
            userSubject: true,
            lastUserSubject: false,
            displayName: subject.descricao,
            generalSubject: subject.disciplina_geral
          });
        }
      }

      const generalSubjectItems: Array<SubjectItem> = generalSubjects.map((subject: GeneralSubject) => {
        return {
          objectId: subject.objectId,
          id: -1,
          time: '00:00',
          type: subject.tipo,
          finished: false,
          color: subject.color,
          subjectSearch: subject.Busca_disciplina,
          shortName: subject.abreviatura,
          name: subject.descricao,
          freeSubject: true,
          userSubject: false,
          displayName: subject.descricao
        };
      });

      userSubjectItems.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
      if (userSubjectItems.length > 0) userSubjectItems[userSubjectItems.length - 1].lastUserSubject = true;

      this._userSubjectItems.next([...userSubjectItems]);
      userSubjectItems.push(...generalSubjectItems);
      this._subjectItems.next(userSubjectItems);
    } catch (err) {
      console.log(err);
      this._subjectItems.next(undefined);
    }
  }

  private async fetchOnlyDisciplinasGerais() {
    try {
      const generalSubjects = await this.generalSubjectCachingService.read();

      const generalSubjectItems: Array<SubjectItem> = generalSubjects.map((subject: GeneralSubject) => {
        return {
          objectId: subject.objectId,
          id: -1,
          time: '00:00',
          finished: false,
          color: subject.color,
          shortName: subject.abreviatura,
          name: subject.descricao,
          freeSubject: true,
          userSubject: false,
          displayName: subject.descricao
        } as SubjectItem;
      });

      this._subjectItems.next(generalSubjectItems);
    } catch (err) {
      console.log(err);
      this._subjectItems.next(undefined);
    }
  }

  findUserSubjectByDescription(subjectItem: SubjectItem): SubjectItem {
    return this.userSubjectItems.find((item) => item.subjectSearch === this.normalizeString(subjectItem.subjectSearch));
  }

  findUserSubjectByName(subjectItem: SubjectItem): SubjectItem {
    return this.userSubjectItems.find((item) => this.normalizeString(item.name) === this.normalizeString(subjectItem.name));
  }
  

  private normalizeString(txt: string) {
    return txt
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();
  }
}

import { Injectable } from "@angular/core";
import { CycleBlock } from "@models/Ciclo";
import { CycleBlockDto } from "@models/CycleBlockDto";
import { SyncResult } from "@models/sync-result.interface";
import { CycleService } from "@app/@services/api/cycle/cycle.service";
import { OfflineStorageService } from "@app/@services/api/offline-storage/offline-storage.service";
import { SyncService } from "@app/@services/api/sync/sync.service";
import { UserStoreService } from "@app/@services/api/user-store/user-store.service";
import { interval, BehaviorSubject } from "rxjs";
import { CachingUtils } from "src/app/util/caching-utils";
import { ClearCachingService } from "../clear-caching/clear-caching.service";
import { CycleCachingService } from "../cycle-caching/cycle-caching.service";
import { UserSubjectCachingService } from "../user-subject-caching/user-subject-caching.service";

@Injectable({
  providedIn: "root",
})
export class CycleBlocksCachingService {
  entityName = "cachedCycleBlocks";
  cachedData: Array<CycleBlock> = [];
  lastUpdate: Date = null;
  syncJobEnabled = true;
  syncTimer = interval(60000);
  cycleBlockDto: CycleBlockDto = {
    cycleIds: [],
  };
  hasDataToLoad = true;
  isSyncing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private clearCaching: ClearCachingService<CycleBlock>,
    private offlineStorage: OfflineStorageService,
    private cycleService: CycleService,
    private cycleCachingService: CycleCachingService,
    private userSubjectCachingService: UserSubjectCachingService,
    private syncService: SyncService,
    private userStore: UserStoreService
  ) {}

  private async getAll(): Promise<CycleBlock[]> {
    const data = await this.offlineStorage.get<Array<CycleBlock>>(
      this.entityName
    );
    return data ? data : [];
  }

  async clear(): Promise<void> {
    await this.clearCaching.clear(this);
  }

  private clearResult(syncResult: SyncResult<CycleBlock>) {
    syncResult.doUpdateOrCreate.forEach((r) => {
      delete r.user;
      if (r.disciplina_usuario) delete r.disciplina_usuario.user;
    });
  }

  private async updateSubjectOfBlock(
    blocks: CycleBlock[],
    subjectIds: string[],
    subjectLocalIds: string[]
  ) {
    const allSubjects = await this.userSubjectCachingService.read();
    const userSubjects = allSubjects.filter(
      (subject) =>
        subjectIds.includes(subject.objectId) ||
        subjectLocalIds.includes(subject.localObjectId)
    );
    blocks.forEach((block) => {
      if (
        block.disciplina_usuario &&
        (subjectIds.includes(block.disciplina_usuario.objectId) ||
          subjectLocalIds.includes(block.disciplina_usuario.localObjectId))
      ) {
        const newSubject = userSubjects.find((subject) => {
          if (subject.objectId && block.disciplina_usuario.objectId)
            return subject.objectId === block.disciplina_usuario.objectId;
          return (
            subject.localObjectId === block.disciplina_usuario.localObjectId
          );
        });
        if (newSubject) block.disciplina_usuario = newSubject;
      }
    });
  }

  private async fetchBlocksToWrite(): Promise<CycleBlock[]> {
    const blocksToWrite: CycleBlock[] = this.cachedData.filter(
      (block) => block.hasPendingWrite
    );
    const subjectIds: string[] = blocksToWrite
      .filter(
        (block) => block.disciplina_usuario && block.disciplina_usuario.objectId
      )
      .map((block) => block.disciplina_usuario.objectId);
    const subjectLocalIds: string[] = blocksToWrite
      .filter(
        (block) =>
          block.disciplina_usuario && block.disciplina_usuario.localObjectId
      )
      .map((block) => block.disciplina_usuario.localObjectId);
    await this.updateSubjectOfBlock(blocksToWrite, subjectIds, subjectLocalIds);
    return blocksToWrite.map((block) => {
      delete block.hasPendingWrite;
      return block;
    });
  }

  private async fetchBlocksToDestroy(): Promise<CycleBlock[]> {
    const blocksToDelete: CycleBlock[] = this.cachedData.filter(
      (block) => block.hasPendingDelete
    );
    return blocksToDelete.map((block) => {
      delete block.hasPendingDelete;
      return block;
    });
  }

  private async setLastUpdate(cycleBlocks: CycleBlock[]) {
    if (cycleBlocks.length === 0) return;
    const maxUpdated = Math.max(
      ...cycleBlocks.map((block) => new Date(block.updatedAt).getTime())
    );
    if (typeof maxUpdated === "number" && isFinite(maxUpdated))
      await this.offlineStorage.set(
        `${this.entityName}LastUpdate`,
        new Date(maxUpdated)
      );
  }

  async sync(): Promise<CycleBlock[]> {
    try {
      let all: CycleBlock[] = await this.getAll();

      const lastDate = await this.offlineStorage.get(
        `${this.entityName}LastUpdate`
      );
      this.cachedData = all;
      this.isSyncing.next(true);
      this.cycleBlockDto.page = 0;
      this.cycleBlockDto.cycleIds = (await this.cycleCachingService.read()).map(
        (cycle) => cycle.objectId
      );
      this.cycleBlockDto.lastUpdate = lastDate ? (lastDate as Date) : null;
      this.cycleBlockDto.toWrite = await this.fetchBlocksToWrite();
      this.cycleBlockDto.toDelete = await this.fetchBlocksToDestroy();
      this.cycleBlockDto.data = CachingUtils.buildTrackingToSync(all);

      const syncResult = (await this.cycleService.syncCycleBlocks(
        this.cycleBlockDto
      )) as SyncResult<CycleBlock>;
      this.clearResult(syncResult);
      all = CachingUtils.mergeWithSync(all, syncResult);
      this.cycleBlockDto.page++;
      await this.offlineStorage.set(this.entityName, all);
      this.cachedData = all;
      return all;
    } catch (e) {
      console.log(e);
      this.isSyncing.next(false);
    }
  }

  async mergeBlocks(blocks: CycleBlock[]) {
    this.cachedData = await this.read();
    blocks.forEach((block) => {
      delete block.user;
      if (block.disciplina_usuario) delete block.disciplina_usuario.user;

      const cachedCycleBlock: CycleBlock = this.cachedData.find(
        (s) => s.objectId == block.objectId
      );
      if (cachedCycleBlock) {
        const cachedIndex = this.cachedData
          .map((eBlock) => eBlock.objectId)
          .indexOf(block.objectId);
        const mergedBlock: CycleBlock = Object.assign(cachedCycleBlock, block);
        this.cachedData[cachedIndex] = mergedBlock;
      } else {
        this.cachedData.unshift(block);
      }
    });

    await this.offlineStorage.set(this.entityName, this.cachedData);
    await this.setLastUpdate(this.cachedData);
  }

  async read(): Promise<CycleBlock[]> {
    let data = await this.offlineStorage.get<CycleBlock[]>(this.entityName);
    data = data ? data : [];
    return data ? data.filter((cycle) => !cycle.hasPendingDelete) : [];
  }

  async set(items: CycleBlock[]): Promise<void> {
    items.forEach((item) => delete item.needUpdate);
    await this.offlineStorage.set(this.entityName, items);
    await this.setLastUpdate(items);
  }

  async create(object: CycleBlock): Promise<void> {
    await this.offlineStorage.create<CycleBlock>(this.entityName, object);
    this.syncService.setSyncStatus(true);
  }

  async delete(object: Partial<CycleBlock>): Promise<void> {
    await this.offlineStorage.delete(this.entityName, object);
    this.syncService.setSyncStatus(true);
  }

  async update(object: Partial<CycleBlock>): Promise<void> {
    delete object.needUpdate;
    await this.offlineStorage.update(this.entityName, object);
    this.syncService.setSyncStatus(true);
  }

  async needSync(): Promise<boolean> {
    try {
      const data = await this.getAll();
      const lastUpdatedLocal_ = this.getLastUpdated(data).then((result) =>
        result ? result.updatedAt : null
      );
      const lastUpdatedAtServer_ = this.cycleService
        .getBlockLastUpdatedAt(this.userStore.user.email)
        .then((result) => (result ? result.lastUpdatedAt : null));

      const hasPending_ = Promise.resolve(data).then((values: any[]) => {
        if (values && values.length > 0) {
          const value = values.find((v) => {
            const { hasPendingDelete, hasPendingWrite } = v;

            if (hasPendingDelete || hasPendingWrite) {
              return true;
            }
            return false;
          });
          if (value) {
            return true;
          }
          return false;
        }
        return false;
      });

      const hasPending = await hasPending_;

      if (hasPending) {
        return true;
      }

      const [lastUpdatedLocal, lastUpdatedAtServer] = await Promise.all([
        lastUpdatedLocal_,
        lastUpdatedAtServer_,
      ]);

      return lastUpdatedLocal !== lastUpdatedAtServer;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private async getLastUpdated(data): Promise<CycleBlock | null> {
    const dataSorted = CachingUtils.orderByUpdatedAt<CycleBlock>(data);

    if (dataSorted.length > 0) {
      return dataSorted[0];
    }

    return null;
  }
}

import { Injectable } from '@angular/core';
import { AlertController, IonRouterOutlet, ModalController, Platform } from '@ionic/angular';
import { debounceTime } from 'rxjs/operators';

import { AnalyticsEventEnum } from '@models/AnalyticsEventEnum';
import { EstudoService } from '@services/api/estudo/estudo.service';
import { InAppBrowserService } from '@services/api/in-app-browser/in-app-browser.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { LoadingService } from '@services/loading/loading.service';
import { ModalService } from '@services/modal/modal.service';
import { NetworkService } from '@services/utils/network.service';
import { CurrentSubscriptionIosPage } from './subscriptions/current-subscription-ios/current-subscription-ios.page';
import { CurrentSubscriptionPage } from './subscriptions/current-subscription/current-subscription.page';
import { PlanIosListPage } from './subscriptions/plan-ios-list/plan-ios-list.page';
import Swal from 'sweetalert2';
import { CycleCachingService } from '@services/api/caching/cycle-caching/cycle-caching.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '@services/storage/storage.service';
import { CheckoutSelectAndroidPlanPage } from './subscriptions/checkout-select-android-plan/checkout-select-android-plan.page';
import { OneSignalService } from '@services/one-signal/one-signal.service';
import { SentryService } from './core/sentry.service';
import { ErrorReportService } from '@services/error-report/error-report.service';
import { SyncService } from '@services/api/sync/sync.service';
import { InAppPurchaseService } from '@services/in-app-purchase/in-app-purchase.service';
import { BackButtonService } from '@services/back-button/back-button.service';
import { SessionExpiredService } from '@services/session-expired/session-expired.service';
import { ParseService } from '@services/api/parse/parse.service';
import { Severity } from '@sentry/types';
import { RatingService } from '@services/rating/rating.service';
import { AdsService } from '@services/ads/ads.service';
import { EventService } from '@services/event-service/event.service';
import { WebUtils } from './util/WebUtils';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { FirebaseMessagingService } from '@services/firebase-messaging/firebase-messaging.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  protected Swal = Swal;
  public isIOS: boolean = false;

  constructor(
    public alertCtrl: AlertController,
    public modalCtrl: ModalController,
    public modalController: ModalController,
    public networkService: NetworkService,
    public estudoService: EstudoService,
    public cycleCachingService: CycleCachingService,
    public userStoreService: UserStoreService,
    public inAppBrowserService: InAppBrowserService,
    public loadingService: LoadingService,
    public platform: Platform,
    private modalService: ModalService,
    private storageService: StorageService,
    private oneSignalService: OneSignalService,
    private syncService: SyncService,
    private inAppPurchaseService: InAppPurchaseService,
    private backButtonService: BackButtonService,
    private sessionExpiredService: SessionExpiredService,
    private parseService: ParseService,
    private sentryService: SentryService,
    private errorReportService: ErrorReportService,
    private ratingService: RatingService,
    private adsService: AdsService,
    private eventService: EventService,
    private analyticsService: AnalyticsService,
    private firebaseMessagingService: FirebaseMessagingService
    ) {}

  async init(routerOutlet: IonRouterOutlet) {
    await this.platform.ready();

    this.initForceSync();
    this.sentryService.initialize();
    await this.resolveTrialWelcome(); 
    this.oneSignalService.init();
    this.backButtonService.init(routerOutlet);
    await this.syncService.init();
    this.isIOS = this.platform.is('ios');
    
    this.firebaseMessagingService.requestPermission();

    if(!WebUtils.isWeb()) {
      this.adsService.init();
      this.inAppPurchaseService.init();
      this.ratingService.init();
    }

  }

  private initForceSync() {
    if(WebUtils.isWeb()) {
      this.syncService.getSyncStatus()
      .pipe(debounceTime(1750))
      .subscribe((state)=> {
        if(state) {
         this.eventService.publish('doSync')          
        }
      })
    }
  }

  private async resolveTrialWelcome() {
    const showTrial = await this.storageService.get('show-trial');
    if (showTrial === null) {
      await this.storageService.set('show-trial', true);
    }
  }

  async openMySubscription() {
    this.analyticsService.logEvent(AnalyticsEventEnum.BT_MY_SUBSCRIPTION);
    this.networkService.executeIfOnline(async () => {
      await this.loadingService.show();

      try {
        const user = this.userStoreService.user;
        const params = { userId: user.objectId };

        if (this.isIOS) {
          if (user.planoAtual.id_moip !== 'Estudaqui_Trial') {
            const modal = await this.modalController.create({
              component: CurrentSubscriptionIosPage,
              cssClass: 'sd modal-transparent',
              componentProps: { ...params }
            });
            await modal.present();
          } else {
    
            const modal = await this.modalController.create({
              component: PlanIosListPage,
              cssClass: 'sd modal-transparent',
              componentProps: { ...params }
            });
            await modal.present();
          }
        } else {
          if (user.planoAtual.id_moip === 'Estudaqui_Trial') {
            const modal = await this.modalController.create({
              component: CheckoutSelectAndroidPlanPage,
              cssClass: 'sd modal-transparent',
              componentProps: { ...params }
            });
            this.modalService.storeModal(modal);
            modal.present();
            // this.nav.push('CheckoutSelectPlanPage', params);
          } else {
            const modal = await this.modalController.create({
              component: CurrentSubscriptionPage,
              cssClass: 'sd modal-transparent',
              componentProps: params
            });
            this.modalService.storeModal(modal);
            modal.present();
          }
        }
      } catch (err) {
        console.error(err);
      }

      await this.loadingService.dismiss();
    });
  }

  async showSyncErrorDialog(jsonErr = '') {
    try {
      const error = JSON.parse(jsonErr);
      const { message } = error;
      if (message === 'Invalid session token') {
        this.sessionExpiredService.execute();
        return;
      }
    } catch (e) {
      console.error(e);
    }
    
    const userEmail = this.userStoreService.user?.email ?? ""; 
    const subject: string = encodeURIComponent(`Erro ao sincronizar dados - Versão ${environment.appVersion}`);
    const body: string = encodeURIComponent(`Houve um erro durante a sincronização dos dados\n\nemail: ${userEmail}\n\nErro: ${jsonErr}`);
    Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'ENTENDI',
      confirmButtonColor: '#3085d6',
      showCancelButton: false,
      title: 'Alerta',
      html: `<p class="sweet-alert-text">Erro de sincronização, feche o aplicativo e tente novamente.</p>
      <p class="sweet-alert-text">Se o erro persistir nos avise <a href="mailto:suporte@estudaqui.com?subject=${subject}&body=${body}" class="msg-email">clicando aqui</a>.</p>`
    });

    this.errorReportService.sendReport({
      sentry: {
        err: JSON.parse(jsonErr),
        tags: { fn_a: AnalyticsEventEnum.ERROR_SYNC_POPUP },
        level: Severity.Critical
      },
      amplitude: {
        event: AnalyticsEventEnum.ERROR_SYNC_POPUP
      }
    });
  }

  public async resolveShowFiveDaysDialog(): Promise<void> {
    const isNeedShowDialog = await this.syncService.lastSyncIsMoreThan(5);

    if (isNeedShowDialog) {
      this.Swal.fire({
        heightAuto: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: 'ENTENDI',
        confirmButtonColor: '#3085d6',
        showCancelButton: false,
        title: 'Sincronize seus dados',
        html: `<p class="sweet-alert-text">Já são 5 dias da última sincronização desse aparelho com nosso servidor.</p>
        <p class="sweet-alert-text">Conecte-se à internet e sincronize seus dados o quanto antes, para evitar perder alterações recentes.</p>`
      });
    }
  }

  public async resolveSessionExpired() {
    const user = await this.userStoreService.getUser();
    const userParse = await this.parseService.currentUser();

    if (user && !userParse) {
      await this.sessionExpiredService.execute();
    }
  }
}

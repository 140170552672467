<div class="container-metas-total">
  <div class="metas-total">
    <div class="container-info">
      <div class="container-img item-col-1">
        <img class="img-1" src="assets/imgs/icon-pause-eficiencia.png" />
        <app-percentage-circle [percent]="100"></app-percentage-circle>
      </div>
    </div>
    <div class="container-time">
      <span>Pausado</span>
      <span>{{ timeStopped }}</span>
    </div>
  </div>
  <div class="metas-total">
    <div class="container-info">
      <div class="container-img item-col-2">
        <img class="img-2" src="assets/imgs/icon-eficiencia.png" />
        <app-percentage-circle [percent]="100"></app-percentage-circle>
      </div>
    </div>
    <div class="container-time">
      <span>Líquido</span>
      <span>{{ timeEffectiveRatio }}%</span>
    </div>
  </div>
</div>
<div class="slides-time-chart">
  <ion-slides
    [ngClass]="{ 'has-charts': hasStatistics }"
    pager
    (ionSlidesDidLoad)="slideChanged($event)"
    (ionSlideDidChange)="slideChanged($event)"
  >
    <ion-slide [ngClass]="{ 'has-chart': hasStatistics }" class="ion-no-padding ion-padding-top">
      <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">
        <div class="chart" id="chart-1"></div>
      </div>
      <div class="empty-grafico ion-padding" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve utilização do cronômetro nesse período<br /><span
            class="small"
            >(exemplo do gráfico abaixo)</span
          >
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/eficiencia-1.png" alt="" />
        </figure>
      </div>
    </ion-slide>

    <ion-slide [ngClass]="{ 'has-chart': hasStatistics }" class="ion-no-padding ion-padding-top">
      <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">  
        <div class="chart" id="chart-2" *ngIf="hasStatistics"></div>
      </div>
      <div class="empty-grafico ion-padding" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve utilização do cronômetro nesse período<br /><span
            class="small"
            >(exemplo do gráfico abaixo)</span
          >
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/eficiencia-2.png" alt="" />
        </figure>
      </div>
    </ion-slide>

    <ion-slide [ngClass]="{ 'has-chart': hasStatistics }" class="ion-no-padding ion-padding-top">
      <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">
        <div class="chart" id="chart-3"></div>
      </div>
      <div class="empty-grafico ion-padding" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve utilização do cronômetro nesse período<br /><span
            class="small"
            >(exemplo do gráfico abaixo)</span
          >
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/eficiencia-3.png" alt="" />
        </figure>
      </div>
    </ion-slide>
  </ion-slides>
</div>

import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loading: HTMLIonLoadingElement = null;
  private isShowing = false;
  constructor(private loadingCtrl: LoadingController) { }

  async show(content?: string, canDismiss = false) {
    if (!this.isShowing) {
      this.isShowing = true;

      this.loading = await this.loadingCtrl.create({
        message: content ? content : 'Carregando...',
        showBackdrop: true,
        backdropDismiss: canDismiss,
      });

      await this.loading.present();
    }
    return this.loading;
  }

  async dismiss() {
    if (this.isShowing) {
      await this.loading.dismiss().catch(()=>{});
      this.isShowing = false;
      this.loading = null;
    }
  }
}

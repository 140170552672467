<app-close-popup></app-close-popup>

<ion-content class="ion-no-padding ion-no-border" fullscreen="true">
  <div class="container">
    <app-chat-progress [avatar]="avatar" [title]="title"></app-chat-progress>
    <app-chat-messages></app-chat-messages>

    <div class="footer-buttons">
      <ion-button class="button-with-icon" (click)="openTutorialOnYouTube()">
        <img class="img-icon" src="assets/icon/youtube.png" alt="YouTube icon" />
        <span *ngIf="!youtubeTextHTML">Ver tutorial<br />dessa tela</span>
        <span *ngIf="youtubeTextHTML"><div [innerHTML]="youtubeTextHTML"></div></span>
      </ion-button>

      <ion-button class="underline-button" (click)="dismiss()">
        Obrigado, já sei usar
      </ion-button>
    </div>
  </div>
</ion-content>
import { Component, Input, OnInit } from '@angular/core';
import { BotProgress } from '@models/InterviewBot';

@Component({
  selector: 'app-chat-progress',
  templateUrl: './chat-progress.component.html',
  styleUrls: ['./chat-progress.component.scss'],
})
export class ChatProgressComponent {

  @Input()
  avatar: string;
  @Input()
  title: string;
  @Input()
  progress: BotProgress;

  constructor() {}

  get avatarSrc() {
    return this.avatar ? this.avatar : 'assets/imgs/metas/avatar-1.png';
  }

  get hasProgress() {
    return this.progress;
  }

  get first() {
    return this.progress && this.progress.first;
  }

  get second() {
    return this.progress && this.progress.second;
  }

  get third() {
    return this.progress && this.progress.third;
  }

  get fourth() {
    return this.progress && this.progress.fourth;
  }

}

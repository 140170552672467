import { Injectable } from "@angular/core";
import { CachedItem } from "@models/cached-item.interface";
import { Statistic } from "@models/Statistic";
import { SyncResult } from "@models/sync-result.interface";
import { ParseService } from "../parse/parse.service";
import { TopicsService } from "../topics/topics.service";

export interface StatisticDto {
  email: string;
  start: string;
  end: string;
  lastUpdate?: Date;
  lastObjectUpdatedDate?: Date;
  page?: number;
  toWrite?: Statistic[];
  deleted?: Statistic[];
  data?: CachedItem[];
}

@Injectable({
  providedIn: "root",
})
export class EstudoService {
  constructor(
    private parseService: ParseService,
    private topicsService: TopicsService
  ) {}

  estatisticaSubscription() {
    return this.parseService.subscribe("estatistica").then((res) => {
      console.log(res);
      return res;
    });
  }

  updateCicloAtivo(userId, cicloId): Promise<any> {
    return this.parseService.run("updateCicloAtivo", {
      userId: userId,
      cicloId: cicloId,
    });
  }

  getConcursos(params?: { lastUpdate: Date }): Promise<any> {
    return this.parseService.run("getConcursos", {
      lastUpdate: params ? params.lastUpdate : null,
    });
  }

  async getTiposEstudo(): Promise<any> {
    return this.parseService.run("getTiposEstudo");
  }

  syncStudyTypes(lastUpdate: Date): Promise<any> {
    return this.parseService.run("syncStudyTypes", {
      searchDto: {
        lastUpdate: lastUpdate,
      },
    });
  }

  getLogicaCiclo(obj): Promise<any> {
    return this.parseService.run("getCicloDist", obj);
  }

  createEstatistica(obj): Promise<any> {
    return this.parseService.run("createEstatistica", obj);
  }

  updateCycle(ciclo): Promise<any> {
    return this.parseService.run("updateCycle", ciclo);
  }

  createCiclo(obj): Promise<any> {
    return this.parseService.run("createCycle", obj);
  }

  findCycleByGuid(guid): Promise<any> {
    return this.parseService.run("findCycleByGuid", { guid });
  }

  findCycleByEmail(ciclo): Promise<any> {
    return this.parseService.run("findCycleByEmail", ciclo);
  }

  deleteCiclo(ciclo): Promise<any> {
    return this.parseService.run("deleteCiclo", ciclo);
  }

  createCicloError(obj): Promise<any> {
    return this.parseService.run("createCicloError", obj);
  }

  getEstatisticasByKey(ciclo): Promise<any> {
    return this.parseService.run("getEstatisticasByKey", ciclo);
  }

  getEstatisticasPage(estatistica): Promise<any> {
    return this.parseService.run("getEstatisticasPage", estatistica);
  }

  getEstatisticasByData(estatistica): Promise<any> {
    return this.parseService.run("getEstatisticasByData", estatistica);
  }

  getEstatisticasByName(estatistica): Promise<any> {
    return this.parseService.run("getEstatisticasByName", estatistica);
  }

  getEstatisticas(estatistica): Promise<any> {
    return this.parseService.run("getEstatisticas", estatistica);
  }

  updateEstatisticas(estatistica): Promise<any> {
    return this.parseService.run("updateEstatistica", estatistica);
  }

  deleteEstatisticas(estatistica): Promise<any> {
    return this.parseService.run("deleteEstatistica", estatistica);
  }

  deleteAllEstatistics(params): Promise<any> {
    return this.parseService.run("deleteAllStatistics", params);
  }

  getEstatisticasByDate(estatistica): Promise<any> {
    return this.parseService.run("getEstatisticasByDate", estatistica);
  }

  syncStatistics(statisticDto: StatisticDto): Promise<SyncResult<Statistic>> {
    return this.parseService
      .run("syncStatisticsOffline_v3", {
        statisticDto,
        preventInclude: true,
      })
      .then(async (result: SyncResult<Statistic>) => {
        const topicsMap =
          await this.topicsService.getTopicsAsMapWithObjectIdKey();
        result.doUpdateOrCreate.forEach((statistic) => {
          if (statistic.topic && statistic.topic.objectId)
            statistic.topic = topicsMap.get(statistic.topic.objectId);
        });
        return result;
      });
  }

  getLastUpdatedAtStatistic(email: string) {
    return this.parseService.run("getStatisticLastUpdatedAt", { email });
  }

  getPlanos(): Promise<any> {
    return this.parseService.run("getPlanos");
  }

  getTutoriaisByPagina(tutoriais): Promise<any> {
    return this.parseService.run("getTutoriais", tutoriais);
  }

  getPesquisas(): Promise<any> {
    return this.parseService.run("getPesquisas");
  }
}

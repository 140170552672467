import { Pipe, PipeTransform } from '@angular/core';
import { UserSubject } from '@models/Subject';
import { UserSubjectCachingService } from '@services/api/caching/user-subject-caching/user-subject-caching.service';

@Pipe({
  name: 'corCodeFromUserSubject'
})
export class CorCodeFromUserSubject implements PipeTransform {
  constructor(private userSubjectCachingProvider: UserSubjectCachingService) {}

  async transform(objectId: string, userSubjects?: UserSubject[]): Promise<string> {
    const found = await this.userSubjectCachingProvider.findUserSubjectByObjectId(objectId, userSubjects);
    return found?.cor?.codigo || '#9c8570';
  }
}

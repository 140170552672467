import { Injectable } from "@angular/core";
import { ParseService } from "@app/@services/api/parse/parse.service";

@Injectable({
  providedIn: "root",
})
export class PagarmeService {
  constructor(private parseService: ParseService) {}

  findSubcription(subscriptionId: number) {
    return this.parseService.run("pagarmeFindSubscription", {
      subscription: {
        subscriptionId,
      },
    });
  }

  createSubscription(subscription: any): Promise<any> {
    return this.parseService.run("pagarmeCreateSubscription", {
      assinatura: subscription,
    });
  }

  upgradeSubscription(
    subscription: any,
    subscriptionObjectId: string
  ): Promise<any> {
    return this.parseService.run("pagarmeUpgradeAssinatura", {
      subscription: {
        ...subscription,
        assinaturaObjectId: subscriptionObjectId,
      },
    });
  }

  validateCard(card): Promise<any> {
    return this.parseService.run("pagarmeValidateCard", { card: card });
  }

  cancelSubscription(subscriptionId: number) {
    return this.parseService.run("pagarmeCancelSubscription", {
      subscription: {
        subscriptionId,
      },
    });
  }
}

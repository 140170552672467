import { Injectable } from '@angular/core';
import { PlansComparePage } from '@app/pages/plans-compare/plans-compare.page';
import { WebUtils } from '@app/util/WebUtils';
import { ModalController, Platform } from '@ionic/angular';
import { ParseService } from '@services/api/parse/parse.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { EventService } from '@services/event-service/event.service';
import { LoadingService } from '@services/loading/loading.service';
import { StorageService } from '@services/storage/storage.service';

const KEY_POINTS = 'PREMIUM_POINTS';
@Injectable({
  providedIn: 'root'
})
export class PremiumService {
  constructor(
    private modalCtrl: ModalController,
    private storageService: StorageService,
    private userStoreService: UserStoreService,
    private parse: ParseService,
    private loadingService: LoadingService,
    private eventService: EventService
  ) {}

  private async acceptFreemium() {
    try {
      await this.loadingService.show();
      await this.parse.run('acceptFreemium');
      await this.loadingService.dismiss();
    } catch (e) {
      console.log(e);
    }
  }

  private async acceptTrial() {
    try {
      await this.loadingService.show();
      await this.parse.run('acceptTrial');
      await this.loadingService.dismiss();
    } catch (e) {
      console.log(e);
    }
  }

  async addPoint() {
    let points = await this.getPointBalance();

    points = points + 1;

    await this.storageService.set(KEY_POINTS, points);
  }

  async removePoint() {
    let points = await this.getPointBalance();

    points = points - 1;

    await this.storageService.set(KEY_POINTS, points);
  }

  async getPointBalance() {
    const balance = await this.storageService.get(KEY_POINTS);
    return balance ?? 1;
  }

  async waitResolveExpired() {
    const modal = await this.createPlanModal('EXPIRED');
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data.acceptFreemium) {
      await this.acceptFreemium();
    }
  }

  async waitResolvePaywall(): Promise<boolean> {
    const userIsExpired = this.userStoreService.userIsExpired();
    if (userIsExpired) {
      const modal = await this.createPlanModal('PAY_WALL');
      await modal.present();
      return false;
    }

    return true;
  }

  async waitResolvePremiumModal(): Promise<boolean> {
    const userIsExpired = this.userStoreService.userIsExpired();
    const pointBalance = await this.getPointBalance();

    if (userIsExpired && pointBalance <= 0) {
      const modal = await this.createPlanModal('BLOCK_PREMIUM');
      await modal.present();
      const { data } = await modal.onDidDismiss();

      if (data.acceptTrial) {
        await this.acceptTrial();
      }

      return data.rewared;
    }
    await this.removePoint();

    return true;
  }

  async waitResolveOffline() {
    const isFreemium = this.userStoreService.userIsFreemium();

    if (isFreemium) {
      const modal = await this.createPlanModal('BLOCK_OFFLINE');
      await modal.present();
      return modal;
    }
  }

  async waitResolveBrowserFreemium(canClose?: boolean) {
    console.log("teste");
    const userIsExpired = this.userStoreService.userIsExpired();
    const isFreemium = this.userStoreService.userIsFreemium();
    
    const desktop = WebUtils.isWeb();
    //return true;
    if ( (userIsExpired || isFreemium) && desktop) {
      const modal = await this.createPlanModal('BLOCK_DESKTOP_FREEMIUM', canClose);
      await modal.present();
      
      await modal.onDidDismiss();
      this.eventService.publish('logout');
    }
  }

  async waitResolveBrowserLogin() {
    const modal = await this.createPlanModal('BLOCK_DESKTOP_FREEMIUM', true);
    await modal.present();
    return modal;
  }

  async waitResolveAcceptFreemium() {
    const modal = await this.createPlanModal();
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data.acceptFreemium) {
      await this.acceptFreemium();
    }
  }

  async showBenefitsPlaModal() {
    const modal = await this.createPlanModal('BENEFITS_IOS', true);
    await modal.present();
  }

  private async createPlanModal(
    origin?: 'EXPIRED' | 'BLOCK_DESKTOP_FREEMIUM' | 'BLOCK_OFFLINE' | 'BLOCK_PREMIUM' | 'PAY_WALL' | 'BENEFITS_IOS',
    canClose?: boolean
  ) {
    return this.modalCtrl.create({
      id: 'PremiumModal',
      component: PlansComparePage,
      cssClass: 'modal-transparent fullscreen sd',
      componentProps: { origin, showCloseButton: canClose },
      showBackdrop: canClose ?? false,
      backdropDismiss: canClose ?? false
    });
  }
}

import { Injectable } from '@angular/core';
import { User } from '@models/User';
import * as Parse from 'parse';
import { environment } from 'src/environments/environment';
import { ParseService } from '../parse/parse.service';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { StorageService } from '@app/@services/storage/storage.service';
import { WebUtils } from '@app/util/WebUtils';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private parseService: ParseService,
    private facebook: Facebook,
    private googlePlus: GooglePlus,
    private storageService: StorageService,
    private socialAuthService: SocialAuthService 
  ) {}

  updateNewsletterGranted(newsLetterGranted: boolean) {
    return this.parseService.run('updateNewsletterGranted', {
      newsLetterGranted
    });
  }

  async fetchNewsletterGranted(): Promise<boolean | undefined> {
    const reponse = await this.parseService.run('fetchNewsletterGranted');
    return reponse.newsLetterGranted;
  }

  userSubscription(userId) {
    return this.parseService.userSubscription(userId);
  }

  transferData(code: string, payload: string): Promise<any> {
    return this.parseService.run('transferData', {
      code,
      payload
    });
  }

  restoreData(code: string): Promise<any> {
    return this.parseService.run('restoreData', {
      code
    });
  }

  resendConfirmationEmail(email): Promise<any> {
    return this.parseService.run('resendConfirmationEmail', { email });
  }

  loginUser(user): Promise<any> {
    return this.parseService.run('loginUser', user);
  }

  currentUser() {
    return Parse.User.current();
  }

  async logIn(params: { user: { email: string; password: string } }): Promise<any> {
    const user = params.user;
    const { email, password } = user;

    if(this.currentUser()) {
      await Parse.User.logOut().catch((e)=> console.error(e));
    }

    return this.parseService.run('loginUser', { user }).then(async (result) => {
      await Parse.User.logIn(email, password); //force login via SDK for session context
      return result;
    });
  }

  logOut(): Promise<any> {
    this.storageService.remove<any>('user-estudo');
    this.storageService.remove<any>('cicloAtivo');
    this.storageService.remove<any>('currentCycle');
    this.storageService.remove<any>('cicloBlocosAtivo');
    this.storageService.remove<any>('ciclo');
    this.storageService.remove<any>('didSyncAll');

    return Parse.User.logOut();
  }

  turnOnPurchaseMetrics(user: User): Promise<any> {
    return this.parseService.run('turnOnPurchaseMetrics', {
      userId: user.objectId
    });
  }

  logCrashError(userId, error) {
    return this.parseService.run('logCrashError', {
      userId,
      error: `${error} | [version ${environment.appVersion}]`
    });
  }

  async requestPasswordReset(email): Promise<any> {
    return this.parseService.run('requestPasswordReset', { email });
  }

  async changePassword(params): Promise<any> {
    const response = await this.parseService.run('changePassword', params);
    const { sessionToken } = response;
    await Parse.User.become(sessionToken);
  }

  createUser(user): Promise<any> {
    return this.parseService.run('createUser', user);
  }

  addFoto(user): Promise<any> {
    return this.parseService.run('addFoto', user);
  }

  didRate(data): Promise<any> {
    return this.parseService.run('updateRating', data);
  }

  updateUser(user): Promise<any> {
    return this.parseService.run('updateUser', user);
  }

  isUserExpired(id): Promise<any> {
    return this.parseService.run('isUserExpired', { id });
  }

  hasWebAccess(email: string) : Promise< boolean > {
    return this.parseService.run('hasWebAccess', { email }).then((result)=> result.hasWebAccess);
  }

  getLastGoal(userId) {
    return this.parseService.run('getLastGoal', { userId });
  }

  persistAssinante(assinante): Promise<any> {
    return this.parseService.run('persistAssinante', assinante);
  }

  clearUserData(user: User, cycleIds: string[]) {
    return this.parseService.run('clearUserData', {
      email: user.email,
      userId: user.objectId,
      cycleIds
    });
  }

  deleteAccount(params): Promise<any> {
    return this.parseService.run('deleteAccount', params);
  }

  async loginWithSocialProvider(provider: 'Facebook' | 'Google' | 'Apple') {
    if (provider === 'Facebook') {
      return this.logInWithFacebook();
    } else if (provider === 'Google') {
      return this.logInWithGoogle();
    }
    throw new Error('Provider incorreto');
  }

  async fetchSessionWithSocialProvider(provider: 'Facebook' | 'Google' | 'Apple') {
    if (provider === 'Facebook') {
      return this.logInWithFacebook();
    } else if (provider === 'Google') {
      return this.logInWithGoogle();
    }
    throw new Error('Provider incorreto');
  }

  private async logInWithFacebook() {
    if(WebUtils.isWeb()) {
      const result = await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).catch((e)=> console.log(e));
      const email = result['email'];
      const hasWebAccess = await this.hasWebAccess(email);
      if(!hasWebAccess) {
        throw new Error('Sem acesso web');
      }

      const authToken = result['authToken'];

      return this.becomeWithSocialProvider(authToken, 'Facebook');
    }

    const permissions = ['public_profile', 'email'];
    const response = await this.facebook.login(permissions);
    const accessToken = response.authResponse.accessToken;

    return this.becomeWithSocialProvider(accessToken, 'Facebook');
  }

  private async logInWithGoogle() {
    if(WebUtils.isWeb()) {
      const result = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).catch((e)=> console.log(e));
      const authToken = result['authToken'];
      const email = result['email'];

      const hasWebAccess = await this.hasWebAccess(email);
      if(!hasWebAccess) {
        throw new Error('Sem acesso web');
      }
      return this.becomeWithSocialProvider(authToken, 'Google');
    }

    const response = await this.googlePlus.login({});
    const accessToken = response.accessToken;

    return this.becomeWithSocialProvider(accessToken, 'Google');
  }

  private async fetchUserWithSocialProvider(accessToken: string, provider: 'Facebook' | 'Google' | 'Apple') {
    const user = await this.parseService.run('loginUserWithSocialProvider', {
      authData: { accessToken, provider },
      isFreemium: true
    });

    return user;
  }

  private async becomeWithSocialProvider(accessToken: string, provider: 'Facebook' | 'Google' | 'Apple') {
    const user = await this.fetchUserWithSocialProvider(accessToken, provider);
    const sessionToken = user.sessionToken;
    if (!sessionToken) {
      throw new Error('Session Token');
    }

    await Parse.User.become(sessionToken);
    return user;
  }

  async isLogged(): Promise<boolean> {
    return !!(await this.storageService.get('user-estudo'));
  }

  async become(sessionToken: string) {
    return Parse.User.become(sessionToken);
  }
}

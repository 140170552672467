import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-percentage-circle",
  templateUrl: "./percentage-circle.component.html",
  styleUrls: ["./percentage-circle.component.scss"],
})
export class PercentageCircleComponent implements OnInit {
  private _percent: number;
  percentClass: string;
  colorClass: string;

  @Input("percent") set percent(value: number) {
    this._percent = parseInt(value.toString());
    this.percentClass = `p${value}`;

    if (this._percent <= 70) {
      this.colorClass = "red";
    } else if (this._percent >= 71 && this._percent <= 99) {
      this.colorClass = "blue";
    } else if (this._percent > 99) {
      this.colorClass = "green";
    }
  }

  @Input() data: any;
  @Input() id: string;
  @Input() tempo: string;

  get percent() {
    return this._percent;
  }

  ngOnInit() {}
}

export interface ChatMessage {
  id: string;
  messages: Array<ChatMessageChunk>;
  type: ChatMessageType;
}

export interface ChatMessageChunk {
  message: string;
  isBold: boolean;
}

export enum ChatMessageType {
  BOT = 'BOT',
  USER = 'USER',
}

export interface ChatMessageChunk {
  message: string;
  isBold: boolean;
}

export interface ChatMessageSentence {
  first: number; // Start index
  second: number; // End index
  isBold: boolean; // Is bold
}
<ion-header>
<ion-toolbar class="ion-text-center" [class.is-light]="isLight">
  <ion-buttons slot="start">
    <ion-icon *ngIf="hasDrawer && syncStatus | async"  class="drawer-badge" color="warning" name="sync-circle"></ion-icon>
    <ion-menu-button *ngIf="hasDrawer"></ion-menu-button>

    <ion-button class="toolbar-icon-button" *ngIf="!hasDrawer" color="dark" (click)="_leftIconTap()">
      <ion-icon style="font-size: 30px;" name="close" [class.is-light]="isLight"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title *ngIf="!hasLogo" class="ion-no-margin ion-no-padding" [class.is-light]="isLight">
    <h4 class="title-text">{{ title }}</h4>
  </ion-title>

  <div *ngIf="hasLogo" class="logo-container">
    <img class="img-logo" src="assets/imgs/estudaqui_logo.png" />
    <p>estudaqui</p>
  </div>

  <ion-buttons slot="primary" class="ion-no-padding ion-no-margin">
    <ion-button *ngIf="hasFilter" (click)="_filterIconTap()">
      <ion-icon slot="icon-only" color="light" name="funnel"></ion-icon>
    </ion-button>
    <ion-button class="toolbar-icon-button" *ngIf="hasQuestionTutorial && !hasTutorial" color="primary" fill="clear" size="large" (click)="_rightIconTap()">
      <ion-icon slot="icon-only" src="/assets/svg/help-circle.svg"></ion-icon>
    </ion-button>

    <ion-button *ngIf="enableScreenshot" (click)="shareScreenShot()">
      <ion-icon slot="icon-only" src="/assets/svg/share-alt-solid.svg"></ion-icon>
    </ion-button>
    
    <ion-button class="toolbar-icon-button" *ngIf="hasTutorial" color="primary" fill="clear" size="large" (click)="_rightIconTap()">
      <ion-icon slot="icon-only" src="/assets/svg/information-circle.svg"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
</ion-header>

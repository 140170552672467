<app-common-header
  title="Planos"
  [isDark]="true"
  [hasFilter]="false"
  [hasTutorial]="false"
  [hasQuestionTutorial]="false"
  (leftIconTap)="dismiss()"
></app-common-header>

<ion-content>
    <div *ngIf="!isFetchingData">
      <div class="description-plan" *ngIf="isTrial">
        <h2>Plano Trial</h2>
        <p>Sua assinatura está no <b>período Trial</b>. Você poderá utilizar o aplicativo gratuitamente <b>até {{expiryDate}}</b>.</p>
      </div>
      <div class="description-plan" *ngIf="!isTrial">
        <h2>Plano {{nomePlano}}</h2>
        <div>
          <span>Você pode estender sua assinatura do Plano {{nomePlano}} fazendo um upgrade de plano.</span><br>
          <span class="small">* Caso você possua o plano anual, é possível extendê-lo por mais 1 ano.</span>
        </div>
      </div>
      <div>
        <ion-card *ngFor="let plano of planos; let i = index" [ngClass]="{'low-opacity' : planos[i].disabled}">
          <div class="overlay"></div>
          <img [src]="planos[i].urlImagem">
          <ion-card-content>
            <p class="item-valor-mes">{{ plano?.price }}</p>
            <p class="item-valor-total">{{ plano?.description  }}</p>
          </ion-card-content>
          <ion-row>
            <ion-col class="container-button">
              <ion-button color="primary" (click)="buy(plano)" [disabled]="planos[i].disabled">
                <div class="comprar-button-content">
                  <ion-icon color="light" name="thumbs-up"></ion-icon>
                  <span>COMPRAR</span>
                </div>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card>
      </div>
      <div class="terms-and-policy" (click)="navigateToTermsAndPolicy()">
        <span><strong>Termos de Uso</strong> e <strong>Política de Privacidade</strong></span>
      </div>
    </div>
    <div class="skeleton" *ngIf="isFetchingData">
      <app-skeleton-item height="2em" width="50vw"></app-skeleton-item>
      <app-skeleton-item height="3.5em" width="85vw"></app-skeleton-item>
      <app-skeleton-item height="65vh" width="85vw"></app-skeleton-item>
    </div>
</ion-content>

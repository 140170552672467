import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  fMessaging: any;

  constructor() {
    this.fMessaging = (<any>window).cordova?.plugins?.firebase.messaging;
  }

  async requestPermission() {
    if(this.fMessaging) {

      this.fMessaging
      .requestPermission({ forceShow: true })
      .then(function () {
        console.log('Push messaging is allowed');
      })
      .catch((e) => {
        console.log('erro request permission');
        console.log(e);
      });
    }

  }
}

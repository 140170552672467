<app-common-header
  (leftIconTap)="dismiss()"
  title="Criar Assunto"
  [isDark]="true"
  [hasLogo]="false"
  [hasDrawer]="false"
  [hasFilter]="false"
  [hasTutorial]="false"
>
</app-common-header>

<ion-content class="ion-padding">
  <form-create-topic
    [pageParams]="pageParams"
    [discipline]="discipline"
    [topic]="topic"
    [selectedColor]="selectedColor"
  ></form-create-topic>
</ion-content>

import { Injectable } from "@angular/core";
import { ParseService } from "@app/@services/api/parse/parse.service";
import { PlanPeriod, PlanType } from "./plan";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  constructor(private parseProvider: ParseService) {}

  queryPlanByTypeAndPeriod(planType: PlanType, planPeriod: PlanPeriod): Promise<any> {
    return this.parseProvider.run('queryPlanByTypeAndPeriod', { planType, planPeriod });
  }

  createPrepaidSubscription({ userId, numInstallments, planPeriod, paymentMethod, customer, address, card }) {
    return this.parseProvider.run('createPrepaidSubscription', {
      userId,
      numInstallments,
      planPeriod,
      paymentMethod,
      customer,
      address,
      card,
    });
  }
}

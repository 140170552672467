import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

export enum TabIdentifier {
  CICLO,
  ESTATISTICA,
  CRONOMETRO,
  HISTORICO,
  REVIEW,
  CICLO_EDIT
}

@Injectable({
  providedIn: "root",
})
export class TabService {
  tabs: Array<TabIdentifier> = [
    TabIdentifier.CICLO,
    TabIdentifier.ESTATISTICA,
    TabIdentifier.CRONOMETRO,
    TabIdentifier.HISTORICO,
    TabIdentifier.REVIEW,
    TabIdentifier.CICLO_EDIT
  ];

  tabParams: Map<TabIdentifier, any> = new Map<TabIdentifier, any>();
  fromTab: boolean = false;
  openCreateAutomaticCycle: boolean = false;
  editCycle: boolean = false;

  private current: BehaviorSubject<TabIdentifier> = new BehaviorSubject(
    TabIdentifier.CICLO
  );

  private hasCurrentReview = new BehaviorSubject<Array<any>>([]);

  loadReview = new EventEmitter<boolean>();

  constructor() {}

  getCurrentTab(): TabIdentifier {
    return this.current.getValue();
  }

  setCurrentTab(tabIndex: number): void {
    const tabSelected = this.tabs[tabIndex];
    // Não alterar se forem as tabs que abrem modal
    if (tabSelected !== TabIdentifier.CRONOMETRO) {
      this.current.next(this.tabs[tabIndex]);
    }
  }

  setNotificationReview(value: any) {
    this.hasCurrentReview.next(value);
  }

  getNotificationReview() {
    return this.hasCurrentReview;
  }

  getReview(value: boolean) {
    this.loadReview.emit(value);
  }
}

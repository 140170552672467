import { Injectable } from '@angular/core';
import { CachedItem } from '@models/cached-item.interface';
import { SyncResult } from '@models/sync-result.interface';

@Injectable()
export class CachingUtils {
   static generateItemKey<T>(item: T): string {
      const key: any = {};
      // protiry to use localobjectId as key
      // if localobjectid doesn't exist, objectId is used
      if (item['localObjectId']) key['localObjectId'] = item['localObjectId'];
      else if (item['objectId']) key['objectId'] = item['objectId'];
      return JSON.stringify(key);
   }

   public static buildTrackingToSync<T>(items: T[]): CachedItem[] {
      return items
         .filter((item) => !!item['objectId'])
         .map((item) => {
            return {
               objectId: item['objectId'],
               localObjectId: item['localObjectId'],
               updatedAt: item['updatedAt']
            } as CachedItem;
         });
   }

   public static mergeWithSync<T>(all: T[], syncResult: SyncResult<T>): T[] {
      const mapMerge: Map<string, T> = new Map<string, T>();

      all.forEach((item) => {
         const key: string = CachingUtils.generateItemKey(item);
         mapMerge.set(JSON.stringify(key), item);
      });

      syncResult.doDelete.forEach((item) => {
         const key: string = CachingUtils.generateItemKey(item);
         mapMerge.delete(JSON.stringify(key));
      });

      syncResult.doUpdateOrCreate.forEach((item) => {
         const key: string = CachingUtils.generateItemKey(item);
         mapMerge.set(JSON.stringify(key), item);
      });

      return Array.from(mapMerge.values());
   }

   public static orderByUpdatedAt<T>(data: T[], propName = 'updatedAt'): T[] {
      const dataSorted = data.sort((a, b) => {
         const aDate = new Date(a[propName]).getTime();
         const bDate = new Date(b[propName]).getTime();
         return bDate - aDate;
      });

      return dataSorted;
   }
}

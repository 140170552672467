<app-common-header
  (leftIconTap)="dismiss()"
  title="Planos de acesso"
  [isDark]="true"
  [hasLogo]="false"
  [hasDrawer]="false"
></app-common-header>

<ion-content>
  <div id="content-page">
    <div class="legend ion-margin-top">
      <span>Renovação automática<br />Cancele quando quiser</span>
    </div>
    <div class="subtitle">
      <h5>Selecione um plano Premium</h5>
    </div>

    <div *ngFor="let product of products"  class="ion-margin-top ion-margin-bottom">
      <ion-card class="ion-card" (click)="purchase(product)">
        <ion-card-header color="orange" class="ion-no-padding ion-margin-left card-header" *ngIf="product.id === '365_annual_subs_estudaqui'" no-padding>
          <div style="display: flex; color: yellow;margin-left:15px; margin-top: 3px; align-items: center;">
            <ion-icon name="star" slot="start" style="margin-right: 5px;" ></ion-icon>
            <h3 style="color: yellow !important;"> mais popular</h3>
          </div>
        </ion-card-header> 
        <ion-card-content
          class="card-content"
          [ngClass]="{'card-highlight': product.id == '365_annual_subs_estudaqui' }"
        >
          <div class="content-main">
            <span > {{ product.title }} </span>
            <span > {{ product.priceMicros / 1000000 | currency:product.currency:true }}</span>
          </div>
          <p *ngIf="product.id !== '30_monthly_subs_estudaqui' " style="color:white; font-size: 0.8rem;">Equivale a {{ calculateMonthlyAmount(product.id, product.priceMicros) | currency:product.currency:true }}/mês.</p>
        </ion-card-content>
      </ion-card>
    </div>

    <div class="extra-option ion-padding">
      Para <span> pagar com boleto </span><a href="https://app.estudaqui.com/assinar"  target="_blank" rel="noopener noreferrer">Clique Aqui</a>.
    </div>
  </div>
</ion-content>

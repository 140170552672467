<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Cartão de Crédito</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="!isFetchingData" class="container">
    <ion-list>
      <div class="payment-method-selection" *ngIf="canPayWithBankSlip">
        <div
          class="payment-method mr-3"
          [ngClass]="isPaymentViaCreditCard ? 'active' : ''"
          (click)="setPaymentMethod(getPaymentMethod().CREDIT_CARD)"
        >
        <ion-icon name="checkmark-circle" class="mr-3" size="large"></ion-icon>
        <span>Cartão de Crédito</span>
        </div>
        <div
          class="payment-method"
          [ngClass]="isPaymentViaCreditCard ? '' : 'active'"
          (click)="setPaymentMethod(getPaymentMethod().BANK_PAYMENT_SLIP)"
        >
        <ion-icon name="checkmark-circle" class="mr-3" size="large"></ion-icon>
        <img [src]="urlBankSlipImage" alt="boleto" />
        </div>
      </div>

      <div class="info-plano">
        <h3>{{nomePlano}}</h3>

        <div style="margin: 0 10px 10px 10px;" *ngIf="(planQuery.planPeriod === 'QUARTERLY' || planQuery.planPeriod === 'ANNUAL') && paymentMethod !== 'BANK_PAYMENT_SLIP'">
          <app-dropdown-button
            [displayText]="numInstallments === 0 ? 'Selecione a quantidade de parcelas *': selectedLabel"
            [displayColor]="numInstallments == 0 ? '#3478b9' : '#000'"
            (click)="openInstallmentsModal()"
            [items]="dropdownItems"
          ></app-dropdown-button>
        </div>

        <div class="text subscription-info" *ngIf="planQuery.planType === 'SUBSCRIPTION'">
          <p>A <b>próxima renovação</b> da sua assinatura será em <b>{{ expirationPlano }}</b>.</p>
          <p>Gerencie a renovação automática no menu Meu plano de acesso > Pagamento e renovação</p>
        </div>
        <span class="text prepaid-info" *ngIf="planQuery.planType === 'PREPAID'">
          <p>Seu acesso irá até o dia <b>{{ expirationPlano }}</b>.</p>
          <p>Gerencie seu plano a qualquer momento no menu Meu plano de acesso.</p>
        </span>

      </div>

      <div class="coupon-container" *ngIf="canAddCoupon">
        <span (click)="toggleCoupon()">Possui <span class="coupon-link">cupom?</span></span>
        <span class="coupon-name" *ngIf="couponName">Cupom {{couponName}}</span>
        <div class="coupon-code-container" *ngIf="showCouponInput">
          <div class="credit-card-field">
            <ion-item>
              <ion-label position="floating">Código do cupom</ion-label>
              <ion-input
                type="text"
                [(ngModel)]="couponCode"
                (ngModelChange)="couponCode = $event.toLocaleUpperCase()"
                (keyup)="resetCoupon()"
                class="underline-border"
              ></ion-input>
            </ion-item>
            <ion-icon name="pricetag" *ngIf="isCouponValid === null"></ion-icon>
            <ion-icon name="checkmark" color="green" *ngIf="isCouponValid === true"></ion-icon>
            <ion-icon name="close" color="danger" *ngIf="isCouponValid === false"></ion-icon>
          </div>
        </div>
      </div>

      <div *ngIf="isPaymentViaCreditCard">
        <div class="change-card" *ngIf="hasCard">
          <span (click)="changeCard()">Utilizar outro cartão de crédito.</span>
        </div>

        <app-credit-card [card]="card" [hasCard]="hasCard"></app-credit-card>
      </div>
      <div *ngIf="!isPaymentViaCreditCard" class="info-plano ptb-3">
        <span>
          <b>ATENÇÃO:</b> a assinatura será efetivada somente quando o pagamento do boleto for identificado no sistema
          bancário, o que costuma demorar até 3 dias úteis.
        </span>
      </div>
    </ion-list>
    <div class="processed-by">
      <img class="provider-logo" src="assets/subscriptions/pagarme-logo.svg" alt="Logo Pagar.me">
      <span>Autenticado e processado por Pagar.me.</span>
    </div>

   
      <ion-button class="button-assinatura"  (click)="doSubscription()">
        CONFIRMAR PLANO E PAGAR
      </ion-button>



  </div>

</ion-content>

import { Component } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
import { ExamService } from '@services/api/exam/exam.service';

import { MyExamCreatePage } from '../my-exam-create/my-exam-create.page';
import { PremiumService } from '@services/premium/premium.service';
import { MyExamCreateHelperPage } from '../my-exam-create-helper/my-exam-create-helper.page';
import { CreateAutomaticCyclePage } from '@app/cycle/create-automatic-cycle/create-automatic-cycle.page';
import { EventService } from '@services/event-service/event.service';

@Component({
  selector: 'app-my-exams-list',
  templateUrl: './my-exams-list.page.html',
  styleUrls: ['./my-exams-list.page.scss']
})
export class MyExamsListPage extends BasePageController {
  examNameInput: string;
  examListView: any[] = [];

  constructor(
    private examService: ExamService,
    private eventService: EventService,
    private premiumService: PremiumService
  ) {
    super();
  }

  async ngOnInit() {
    this.loadExams();
  }

  private async loadExams() {
    const result = await this.examService.loadMyExams();
    this.examListView = result.filter(({ ativo }) => ativo);
  }

  async openCreateExamHelper() {
    const reward = await this.premiumService.waitResolvePremiumModal();

    if (reward) {
      const modal = await this.modalCtrl.create({
        component: MyExamCreateHelperPage,
        cssClass: 'sd modal-transparent',
        componentProps: {
          startFromFirstStep: true
        }
      });

      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data?.reload) {
        await this.loadExams();
      }

      if (data?.examResult) {
        const { objectId } = data.examResult;

        const customExam = this.examListView.find((e) => e.objectId === objectId);
        customExam.custom = true;
        this.eventService.publish('OPEN_CREATE_AUTOMATIC_PLAN', { customExam });
        this.dismiss();
      }
    }
  }

  async doDisable(exam) {
    if (!this.networkService.isConnected()) {
      this.showToastWarningMessage('Precisa estar online para deletar provas');
      return;
    }

    const alert = await this.alertCtrl.create({
      header: 'Excluir Prova ',
      message: 'Tem certeza que deseja remover essa prova?',
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {}
        },
        {
          text: 'Sim',
          handler: () => {
            this.disableExam(exam);
          }
        }
      ]
    });

    alert.present();
  }

  private async disableExam(exam) {
    await this.showLoading();
    const { objectId } = exam;

    try {
      await this.examService.disableMyExam(objectId);
      await this.loadExams();
    } catch (e) {
      this.showToastWarningMessage('Um erro ocorreu ao tentar deletar a prova.');
    } finally {
      await this.dismissLoading();
    }
  }

  async openCreateOrUpdateExamModal(exam?: any) {
    if (!this.networkService.isConnected()) {
      this.showToastWarningMessage('Precisa estar online para criar provas');
      return;
    }

    const reward = await this.premiumService.waitResolvePremiumModal();

    if (reward) {
      const modal = await this.modalCtrl.create({
        component: MyExamCreatePage,
        cssClass: 'sd modal-transparent',
        componentProps: {
          myExam: exam
        }
      });

      await modal.present();

      const { data } = await modal.onDidDismiss();

      if (data?.reload) {
        this.loadExams();
      }
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-how-to-cancel-plan',
  templateUrl: './how-to-cancel-plan.component.html',
  styleUrls: ['./how-to-cancel-plan.component.scss']
})
export class HowToCancelPlanComponent {
  stores = {
    android: {
      platform: 'Android',
      icon: 'logo-android',
      via: 'Google Play',
      company: 'Google',
      cancelLink:
        'https://support.google.com/googleplay/workflow/9827184?visit_id=637967775143742349-3352465608&p=cancelsubsawf&rd=1'
    },
    ios: {
      platform: 'Apple',
      icon: 'logo-apple',
      via: 'iTunes Store',
      company: 'Apple',
      cancelLink:
        'https://support.apple.com/pt-br/HT202039'
    }
  };

  store: any = {};
  constructor(private modalCtrl: ModalController, private platform: Platform, private userStoreService: UserStoreService ) {}

  ionViewWillEnter() {
    const isIOS = this.platform.is('ios');
    this.store = isIOS ? this.stores.ios : this.stores.android;
  }

  get appVersion() {
    return environment.appVersion;
  }

  get userEmailEncoded() {
    return encodeURIComponent(this.userStoreService.user?.email ?? "");
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

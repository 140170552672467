import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EfficiencyChartsComponent } from '@app/components/statistics/efficiency-charts/efficiency-charts.component';
import { ExerciseChartsComponent } from '@app/components/statistics/exercise-charts/exercise-charts.component';
import { PageChartsComponent } from '@app/components/statistics/page-charts/page-charts.component';
import { TimeChartsComponent } from '@app/components/statistics/time-charts/time-charts.component';
import { BasePageController } from '@app/pages/BasePage';
import { IPeriod } from '@models/IPeriod';
import { Meta } from '@models/Meta';
import { Statistic, StatisticPageType } from '@models/Statistic';
import { UserStoreService } from '@services/api/user-store/user-store.service';

@Component({
  selector: 'app-statistic-tab-year',
  templateUrl: './statistic-tab-year.page.html',
  styleUrls: ['./statistic-tab-year.page.scss'],
})
export class StatisticTabYearPage {

  @Input() statistics: Statistic[];
  @Input() period: IPeriod;
  @Input() tipo: StatisticPageType;
  @Input() isTimeTabSelected: boolean;
  @Input() isExercisesTabSelected: boolean;
  @Input() isPagesTabSelected: boolean;
  @Input() isEfficiencyTabSelected: boolean;

  @ViewChild(TimeChartsComponent) timeCharts: TimeChartsComponent;
  @ViewChild(ExerciseChartsComponent) exerciseCharts: ExerciseChartsComponent;
  @ViewChild(PageChartsComponent) pageCharts: PageChartsComponent;
  @ViewChild(EfficiencyChartsComponent) efficiencyCharts: EfficiencyChartsComponent;

  isLoading : boolean = false;

  constructor() {}
 
  async plotCharts() {
   this.isLoading = true;
    setTimeout(() => {
      if (this.isTimeTabSelected) {
        this.timeCharts.plotAll();
      } else if (this.isExercisesTabSelected) {
        this.exerciseCharts.plotAll();
      } else if (this.isPagesTabSelected) {
        this.pageCharts.plotAll();
      } else {
        this.efficiencyCharts.plotAll();
      }
     this.isLoading = false;
    }, 1000);
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';
import { WelcomeGuard } from './guards/welcome/welcome.guard';
import { ShouldRedirectToWelcomeWebGuard } from './guards/should-redirect-to-welcome-web.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome-onboarding',
    pathMatch: 'full'
  },
  {
    path: 'welcome-onboarding',
    loadChildren: () =>
      import('./pages/welcome-onboarding/welcome-onboarding.module').then((m) => m.WelcomeOnboardingPageModule),
    canActivate: [WelcomeGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [WelcomeGuard, ShouldRedirectToWelcomeWebGuard]
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
    canActivate: [WelcomeGuard, ShouldRedirectToWelcomeWebGuard]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
    canActivate: [ShouldRedirectToWelcomeWebGuard]
  },
  {
    path: 'welcome-web',
    loadChildren: () => import('./pages/welcome-web/welcome-web.module').then((m) => m.WelcomeWebPageModule),
    canActivate: [WelcomeGuard]
  },
  {
    path: 'email-confirmation',
    loadChildren: () =>
      import('./pages/email-confirmation/email-confirmation.module').then((m) => m.EmailConfirmationPageModule)
  },
  {
    path: 'cycle-list',
    loadChildren: () => import('./pages/cycle/cycle-list/cycle-list.module').then((m) => m.CycleListPageModule)
  },
  {
    path: 'metas',
    loadChildren: () => import('./pages/metas/metas.module').then((m) => m.MetasPageModule)
  },
  {
    path: 'notification-settings',
    loadChildren: () =>
      import('./pages/notification-settings/notification-settings.module').then((m) => m.NotificationSettingsPageModule)
  },
  {
    path: 'topic-list',
    loadChildren: () => import('./topics/topic-list/topic-list.module').then((m) => m.TopicListPageModule)
  },
  {
    path: 'discipline-topics',
    loadChildren: () =>
      import('./topics/discipline-topics/discipline-topics.module').then((m) => m.DisciplineTopicsPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./change-password/change-password/change-password.module').then((m) => m.ChangePasswordPageModule)
  },
  {
    path: 'current-subscription',
    loadChildren: () =>
      import('./subscriptions/current-subscription/current-subscription.module').then(
        (m) => m.CurrentSubscriptionPageModule
      )
  },
  {
    path: 'checkout-personal-data',
    loadChildren: () =>
      import('./subscriptions/checkout-personal-data/checkout-personal-data.module').then(
        (m) => m.CheckoutPersonalDataPageModule
      )
  },
  {
    path: 'current-plan',
    loadChildren: () => import('./subscriptions/current-plan/current-plan.module').then((m) => m.CurrentPlanPageModule)
  },
  {
    path: 'choose-discipline-modal',
    loadChildren: () =>
      import('./disciplines/choose-discipline-modal/choose-discipline-modal.module').then(
        (m) => m.ChooseDisciplineModalPageModule
      )
  },
  {
    path: 'create-topic',
    loadChildren: () => import('./topics/create-topic/create-topic.module').then((m) => m.CreateTopicPageModule)
  },
  {
    path: 'dialog-test',
    loadChildren: () => import('./dialog-test/dialog-test.module').then((m) => m.DialogTestPageModule)
  },
  {
    path: 'newsletter-dialog',
    loadChildren: () =>
      import('./pages/newsletter-dialog/newsletter-dialog.module').then((m) => m.NewsletterDialogPageModule)
  },
  {
    path: 'choose-color',
    loadChildren: () => import('./pages/choose-color/choose-color.module').then((m) => m.ChooseColorPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'statistic-history-tab',
    loadChildren: () =>
      import('./statistics/statistic-history-tab/statistic-history-tab.module').then(
        (m) => m.StatisticHistoryTabPageModule
      )
  },
  {
    path: 'statistic-detail',
    loadChildren: () =>
      import('./statistics/statistic-detail/statistic-detail.module').then((m) => m.StatisticDetailPageModule)
  },
  {
    path: 'choose-topic-by-discipline',
    loadChildren: () =>
      import('./topics/shared/choose-topic-by-discipline/choose-topic-by-discipline.module').then(
        (m) => m.ChooseTopicByDisciplinePageModule
      )
  },
  {
    path: 'cycle',
    loadChildren: () => import('./cycle/cycle/cycle.module').then((m) => m.CyclePageModule)
  },
  {
    path: 'create-cycle',
    loadChildren: () => import('./cycle/create-cycle/create-cycle.module').then((m) => m.CreateCyclePageModule)
  },
  {
    path: 'reviews-list',
    loadChildren: () => import('./reviews/reviews-list/reviews-list.module').then((m) => m.ReviewsListPageModule)
  },
  {
    path: 'statistic-tab',
    loadChildren: () => import('./statistics/statistic-tab/statistic-tab.module').then((m) => m.StatisticTabPageModule)
  },
  {
    path: 'referral-dialog',
    loadChildren: () => import('./core/referral-dialog/referral-dialog.module').then((m) => m.ReferralDialogPageModule)
  },
  {
    path: 'help-bot',
    loadChildren: () => import('./shared/help-bot/help-bot.module').then((m) => m.HelpBotPageModule)
  },
  {
    path: 'create-automatic-cycle',
    loadChildren: () =>
      import('./cycle/create-automatic-cycle/create-automatic-cycle.module').then(
        (m) => m.CreateAutomaticCyclePageModule
      )
  },
  {
    path: 'create-manual-cycle',
    loadChildren: () =>
      import('./cycle/create-manual-cycle/create-manual-cycle.module').then((m) => m.CreateManualCyclePageModule)
  },
  {
    path: 'plan-list',
    loadChildren: () => import('./subscriptions/plan-list/plan-list.module').then((m) => m.PlanListPageModule)
  },
  {
    path: 'post-payment',
    loadChildren: () => import('./subscriptions/post-payment/post-payment.module').then((m) => m.PostPaymentPageModule)
  },
  {
    path: 'current-subscription-ios',
    loadChildren: () =>
      import('./subscriptions/current-subscription-ios/current-subscription-ios.module').then(
        (m) => m.CurrentSubscriptionIosPageModule
      )
  },
  {
    path: 'plan-ios-list',
    loadChildren: () =>
      import('./subscriptions/plan-ios-list/plan-ios-list.module').then((m) => m.PlanIosListPageModule)
  },
  {
    path: 'modal-rate',
    loadChildren: () => import('./pages/modal-rate/modal-rate.module').then((m) => m.ModalRatePageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { DisciplineCycleAddDialogPage } from "./discipline-cycle-add-dialog.page";
import { SharedComponentsModule } from "@app/components/shared-components.module";
import { CustomDateTimeModule } from "@app/directives/custom-date-time/custom-date-time.module";
import { PipesModule } from "src/theme/pipes/pipes.module";

@NgModule({
  imports: [
    PipesModule,
    CustomDateTimeModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  declarations: [DisciplineCycleAddDialogPage],
})
export class DisciplineCycleAddDialogPageModule {}

import { Injectable } from '@angular/core';
import { ILocalNotification, LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {
  constructor(private localNotifications: LocalNotifications) {
    this.localNotifications.setDefaults({
      led: { color: '#FF00FF', on: 500, off: 500 },
      color: '#FF6600',
      smallIcon: 'ic_logo_push_notification',
      vibrate: true,
      priority: -1,
      lockscreen: true
    });
  }

  cancelAll() {
    return this.localNotifications.cancelAll();
  }

  on(eventName: string) {
    return this.localNotifications.on(eventName);
  }

  schedule(options : ILocalNotification | ILocalNotification[]) {
    try{
      this.localNotifications.schedule(options)
    } catch (e) {
      console.error(e);
    }
  }
}

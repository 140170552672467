export class Util {
  static validateEmail(email) {
    const reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (reg.test(email)) {
      return true;
    } else {
      return false;
    }
  }
  static validarCPF(strCPF: string) {
    strCPF = strCPF.replace(/\D/g, ''); // Remove mask

    let soma = 0;
    let resto;

    if (strCPF == '00000000000') return false;

    for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(strCPF.substring(9, 10))) return false;

    soma = 0;

    for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  static setTimeoutPromise(ms: number) : Promise<boolean> {
    return new Promise((resolve)=> {
      setTimeout(()=> resolve(true), ms)
    });
  }
}

export const ESTUDAQUI_PLANO_TRIAL = 'TEBTLKF3UO';
export const ESTUDAQUI_PLANO_MENSAL = 'jrh3UAeEGE';
export const ESTUDAQUI_PLANO_TRIMESTRAL = 'EL6pHCQYFH';
export const ESTUDAQUI_PLANO_ANUAL = 'jxG67E8MSJ';

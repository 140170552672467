import { Injectable } from "@angular/core";
import { Color } from "@models/Color";
import { DisciplinasService } from "@app/@services/api/disciplinas/disciplinas.service";
import { StorageService } from "@app/@services/storage/storage.service";
import { interval } from "rxjs";
import { ClearCachingService } from "../clear-caching/clear-caching.service";

@Injectable({
  providedIn: "root",
})
export class ColorCachingService {
  entityName = "cachedColors";
  cachedData: Array<Color> = [];
  lastUpdate: Date = null;
  syncTimer = interval(60000);

  constructor(
    private clearCaching: ClearCachingService<Color>,
    private subjectProvider: DisciplinasService,
    private storage: StorageService
  ) {}
  async clear() {
    return await this.clearCaching.clear(this);
  }

  async sync(): Promise<void> {
    try {
      await this.read();
      const result = (await this.subjectProvider.syncColors(
        this.lastUpdate
      )) as Array<Color>;
      this.lastUpdate = new Date();

      if (!this.cachedData || this.cachedData.length === 0) {
        this.cachedData = result;
      } else {
        result.forEach((color) => {
          const cachedColor: Color = this.cachedData.filter(
            (s) => s.objectId == color.objectId
          )[0];
          if (cachedColor) {
            const cachedIndex = this.cachedData
              .map((cColor) => cColor.objectId)
              .indexOf(color.objectId);
            const mergedColor: Color = Object.assign(cachedColor, color);
            this.cachedData[cachedIndex] = mergedColor;
          } else {
            this.cachedData.push(color);
          }
        });
      }

      await this.storage.set(this.entityName, this.cachedData);
      await this.storage.set(`${this.entityName}LastUpdate`, this.lastUpdate);
    } catch (error) {
      console.log(error);
    }
  }

  async read(): Promise<Color[]> {
    const _lastUpdate = await this.storage.get(`${this.entityName}LastUpdate`);
    this.lastUpdate = _lastUpdate ? new Date(_lastUpdate) : null;
    if (this.cachedData.length === 0)
      this.cachedData = (await this.storage.get(
        this.entityName
      )) as Array<Color>;
    return this.cachedData;
  }
}

<ion-content fullscreen>

  <header class="header-bg2" *ngIf="this.origin === 'BENEFITS_IOS'">
    <a *ngIf="this.origin === 'BENEFITS_IOS'" class="close-button">
      <ion-icon name="close" (click)="dismiss()"></ion-icon>
    </a>
  </header>

  <header class="header-bg" *ngIf="!showOffline  && this.origin !== 'BENEFITS_IOS'">
    <a *ngIf="showCloseButton" class="close-button">
      <ion-icon name="close" (click)="dismiss()"></ion-icon>
    </a>
    <h1 *ngIf="isFreemium" class="text-center">Ops, acesso Premium</h1>
    <h1 *ngIf="!isFreemium" class="text-center">{{ isTrial ? 'Ops, acesso Trial expirado' : 'Ops, acesso Premium
      expirado' }}</h1>

    <a *ngIf="false" class="see-video">
      <img src="assets/imgs/plans-compare/see-video.png" (click)="openVideo()" />
    </a>
  </header>

  <header class="header-bg" *ngIf="showOffline && this.origin !== 'BENEFITS_IOS'">
    <a *ngIf="showCloseButton" class="close-button">
      <ion-icon name="close" (click)="dismiss()"></ion-icon>
    </a>
    <a *ngIf="!showCloseButton && isUserLogged && showDesktopAlert " class="close-button">
      <ion-icon name="close" (click)="dismissAndLogout()"></ion-icon>
    </a>
    <h1 class="text-center">Ops, {{showDesktopAlert ? 'versão' : 'uso' }} <strong>{{showDesktopAlert ? 'Web' : 'Offline' }}</strong> apenas <br /> com Plano Premium </h1>
    <p *ngIf="origin === 'BLOCK_DESKTOP_FREEMIUM' " class="text-center">Para acesso grátis use o aplicativo no seu smartphone</p>
    <a *ngIf="!showDesktopAlert"  class="see-offline">
      <ion-icon color="danger" size="large" name="cloud-offline"></ion-icon>
    </a>
  </header>

  <app-expiration-alert *ngIf="showExpiraitonAlert" [isTrial]="isTrial" [user]="user"></app-expiration-alert>

  <app-compare-table></app-compare-table>
  <app-talking-about></app-talking-about>
  <app-benefits></app-benefits>

</ion-content>
<app-footer-action *ngIf="!showOffline || showDesktopAlert" [isDesktop]="showDesktopAlert" [showFreemiumAdsText]="showFreemiumAdsText"
  [canAcceptTrial]="canAcceptTrial" [isTrial]="isTrial" [isFreemium]="isFreemium" [isPaywall]="isPaywall"
  (openAds)="openAds()" (openSubscribePlan)="openSubscribe()" (openRestoreSubscribe)="restoreSignature()"
  (acceptFreemium)="acceptFreemium()" (dismiss)="dismiss()">
</app-footer-action>

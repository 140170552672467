import { Component, Input } from '@angular/core';
import { User } from '@models/User';

@Component({
  selector: 'app-expiration-alert',
  templateUrl: './expiration-alert.component.html',
  styleUrls: ['./expiration-alert.component.scss']
})
export class ExpirationAlertComponent {
  @Input()
  user: User;

  @Input()
  isTrial: boolean;
}

import { Component, OnInit } from "@angular/core";
import { UserService } from "@app/@services/api/user/user.service";
import { BasePageController } from "../BasePage";

@Component({
  selector: "app-notification-settings",
  templateUrl: "./notification-settings.page.html",
  styleUrls: ["./notification-settings.page.scss"],
})
export class NotificationSettingsPage extends BasePageController {
  notificationSettings = {
    notifyAboutReviews: true,
  };
  newsletterGranted: boolean | undefined;

  constructor() {
    super();
  }

  async ionViewWillEnter() {
    const loading = await this.showLoading();

    const settings = await this.storageService.get("notificationSettings");
    if (settings) this.notificationSettings = settings;

    try {
      this.newsletterGranted = await this.userService.fetchNewsletterGranted();
    } catch (e) {
      console.log(e);
      this.showToastMessage("Um erro ocorreu, tente novamnete");
    }

    loading.dismiss();
  }

  async toggleState() {
    await this.storageService.set(
      "notificationSettings",
      this.notificationSettings
    );
  }

  async onNewsletterToggle(event) {
    const { checked } = event.detail;
    console.log({ checked });
    this.newsletterGranted = checked;
  }

  onSave() {
    this.networkService.executeIfOnline(async () => {
      const loading = await this.showLoading();
      try {
        await this.userService.updateNewsletterGranted(this.newsletterGranted);
        this.showToastMessage("Notificações salvas com sucesso.");
        this.dismiss();
      } catch (e) {
        this.showToastMessage("Um erro ocorreu, tente novamente");
      }
      loading.dismiss();
    });
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

<div class="container-exercises-total">
  <div class="container-exercises-symbol">
    <div class="container-img">
      <img src="assets/imgs/icon-editar.png" />
      <app-percentage-circle [percent]="100"></app-percentage-circle>
    </div>
  </div>
  <div class="container-time">
    <span>Exercícios</span>
    <span class="exercises-percentage">{{ exercisesRatio }}% - {{ totalCorrects }}/{{ totalExercises }}</span>
  </div>
</div>
<div class="slides-time-chart">
<ion-slides [ngClass]="{ 'has-charts': hasStatistics }" pager (ionSlidesDidLoad)="slideChanged($event)"
(ionSlideDidChange)="slideChanged($event)">

  <ion-slide  class="ion-no-padding ion-padding-top" [ngClass]="{ 'has-chart': hasStatistics }">
    <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">
      <div class="chart ion-padding-top"  id="chart-1"></div>
    </div>
    <div class="empty-grafico ion-padding" *ngIf="!hasStatistics">
      <p class="sample-title ion-padding">
        Não houve marcação de exercícios nesse período.<br /><span class="small">(exemplo do gráfico abaixo)</span>
      </p>

      <figure class="sample-image">
        <img src="assets/imgs/statistics/exercicios-1.png" alt="" />
      </figure>
    </div>
  </ion-slide>

  <ion-slide  class="ion-no-padding ion-padding-top" [ngClass]="{ 'has-chart': hasStatistics }">
    <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">
      <div class="chart ion-padding-top" id="chart-2"></div>
    </div>
    <div class="empty-grafico ion-padding" *ngIf="!hasStatistics">
      <p class="sample-title ion-padding">
        Não houve marcação de exercícios nesse período.<br /><span class="small">(exemplo do gráfico abaixo)</span>
      </p>

      <figure class="sample-image">
        <img src="assets/imgs/statistics/exercicios-2.png" alt="" />
      </figure>
    </div>
  </ion-slide>

  <ion-slide  class="ion-no-padding ion-padding-top" [ngClass]="{ 'has-chart': hasStatistics }">
    <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">
      <div class="chart ion-padding-top" id="chart-3"></div>
    </div>
    <div class="ion-paddding empty-grafico" *ngIf="!hasStatistics">
      <p class="sample-title ion-padding">
        Não houve marcação de exercícios nesse período.<br /><span class="small">(exemplo do gráfico abaixo)</span>
      </p>

      <figure class="sample-image ion-padding">
        <img src="assets/imgs/statistics/exercicios-3.png" alt="" />
      </figure>
    </div>
  </ion-slide>
</ion-slides>
</div>
export interface Tag {
  tag: string;
  unidade_1?: string;
  unidade_2?: string;
  unidade_3?: string;
}

// Tags constants
export const CONCLUIU_ROBO_INICIAL = 'concluiu_robo_inicial';
export const CONHECE_CICLO = 'conhece_ciclo';
export const CRIAR_CICLO = 'criar_ciclo';
export const DEFINIU_METAS = 'definiu_metas';
export const ESTUDO_LIVRE = 'estudo_livre';
export const NAO_CONHECE_CICLO = 'nao_conhece_ciclo';
export const SELECIONOU_OBJETIVO = 'selecionou_objetivo';
export const STATISTIC_CREATED = 'statistic_created';
export const DELETED_ACCOUNT = 'deleted_account';
export const MARKATION_IN_FOUR_DAYS = 'markation_in_four_days';
export const GENERATED_BANKSLIP = 'generated_bankslip';
export const ACCESS_LEAST_ONCE_WITHIN_7_DAYS = 'access_least_once_within_7_days';

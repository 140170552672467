<app-common-header
  title="Disciplinas e Assuntos"
  [isDark]="true"
  (leftIconTap)="dismiss()"
  [hasFilter]="false"
  (rightIconTap)="openInfoModal()"
  [hasQuestionTutorial]="true"
>
</app-common-header>

<ion-content class="ion-text-center">
  <p class="ion-margin">Clique para gerenciar as disciplinas e assuntos</p>
  <div class="items ion-padding">
    <div *ngFor="let discipline of disciplines" class="item no-left-radius" (click)="goToDisciplineTopics(discipline)">
      <div class="info" [style.borderLeftColor]="discipline?.cor?.codigo">
        <h2 class="title">{{ discipline.descricao }}</h2>
        <h3 class="subtitle">{{ discipline.numTopics ? discipline.numTopics : 0 }} Assuntos</h3>
      </div>
      <div class="actions">
        <ion-button color="light" slot="icon-only" fill="clear">
          <ion-icon name="chevron-forward"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="openModalCreateDiscipline()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

import { Component, OnInit } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
import { PagarmeSubscription } from '@models/PagarmeSubscription';
import { User } from '@models/User';
import { MoipService } from '@app/@services/api/moip/moip.service';
import { PagarmeService } from '@app/@services/api/pagarme/pagarme.service';
import moment from 'moment';
import { CheckoutSelectAndroidPlanPage } from '../checkout-select-android-plan/checkout-select-android-plan.page';
import { GooglePurchaseService } from '@services/google-purchase/google-purchase.service';
import { InAppPurchaseService } from '@services/in-app-purchase/in-app-purchase.service';
import { HowToCancelPlanComponent } from './how-to-cancel-plan/how-to-cancel-plan.component';

@Component({
  selector: 'app-current-plan',
  templateUrl: './current-plan.page.html',
  styleUrls: ['./current-plan.page.scss']
})
export class CurrentPlanPage extends BasePageController {
  assinatura: any;
  user: User;
  isFetchingData = true;
  creationDate: string;
  nextInvoiceDate: string;
  nomePlano: string;
  urlImagemPlano: string;
  status: string;
  expiryDate: string;
  constructor(
    private moip: MoipService,
    private pagarmeService: PagarmeService,
    private googlePurchaseService: GooglePurchaseService,
    private inAppPurchaseService: InAppPurchaseService
  ) {
    super();
    this.user = this.userStoreService.user;
  }

  get isFreemium() {
    return this.userStoreService.userIsFreemium();
  }

  get isInternalPlanOnly() {
    return this.user && this.user.planoAtual.isInternalOnly;
  }

  get isPagarmeSubscription() {
    return this.user && this.user?.assinaturaAtual?.pagarmeSubscriptionId;
  }

  get isGooglePaySubscription() {
    return (
      this.user &&
      this.user.assinaturaAtual &&
      this.user.assinaturaAtual.payment_method &&
      this.user.assinaturaAtual.payment_method === 'IN_APP_PURCHASE_GOOGLE'
    );
  }

  get enableRestore() {
    return (
      this.user &&
      !this.isGooglePaySubscription &&
      this.inAppPurchaseService.ownedProduct &&
      this.inAppPurchaseService.ownedProduct.owned
    );
  }

  async ionViewWillEnter() {
    await this.loadPlanData();
    this.isFetchingData = false;
  }

  formatDate(dateObject) {
    const { day, month, year } = dateObject;
    return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`;
  }

  async confirmarCancelamentoRenovacaoAutomatica() {
    const loading = await this.showLoading('Cancelando...');

    const code = this.assinatura.code;

    try {
      await this.moip.suspenderAssinatura({ code });
      this.toast('Renovação automática cancelada com sucesso!');
      await this.reloadScreen();
    } catch (err) {
      this.toast('Ocorreu um erro ao cancelar a renovação automática.');
    }

    await loading.dismiss();
  }

  async confirmarRetomadaRenovacaoAutomatica() {
    const loading = await this.showLoading('Retomando...');

    const code = this.assinatura.code;

    try {
      await this.moip.retomarAssinatura({ code });
      this.toast('Renovação automática retomada com sucesso!');
      await this.reloadScreen();
    } catch (err) {
      this.toast('Ocorreu um erro ao retomar a renovação automática.');
    }

    loading.dismiss();
  }

  cancelarRenovacaoAutomatica() {
    this.alert('Atenção', 'Certeza que deseja cancelar a renovação automática do seu plano?', () =>
      this.confirmarCancelamentoRenovacaoAutomatica()
    );
  }

  async cancelPagarmeSubscription() {
    const result = await this.Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      cancelButtonText: 'NÃO',
      confirmButtonText: 'SIM',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      showCancelButton: true,
      title: 'Atenção',
      html: `<p class="sweet-alert-text">Você está prestes a cancelar a renovação automática da sua assinatura. Seu acesso permanecerá até ${this.expiryDate}.</p>
      <p class="sweet-alert-text">Caso queira reativar sua renovação posteriormente, será necessário fazer uma nova assinatura.</p>
      <p class="sweet-alert-text">Deseja continuar?</p>`
    });

    if (result.value) {
      const loading = await this.showLoading('Retomando...');
      try {
        // 517169
        const result = await this.pagarmeService.cancelSubscription(this.user.assinaturaAtual.pagarmeSubscriptionId);
        this.status = result.status;
        this.toast('Assinatura cancelada com sucesso!');
      } catch (e) {
        let message = 'Erro ao cancelar a assinatura';
        if (e.message && e.message.response) {
          const errorResponse = e.message.response;
          if (errorResponse.errors.length > 0) message = errorResponse.errors[0].message;
        }
        this.toast(message);
      }
      await loading.dismiss();
    }
  }

  retomarRenovacaoAutomatica() {
    this.alert('Atenção', 'Certeza que deseja retomar a renovação automática do seu plano?', () =>
      this.confirmarRetomadaRenovacaoAutomatica()
    );
  }

  async reloadScreen() {
    try {
      this.isFetchingData = true;
      this.ionViewWillEnter();
    } catch (err) {
      this.popActivity();
    }
  }

  statusIsActive() {
    return this.status === 'ACTIVE' || (this.isPagarmeSubscription && this.status === 'paid');
  }

  statusIsSuspended() {
    return this.status === 'SUSPENDED' || (this.isPagarmeSubscription && this.status !== 'paid');
  }

  reSubscribe() {
    this.modalCtrl.dismiss().then(async () => {
      const modal = await this.modalCtrl.create({ component: CheckoutSelectAndroidPlanPage });
      modal.present();
    });
  }

  private definePlanImage() {
    this.urlImagemPlano = 'assets/imgs/planos/mensal.png';
    if (this.user.planoAtual.duracaoDias === 90) this.urlImagemPlano = 'assets/imgs/planos/trimestral.png';
    else if (this.user.planoAtual.duracaoDias === 365) this.urlImagemPlano = 'assets/imgs/planos/anual.png';
  }

  private loadLocalPlanData() {
    this.nomePlano = this.user.planoAtual.nome;
    const creationDateAux = this.isFreemium ? this.user.planoAtual.createdAt : this.user.assinaturaAtual?.createdAt;

    this.creationDate = moment(creationDateAux).format('DD/MM/YYYY');
    this.expiryDate = moment(this.user.validade).format('DD/MM/YYYY');
    this.definePlanImage();
  }

  async loadPlanData() {
    if (this.isInternalPlanOnly) {
      this.loadLocalPlanData();
      return;
    }

    const { planoAtual, assinaturaAtual } = await this.userService.isUserExpired(this.user.objectId);
    this.user.planoAtual = planoAtual;
    this.user.assinaturaAtual = assinaturaAtual;

    if (this.isGooglePaySubscription) {
      this.status = assinaturaAtual.fatura_status;
      this.expiryDate = moment(this.user.validade).format('DD/MM/YYYY');
      this.nomePlano = planoAtual.nome;
      this.creationDate = moment(assinaturaAtual.createdAt).format('DD/MM/YYYY');
      this.definePlanImage();
      return;
    }

    if (this.isPagarmeSubscription) {
      let pagarmeSubscription: PagarmeSubscription;
      try {
        pagarmeSubscription = (await this.pagarmeService.findSubcription(
          this.user.assinaturaAtual.pagarmeSubscriptionId
        )) as PagarmeSubscription;
      } catch (e) {
        this.loadLocalPlanData();
        this.toast('Falha ao buscar informações da assinatura');
        return;
      }

      this.status = pagarmeSubscription.status;
      this.expiryDate = moment(this.user.validade).format('DD/MM/YYYY');
      this.nomePlano = this.user.planoAtual.nome;
      this.creationDate = moment(pagarmeSubscription.date_created).format('DD/MM/YYYY');
      this.definePlanImage();

      if (this.status === 'paid')
        this.nextInvoiceDate = moment(pagarmeSubscription.current_period_end).format('DD/MM/YYYY');

      return;
    }

    try {
      this.assinatura = this.user.assinaturaAtual;
      if (this.isGooglePaySubscription) {
        this.assinatura = await this.userService.isUserExpired(this.user.objectId).then((user) => user.assinaturaAtual);
      } else if (!this.isGooglePaySubscription) {
        this.assinatura = await this.moip.consultarAssinatura(this.user.objectId);
      }

      if (this.assinatura) {
        this.status = this.assinatura.status;
        this.expiryDate = moment(this.user.validade).format('DD/MM/YYYY');

        if (this.assinatura.plan.code === 'Estudaqui_Anual') {
          this.nomePlano = 'Anual';
          this.urlImagemPlano = 'assets/imgs/planos/anual.png';
        } else if (this.assinatura.plan.code === 'Estudaqui_Trimestral') {
          this.nomePlano = 'Trimestral';
          this.urlImagemPlano = 'assets/imgs/planos/trimestral.png';
        } else {
          this.nomePlano = 'Mensal';
          this.urlImagemPlano = 'assets/imgs/planos/mensal.png';
        }

        this.creationDate = this.formatDate(this.assinatura.creation_date);

        if (this.status === 'ACTIVE') {
          this.nextInvoiceDate = this.formatDate(this.assinatura.next_invoice_date);
        }
      }
    } catch (e) {
      this.loadLocalPlanData();
    }
  }

  async restoreGoogleSubscription() {
    const loading = await this.showLoading('', false);

    try {
      const product = this.inAppPurchaseService.ownedProduct;
      const transaction = product.transaction;
      if (transaction.type === 'android-playstore') {
        const { purchaseToken } = JSON.parse(transaction.receipt);
        await this.googlePurchaseService.restoreSubscription(purchaseToken, this.userStoreService.user.objectId);
      }
      loading.dismiss();
      this.toast('Sucesso');
      this.popActivity();
    } catch (e) {
      loading.dismiss();
      this.toast('Ocorreu um erro ao recuperar inscrição para o novo email');
    }
  }

  async handleHowToCancel() {
    const modal = await this.modalCtrl.create({ component: HowToCancelPlanComponent });
    modal.present();
  }

  toast(message: string) {
    this.showToastMessage(message);
  }

  async alert(title: string, message: string, callback: Function) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {}
        },
        {
          text: 'Sim',
          handler: () => {
            callback();
            return;
          }
        }
      ]
    });
    alert.present();
  }

  popActivity() {
    this.modalCtrl.dismiss();
  }
}

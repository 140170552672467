import { Injectable } from '@angular/core';
import { SessionExpiredModal } from '@app/pages/session-expired-modal/session-expired-modal.page';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SessionExpiredService {
  private isOpened: boolean;

  constructor(private modalCtrl: ModalController) {
    this.isOpened = false;
  }

  async execute() {
    if(!this.isOpened) {
      this.isOpened = true;
      await this.executeModal();
      this.isOpened = false;
    }
  }

  private async executeModal() {
    const modal = await this.modalCtrl.create({component: SessionExpiredModal, backdropDismiss: false});
    await modal.present();
  }


}

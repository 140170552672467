import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IonSlides } from "@ionic/angular";
import moment, { Moment } from "moment";
moment.locale("pt-BR");
const NUM_OF_DAYS = 7;

@Component({
  selector: "app-calendar-component",
  templateUrl: "./calendar-component.component.html",
  styleUrls: ["./calendar-component.component.scss"],
})
export class CalendarComponent implements OnInit {
  @ViewChild(IonSlides) slider: IonSlides;
  @Input() events: any = [];
  @Output() selectedDateEvent = new EventEmitter();
  @Output() slideChangedEvent = new EventEmitter();

  weekNames: Array<string> = ["S", "T", "Q", "Q", "S", "S", "D"];
  selectedDate: any;
  today: any;
  months: Array<any> = [];
  firstDay = false;

  slideOpts = {
    initialSlide: 1,
  };

  constructor() {}

  ngOnInit() {
    const currentMonth = moment(new Date());
    this.selectedDate = moment(new Date());

    const prevMonth = currentMonth.clone().month(currentMonth.month() - 1);
    const nextMonth = currentMonth.clone().month(currentMonth.month() + 1);
       
    this.init(prevMonth);    
    this.init(currentMonth);
    this.init(nextMonth);

  }

  init(month: Moment) {
    const monthObj = {};
    monthObj["selectedMonth"] = month;
    this.initMonth(monthObj);
    this.months.push(monthObj);
  }

  setDayToMonday(dateLocal: Moment): Moment {
    return dateLocal.day(1).hour(0).minute(0).second(0).millisecond(0);
  }

  createWeek(forDateObj) {
    const weekDays = [];
    let count = 0;

    while (count < NUM_OF_DAYS) {
      weekDays.push(forDateObj);
      forDateObj = forDateObj.clone();
      forDateObj.add(1, "d");
      count++;
    }
    return weekDays;
  }

  isToday(day) {
    if(!this.today){
      this.today = moment();
    }
    return this.today.isSame(day, 'day');
  }
  private getDayEvent(day) {
    const events: any = this.events;
    const dia = moment(day).format("YYYY-MM-DD");
    return events.horarios[dia];
  }

  temEvento(dia, monthObj?, rowIndex?) {
    const d = moment(dia).format("YYYY-MM-DD");
    const dados: any = this.events;
    const keys = Object.keys(dados.horarios);

    if (keys[0] === d && this.firstDay == false) {
      this.firstDay = true;
      this.selectEventDay(monthObj, dia, rowIndex);
    }

    if (dados.horarios[d]) {
      return true;
    } else {
      return false;
    }
  }

  createMonth(monthObj, forMonthObj: Moment) {
    monthObj.weeks = [];
    const month = forMonthObj.clone();
    let done = true;

    while (done) {
      monthObj.weeks.push({ days: this.createWeek(month.clone()) });
      monthObj.weeks.push({});
      month.add(1, "w");

      if (month.month() !== monthObj.selectedMonth.month()) {
        done = false;
      }
    }
  }

  initPrev(month) {
    const monthObj = {};
    monthObj["selectedMonth"] = month;
    this.initMonth(monthObj);
    this.months.unshift(monthObj);
    this.months.pop();
  }

  initMonth(monthObj) {
    monthObj.selectedDate = monthObj.selectedMonth.clone();
    monthObj.current = monthObj.selectedDate.clone();
    const startMonth = monthObj.selectedDate.clone();
    startMonth.date(1);
    this.setDayToMonday(startMonth.day(1));
    this.createMonth(monthObj, startMonth);
  }

  selectEventDay(monthObj, day, rowIndex) {
    if (day.isSame(monthObj.selectedDate) && monthObj.selectedRowIndex !== -1) {
      monthObj.selectedRowIndex = -1;
    } else {
      monthObj.selectedRowIndex = rowIndex;
    }

    monthObj.selectedDate = day;
    this.selectedDate = day;

    this.selectedDateEvent.emit({
      data: day,
    });
  }

  select(monthObj, day, rowIndex) {
    if (day.isSame(monthObj.selectedDate) && monthObj.selectedRowIndex !== -1) {
      monthObj.selectedRowIndex = -1;
    } else {
      monthObj.selectedRowIndex = rowIndex;
    }

    monthObj.selectedDate = day;
    this.selectedDate = day;

    this.selectedDateEvent.emit({
      data: day,
      event: this.getDayEvent(day),
    });
  }

  async handleSlideView() {
    const activeIndex = await this.slider.getActiveIndex();
    const activeMonth = this.months[activeIndex].selectedMonth;
    this.selectedDate = activeMonth;
    const nextMonth = activeMonth.clone().month(activeMonth.month() + 1);
    const prevMonth = activeMonth.clone().month(activeMonth.month() - 1);

    if (activeIndex === 0) {
      this.initPrev(prevMonth);
      this.slider.slideTo(1, 0, false);
    } else if (activeIndex === this.months.length - 1) {
      this.init(nextMonth);
      this.months.shift();
      this.slider.slideTo(this.months.length - 2, 0, false);
    }

    this.slideChangedEvent.emit({
      activeMonth,
      nextMonth,
      prevMonth,
    });
  }

  prev() {
    this.slider.slidePrev();
  }

  next() {
    this.slider.slideNext();
  }

  onSlideChanged() {
    this.handleSlideView();
  }
}

import { Injectable } from "@angular/core";
import * as moment from "moment";
import * as _ from "underscore";
import { UserSubjectUtils } from "./UserSubjectUtils";
import { DefineRevisionData } from "../models/events/DefineRevisionData";
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Review } from "@models/Review";
import { ModalController } from "@ionic/angular";
import { StorageService } from "@app/@services/storage/storage.service";
import { TabService } from '@services/tab-service/tab.service';
import { HelpBotPage } from "@app/shared/help-bot/help-bot.page";
import { CreateReviewPage } from "@app/reviews/create-review/create-review.page";
import { Router } from "@angular/router";
import { PremiumService } from "@services/premium/premium.service";

@Injectable({ providedIn: "root" })
export class ReviewUtils {
  constructor(
    private modalCtrl: ModalController,
    private localNotifications: LocalNotifications,
    private storage: StorageService, //private app: App,
    private router: Router,
    private tabService: TabService,
    private premiumService: PremiumService
  ) {}

  public loadTodayReviews(
    allReviews: Review[],
    today: moment.MomentInput
  ): Review[] {
    return allReviews
      ? allReviews.filter(
          (review) =>
            !review.hasPendingDelete &&
            moment(review.reviewDate).startOf("day").isSame(today) &&
            review.status === "active"
        )
      : [];
  }

  public static groupReviews(
    reviews: Review[],
    field = "reviewDate"
  ): { reviewDate: string; reviews: Review[] }[] {
    const grouped = [];
    _.each(_.groupBy(reviews, field), (reviews: Review[], reviewDate: Date) =>
      grouped.push({ reviewDate, reviews })
    );
    return grouped;
  }

  public static reviewSortAsc(groupedReviews: any[], field = "reviewDate") {
    return groupedReviews.sort((a, b) => {
      const timeA = new Date(a[field]),
        timeB = new Date(b[field]);
      return timeA.getTime() - timeB.getTime();
    });
  }

  public static reviewSortDesc(groupedReviews: any[], field = "reviewDate") {
    return groupedReviews.sort((a, b) => {
      const timeA = new Date(a[field]),
        timeB = new Date(b[field]);
      return timeB.getTime() - timeA.getTime();
    });
  }

  public async showReviewWarning() {
    const modal = await this.modalCtrl.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      animated: false,
      componentProps: {
        actionsType: "GENERAL_YES_NO",
        useInterviewActions: true,
        interviewActionsButtonHandler: async (buttonIndex) => {
          if (buttonIndex == 1) {
            modal.dismiss();
            await this.router.navigate(["tabs/review-list"], {replaceUrl: true});
          }
        },
        avatar: "assets/imgs/metas/help-bot.png",
        message: `Você tem revisão programada para hoje!
        <Deseja visualizar suas revisões agora?>
        OBS: você poderá acessá-las a qualquer momento no botão "Revisões" da tela principal do aplicativo.`,
        title: "Revisão",
      },
    });

    modal.present();
  }

  public async showFinishedReviewsDialog(review: Review): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        actionsType: "GENERAL_YES_NO_WITH_CROWN",
        useInterviewActions: true,
        interviewActionsButtonHandler: async (buttonIndex) => {
          if (buttonIndex == 1) {
            const canOpen = await this.premiumService.waitResolvePaywall();

            if (canOpen) {
              const reviewModal = await this.modalCtrl.create({
                component: CreateReviewPage,
                cssClass: 'sd modal-transparent',
                componentProps: {
                  revisionData: {
                    date: moment().format('YYYY-MM-DD'),
                    subjectItem: UserSubjectUtils.toSubjectItem(review.userSubject),
                    topic: review.topic,
                    shouldDisableFields: true,
                    lastSequence: review.totalReviews
                  } as DefineRevisionData
                }
              });

              await reviewModal.present();
              reviewModal.onDidDismiss().then((result) => {
                this.tabService.getReview(true);
              });
            }
          }
        },
        avatar: "assets/imgs/metas/help-bot.png",
        message: `Você concluiu a última revisão programada para o assunto "${
          review.topic.description
            ? review.topic.description
            : review.topic.toString()
        }" da disciplina "${review.userSubject.descricao}".
        <Deseja agendar novas revisões desse assunto?>`,
        title: "Revisões finalizadas",
      },
    });

    await modal.present();
  }

  cancelAllNotifications() {
    return this.localNotifications.cancelAll();
  }
}

<ion-content>
  <div class="container-dismiss" tappable (click)="dismiss()"></div>

  <div class="container-modal">
    <ion-button fill="clear" class="btn-fechar" (click)="dismiss()">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
    <div class="line-marker"></div>

    <div
      class="item-top"
      [ngClass]="{'bg-regular': info.status == 'regular',
                         'bg-ruim': info.status == 'ruim',
                         'bg-bom': info.status == 'bom'}"
    >
      <div class="item-info">
        <div class="icon-status">
          <img
            src="assets/imgs/icon-smile-ruim-outline.png"
            *ngIf="info.status == 'ruim'"
          />
          <img
            src="assets/imgs/icon-smile-regular-outline.png"
            *ngIf="info.status == 'regular'"
          />
          <img
            src="assets/imgs/icon-smile-bom-outline.png"
            *ngIf="info.status == 'bom'"
          />
        </div>
        <div class="text-info">
          <p>{{info.dia}}</p>
          <h3>{{info.data}}</h3>
        </div>
      </div>
    </div>

    <div class="item-detalhe">
      <div class="summary">
        <div class="summary-item">
          <div class="circle total">
            <img class="img-1" src="assets/imgs/icon-ampulheta-2x.png" />
          </div>
          <p class="legend">
            Total <br />
            {{this.tempoTotal()}}
          </p>
        </div>

        <div class="summary-item">
          <div class="circle goal">
            <ion-icon name="radio" aria-hidden="true"></ion-icon>
          </div>
          <p class="legend">
            Meta <br />
            {{this.getHora(info.meta)}}
          </p>
        </div>

        <div class="summary-item">
          <div class="circle exercise">
            <img class="img-3" src="assets/imgs/icon-editar.png" />
          </div>
          <p class="legend">
            Exercício <br />
            {{this.getHora(info.tempo_estudo.exercicio)}}
          </p>
        </div>

        <div class="summary-item">
          <div class="circle review">
            <img class="img-4" src="assets/imgs/icon-revisao.png" />
          </div>
          <p class="legend">
            Revisão <br />
            {{this.getHora(info.tempo_estudo.revisao)}}
          </p>
        </div>
      </div>

      <p class="p-divider">
        <span class="grow">Disciplinas:</span>
        <span>
          <ion-icon class="time-tracking-icons" name="play"></ion-icon>
          <ion-icon class="time-tracking-icons" name="pause"></ion-icon>
        </span>
      </p>

      <div class="container-disciplinas">
        <div
          class="item-disciplinas"
          *ngFor="let item of disciplinas; let i = index;"
        >
          <div class="lb-nome">
            <div class="bolinha" [ngStyle]="{background: item.cor }"></div>
            {{item.name}}
          </div>
          <div class="lb-tempo">
            {{this.getHora(item.data[0])}} | {{this.getHora(item.tempoParado)}}
          </div>
        </div>
        <!-- TOTAL----------- -->
        <div class="item-disciplinas tp-margin tp-margin-top">
          <div class="lb-nome">Total</div>
          <div class="lb-tempo">
            {{this.tempoTotal()}} | {{this.tempoDisperdicado()}}
          </div>
        </div>
      </div>
    </div>
    <div id="graficoDia" style="max-height: 500px;" class="container-grafico"></div>
  </div>
</ion-content>

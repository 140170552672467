import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DateTimeDialogEventsService } from "@app/directives/custom-date-time/date-time-dialog-events.service";
import {
  IonButton,
  ModalController,
  NavParams,
  ToastController,
} from "@ionic/angular";

@Component({
  selector: 'app-dialog-test',
  templateUrl: './dialog-test.page.html',
  styleUrls: ['./dialog-test.page.scss']
})
export class DialogTestPage {
  data = {
    hours: '00',
    minutes: '00',
    seconds: '00',
    month: '4',
    year: '2020',
    day: '31'
  };

  formTime: FormGroup;

  formDate: FormGroup;

  statePress = '';

  @ViewChild('upButton')
  upButton: IonButton;

  @ViewChild('inputDay')
  inputDay: ElementRef;

  @ViewChild('inputYear')
  inputYear: ElementRef;

  @ViewChild('inputHours')
  inputHours: ElementRef;

  @ViewChild('inputMinutes')
  inputMinutes: ElementRef;

  @ViewChild('inputSeconds')
  inputSeconds: ElementRef;

  @Input('pickerType')
  pickerType = 'time';

  timer: any;

  @ViewChild('selectMonth')
  selectMonth: ElementRef;

  @Input('timeFormat')
  maxHours = 24;

  @Input('tickMinutes')
  tickMinutes = 1;

  inputTime: string;
  inputDate: string;

  months: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];

  keepValue: any = '';

  @Input('customDateTime')
  customDateTime = 'time';

  @Input('time')
  time: string;

  @Input('hideSeconds')
  hideSeconds = false;

  @Input()
  pickerTitle: string;

  @Input('date')
  date: string;

  get resultDate() {
    const m: number = parseInt(this.month.value) + 1;
    const d: number = parseInt(this.day.value);
    const month: string = m < 10 ? `0${m}` : m.toString();
    let day: string = d < 10 ? `0${d}` : d.toString();
    day = isNaN(d) ? '' : day;
    return `${this.year.value}-${month}-${day}`;
  }

  get resultTime() {
    const h = parseInt(this.hours.value ? this.hours.value : '00');
    const m = parseInt(this.minutes.value ? this.minutes.value : '00');
    const s = parseInt(this.seconds.value ? this.seconds.value : '00');
    return [h, m, s].map((i) => (i < 10 ? `0${i}` : `${i}`)).join(':');
  }

  get formattedTime() {
    if (this.pickerType === 'date') {
      const m: number = parseInt(this.month.value) + 1;
      const d: number = this.day.value ? parseInt(this.day.value) : parseInt(this.keepValue);
      const y: number = this.year.value ? parseInt(this.year.value) : parseInt(this.keepValue);
      const month: string = m < 10 ? `0${m}` : m.toString();
      let day: string = d < 10 ? `0${d}` : d.toString();
      day = isNaN(d) ? '' : day;
      return `<span>${day}</span>/<span>${month}</span>/<span>${y}<span>`;
    }

    const h = this.hours.value ? this.hours.value : this.keepValue;
    const m = this.minutes.value ? this.minutes.value : this.keepValue;
    const s = this.seconds.value ? this.seconds.value : this.keepValue;

    if (this.hideSeconds) return `<span>${h}</span>:<span>${m}</span>`;

    return `<span>${h}</span>:<span>${m}</span>:<span>${s}</span>`;
  }

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private toastCtrl: ToastController,
    private dateTimeEvents: DateTimeDialogEventsService
  ) {
    this.formTime = new FormGroup({
      hours: new FormControl(null, [
        Validators.required,
        Validators.max(23),
        Validators.min(0),
        Validators.pattern(/\d+/)
      ]),
      minutes: new FormControl(null, [
        Validators.required,
        Validators.max(59),
        Validators.min(0),
        Validators.pattern(/\d+/)
      ]),
      seconds: new FormControl(null, [
        Validators.required,
        Validators.max(59),
        Validators.min(0),
        Validators.pattern(/\d+/)
      ])
    });

    this.formDate = new FormGroup({
      month: new FormControl(null, [Validators.required, Validators.pattern(/\d+/)]),
      year: new FormControl(null, [Validators.required, Validators.min(1900), Validators.pattern(/\d+/)]),
      day: new FormControl(null, [])
    });

    this.formDate
      .get('day')
      .setValidators([
        Validators.required,
        Validators.min(1),
        Validators.pattern(/\d+/),
        (control: AbstractControl) =>
          Validators.max(
            this.getLastDayOfMonth(
              parseInt(this.formDate.get('year').value),
              parseInt(this.formDate.get('month').value)
            )
          )(control)
      ]);

    this.pickerType = this.navParams.get('type') || 'time';
    this.pickerTitle = this.navParams.get('pickerTitle');
    this.inputTime = this.navParams.get('time') ? this.navParams.get('time') : '00:00:00';
    this.inputDate = this.navParams.get('date');
    this.hideSeconds = this.navParams.get('hideSeconds');
    if (this.inputTime) {
      const split = this.inputTime.split(':');
      this.formTime.get('hours').setValue(split[0] ? split[0] : '00');
      this.formTime.get('minutes').setValue(split[1] ? split[1] : '00');
      this.formTime.get('seconds').setValue(split[2] ? split[2] : '00');
    }

    const today = new Date();

    if (this.inputDate) {
      const split = this.inputDate.split('-');
      this.formDate.get('day').setValue(split[2] ? split[2] : today.getDate().toString());
      this.formDate.get('month').setValue(split[1] ? parseInt(split[1]) - 1 : today.getMonth().toString());
      this.formDate.get('year').setValue(split[0] ? split[0] : today.getFullYear().toString());
    } else {
      this.formDate.get('day').setValue(today.getDate().toString());
      this.formDate.get('month').setValue(today.getMonth().toString());
      this.formDate.get('year').setValue(today.getFullYear().toString());
    }

    window.addEventListener('touchend', () => {
      if (this.timer) clearInterval(this.timer);
    });
  }

  get hours() {
    return this.formTime.get('hours');
  }

  get minutes() {
    return this.formTime.get('minutes');
  }

  get seconds() {
    return this.formTime.get('seconds');
  }

  get day() {
    return this.formDate.get('day');
  }

  get month() {
    return this.formDate.get('month');
  }

  get year() {
    return this.formDate.get('year');
  }

  onKeyDown(evt, unit = '') {
    if (evt.keyCode !== 13) return;
    evt.target.blur();

  }

  onSelectChange() {
    this._checkConstraints();
  }

  openHelpBot() {
    this.dateTimeEvents.add({
      type: 'open-help'
    });
  }

  private _upMonth() {
    let currentMonth: number = parseInt(this.month.value);
    const lastDayOfNextMonth = this.getLastDayOfMonth(parseInt(this.year.value), ++currentMonth);
    this.month.setValue((currentMonth % 12).toString());
    if (parseInt(this.day.value) > lastDayOfNextMonth) {
      this.day.setValue(lastDayOfNextMonth.toString());
    }
  }

  private async _checkConstraints() {
    if (this.pickerType === 'time') {
      const currentHour: number = parseInt(this.hours.value);
      const currentMinute: number = parseInt(this.minutes.value);
      const currentSecond: number = parseInt(this.seconds.value);

      if (currentHour > this.maxHours || currentHour < 0) {
        const message =
          currentHour > this.maxHours
            ? `O valor máximo desse campo é ${this.maxHours - 1} !`
            : 'O valor mínimo desse campo é 0 !';
        const inputElem = this.inputHours.nativeElement;
        inputElem.classList.add('error');

        const toast = await this.toastCtrl.create({
          duration: 2500,
          message: message
        });

        toast.present();

        toast.onDidDismiss().then(() => {
          inputElem.focus();
        });
      } else if (currentMinute > 60 || currentMinute < 0) {
        const message = currentMinute > 60 ? 'O valor máximo desse campo é 59 !' : 'O valor mínimo desse campo é 0 !';
        const inputElem = this.inputMinutes.nativeElement;
        inputElem.classList.add('error');
        const toast = await this.toastCtrl.create({
          duration: 2500,
          message: message
        });
        toast.present();

        toast.onDidDismiss().then(() => {
          inputElem.focus();
        });
      } else if (currentSecond > 60 || currentSecond < 0) {
        const message = currentSecond > 60 ? 'O valor máximo desse campo é 59 !' : 'O valor mínimo desse campo é 0 !';
        const inputElem = this.inputSeconds.nativeElement;
        inputElem.classList.add('error');
        const toast = await this.toastCtrl.create({
          duration: 2500,
          message: message
        });

        toast.present();

        toast.onDidDismiss().then(() => {
          inputElem.focus();
        });
      }

      return;
    }

    const currentYear: number = parseInt(this.year.value);
    const currentDay: number = parseInt(this.day.value);
    const currentMonth: number = parseInt(this.month.value);
    const lastDayOfMonth = this.getLastDayOfMonth(currentYear, currentMonth);

    if (currentDay > lastDayOfMonth || currentDay <= 0) {
      const message =
        currentDay > lastDayOfMonth
          ? `O valor máximo desse campo é ${lastDayOfMonth} !`
          : 'O valor mínimo desse campo é 1 !';
      const inputElem = this.inputDay.nativeElement;
      inputElem.classList.add('error');
      const toast = await this.toastCtrl.create({
        duration: 2500,
        message: message
      });

      toast.present();
      toast.onDidDismiss().then((v) => {
        inputElem.focus();
      });
    }
    if (currentYear < 1900) {
      const inputElem = this.inputYear.nativeElement;
      inputElem.classList.add('error');
      const toast = await this.toastCtrl.create({
        duration: 2500,
        message: 'O valor mínimo desse campo é 1900 !'
      });
      toast.present();
      toast.onDidDismiss().then(() => {
        inputElem.focus();
      });
    }
  }

  private _downMonth() {
    let currentMonth: number = parseInt(this.month.value);
    const lastDayOfNextMonth = this.getLastDayOfMonth(parseInt(this.year.value), --currentMonth);
    if (currentMonth < 0) currentMonth = 11;
    this.month.setValue((currentMonth % 12).toString());
    if (parseInt(this.day.value) > lastDayOfNextMonth) {
      this.day.setValue(lastDayOfNextMonth.toString());
    }
  }

  private _upDay() {
    let currentDay: number = parseInt(this.day.value);
    const currentMonth: number = parseInt(this.month.value);
    const maxDayInMonth = this.getLastDayOfMonth(parseInt(this.year.value), currentMonth);
    if (currentDay + 1 > maxDayInMonth) {
      this.day.setValue('1');
      return;
    }

    this.day.setValue((++currentDay % (maxDayInMonth + 1)).toString());
  }

  private _downDay() {
    let currentDay: number = parseInt(this.day.value);
    const currentMonth: number = parseInt(this.month.value);
    const maxDayInMonth = this.getLastDayOfMonth(parseInt(this.year.value), currentMonth);
    if (--currentDay <= 0) currentDay = maxDayInMonth;

    this.day.setValue((currentDay % (maxDayInMonth + 1)).toString());
  }

  private _upYear() {
    let currentYear: number = parseInt(this.year.value);
    const currentDay: number = parseInt(this.day.value);
    const currentMonth: number = parseInt(this.month.value);
    this.year.setValue((++currentYear).toString());
    const maxDayInMonth = this.getLastDayOfMonth(parseInt(this.year.value), currentMonth);
    if (currentDay > maxDayInMonth) this.year.setValue(maxDayInMonth.toString());
  }

  private _downYear() {
    let currentYear: number = parseInt(this.year.value);
    if (--currentYear < 1970) return;
    this.year.setValue(currentYear.toString());

    const currentDay: number = parseInt(this.day.value);
    const currentMonth: number = parseInt(this.month.value);
    const maxDayInMonth = this.getLastDayOfMonth(parseInt(this.year.value), currentMonth);
    if (currentDay > maxDayInMonth) this.day.setValue(maxDayInMonth.toString());
  }

  upButtonDate(unit) {
    switch (unit) {
      case 'month':
        this._upMonth();
        break;

      case 'day':
        this._upDay();
        break;

      case 'year':
        this._upYear();
        break;
    }
  }

  downButtonDate(unit, position = 'down') {
    switch (unit) {
      case 'month':
        this._downMonth();
        break;

      case 'day':
        this._downDay();
        break;

      case 'year':
        this._downYear();
        break;
    }
  }

  upButtonTime(unit = '') {
    if (unit === 'minutes') {
      this.upButtonMinutes();
      return;
    }

    const max = unit === 'hours' ? this.maxHours : 60;
    let currentValue = parseInt(this.formTime.get(unit).value);
    if (!isNaN(currentValue)) {
      currentValue = ++currentValue % max;
      this.formTime.get(unit).setValue(this._formatWithZero(currentValue));
    }
  }

  downButtonTime(unit = '') {
    if (unit === 'minutes') {
      this.downButtonMinutes();
      return;
    }

    const max = unit === 'hours' ? this.maxHours : 60;
    let currentValue = parseInt(this.formTime.get(unit).value);
    if (!isNaN(currentValue)) {
      if (--currentValue < 0) currentValue = max - 1;
      currentValue = currentValue % max;
      this.formTime.get(unit).setValue(this._formatWithZero(currentValue));
    }
  }

  private upButtonMinutes() {
    const formPath = 'minutes';
    let currentValue = parseInt(this.formTime.get(formPath).value);
    const tick = this.tickMinutes;

    if (!isNaN(currentValue)) {
      if (currentValue + tick > 59) {
        currentValue = 0
      } else {
        currentValue = currentValue + tick;
      }

      this.formTime.get(formPath).setValue(this._formatWithZero(currentValue));
    }
  }

  private downButtonMinutes() {
    const formPath = 'minutes';
    let currentValue = parseInt(this.formTime.get(formPath).value);
    const tick = this.tickMinutes;
    
    if (!isNaN(currentValue)) {
      if (currentValue - tick < 0) {
        currentValue = 45
      } else {
        currentValue = currentValue - tick;
      }

      this.formTime.get(formPath).setValue(this._formatWithZero(currentValue));
    }
  }

  private _formatWithZero(n: number): string {
    return n < 10 ? `0${n}` : n.toString();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  /**
   * Returns the last of a given month
   * @param year Year
   * @param month Month (in JS mode)
   */
  getLastDayOfMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  longPressStart(unit, type = 'up') {
    const _type = type;
    if (this.timer) clearInterval(this.timer);

    if (this.pickerType === 'time') {
      this.timer = setInterval(() => {
        if (_type == 'up') {
          this.upButtonTime(unit);
        } else {
          this.downButtonTime(unit);
        }
      }, 66);
      return;
    }

    this.timer = setInterval(() => {
      if (_type == 'up') {
        this.upButtonDate(unit);
      } else {
        this.downButtonDate(unit);
      }
    }, 66);
  }

  longPressEnd(text = '', position = '') {
    if (this.timer) clearInterval(this.timer);
  }

  focusField(unit: string, event: Event = null) {
    if (event) {
      const fieldElem: any = event.target;
      fieldElem.classList.remove('error');
    }
    // this.keepValue = this.data[unit];
    if (this.pickerType == 'time') {
      this.keepValue = this.formTime.get(unit).value;
      this.formTime.get(unit).setValue('');
    } else if (this.pickerType == 'date') {
      this.keepValue = this.formDate.get(unit).value;
      this.formDate.get(unit).setValue('');
    }
  }

  blurField(unit: string) {
    const n: number = parseInt(this.data[unit]);
    if (isNaN(n)) this.data[unit] = this.keepValue;

    if (this.pickerType == 'time') {
      const n: number = parseInt(this.formTime.get(unit).value);
      if (isNaN(n)) this.formTime.get(unit).setValue(this.keepValue);
    } else if (this.pickerType == 'date') {
      const n: number = parseInt(this.formDate.get(unit).value);
      if (isNaN(n)) this.formDate.get(unit).setValue(this.keepValue);
    }

    this.keepValue = '';

    this._checkConstraints();
  }

  selectedDate() {
    this.modalCtrl.dismiss({
      time: this.resultTime,
      date: this.resultDate
    });
  }

  onInput(unit: string) {
    if (this.pickerType === 'time') this.formTime.get(unit).setValue(this.formTime.get(unit).value.replace(/\D/g, ''));
    else if (this.pickerType === 'date')
      this.formDate.get(unit).setValue(this.formDate.get(unit).value.replace(/\D/g, ''));
  }
}

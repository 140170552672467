export const commonEnv = {
  appVersion: '4.2.0',
  appVersionCode: 100110257,
  firebase: {
    apiKey: "AIzaSyCv7aFStwW6QMt5mK8HtvBiVzCHOABWza4",
    authDomain: "push-estudaqui.firebaseapp.com",
    databaseURL: "https://push-estudaqui.firebaseio.com",
    projectId: "push-estudaqui",
    storageBucket: "push-estudaqui.appspot.com",
    messagingSenderId: "1018418760703",
    appId: "1:1018418760703:web:b08f2f46130910a54af5ca",
    measurementId: "G-S1FVN0QVWT"
  },
  googleClientId: '1018418760703-bvq9453ifqs8m31svtehcnn6rpqup85h.apps.googleusercontent.com',
  facebookAppId: '405977553999839'
};

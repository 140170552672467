import { Component } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
import { ModalService } from '@services/modal/modal.service';
import { CheckoutPersonalDataPage } from '../checkout-personal-data/checkout-personal-data.page';
import { CheckoutSelectAndroidPlanPage } from '../checkout-select-android-plan/checkout-select-android-plan.page';
import { CurrentPlanPage } from '../current-plan/current-plan.page';

@Component({
  selector: 'app-current-subscription',
  templateUrl: './current-subscription.page.html',
  styleUrls: ['./current-subscription.page.scss']
})
export class CurrentSubscriptionPage extends BasePageController {
  constructor(private modalService: ModalService) {
    super();
  }

  async onPersonalData() {
    const modal = await this.modalCtrl.create({
      component: CheckoutPersonalDataPage
    });

    modal.present();
  }

  async onMySubscription() {
    const modal = await this.modalCtrl.create({
      component: CurrentPlanPage
    });

    modal.present();
  }

  async onSelectPlan() {
    const modal = await this.modalCtrl.create({
      component: CheckoutSelectAndroidPlanPage
    });

    modal.present();
    this.modalService.storeModal(modal);

  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

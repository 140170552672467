import { Injectable } from "@angular/core";
import { UserSubjectCachingService } from "@app/@services/api/caching/user-subject-caching/user-subject-caching.service";
import { SubjectItem, UserSubject } from "../models/Subject";

@Injectable({
  providedIn: "root",
})
export class UserSubjectUtils {
  constructor(private userSubjectCachingProvider: UserSubjectCachingService) {}

  async find(userSubject: Partial<UserSubject>): Promise<UserSubject> {
    const read = await this.userSubjectCachingProvider.read();
    return read.find(
      (obj) =>
        (obj.localObjectId &&
          obj.localObjectId === userSubject.localObjectId) ||
        (obj.objectId && obj.objectId === userSubject.objectId)
    );
  }

  static fromSubjectItemToUserSubject(subjectItem: SubjectItem): UserSubject {
    return {
      abreviatura: subjectItem.shortName,
      cor: subjectItem.color,
      descricao: subjectItem.name,
      tipo: subjectItem.type,
      user: null,
      objectId: subjectItem.objectId,
      localObjectId: subjectItem.localObjectId,
    };
  }

  static toSubjectItem(subject: UserSubject): SubjectItem {
    return {
      objectId: subject.objectId,
      localObjectId: subject.localObjectId,
      id: -1,
      time: "00:00",
      excluida: subject.excluida,
      subjectSearch: subject.descricao_busca,
      finished: false,
      color: subject.cor,
      shortName: subject.abreviatura,
      name: subject.descricao,
      type: subject.tipo,
      freeSubject: true,
      userSubject: true,
      lastUserSubject: false,
      displayName: subject.descricao,
      generalSubject: subject.disciplina_geral,
    } as SubjectItem;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MetasPage } from './metas.page';
import { SharedComponentsModule } from '@app/components/shared-components.module';
import { CustomDateTimeModule } from '@app/directives/custom-date-time/custom-date-time.module';
import { BotModule } from '@app/bot/bot.module';
import { MetasHelperComponent } from './metas-helper/metas-helper.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    SharedComponentsModule,
    SharedModule,
    CustomDateTimeModule,
    CommonModule,
    FormsModule,
    IonicModule,
    BotModule
  ],
  declarations: [MetasPage, MetasHelperComponent],
  exports: [MetasHelperComponent]
})
export class MetasPageModule {}

import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { WebUtils } from '@app/util/WebUtils';

@Injectable({
  providedIn: 'root'
})
export class ShouldRedirectToWelcomeWebGuard implements CanActivate {
  constructor(private router: Router) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const isWeb = WebUtils.isWeb();

    if (isWeb) {
      this.router.navigate(['welcome-web'], { replaceUrl: true });
      return false;
    }

    return true;
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChatMessagesComponent } from '@app/bot/chat-messages/chat-messages.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-offline-tutorials',
  templateUrl: './offline-tutorials.page.html',
  styleUrls: ['./offline-tutorials.page.scss'],
})
export class OfflineTutorialsPage implements OnInit {
  @ViewChild(ChatMessagesComponent, {static: true}) chat: ChatMessagesComponent;

  @Input()
  message: string;

  @Input()
  avatar: string = 'assets/imgs/metas/help-bot.png';

  @Input()
  title: string;

  @Input()
  youtubeURL: string;

  @Input()
  youtubeTextHTML: string | null;
  constructor(
    private modalCtrl: ModalController,
    private inAppBrowser: InAppBrowser
  ) { }

  ngOnInit() {
    if(!this.youtubeURL) throw new Error('Missing youtube URL');
    this.chat.clearChatAndTypeBotMessage(this.message);
  }

  openTutorialOnYouTube() {
    if(!this.chat.hasFinishedTyping()) {
      this.chat.showCompleteText()
      return;
    }

    this.dismiss();
    this.inAppBrowser.create(this.youtubeURL);
  }

  dismiss() {
    return this.modalCtrl.dismiss();
  }

}

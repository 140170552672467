<app-common-header
  (leftIconTap)="dismiss()"
  [isDark]="true"
  [hasLogo]="false"
  [hasDrawer]="false"
  [hasFilter]="false"
  title="Programar Revisão"
></app-common-header>

<ion-content class="ion-padding" fullscreen="true">
  <div class="container-modal">
    <div class="content">
      <div class="select-item ion-item-white-border" (click)="openDateDialog()">
        <span style="margin-left: 20px" class="ld-data">Data do estudo *</span>
        <span>{{ formattedDate }}</span>
      </div>

      <div
        class="select-item ion-item-white-border"
        (click)="openChooseDisciplineDialog()"
        [class.danger]="!selectedUserSubject || !selectedUserSubject.descricao"
      >
        <span style="margin-left: 20px" class="ld-data subject-selection">
          {{ selectedUserSubject ? selectedUserSubject.descricao : 'Disciplina'
          }}
        </span>
        <span>
          <ion-icon slot="end" name="caret-down-outline"> </ion-icon>
        </span>
      </div>

      <div
        class="select-item ion-item-white-border"
        (click)="openChooseTopicModal()"
        [class.danger]="!hasStatisticTopic && !revisionData.topic"
      >
        <span style="margin-left: 20px;  overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
          {{ revisionData && revisionData.topic ? revisionData.topic.description
          : 'Assunto' }}
        </span>
        <span>
          <ion-icon slot="end" name="caret-down-outline"> </ion-icon>
        </span>
      </div>

      <div class="check-item" (click)="defineProgramType('default')" tappable>
        <div class="checkbox" style="pointer-events: none;">
          <ion-checkbox
            [checked]="programType === 'default' "
            mode="ios"
            
          ></ion-checkbox>
        </div>
        <div class="label">
          <p class="white" [style.margin]="0">Padrão (1-7-14-30)</p>
        </div>
        <img
          src="assets/svg/question-blue.svg"
          alt="help"
          class="help-icon"
          (click)="openHelpBot()"
        />
      </div>

      <div class="check-item" (click)="defineProgramType('custom')" tappable>
        <div class="checkbox" style="pointer-events: none;">
          <ion-checkbox
            [checked]="programType === 'custom' "
            mode="ios"
            ></ion-checkbox>
        </div>
        <div class="label">
          <div style="display: flex; flex-direction: row; align-items: center;">
          <p class="white" [style.margin]="0">Personalizado </p>
          <img  src="assets/imgs/plans-compare/coroa_high.png" style="margin-left: 5px;" width="25px"> 
        </div>
        </div>
      </div>

      <div
        *ngIf="programType === 'custom'"
        style="display: flex; justify-content: space-between"
      >
        <div class="left">
          <div class="check-item left">
            <div class="label">
              <p class="white" [style.margin]="0">1 dia</p>
            </div>
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[1]"
              ></ion-checkbox>
            </div>
          </div>

          <div class="check-item left">
            <div class="label">
              <p class="white" [style.margin]="0">2 dias</p>
            </div>
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[2]"
              ></ion-checkbox>
            </div>
          </div>

          <div class="check-item left">
            <div class="label">
              <p class="white" [style.margin]="0">3 dias</p>
            </div>
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[3]"
              ></ion-checkbox>
            </div>
          </div>

          <div class="check-item left">
            <div class="label">
              <p class="white" [style.margin]="0">5 dias</p>
            </div>
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[5]"
              ></ion-checkbox>
            </div>
          </div>

          <div class="check-item left">
            <div class="label">
              <p class="white" [style.margin]="0">7 dias</p>
            </div>
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[7]"
              ></ion-checkbox>
            </div>
          </div>

          <div class="check-item left">
            <div class="label">
              <p class="white" [style.margin]="0">14 dias</p>
            </div>
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[14]"
              ></ion-checkbox>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="check-item">
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[15]"
              ></ion-checkbox>
            </div>
            <div class="label">
              <p class="white" [style.margin]="0">15 dias</p>
            </div>
          </div>

          <div class="check-item">
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[21]"
              ></ion-checkbox>
            </div>
            <div class="label">
              <p class="white" [style.margin]="0">21 dias</p>
            </div>
          </div>

          <div class="check-item">
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[30]"
              ></ion-checkbox>
            </div>
            <div class="label">
              <p class="white" [style.margin]="0">30 dias</p>
            </div>
          </div>

          <div class="check-item">
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[60]"
              ></ion-checkbox>
            </div>
            <div class="label">
              <p class="white" [style.margin]="0">60 dias</p>
            </div>
          </div>

          <div class="check-item">
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[90]"
              ></ion-checkbox>
            </div>
            <div class="label">
              <p class="white" [style.margin]="0">90 dias</p>
            </div>
          </div>

          <div class="check-item">
            <div class="checkbox">
              <ion-checkbox
                mode="md"
                (ionChange)="toggleCheckReview()"
                [(ngModel)]="revisionPattern[120]"
              ></ion-checkbox>
            </div>
            <div class="label">
              <p class="white" [style.margin]="0">120 dias</p>
            </div>
          </div>
        </div>
      </div>

      <div class="programmed-revision">
        <h5>Próximas revisões:</h5>
        <p *ngIf="reviews.length === 0">Nenhuma revisão</p>
        <p *ngFor="let review of reviews">
          {{ review.sequence }}ª revisão - {{ review.reviewDate | date:
          'dd/MM/yyyy' }} <span *ngIf="isReviewExpired(review)">(vencida)</span>
        </p>
        <br />
        <br />
      </div>
    </div>

  </div>

  <div
    #divCustomTime
    customDateTime="date"
    [hideSeconds]="true"
    (onSelect)="onTimeSelect($event)"
    class="content"
    style="padding: 10px"
  ></div>
</ion-content>
<ion-footer class="ion-no-border ion-padding">
    <ion-button shape="round" color="primary" expand="block" (click)="onClickDefineRevision()">
      Criar
    </ion-button>
</ion-footer>


import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-facebook-button',
  templateUrl: './facebook-button.component.html',
  styleUrls: ['./facebook-button.component.scss']
})
export class FacebookButtonComponent {
  @Input() text: string = 'Facebook';
}

import { Injectable } from '@angular/core';
import { Meta } from '@models/Meta';
import { User } from '@models/User';
import { StorageService } from '@app/@services/storage/storage.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { UserService } from '../user/user.service';
import { ESTUDAQUI_PLANO_TRIAL } from '@app/util/util';
import DateUtils from '@app/util/DateUtils';
import { environment } from 'src/environments/environment';
import { Subscription, interval } from 'rxjs';
import { AnalyticsService } from '@services/analytics/analytics.service';


const SIX_HOURS_MS = 1000*60*60*6

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {
  private _user: BehaviorSubject<User> = new BehaviorSubject(undefined);
  private userPullingSubscription: Subscription;

  constructor(
    private storage: StorageService,
    private userService: UserService,
    private analyticsService: AnalyticsService
  ) {}

  async init() {
    this.initPullingUser();

    const user = await this.storage.get('user-estudo');
    if (user) {
      this._user.next(user);
      return;
    }
    this._user.next(undefined);  

  }

  async initPullingUser() {
    if(!this.userPullingSubscription) {
      this.userPullingSubscription = interval(SIX_HOURS_MS).subscribe(async ()=> {
        if(this.user) {
          const u : User =  await this.userService.isUserExpired(this.user.objectId);
          this.updateUser(u);
        }
      })
    }

  }

  user$() {
    return this._user;
  }

  initSubscribeUserUpdates() {
    const user = this.user;
    if (user) {
      this.analyticsService.setUserId(user.objectId);
    }
  }

  get user() {
    return this._user.getValue();
  }

  get hasSubscription() {
    const user = this.user;
    return user && user.assinaturaAtual && user.planoAtual && user.planoAtual.objectId !== ESTUDAQUI_PLANO_TRIAL;
  }

  async getUser(): Promise<User> {
    let user = this._user.getValue();

    if (!user) {
      user = await this.storage.get('user-estudo');
    }

    this._user.next(user);

    return user;
  }

  async saveUser(user: User) {
    await this.updateUser(user);
  }


  private async updateUser(user: User) {
    this.analyticsService.setUserId(user.objectId);
    this._user.next(user);
    return await this.storage.set('user-estudo', user);
  }

  async updateUserGoal(objectId: string, goalName: string) {
    this.user.goal = {
      objectId,
      name: goalName
    };
    this.analyticsService.setUserObjective(goalName);

    const jobSaveUser = this.updateUser(this.user);
    const jobSaveGoal = this.storage.set('user-goal', { name: goalName, objectId });
    await Promise.all([jobSaveUser, jobSaveGoal]);
  }

  async readUserGoal(): Promise<{ name: string; objectId: string } | null> {
    return this.storage.get('user-goal');
  }

  userIsExpired() {
    const { validade } = this.user;
    return DateUtils.isExpirationDateBeforeToday(validade);
  }

  userIsFreemium() {
    const { objectId } = this.user.planoAtual;
    return objectId === environment.freemiumPlanId;
  }

  userIsTrial() {
    const { objectId } = this.user.planoAtual;
    return objectId === environment.trialPlanId;
  }

  canAcceptTrial() {
    const { canAcceptTrial } = this.user;
    return canAcceptTrial ?? false;
  }

  userIsFreemiumOrTrial() {
    return this.userIsFreemium() || this.userIsTrial();
  }

  clear() {
    const ratingData = this.storage.remove('ratingData');
    const userGoal = this.storage.remove('user-goal');
    const userEstudo = this.storage.remove('user-estudo');
    const tag = this.storage.remove('accessTag');
    this._user.next(undefined);

    return Promise.all([ratingData, userGoal, userEstudo, tag]);
  }
}

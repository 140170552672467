import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PickWeekdaysModalComponent } from './pick-weekdays-modal/pick-weekdays-modal.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { DialogSelectItemComponent } from './dialog-select-item/dialog-select-item.component';
import { EmptyListComponent } from './empty-list/empty-list.component';
import { ClosePopupComponent } from './close-popup/close-popupcomponent';

@NgModule({
  declarations: [
    DropdownButtonComponent,
    EmptyListComponent,
    PickWeekdaysModalComponent,
    DropdownButtonComponent,
    DialogSelectItemComponent,
    ClosePopupComponent
  ],
  exports: [
    IonicModule,
    CommonModule,
    PickWeekdaysModalComponent,
    DropdownButtonComponent,
    DialogSelectItemComponent,
    EmptyListComponent,
    ClosePopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ]
})
export class SharedModule { }

import { Component } from "@angular/core";
import { ChooseDisciplineModalPage } from "@app/disciplines/choose-discipline-modal/choose-discipline-modal.page";
import { BasePageController } from "@app/pages/BasePage";
import { AnalyticsEventEnum } from "@models/AnalyticsEventEnum";
import { ChooseDisciplineParams } from "@models/choose-discipline-params";
import { SubjectItem, UserSubject } from "@models/Subject";
import { UserSubjectCachingService } from "@app/@services/api/caching/user-subject-caching/user-subject-caching.service";
import { DisciplineService } from "@app/@services/api/discipline/discipline.service";
import { SubjectService } from "@app/@services/api/subject/subject.service";
import { DisciplineTopicsPage } from "../discipline-topics/discipline-topics.page";
import { TopicsService } from "@services/api/topics/topics.service";

@Component({
  selector: "app-topic-list",
  templateUrl: "./topic-list.page.html",
  styleUrls: ["./topic-list.page.scss"],
})
export class TopicListPage extends BasePageController {
  disciplines: UserSubject[] = [];

  constructor(
    private disciplineService: DisciplineService,
    private userSubjectCaching: UserSubjectCachingService,
    private subjectService: SubjectService,
    private topicsService: TopicsService
  ) {
    super();
  }

  async ionViewWillEnter() {
    await this.loadDisciplines();
  }

  private async loadDisciplines() {

    const loading = await this.showLoading("");

    const allUserSubjects = await this.userSubjectCaching.read();
    const userSubjects = allUserSubjects.filter((userSubject) => userSubject.excluida === false);

    this.disciplines = userSubjects
      .map((subject) => {
        delete subject.user;
        return subject;
      })
      .sort((a, b) => a.descricao.toLowerCase().localeCompare(b.descricao.toLowerCase()));



    await loading.dismiss();
  }

  async goToDisciplineTopics(discipline: UserSubject) {
    const modal = await this.modalCtrl.create({
      component: DisciplineTopicsPage,
      cssClass: 'transparent sd',
      animated: false,
      componentProps: {
        discipline: discipline,
      },
    });

    await modal.present();

    modal.onDidDismiss().then(() => {
      this.loadDisciplines();
    });
  }

  async openModalCreateDiscipline() {
    this.analyticsService.logEvent(AnalyticsEventEnum.BT_SUBJECT_ADD);
    const modal = await this.modalCtrl.create({
      component: ChooseDisciplineModalPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        pageParams: {
          title: "Adicionar Disciplina",
          canCreateDiscipline: true,
          loadOnly: "GENERAL",
        } as ChooseDisciplineParams,
      },
    });

    const loading = await this.showLoading();
    // Load subjects list before opening subject's list page
    if (
      !this.subjectService.subjectItems ||
      this.subjectService.subjectItems.length === 0
    ) {
      await this.subjectService.loadOnlyDisciplinasGerais();
    }

    await modal.present();
    await loading.dismiss();
    modal.onDidDismiss().then(async (response) => {
      const { data } = response;

      if (!data || !data.selectedSubjectItem) return;
      const { selectedSubjectItem } = data as {
        selectedSubjectItem: SubjectItem;
      };
      const foundDiscipline: UserSubject =
        await this.disciplineService.findUserDisciplineByDescription(
          selectedSubjectItem.name
        );
      if (!foundDiscipline)
        await this.disciplineService.createUserSubjectGeneralSubjectItem(
          selectedSubjectItem
        );
      await this.loadDisciplines();
    });
  }

  openInfoModal() {
    this.analyticsService.logEvent(AnalyticsEventEnum.BT_HELP, { origin: 'subjects_topics' });
    this.topicsService.showTopicsAndDisciplinesInfoDialog();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

import ChartUtils from '../../../util/ChartUtils';
import DateUtils from '../../../util/DateUtils';

export const getChart1 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-1',
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: 'Média de acertos por disciplina/assunto',
      style: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        fontFamily: 'Comfortaa, sans-serif',   
      }
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif',
        },
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      labels: {
        enabled: true,
        format: '{value:.0f}%',
      },
      title: null,
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '{point.y:.0f}% {point.totalCorrects}/{point.totalExercises}',
          style: {
            fontSize: '0.61rem',
            fontFamily: 'Comfortaa, sans-serif',
          }
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false,
          },
          select: {
            animation: {
              enabled: false,
            },
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: undefined,
        name: 'Média',
        colorByPoint: true,
        data: data,
        pointPadding: 0.15,
      },
    ],
  };
};

export const getChart2 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-2',
      type: 'column',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: 'Exercícios por disciplina/assunto',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif',
        },
      },
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: true,
        format: '{value:.0f}',
      },
      title: null,
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '{point.y}',
          style: {
            fontSize: '0.6rem',
            fontFamily: 'Comfortaa, sans-serif',
          },
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false,
          },
          select: {
            animation: {
              enabled: false,
            },
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        type: undefined,
        name: 'Exerc. por disciplina/assunto',
        colorByPoint: true,
        data: data,
      },
    ],
  };
};

export const getChart3 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscendingByDate);

  return {
    chart: {
      animation: false,
      renderTo: 'chart-3',
      type: 'spline',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: 'Evolução de acertos',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        formatter: function() {
          return DateUtils.convertDatetoDDMM(this.value);
        },
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif',
        },
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      labels: {
        enabled: true,
        format: '<b>{value}%</b>',
      },
      title: null,
    },
    plotOptions: {
      spline: {
        allowPointSelect: false,
        animation: false,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '<b>{point.y:.0f}%</b>',
          style: {
            fontSize: '0.6rem',
            fontFamily: 'Comfortaa, sans-serif',
          },
        },
      },
    },
    tooltip: {
      pointFormat: 'Porcentagem: <b>{point.y:.0f}%</b>',
    },
    series: [
      {
        data: data,
      },
    ],
  };
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChooseRegistertypeModalPage } from './choose-registertype-modal.page';
import { SharedComponentsModule } from '@app/components/shared-components.module';
import { BotModule } from '@app/bot/bot.module';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedComponentsModule,
    BotModule,
    SharedModule
    ],
  declarations: [ChooseRegistertypeModalPage]
})
export class ChooseRegistertypeModalPageModule {}

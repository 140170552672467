import { Type } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  NavController,
  Platform,
  ToastController
} from '@ionic/angular';
import { UserStoreService } from '@app/@services/api/user-store/user-store.service';
import { UserService } from '@app/@services/api/user/user.service';
import { AppInjectorService } from '@app/@services/app-injector/app-injector.service';
import { StorageService } from '@app/@services/storage/storage.service';
import { NetworkService } from '@app/@services/utils/network.service';
import Swal from 'sweetalert2';
import { SentryService } from '@app/core/sentry.service';
import { Subscription } from 'rxjs';
import { ToastPositionType } from '@services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { WebUtils } from '@app/util/WebUtils';
import { AnalyticsService } from '@services/analytics/analytics.service';

export abstract class BasePageController {
  private subscriptions: Subscription[] = [];
  //controllers
  protected loadingCtrl: LoadingController;
  protected alertCtrl: AlertController;
  protected navCtrl: NavController;
  protected modalCtrl: ModalController;
  protected toastController: ToastController;
  protected menuController: MenuController;
  protected platform: Platform;
  protected router: Router;
  protected Swal = Swal;
  //services
  protected analyticsService: AnalyticsService;
  protected userStoreService: UserStoreService;
  protected userService: UserService;
  protected networkService: NetworkService;
  protected storageService: StorageService;
  protected sentryService: SentryService;

  //props
  public isIOS: boolean = false;
  public isAndroid: boolean = false;
  public isWeb: boolean = false;

  private loader: HTMLIonLoadingElement;

  constructor() {
    const injector = AppInjectorService.getInjector();
    if (!injector) return;
    this.loadingCtrl = injector.get<LoadingController>(LoadingController as Type<LoadingController>);
    this.alertCtrl = injector.get<AlertController>(AlertController as Type<AlertController>);
    this.navCtrl = injector.get<NavController>(NavController as Type<NavController>);
    this.modalCtrl = injector.get<ModalController>(ModalController as Type<ModalController>);
    this.toastController = injector.get<ToastController>(ToastController as Type<ToastController>);
    this.menuController = injector.get<MenuController>(MenuController as Type<MenuController>);
    //
    this.platform = injector.get<Platform>(Platform as Type<Platform>);
    this.router = injector.get<Router>(Router as Type<Router>);
    this.platform.ready().then(()=> {
      this.isIOS = this.platform.is('ios');
      this.isAndroid = this.platform.is('android');
      this.isWeb = WebUtils.isWeb();
    })

    //services
    this.analyticsService = injector.get<AnalyticsService>(AnalyticsService as Type<AnalyticsService>);
    this.userService = injector.get<UserService>(UserService as Type<UserService>);
    this.userStoreService = injector.get<UserStoreService>(UserStoreService as Type<UserStoreService>);
    this.networkService = injector.get<NetworkService>(NetworkService as Type<NetworkService>);
    this.storageService = injector.get<StorageService>(StorageService as Type<StorageService>);
    this.sentryService = injector.get<SentryService>(SentryService as Type<SentryService>);
  }

  protected async showLoading(
    message: string = 'Aguarde um instante',
    backdropDismiss: boolean = false
  ): Promise<HTMLIonLoadingElement> {
    this.loader = await this.loadingCtrl.create({
      message,
      translucent: false,
      mode: 'ios',
      spinner: 'crescent',
      backdropDismiss
    });

    await this.loader.present();
    return this.loader;
  }

  protected async dismissLoading() {
    return this.loader.dismiss();
  }

  protected async showAlert(args: {
    header: string;
    subHeader?: string;
    message?: string;
    buttons?: any;
  }): Promise<HTMLIonAlertElement> {
    const { header, subHeader, message, buttons } = args;
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: buttons || ['OK']
    });

    await alert.present();

    return alert;
  }

  protected async showAlertError(args: {
    header: string;
    subHeader?: string;
    message?: string;
  }): Promise<HTMLIonAlertElement> {
    const { header, subHeader, message } = args;
    const userEmail: string = this.userStoreService.user?.email ?? "";

    const messageAux = `<br/> <p>Se o problema persistir envie um e-mail para <a href="mailto:suporte@estudaqui.com?subject=Ops, algo deu errado! - Versão ${environment.appVersion} - ${encodeURIComponent(userEmail)}" class="msg-email">suporte@estudaqui.com</a></p>`;

    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message: message || messageAux,
      buttons: ['OK'],
      mode: 'ios'
    });

    await alert.present();

    return alert;
  }

  protected async showToast(args: {
    message: string;
    duration?: number; //default 2500
    position?: ToastPositionType; //default bottom
    buttons?: [];
    color?: string
  }): Promise<HTMLIonToastElement> {
    const { message, duration, position, buttons, color } = args;
    const toast = await this.toastController.create({
      message,
      duration: duration || 2500,
      position: position || 'bottom',
      buttons,
      animated: false,
      color: color || 'dimgrey'
    });

    await toast.present();

    return toast;
  }

  protected async showToastMessage(message: string, position?: ToastPositionType) {
    return this.showToast({ message, position });
  }

  protected async showToastWarningMessage(message: string, position?: ToastPositionType) {
    return this.showToast({ message, position, color: 'warning' });
  }

  public counter(i: number) {
    return new Array(i);
  }

  protected pushSubscription(...subscription: Subscription[]) {
    this.subscriptions.push(...subscription);
  }

  protected unsubscribeAllSubscriptions() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  protected async forceCheckIfUserIsExpired(): Promise<boolean> {
    try {
      const { objectId } = this.userStoreService.user;
      const user = await this.userService.isUserExpired(objectId).catch((e) => this.userStoreService.user);
      await this.userStoreService.saveUser(user);
      return this.userStoreService.userIsExpired();
    } catch (e) {
      this.sentryService.captureException(e);
    }

    return true;
  }
}

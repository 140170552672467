import { Component, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { AnalyticsEventEnum } from '@models/AnalyticsEventEnum';
import { ChatMessage, ChatMessageChunk, ChatMessageSentence, ChatMessageType } from '../shared/interfaces.model';
import { AnalyticsService } from '@services/analytics/analytics.service';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
})
export class ChatMessagesComponent implements OnInit, OnDestroy, AfterViewInit {

  private timers: Array<NodeJS.Timeout> = [];

  private currentMessageIndex = 0;
  private currentChunkIndex = 0;
  private automaticMessages: Array<string> = [];
  public shownMessages: Array<ChatMessage> = [];
  private messages: Array<ChatMessage> = [];

  constructor(private analyticsService: AnalyticsService) {}


  ngOnInit() {}

  ngAfterViewInit() {
    this.startTyping();
  }

  ngOnDestroy() {
    // Clear timers when destroying
    this.stopTyping();
  }

  hasFinishedTyping() {
    // If any of messages shown is different from the complete
    // messages, so it's not finished yet
    for (let i = 0; i < this.automaticMessages.length; i++) {
      for (let j = 0; j < this.shownMessages.length; j++) {
        const message: ChatMessageChunk = this.messages[i].messages[j];
        const shownMessage: ChatMessageChunk = this.shownMessages[i].messages[j];

        if (message && shownMessage) {
          if (this.messages[i].messages[j].message != this.shownMessages[i].messages[j].message) {
            return false;
          }
        }
      }
    }

    return true;
  }

  showCompleteText(logEvent = false, from?: string) {
    if (logEvent) {
      this.analyticsService.logEvent(AnalyticsEventEnum.POPUP_ONBOARDING_SPEEDUP_ROBOT, {
        from,
      });
    }
    // Clear all timers
    this.stopTyping();

    // Show all messages
    for (let i = 0; i < this.messages.length; i++) {
      this.shownMessages[i] = this.messages[i];
    }

    // Set index as the final message's index
    this.currentMessageIndex = this.automaticMessages.length - 1;

    // Scroll to last
    const timer = setTimeout(() => {
      this.scrollToLastMessage();
    }, 100);

    this.timers.push(timer);
  }

  clearChatAndTypeBotMessage(message: string): void {
    this.automaticMessages = message.split('\n').map(m => m.trim());
    this.startTyping();
  }

  typeBotMessage(message: string): void {
    // Set next index
    this.currentMessageIndex = this.shownMessages.length;
    this.currentChunkIndex = 0;

    for (const m of message.split('\n')) {
      const id = this.shownMessages.length;
      this.automaticMessages.push(m.trim());
      const messageChunkArr: Array<ChatMessageChunk> = this.stringToChatMessageChunk(m.trim());

      this.messages.push({
        id: `message_${id}`,
        messages: messageChunkArr,
        type: ChatMessageType.BOT,
      });

      const chatMessage: ChatMessage = {
        id: `message_${id}`,
        messages: messageChunkArr.map(m => {
          return {
            isBold: m.isBold,
            message: '',
          };
        }),
        type: ChatMessageType.BOT,
      };

      this.shownMessages.push(chatMessage);
    }

    this.typeLetterDelayed(500);
  }

  typeUserMessage(message: string): void {
    const id = this.shownMessages.length;

    const chatMessage: ChatMessage = {
      id: `message_${id}`,
      messages: this.stringToChatMessageChunk(message),
      type: ChatMessageType.USER,
    };

    this.messages.push(chatMessage);
    this.shownMessages.push(chatMessage);
  }

  private startTyping() {
    // Useful to clear previous timers
    this.stopTyping();

    this.messages.length = 0;
    this.shownMessages.length = 0;
    this.currentMessageIndex = 0;
    this.currentChunkIndex = 0;
    this.automaticMessages.forEach(m => {
      const id = this.shownMessages.length;
      const messageChunkArr: Array<ChatMessageChunk> = this.stringToChatMessageChunk(m);

      this.messages.push({
        id: `message_${id}`,
        messages: messageChunkArr,
        type: ChatMessageType.BOT,
      });

      const chatMessage: ChatMessage = {
        id: `message_${id}`,
        messages: messageChunkArr.map(m => {
          return {
            isBold: m.isBold,
            message: '',
          };
        }),
        type: ChatMessageType.BOT,
      };

      this.shownMessages.push(chatMessage);
    });

    this.typeLetterDelayed(400);
  }

  private stopTyping() {
    // Clear all timeouts
    for (const timer of this.timers) {
      clearTimeout(timer);
    }

    // Clear timers array
    this.timers = [];
  }

  private typeLetterDelayed(delay: number) {
    const timer = setTimeout(() => {
      this.typeLetter();
    }, delay);

    this.timers.push(timer);
  }

  private DELAY_NEXT_PARAGRAPH = 400;
  private DELAY_NEXT_LETTER = 25;
  private DELAY_NEXT_LETTER_AFTER_COMMA = 300;

  private typeLetter() {
    if (this.currentMessageIndex < this.messages.length) {
      const currentMessage: ChatMessage = this.messages[this.currentMessageIndex];

      if (this.currentChunkIndex < currentMessage.messages.length) {
        const currentChunk: ChatMessageChunk = currentMessage.messages[this.currentChunkIndex];

        const currentShowingChunk: ChatMessageChunk = this.shownMessages[this.currentMessageIndex].messages[
          this.currentChunkIndex
        ];

        currentShowingChunk.message = currentChunk.message.substring(0, currentShowingChunk.message.length + 1);

        if (currentShowingChunk.message.length == currentChunk.message.length) {
          this.currentChunkIndex++;
        }

        if (currentShowingChunk.message[currentShowingChunk.message.length - 1] === ',') {
          // Delayed letter after a comma ","
          this.typeLetterDelayed(this.DELAY_NEXT_LETTER_AFTER_COMMA);
        } else {
          // Delayed letter with regular delay
          this.typeLetterDelayed(this.DELAY_NEXT_LETTER);
        }

        this.scrollToLastMessage();
      } else {
        this.currentMessageIndex++;
        this.currentChunkIndex = 0;

        // Delayed letter after finishing a paragraph
        this.typeLetterDelayed(this.DELAY_NEXT_PARAGRAPH);
      }
    }
  }

  private scrollToLastMessage() {
    const lastMessage = this.shownMessages[this.shownMessages.length - 1];

    if (lastMessage) {
      const id = this.shownMessages.length - 1;
      const yOffset = document.getElementById(`message_${id}`);

      if (yOffset) {
        yOffset.scrollIntoView(true);
      }
    }
  }

  private stringToChatMessageChunk(m: string): Array<ChatMessageChunk> {
    const messages: Array<ChatMessageChunk> = [];
    const pairs: Array<ChatMessageSentence> = [];

    for (let i = 0; i < m.length; i++) {
      if (m[i] === '<') {
        pairs.push({
          first: i,
          second: -1,
          isBold: true,
        });

        // Verify the previous Pair
        if (pairs[pairs.length - 2]) {
          pairs[pairs.length - 2].second = i - 1;
        }
      } else if (m[i] === '>') {
        pairs[pairs.length - 1].second = i;
      } else {
        if (pairs.length == 0 || (pairs[pairs.length - 1].isBold && pairs[pairs.length - 1].second !== -1)) {
          pairs.push({
            first: i,
            second: -1,
            isBold: false,
          });
        }
      }
    }
    if(pairs.length > 0 ) {
      pairs[pairs.length - 1].second = m.length - 1;
    }

    for (const pair of pairs) {
      if (pair.isBold) {
        messages.push({
          isBold: true,
          message: m.substring(pair.first + 1, pair.second),
        });
      } else {
        messages.push({
          isBold: false,
          message: m.substring(pair.first, pair.second + 1),
        });
      }
    }

    return messages;
  }

}

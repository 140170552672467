import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { Platform } from '@ionic/angular';
import { RatingData } from '@models/rating-data';
import { User } from '@models/User';
import { UserService } from '@services/api/user/user.service';
import { EventService } from '@services/event-service/event.service';
import { SideMenuService } from '@services/side-menu/side-menu.service';
import { StorageService } from '@services/storage/storage.service';
import moment from 'moment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class RatingService {
  constructor(
    private eventService: EventService,
    private userService: UserService,
    private storageService: StorageService,
    private platform: Platform,
    private sideMenuService: SideMenuService
  ) {
  }

  init() {
    this.eventService.subscribe('check_rating', ()=> this.resolveAskRating());
  }

  async resolveAskRating() {
    const { needCreateRatingData, ratingData } = await this.createIfNotExist();

    //first time
    if (needCreateRatingData) {
      return;
    }

    const { needRate } = ratingData;

    let showRating = false;

    if (needRate) {
      const resolveCount = await this.resolveCount(ratingData);
      if(resolveCount) {
        showRating = true;
      }
    }

    if(showRating) {
      setTimeout(()=> {
        this.sideMenuService.openModalRate();
      }, 1300);
    }
  }


  private async resolveCount(ratingData) {
    const { lastMark, count } = ratingData;
    const today = moment().startOf('day');
    let response = false;

    const lastMarkDate = lastMark ? moment(lastMark).startOf('day').toDate() : moment().startOf('day').toDate();
    const timeDiff = today.toDate().getTime() - lastMarkDate.getTime();
    const durationAfterLastMark = timeDiff / (12 * 60 * 60 * 1000); //12 hrs
   
   if (count === 2) {
      response = true;
    } else {
      if (durationAfterLastMark >= 1) {
        ratingData.count++; // incrementa a contagem de marcacoes
        ratingData.lastMark = today.toISOString();
        await this.setRating(ratingData);
      }
    }

    return response;
  }

  private async createIfNotExist() {
    const today = moment().startOf('day');

    let ratingData: RatingData = await this.getRating();
    const needCreateRatingData: boolean = !ratingData || typeof ratingData.needRate === 'undefined';

    if (needCreateRatingData) {
      ratingData = {
        ratedAt: null,
        count: 1,
        purchasedAt: null,
        needRate: true,
        lastMark: today.toISOString()
      };
      await this.setRating(ratingData);
    }

    return { needCreateRatingData, ratingData };
  }

  setRating(ratingData: RatingData) {
    return this.storageService.set('ratingData', ratingData);
  }

  async getRating(): Promise<RatingData> {
    return (await this.storageService.get('ratingData')) as RatingData;
  }

  reactEvent() {
    Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'AVALIAR',
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      title: 'Que ótimo!',
      html: `<p class="sweet-alert-text">Ficamos felizes por te ajudar a alcançar seu objetivo. <br/><br/>Você poderia nos dar <strong>5 estrelas</strong> na loja? Isso nos ajuda muito!</p>
        <div class="buttons" style="display: flex; padding-top: 7.5px; justify-content: space-between;">
          <button class="swal2-confirm swal2-styled do-not-rate" style="background-color: #d33; box-shadow: 2px 3px 4px #ccc; font-weight: bold; font-size: 11.5px; border-color: #d33; flex:1;">
            <span style="display:block;margin-bottom: 3px;width: 100%;">NÃO</span>
            <span>QUERO</span>
          </button>
          <button class="swal2-confirm swal2-styled remind-me-later" style="background-color: orange; box-shadow: 2px 3px 4px #ccc; font-size: 11.5px; font-weight: bold; border-color: orange; flex:1;">MAIS TARDE</button>
          <button class="swal2-confirm swal2-styled rate-app" style="flex: 1; font-size: 11.5px; box-shadow: 2px 3px 4px #ccc; font-weight: bolf;">AVALIAR</button>
        </div>
      `
    }).then((result) => {
      if (result && result.dismiss && result.dismiss == Swal.DismissReason.close) {
        const scheduledFor = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
        this.scheduleRate(scheduledFor);
      }
    });

    document.querySelector('.do-not-rate').addEventListener('click', async () => {
      await this.didRate();
      Swal.close();
    });

    document.querySelector('.remind-me-later').addEventListener('click', async () => {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      const scheduledFor = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
      await this.scheduleRate(scheduledFor);

      Swal.close();
    });

    document.querySelector('.rate-app').addEventListener('click', async () => {
      const storeUrl = this.platform.is('ios')
        ? 'tms-apps://itunes.apple.com/app/id1445700738'
        : 'market://details?id=br.estudaqui.com';
      await this.didRate();
      window.open(storeUrl, '_system');
      Swal.close();
    });
  }

  private async scheduleRate(schedule: Date) {
    const ratingData = await this.storageService.get('ratingData');
    const user = await this.storageService.get('user-estudo');
    ratingData['scheduledRate'] = schedule.toISOString();
    ratingData['needRate'] = true;
    await this.storageService.set('ratingData', ratingData);
    await this.userService.didRate({
      scheduledRate: schedule,
      ratedAt: null,
      user: user
    });
  }

  private async didRate() {
    const ratingData = await this.storageService.get('ratingData');
    ratingData.needRate = false;
    ratingData.ratedAt = new Date().toISOString();
    await this.storageService.set('ratingData', ratingData);
    const user = await this.storageService.get('user-estudo');
    await this.userService.didRate({
      scheduledRate: null,
      ratedAt: new Date(),
      user: user
    });
  }

  private fireInviteRateAppAlert() {
    Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'AVALIAR',
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      title: 'Que ótimo!',
      html: `<p class="sweet-alert-text">Ficamos felizes por te ajudar a alcançar seu objetivo. <br/><br/>Você poderia nos dar <strong>5 estrelas</strong> na loja? Isso nos ajuda muito!</p>
        <div class="buttons" style="display: flex; padding-top: 7.5px; justify-content: space-between;">
          <button class="swal2-confirm swal2-styled do-not-rate" style="background-color: #d33; box-shadow: 2px 3px 4px #ccc; font-weight: bold; font-size: 11.5px; border-color: #d33; flex:1;">
            <span style="display:block;margin-bottom: 3px;width: 100%;">NÃO</span>
            <span>QUERO</span>
          </button>
          <button class="swal2-confirm swal2-styled remind-me-later" style="background-color: orange; box-shadow: 2px 3px 4px #ccc; font-size: 11.5px; font-weight: bold; border-color: orange; flex:1;">MAIS TARDE</button>
          <button class="swal2-confirm swal2-styled rate-app" style="flex: 1; font-size: 11.5px; box-shadow: 2px 3px 4px #ccc; font-weight: bolf;">AVALIAR</button>
        </div>
      `
    }).then((result) => {
      if (result && result.dismiss && result.dismiss == Swal.DismissReason.close) {
        const scheduledFor = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
        this.scheduleRate(scheduledFor);
      }
    });

    document.querySelector('.do-not-rate').addEventListener('click', async () => {
      await this.didRate();
      Swal.close();
    });

    document.querySelector('.remind-me-later').addEventListener('click', async () => {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);

      const scheduledFor = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
      await this.scheduleRate(scheduledFor);

      Swal.close();
    });

    document.querySelector('.rate-app').addEventListener('click', async () => {
      const storeUrl = this.platform.is('ios')
        ? 'tms-apps://itunes.apple.com/app/id1445700738'
        : 'market://details?id=br.estudaqui.com';
      await this.didRate();
      window.open(storeUrl, '_system');
      Swal.close();
    });
  }

  private async fireInviteForm() {
    const user = await this.storageService.get('user-estudo');

    Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      cancelButtonText: 'NÃO RESPONDER',
      confirmButtonText: 'RESPONDER',
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      title: 'Que pena...',
      html: `
        <p class="sweet-alert-text">O que precisaria acontecer pra você realmente gostar do <strong>Estudaqui?</strong> Poderia responder uma <strong>pesquisa</strong> de apenas <strong>1 minuto?</strong><br /><br />Nós lemos TODAS as respostas, sem exceção!</p>
        <div class="buttons" style="display: flex; padding-top: 7.5px; justify-content: space-between;">

          <button class="swal2-confirm swal2-styled not-respond" style="background-color: #d33; box-shadow: 2px 3px 4px #ccc; font-weight: bold; font-size: 11.5px; border-color: #d33; flex:1;">
            <span style="display:block;margin-bottom: 3px;width: 100%;">NÃO</span>
            <span>RESPONDER</span>
          </button>

          <button class="swal2-confirm swal2-styled reply-form" style="flex: 1; font-size: 11.5px; box-shadow: 2px 3px 4px #ccc; font-weight: bolf;">RESPONDER</button>
        </div>
      `
    }).then((result) => {
      if (result && result.dismiss && result.dismiss == Swal.DismissReason.close) {
        const scheduledFor = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
        this.scheduleRate(scheduledFor);
      }
    });

    document.querySelector('.not-respond').addEventListener('click', async () => {
      await this.didRate();
      Swal.close();
    });

    document.querySelector('.reply-form').addEventListener('click', async () => {
      const urlForm = `https://pt.surveymonkey.com/r/Pesq_Insatif?email=${user.email}`;
      await this.didRate();
      window.open(urlForm, '_system');
      Swal.close();
    });
  }

  async disableRate() {
    const ratingData = await this.getRating();
    ratingData.needRate = false;
    await this.setRating(ratingData);
  } 
}

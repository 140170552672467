import { Injectable } from '@angular/core';
import { CachedItem } from '@models/cached-item.interface';
import { GeneralSubject, UserSubject } from '@models/Subject';
import { SyncResult } from '@models/sync-result.interface';
import { DisciplinasService } from '@app/@services/api/disciplinas/disciplinas.service';
import { OfflineStorageService } from '@app/@services/api/offline-storage/offline-storage.service';
import { SyncService } from '@app/@services/api/sync/sync.service';
import { UserStoreService } from '@app/@services/api/user-store/user-store.service';
import { interval } from 'rxjs/internal/observable/interval';
import { CachingUtils } from 'src/app/util/caching-utils';
import { ClearCachingService } from '../clear-caching/clear-caching.service';
import StringUtils from '@app/util/StringUtils';
import { Color } from '@models/Color';

@Injectable({
  providedIn: 'root'
})
export class UserSubjectCachingService {
  entityName = 'cachedUserSubjects';
  lastUpdate: Date = null;
  cachedData: Array<UserSubject> = [];
  syncTimer = interval(60000);

  constructor(
    private disciplinasService: DisciplinasService,
    private offlineStorageService: OfflineStorageService,
    private userStore: UserStoreService,
    private clearCaching: ClearCachingService<UserSubject>,
    private syncService: SyncService
  ) {}

  async getAll(): Promise<UserSubject[]> {
    const data = await this.offlineStorageService.get<Array<UserSubject>>(this.entityName);
    return data ? data : [];
  }

  set(items: UserSubject[]) {
    throw new Error('Method not implemented.');
  }

  async update(object: Partial<UserSubject>) {
    const updated = await this.offlineStorageService.update(this.entityName, object);
    this.syncService.setSyncStatus(true);
    return updated;
  }

  async clear() {
    return this.clearCaching.clear(this);
  }

  async delete(object: Partial<UserSubject>) {
    await this.offlineStorageService.delete(this.entityName, object);
    this.syncService.setSyncStatus(true);
  }

  async create(object: UserSubject): Promise<UserSubject> {
    const created = await this.offlineStorageService.create<UserSubject>(this.entityName, object);
    this.syncService.setSyncStatus(true);
    return created;
  }

  async createFromGeneralSubject(generalSubject: GeneralSubject, color: Color) {
    const newUserSubject: UserSubject = {
      cor: color,
      abreviatura: generalSubject.abreviatura,
      descricao: generalSubject.descricao,
      tipo: generalSubject.tipo,
      user: generalSubject.user,
      disciplinaGeral: generalSubject,
      disciplina_geral: generalSubject,
      migration_from: 'app',
      excluida: false,
    };
    return this.create(newUserSubject);
  }

  async save() {
    this.lastUpdate = new Date();
    await this.offlineStorageService.set(this.entityName, this.cachedData);
    await this.offlineStorageService.set(`${this.entityName}LastUpdate`, this.lastUpdate);
  }

  async reset() {
    this.cachedData = await this.offlineStorageService.get<UserSubject[]>(this.entityName);
  }

  async sync(): Promise<void> {
    let all: UserSubject[] = await this.getAll();
    const tracking: CachedItem[] = CachingUtils.buildTrackingToSync(all);
    const syncResult = (await this.disciplinasService.syncUserSubjects(
      this.userStore.user.objectId,
      this.lastUpdate,
      all
        .filter((subject) => subject.hasPendingDelete)
        .map((subject) => {
          subject.user = this.userStore.user;
          return subject;
        }),

      all
        .filter((subject) => subject.hasPendingWrite)
        .map((subject) => {
          subject.user = this.userStore.user;
          return subject;
        }),
      tracking
    )) as SyncResult<UserSubject>;
    all = CachingUtils.mergeWithSync(all, syncResult);
    await this.offlineStorageService.set(this.entityName, all);
  }

  async read(): Promise<Array<UserSubject>> {
    let data = await this.offlineStorageService.get<Array<UserSubject>>(this.entityName);
    data = data ? data : [];
    return data ? data.filter((s) => !s.hasPendingDelete) : [];
  }

  async needSync(): Promise<boolean> {
    try {
      const data = await this.getAll();
      const lastUpdatedLocal_ = this.getLastUpdated(data).then((result) => (result ? result.updatedAt : null));
      const lastUpdatedAtServer_ = this.disciplinasService
        .getUserSubjectLastUpdatedAt(this.userStore.user.objectId)
        .then((result) => (result ? result.lastUpdatedAt : null));

      const hasPending_ = Promise.resolve(data).then((values: any[]) => {
        if (values && values.length > 0) {
          const value = values.find((v) => {
            const { hasPendingDelete, hasPendingWrite } = v;

            if (hasPendingDelete || hasPendingWrite) {
              return true;
            }
            return false;
          });
          if (value) {
            return true;
          }
          return false;
        }
        return false;
      });

      const hasPending = await hasPending_;

      if (hasPending) {
        return true;
      }

      const [lastUpdatedLocal, lastUpdatedAtServer] = await Promise.all([lastUpdatedLocal_, lastUpdatedAtServer_]);

      return lastUpdatedLocal !== lastUpdatedAtServer;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private async getLastUpdated(data): Promise<UserSubject | null> {
    const dataSorted = CachingUtils.orderByUpdatedAt<UserSubject>(data);

    if (dataSorted.length > 0) {
      return dataSorted[0];
    }

    return null;
  }
  /** Find user subject using localObjectId and descricaoBusca. 
   * @param  {{localObjectId?:string} args
   * @param  {string}} descricaoBusca?
   * @param  {UserSubject[]} userSubjects?
   */
  async findUserSubject(where: {
    localObjectId?: string,
    descricaoBusca?: string
  },  userSubjects?: UserSubject[]): Promise<UserSubject | null> {
    const collection = userSubjects || (await this.read());
    let responseUserSuject: UserSubject | null = null;
    
    if(where.localObjectId) {
      responseUserSuject = await this.findUserSubjectByLocalObjectId(where.localObjectId, collection); 
    }

    if(!responseUserSuject && where.descricaoBusca) {
      responseUserSuject = await this.findUserSubjectByDescricaoBusca(where.descricaoBusca, collection);
    }

    return responseUserSuject;
  }

  /** Find the userSubject on an UserSubject[] (default from cache) using descricaoBusca
   * @param  {string} descricaoBusca
   * @param  {UserSubject[]} userSubjects? If not pass, it uses the collection from the caches
   * @returns Promise
   */
  async findUserSubjectByDescricaoBusca(descricaoBusca : string, userSubjects?: UserSubject[]) : Promise<UserSubject | null> {
    const collection = userSubjects || (await this.read());
    descricaoBusca = StringUtils.normalize(descricaoBusca);
    return collection.find((userSubject)=> StringUtils.normalize(userSubject.descricao) === descricaoBusca);
  }

  
  /** Find the userSubject on an UserSubject[] (default from cache) using localObjectId 
   * @param  {string} localObjectId
   * @param  {UserSubject[]} userSubjects? If not pass, it uses the collection from the cache
   * @returns Promise
   */
  async findUserSubjectByLocalObjectId(
    localObjectId: string,
    userSubjects?: UserSubject[]
  ): Promise<UserSubject | null> {
    
    const collection = userSubjects || (await this.read());
    return collection.find((userSubject) => userSubject.localObjectId && userSubject.localObjectId === localObjectId);
  }

  async findUserSubjectByObjectId(
    objectId: string,
    userSubjects?: UserSubject[]
  ): Promise<UserSubject | null> {
    
    const collection = userSubjects || (await this.read());
    return collection.find((userSubject) => userSubject.objectId && userSubject.objectId === objectId);
  }

  async findUserSubjectByDescription(description: string, userSubjects?: UserSubject[]) {
    const collection = await this.read();
    return collection.find((userSubject)=> userSubject.descricao.includes(description));

  }
}

<app-common-header
  title="Disciplina e Assuntos"
  [isDark]="true"
  (leftIconTap)="dismiss()"
  [hasFilter]="false"
  (rightIconTap)="openInfoModal()"
  [hasQuestionTutorial]="true"
></app-common-header>

<ion-content class="ion-padding ion-text-center">
  <div class="items">
    <div class="item no-left-radius">
      <div
        class="info"
        [style.borderLeftColor]="discipline?.cor?.codigo"
        (click)="openModalDisciplineUpdateDialog( discipline )"
      >
        <h2 class="title">{{ discipline?.descricao }}</h2>
        <h3 class="subtitle">{{ topics.length }} Assuntos</h3>
      </div>

      <div class="actions pad-end">
        <ion-button
          class="ion-no-margin ion-no-padding"
          fill="clear"
          color="medium"
          (click)="openModalDisciplineUpdateDialog(discipline)"
        >
          <ion-icon slot="icon-only" class="gray" name="pencil"></ion-icon>
        </ion-button>

        <ion-button
          class="ion-no-padding ion-margin-start"
          fill="clear"
          color="medium"
          (click)="doDisciplineDeletion(discipline)"
        >
          <ion-icon slot="icon-only" class="gray" name="trash-outline"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>

  <div class="buttons">
    <ion-button
      fill="outline"
      shape="round"
      size="small"
      color="light"
      style="text-transform: none !important"
      *ngIf="isEditing"
      (click)="cancelEdition()"
    >
      Cancelar
    </ion-button>
    <ion-button
      fill="outline"
      shape="round"
      size="small"
      color="light"
      style="text-transform: none !important"
      *ngIf="isEditing"
      (click)="saveChanges()"
    >
      Salvar
    </ion-button>

    <ion-button
      fill="outline"
      shape="round"
      size="small"
      color="light"
      style="text-transform: none !important"
      *ngIf="!isEditing && topics.length > 0"
      (click)="startEdition()"
    >
      Reordenar
    </ion-button>
  </div>

  <div *ngIf="topics.length === 0">
    <p style="text-align: center; color: #fff">Ainda não há assuntos vinculados a esta disciplina.</p>
  </div>

  <p class="white-text" *ngIf="topics.length > 0">Clique para editar</p>
  <ion-list class="topics ion-no-padding" side="start">
    <ion-reorder-group (ionItemReorder)="doReorder($event)">
      <ion-item
        lines="none"
        *ngFor="let topic of topics; let i = index;"
        [class.editing]="isEditing"
        [style.borderLeftColor]="topic?.color?.codigo"
      >
        <ion-label style="scrollbar-color: white transparent;" (click)="goToTopicDetails(topic)">{{ i+1 }} - {{ topic.description }}</ion-label>

        <ion-button
          size="large"
          color="medium"
          slot="end"
          fill="clear"
          (click)="showDialogConfirmTopicDeletion(i)"
        >
          <ion-icon slot="icon-only" name="trash"></ion-icon>
        </ion-button>
        <ion-reorder slot="start"> </ion-reorder>
      </ion-item>
    </ion-reorder-group>
  </ion-list>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="openTopicModal()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

import { Injectable } from "@angular/core";
import { SyncResult } from "@models/sync-result.interface";
import { Topic } from "@models/topic.model";
import { DisciplineService } from "@app/@services/api/discipline/discipline.service";
import { OfflineStorageService } from "@app/@services/api/offline-storage/offline-storage.service";
import { ParseService } from "@app/@services/api/parse/parse.service";
import { SyncService } from "@app/@services/api/sync/sync.service";
import { UserStoreService } from "@app/@services/api/user-store/user-store.service";
import { BehaviorSubject, Observable } from "rxjs";
import { CachingUtils } from "src/app/util/caching-utils";
import { ClearCachingService } from "../clear-caching/clear-caching.service";
import { auditTime } from "rxjs/internal/operators/auditTime";

@Injectable({
  providedIn: "root",
})
export class TopicCachingService {
  entityName = "cached_topics";
  cachedData: Topic[] = [];
  lastUpdate: Date;
  syncTimer: Observable<number>;

  private refresh$: BehaviorSubject<boolean> ;

  constructor(
    private offlineStorage: OfflineStorageService,
    private syncProvider: SyncService,
    private disciplineProvider: DisciplineService,
    private userStore: UserStoreService,
    private parseProvider: ParseService,
    private clearCaching: ClearCachingService<Topic>
  ) {
    this.refresh$ = new BehaviorSubject(false);
  }

  subscribeRefresh() {
    return this.refresh$.pipe(auditTime(30));
  }

  private refresh() {
    this.refresh$.next(true);
  }

  private async getAll(): Promise<Array<Topic>> {
    const data = await this.offlineStorage.get<Array<Topic>>(this.entityName);
    this.cachedData = data;
    return data ? data : [];
  }

  async set(items: Topic[]): Promise<void> {
    await this.offlineStorage.set(this.entityName, items);
    this.cachedData = items;
    this.refresh();
  }

  async clear(): Promise<void> {
    await this.clearCaching.clear(this);
  }

  async create(item: Topic): Promise<Topic> {
    const created = await this.offlineStorage.create<Topic>(
      this.entityName,
      item
    );
    this.syncProvider.setSyncStatus(true);
    this.getAll().then((all) => (this.cachedData = all));
    this.refresh();
    return created;
  }

  async delete(item: Partial<Topic>): Promise<void> {
    await this.offlineStorage.delete(this.entityName, item);
    this.getAll().then((all) => (this.cachedData = all));
    this.syncProvider.setSyncStatus(true);
    this.refresh();
  }

  async update(item: Partial<Topic>): Promise<Partial<Topic>> {
    const updatedTopic = await this.offlineStorage.update(
      this.entityName,
      item
    );
    this.syncProvider.setSyncStatus(true);
    this.getAll().then((all) => (this.cachedData = all));
    this.refresh();
    return updatedTopic;
  }

  save() {
    throw new Error("Method not implemented.");
  }

  async sync(): Promise<Topic[]> {
    let all = await this.offlineStorage.get<Topic[]>(this.entityName);
    all = (all ? all : []).filter((item) => !!item);

    const disciplinesMap = await this.disciplineProvider.getDisciplinesAsMap();
    const toWrite = all
      .filter((obj) => !obj.hasPendingDelete && obj.hasPendingWrite && obj.userDiscipline)
      .map((topic) => {
       const ud = topic.userDiscipline.localObjectId
          ? disciplinesMap.get(topic.userDiscipline.localObjectId)
          : disciplinesMap.get(topic.userDiscipline.objectId);
          topic.userDiscipline = ud;

        return topic;
      })
      .filter((topic) => !!topic.userDiscipline);

      const toDelete = all
        .filter((obj) => obj.hasPendingDelete)
        .map((topic)=> {
          const ud = topic.userDiscipline.localObjectId
             ? disciplinesMap.get(topic.userDiscipline.localObjectId)
             : disciplinesMap.get(topic.userDiscipline.objectId);
             topic.userDiscipline = ud;
   
           return topic;
        })
    const tracking = CachingUtils.buildTrackingToSync(all);

    const syncResult = (await this.parseProvider.run("syncTopics", {
      toDelete,
      toWrite,
      data: tracking,
      userId: this.userStore.user.objectId,
    })) as SyncResult<Topic>;

    all = CachingUtils.mergeWithSync(all, syncResult);
    await this.offlineStorage.set(this.entityName, all);
    this.cachedData = all;
    this.refresh();
    return all;
  }

  async read(includeDeleted?: boolean): Promise<Topic[]> {
    const data = await this.offlineStorage.get<Array<Topic>>(this.entityName);
    this.cachedData = data ? data : [];
    if(includeDeleted) {
      return this.cachedData;
    }
    return this.cachedData.filter((s) => !s.hasPendingDelete);
  }
}

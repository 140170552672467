import * as moment from 'moment';

export default class TimeUtils {
  /**
   * Convert an ammout of milliseconds to format HH:MM.
   * Note that this formatter allows more than 24 hours on its formating.
   *
   * @param time
   *        time in millis
   */
  static formatHHMM(time: number): string {
    const minutes = moment.duration(time).get('minutes');
    let hours = moment.duration(time).get('hours');
    const days = moment.duration(time).get('days');
    hours += days * 24;

    if(isNaN(hours) || isNaN(minutes)) throw new Error('Error when try to format time with formatHHMM - ' + JSON.stringify({time}));

    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }

  /**
   * Convert an ammout of milliseconds to format MM:SS.
   * Note that this formatter allows more than 60 minutes on its formating.
   *
   * @param time
   *        time in millis
   */
  static formatMMSS(time: number): string {
    const seconds = moment.duration(time).get('seconds');
    let minutes = moment.duration(time).get('minutes');
    const hours = moment.duration(time).get('hours');
    minutes += hours * 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  static convertMillisToHHMMExcludingZero(time: number): string {
    if (time == 0) {
      return null;
    }

    return this.formatHHMM(time);
  }

  static secondsToMillis(seconds: number): number {
    return moment.duration(seconds, 's').asMilliseconds();
  }

  static secondsToHHMM(time: number): string {
    return this.millisToHHMM(this.secondsToMillis(time));
  }

  static millisToHHMM(timeInMillis: number): string {
    return moment.utc(timeInMillis).format('HH:mm');
  }

  static millisToHHMMSS(timeInMillis: number): string {
    return moment.utc(timeInMillis).format('HH:mm:ss');
  }

  static convertHHMMToSeconds(timeHHMM: string): number {
    const time = moment(timeHHMM, 'HH:mm');
    let seconds = time.seconds();
    seconds += time.minutes() * 60;
    seconds += time.hours() * 60 * 60;
    return seconds;
  }

  static convertHHMMToMillis(timeHHMM: string): number {
    if (timeHHMM === null || timeHHMM === undefined || timeHHMM.length === 0) {
      return 0;
    }

    return moment.duration(timeHHMM, 's').asMilliseconds();
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AnimationController, PopoverController } from '@ionic/angular';
import { PremiumService } from '@services/premium/premium.service';
import { StorageService } from '@services/storage/storage.service';
import { timer } from 'rxjs/internal/observable/timer';
import { Subscription } from 'rxjs/internal/Subscription';
import { PopoverListComponent } from './popover-list/popover-list.component';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { tap } from 'rxjs/internal/operators/tap';

const FOOTER_ACTION_FREEMIUM_ADS_KEY = 'FOOTER_ACTION_FREEMIUM_ADS';

@Component({
  selector: 'app-footer-action',
  templateUrl: './footer-action.component.html',
  styleUrls: ['./footer-action.component.scss']
})
export class FooterActionComponent implements AfterViewInit, OnDestroy {
  @ViewChild('seeAdsBtn', { read: ElementRef }) seeAdsBtn: ElementRef;

  @Input() isFreemium: boolean;
  @Input() isTrial: boolean;
  @Input() isPaywall: boolean;
  @Input() canAcceptTrial: boolean;
  @Input() showFreemiumAdsText: boolean;
  @Input() isDesktop: boolean;

  @Output() openAds = new EventEmitter();
  @Output() openSubscribePlan = new EventEmitter();
  @Output() openRestoreSubscribe = new EventEmitter();
  @Output() acceptFreemium = new EventEmitter();
  @Output() dismiss = new EventEmitter();

  private animationSub: Subscription;
  pointBalance: number = 0;

  constructor(
    public popoverController: PopoverController,
    private animationCtrl: AnimationController,
    private premiumService: PremiumService,
    private storageService: StorageService
  ) {}

  async ngAfterViewInit() {
    const nativeElement = this.seeAdsBtn.nativeElement;

    const btnAdsAnimation = this.animationCtrl
      .create('cart-animation')
      .addElement(nativeElement)
      .keyframes([
        { offset: 0, transform: 'scale(1)' },
        { offset: 0.5, transform: 'scale(1.05)' },
        { offset: 0.8, transform: 'scale(0.95)' },
        { offset: 1, transform: 'scale(1)' }
      ]);
    const btnAnimation = this.animationCtrl
      .create('cart-color-animation')
      .addElement(nativeElement)
      .fromTo('transform', 'rotate(0deg)', 'rotate(45deg)');

    const parent = this.animationCtrl
      .create('parent')
      .duration(450)
      .easing('ease-out')
      .iterations(2)
      .direction('alternate')
      .addAnimation([btnAnimation, btnAdsAnimation]);

    // Playing the parent starts both animations
    this.animationSub = timer(1000, 1250).subscribe(() => parent.play());
    this.pointBalance = await this.premiumService.getPointBalance();
  }

  ngOnDestroy() {
    this.animationSub.unsubscribe();
  }

  resolveCrownImg(isEnable: boolean) {
    return isEnable ? 'assets/imgs/plans-compare/coroa_high.png' : 'assets/imgs/plans-compare/crown_disable.png';
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverListComponent,
      event: ev,
      translucent: true
    });
    await popover.present();

    const { data } = await popover.onDidDismiss();

    if (data.dismiss) {
      this.dismiss.emit();
    }
  }

  async handleOpenAdsEmit() {
    try {
      this.openAds.emit();
    } catch (e) {}
  }

  async handleAcceptFreemium() {
    this.storageService.set(FOOTER_ACTION_FREEMIUM_ADS_KEY, true);
    this.acceptFreemium.emit();
  }

  async openAppAssinatura() {
    const url = 'https://app.estudaqui.com/assinar';
    window.open(url, '_blank').focus();
  }
}

<ion-content class="ion-padding">
  <div class="container-info">
    <h1>Boas-vindas!</h1>
    <p class="text-lg text-center">
      <span class="text-orange">Obrigado por usar</span> o estudaqui.
    </p>

    <figure>
      <img src="assets/imgs/estudaqui-welcome-trial-icon.png" alt="Icone trial" />
    </figure>

    <p class="ion-text-lg ion-text-center">
      Se encontrar os <span class="text-orange">ícones</span> acima em alguma tela
      <span class="text-orange">clique neles,</span> são <span class="text-orange">dicas importantes</span>.
    </p>

    <ion-button color="orange" shape="round" strong (click)="continueTesting()">Entendi</ion-button>

    <div class="footer">
      <span class="logged-as">Logado como: {{currentUserEmail || ''}}</span>
      <div class="container-button-exit" (click)="exit()">
        <span class="button-exit">Logout</span>
        <ion-icon name="exit" color="orange"></ion-icon>
      </div>
    </div>
  </div>
</ion-content>
<section id="expiration-alert">
    <div class="text-center">
        <p>Seu acesso {{ isTrial === true ? 'Trial' : 'Premium'}} expirou em <strong>{{user?.validade |
                date:'dd/MM/yyyy' ||
                ""}}</strong></p>
        <p>Você pode continuar seu acesso <strong>GRATUITO</strong> ou assinar o <strong>plano Premium</strong> para
            liberar todas as funcionalidades.
        </p>
    </div>

</section>
export interface BotProgress {
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
}

export interface BotStep {
  messages: Array<string>;
  avatar: string;
  progress: BotProgress;
  inputType: InterviewInputType;
}

export interface ChatMessage {
  id: string;
  messages: Array<ChatMessageChunk>;
  type: ChatMessageType;
}

export interface ChatMessageChunk {
  message: string;
  isBold: boolean;
}

export interface ChatMessageSentence {
  first: number; // Start index
  second: number; // End index
  isBold: boolean; // Is bold
}

export enum ChatMessageType {
  BOT = 'BOT',
  USER = 'USER',
}

export enum DayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export enum InterviewInputType {
  DAY_OF_WEEK_PICKER = 'DAY_OF_WEEK_PICKER',
  FINISHED_INTERVIEW_SELECTED_CYCLE = 'FINISHED_INTERVIEW_SELECTED_CYCLE',
  FINISHED_INTERVIEW_SELECTED_FREE_STUDY = 'FINISHED_INTERVIEW_SELECTED_FREE_STUDY',
  SELECT = 'SELECT',
  SELECT_STUDY_TYPE = 'SELECT_STUDY_TYPE',
  FINISHED_CYCLE = 'FINISHED_CYCLE',
  GENERAL_YES_NO = 'GENERAL_YES_NO',
  GENERAL_YES_NO_WITH_CROWN = 'GENERAL_YES_NO_WITH_CROWN',
  LETS_GO_BUTTON = 'LETS_GO_BUTTON',
  LETS_GO_BUTTON_2 = 'LETS_GO_BUTTON_2',
  GO_TO_STORE = 'GO_TO_STORE',
  OK_BUTTON = 'OK_BUTTON',
  TIME_PICKER = 'TIME_PICKER',
  YES_NO_BUTTON_KNOW_CYCLE = 'YES_NO_BUTTON_KNOW_CYCLE',
  YES_NO_BUTTON_STUDY_EVERYDAY = 'YES_NO_BUTTON_STUDY_EVERYDAY',
  YES_NO_BUTTON_REPEAT_METAS = 'YES_NO_BUTTON_REPEAT_METAS',
  FINISHED_WEEKLY_SCHEDULE = 'FINISHED_WEEKLY_SCHEDULE',
  YES_NO_BUTTON_ARE_WEIGHTS_DIFFERENT = 'YES_NO_BUTTON_ARE_WEIGHTS_DIFFERENT'
}

export interface MetasLocalStorage {
  checkpoint: number;
  progress: BotProgress;
  objective: UserObjective;
  checkpointStepData?: any;
  [prop: string]: any;
}

export interface UserObjective {
  objectId: string;
  name: string;
  order: number;
  [prop: string]: any;
}

export enum YesOrNoResponse {
  YES = 'YES',
  NO = 'NO',
}

export const DAYS_OF_WEEK_TO_STRING = {
  [DayOfWeek.MONDAY]: 'Segunda-feira',
  [DayOfWeek.TUESDAY]: 'Terça-feira',
  [DayOfWeek.WEDNESDAY]: 'Quarta-feira',
  [DayOfWeek.THURSDAY]: 'Quinta-feira',
  [DayOfWeek.FRIDAY]: 'Sexta-feira',
  [DayOfWeek.SATURDAY]: 'Sábado',
  [DayOfWeek.SUNDAY]: 'Domingo',
};

export const DAYS_OF_WEEK_TO_SHORT_STRING = {
  [DayOfWeek.MONDAY]: 'SEG',
  [DayOfWeek.TUESDAY]: 'TER',
  [DayOfWeek.WEDNESDAY]: 'QUA',
  [DayOfWeek.THURSDAY]: 'QUI',
  [DayOfWeek.FRIDAY]: 'SEX',
  [DayOfWeek.SATURDAY]: 'SAB',
  [DayOfWeek.SUNDAY]: 'DOM',
};

export const USER_OBJECTIVES: Array<UserObjective> = [
  {
    objectId: 'DO4KEI2kUw',
    name: 'Concursos Públicos',
    order: 0,
  },
  {
    objectId: '1z7bVVa8ic',
    name: 'Exames classistas (OAB, CFC etc)',
    order: 1,
  },
  {
    objectId: '3I9OIYjUou',
    name: 'ENEM/Vestibulares',
    order: 2,
  },
  {
    objectId: 'Z05AN1qOyP',
    name: 'Carreiras Militares',
    order: 3,
  },
  {
    objectId: '4BSr5Gujmb',
    name: 'Carreiras Médicas (Residência, Especialização, etc)',
    order: 4,
  },
  {
    objectId: 'dC6j5wTnnl',
    name: 'Outros',
    order: 5,
  },
];

export const BOT_THIRD_STEP = 8;

export const LAST_STEP_FREE_STUDY = 11;

/**
 * Put the message in between angular brackets so then it will be rendered as <b> tag.
 *
 * Example: In the phrase below, the word BOLD, would be rendered as a <b> tag.
 *
 * - This message should have a <BOLD> word
 */
 export const BOT_STEPS = [
  {
    avatar: 'assets/imgs/metas/avatar-2.png',
    messages: [
      `Olá, <irei te auxiliar> nos seus primeiros passos 😀.
    Para otimizar seu aprendizado, me diga o seu <principal objetivo> de estudo.
    Seu objetivo poderá ser alterado depois se quiser.`,
    ],
    progress: {
      first: false,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.SELECT,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Excelente, nossa nota entre <concurseiros> é <imbatível> \\o/.
      Com <10 dias> de uso <90% dos concurseiros> já dizem que o Estudaqui <revolucionou> o hábito de estudo deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Maravilha, <provas de classe> são uma <grande especialidade> nossa \\o/.
      Com <15 dias> de uso <85% dos "classistas"> já dizem que o Estudaqui é <absolutamente essencial> para eles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Que sorte, notas de <ENEM e vestibulares> são os que têm os <maiores saltos> ao usar o Estudaqui.
      Com <10 dias> de uso <95% dos estudantes> já dizem que o app <revolucionou> a eficiência deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Maravilha, nossa base de provas <militares> é uma das <mais completas> \\o/.
      Com <15 dias> de uso <90% dos futuros militares> já dizem que o Estudaqui é o <maior diferencial> deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Sensacional, <estudantes da saúde> são os que dizem <melhor aproveitar> o Estudaqui \\o/.
      Com <10 dias> de uso <95% dos médicos> dizem que o app é o <maior diferencial> deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.YES_NO_BUTTON_KNOW_CYCLE,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Está no lugar certo, temos a <maior nota do mercado> entre estudantes de <categorias diferenciadas> \\o/.
      Com <15 dias> de uso <90% das pessoas> gosta tanto que passa a usar o app para <2 ou mais tipos de estudo> 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-4.png',
    messages: [
      `Faltam só 2 passos para terminar tudo e estudar pra valer ☺.
      Vamos <definir suas metas> rapidinho.
      <Você estuda todos os dias da semana?>`,
    ],
    progress: {
      first: true,
      second: true,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.YES_NO_BUTTON_STUDY_EVERYDAY,
  },
  {
    avatar: 'assets/imgs/metas/avatar-5.png',
    messages: [
      `Ótimo, metas definidas! Agora <recomendo> criar seu <1º Ciclo de Estudo>, ele será seu guia pessoal diário, <para saber como, quando e o que estudar.>
      <Mas...> se quiser estudar sem <nenhum planejamento,> clique em <“Estudo Livre”> para finalizar minha ajuda sem criar nenhum plano de estudo.
      Você <poderá criar novos ciclos> de estudo <sempre que quiser> ☺.`,
    ],
    progress: {
      first: true,
      second: true,
      third: true,
      fourth: false,
    },
    inputType: InterviewInputType.SELECT_STUDY_TYPE,
  },
  {
    avatar: 'assets/imgs/metas/avatar-6.png',
    messages: [
      `Muito bem. <Criaremos seu 1º Ciclo de Estudo.>
      Ao usar o Estudaqui, repare que várias telas possuem <dicas no botão “info”,> no canto superior direito. É importante que você as leia.
      Antes dessa última etapa <sugiro que veja nosso tutorial.> Te ajudará a criar um ciclo corretamente.`,
    ],
    progress: {
      first: true,
      second: true,
      third: true,
      fourth: true,
    },
    inputType: InterviewInputType.FINISHED_INTERVIEW_SELECTED_CYCLE,
  },
  {
    avatar: 'assets/imgs/metas/avatar-6.png',
    messages: [
      `Muito bem. Caso <queira criar um ciclo no futuro,> clique em “Definir Ciclo”, na tela principal.
      Repare que várias telas possuem <dicas no botão “info”,> no canto superior direito. É importante que você as leia.
      Pra nos despedirmos, <sugiro ver nosso tutorial.> Ajudará você a usar o Estudaqui corretamente. Até mais ☺!`,
    ],
    progress: {
      first: true,
      second: true,
      third: true,
      fourth: true,
    },
    inputType: InterviewInputType.FINISHED_INTERVIEW_SELECTED_FREE_STUDY,
  },
];


export const GOAL_STEPS = [
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Excelente, nossa nota entre <concurseiros> é <imbatível> \\o/.
      Com <10 dias> de uso <90% dos concurseiros> já dizem que o Estudaqui <revolucionou> o hábito de estudo deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Maravilha, <provas de classe> são uma <grande especialidade> nossa \\o/.
      Com <15 dias> de uso <85% dos "classistas"> já dizem que o Estudaqui é <absolutamente essencial> para eles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Que sorte, notas de <ENEM e vestibulares> são as que têm os <maiores saltos> ao usar o Estudaqui.
      Com <10 dias> de uso <95% dos estudantes> já dizem que o app <revolucionou> a eficiência deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Maravilha, nossa base de provas <militares> é uma das <mais completas> \\o/.
      Com <15 dias> de uso <90% dos futuros militares> já dizem que o Estudaqui é o <maior diferencial> deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Sensacional, <estudantes da saúde> são os que dizem <melhor aproveitar> o Estudaqui \\o/.
      Com <10 dias> de uso <95% dos médicos> dizem que o app é o <maior diferencial> deles 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
  {
    avatar: 'assets/imgs/metas/avatar-3.png',
    messages: [
      `Está no lugar certo, temos a <maior nota do mercado> entre estudantes de <categorias diferenciadas> \\o/.
      Com <15 dias> de uso <90% das pessoas> gosta tanto que passa a usar o app para <2 ou mais tipos de estudo> 😀.
      Vamos definir suas <metas.> Selecione os dias em que você <VAI estudar:>`,
    ],
    progress: {
      first: true,
      second: false,
      third: false,
      fourth: false,
    },
    inputType: InterviewInputType.DAY_OF_WEEK_PICKER,
  },
];

export const STEP_SELECTED_FREE_STUDY = {
  avatar: 'assets/imgs/metas/avatar-6.png',
  messages: [
    `Ok. Para <criar um plano no futuro,> clique em "Definir plano de estudo", na tela principal.
    Para finalizar, <sugiro ver nossos tutoriais.> Te ajudarão a <ganhar tempo> no uso do app. Até mais 😀!`,
  ],
  progress: {
    first: true,
    second: true,
    third: true,
    fourth: true,
  },
  inputType: InterviewInputType.FINISHED_INTERVIEW_SELECTED_FREE_STUDY,
};


export const STEP_AFTER_DEFINED_GOALS = {
  avatar: 'assets/imgs/metas/avatar-4.png',
  messages: [
    `Pra finalizar, <escolha um formato> pro seu 1º plano de estudo.
    <Ciclo de estudo:> estudo cíclico, sem relação com os dias da semana.
    <Agenda semanal:> estudo tradicional, atrelado aos dias da semana.
    <Sem planejamento:> estudo livre, sem criar nenhum plano de estudo.
    É possível mudar sua escolha depois, <quantas vezes quiser> 😃.`,
  ],
  progress: {
    first: true,
    second: true,
    third: false,
    fourth: false,
  },
  inputType: InterviewInputType.SELECT_STUDY_TYPE,
};
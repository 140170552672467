<app-common-header
  title="Dados Pessoais"
  [isDark]="true"
  [hasFilter]="false"
  [hasTutorial]="false"
  [hasQuestionTutorial]="false"
  (leftIconTap)="dismiss()"
></app-common-header>

<ion-content>
  <ion-list class="ion-padding">
    <ion-item>
      <ion-icon size="small" slot="end" name="mail"></ion-icon>
      <ion-label position="stacked">E-mail *</ion-label>
      <ion-input type="email" [disabled]="true" [(ngModel)]="email"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="person"></ion-icon>
      <ion-label position="stacked">Nome completo *</ion-label>
      <ion-input class="underline-border" type="text" [(ngModel)]="fullname"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="person"></ion-icon>
      <ion-label position="stacked">CPF *</ion-label>
      <ion-input class="underline-border" type="text" simpleMask="999.999.999-99" maxlength="14" [(ngModel)]="cpf"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="call"></ion-icon>
      <ion-label position="stacked">Telefone *</ion-label>
      <ion-input type="tel" maxlength="14" simpleMask="(99)999999999" [(ngModel)]="phone"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="calendar"></ion-icon>
      <ion-label position="stacked">Data de nascimento *</ion-label>
      <ion-input [value]="birthDayDisplay" aria-readonly="true" required (click)="openTimePicker()"></ion-input> 
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="pin"></ion-icon>
      <ion-label position="stacked">CEP *</ion-label>
      <ion-input type="tel" maxlength="8" simpleMask="99999999" debounce="500" [(ngModel)]="cep" (keyup)="findAddressByCEP()"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="pin"></ion-icon>
      <ion-label position="stacked">Estado *</ion-label>
      <ion-select cancelText="Cancelar" [(ngModel)]="selectedState" [selectedText]="selectedState.name">
        <ion-select-option *ngFor="let state of states" [value]="state.name">{{state.name}}</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="pin"></ion-icon>
      <ion-label position="stacked">Cidade *</ion-label>
      <ion-input type="text" [(ngModel)]="city"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="pin"></ion-icon>
      <ion-label position="stacked">Bairro *</ion-label>
      <ion-input type="text" [(ngModel)]="district"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="pin"></ion-icon>
      <ion-label position="stacked">Rua *</ion-label>
      <ion-input type="text" [(ngModel)]="street"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="pin"></ion-icon>
      <ion-label position="stacked">Número *</ion-label>
      <ion-input type="text" [(ngModel)]="number"></ion-input>
    </ion-item>

    <ion-item>
      <ion-icon size="small" slot="end" name="pin"></ion-icon>
      <ion-label position="stacked">Complemento</ion-label>
      <ion-input type="text" [(ngModel)]="complement" placeholder="Ex.: Apt. 201"></ion-input>
    </ion-item>
  </ion-list>

  <div class="ion-padding" *ngIf="planoDeInteresse">
    <ion-button expand="block" (click)="salvar()">Continuar para pagamento</ion-button>
  </div>
  <div class="ion-padding" *ngIf="!planoDeInteresse">
    <ion-button expand="block" (click)="salvar()">Salvar</ion-button>
  </div>
</ion-content>

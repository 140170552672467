<app-common-header
  title="Notificações"
  [isDark]="true"
  (leftIconTap)="dismiss()"
  [hasFilter]="false"
  [hasTutorial]="false"
></app-common-header>

<ion-content fullscreen class="container-page ion-margin">
  <ion-list style="background-color: transparent">
    <ion-item details="false" lines="none">
      <ion-label>Aviso de revisões</ion-label>
      <ion-toggle [(ngModel)]="notificationSettings.notifyAboutReviews" (ionChange)="toggleState()"></ion-toggle>
    </ion-item>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap"> Receber dicas, artigos gratuitos e novidades. </ion-label>
      <ion-toggle (ionChange)="onNewsletterToggle($event)" [(ngModel)]="newsletterGranted"></ion-toggle>
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer class="ion-no-border ion-padding">
  <ion-button color="primary" shape="round" expand="block" (click)="onSave()">Salvar</ion-button>
</ion-footer>

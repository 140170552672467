<ion-content>
    <ion-list>
        <ion-list-header>
            <ion-label> <strong>Problemas com a assinatura ? </strong> </ion-label>
        </ion-list-header>
        <ion-item button *ngIf="isIOS" (click)="restoreIOSSubscribe()">
            <ion-label>Restaurar assinatura</ion-label>
        </ion-item>
        <ion-item button *ngIf="!isIOS" (click)="restoreAndroidSubscribe()">
            <ion-label>Restaurar assinatura</ion-label>
        </ion-item>
        <ion-item button (click)="sendEmail()"
            href="mailto:suporte@estudaqui.com?subject=Contato via aplicativo - Versão {{appVersion}} - {{userEmailEncoded}}">
            <ion-label>Avisar sobre um problema</ion-label>
        </ion-item>
    </ion-list>
</ion-content>
import { Directive, EventEmitter, Output, Input } from "@angular/core";
import { DialogTestPage } from "@app/dialog-test/dialog-test.page";
import { ModalController } from "@ionic/angular";
import { Subscription } from "rxjs";
import { DateTimeDialogEventsService } from "./date-time-dialog-events.service";

@Directive({
  selector: "[customDateTime]", // Attribute selector
  host: {
    "(click)": "onClick($event)",
  },
})
export class CustomDateTimeDirective {

  private isOpened: boolean = false;

  @Output()
  onSelect = new EventEmitter<any>();

  @Output()
  onHelpTap = new EventEmitter<any>();

  @Input("customDateTime")
  customDateTime = "time";

  @Input("time")
  time: string;

  @Input("hideSeconds")
  hideSeconds = false;

  @Input("tickMinutes")
  tickMinutes: number = 1;

  @Input()
  pickerTitle: string;

  @Input("date")
  date: string;

  subscriptionEvents: Subscription;

  constructor(
    private modalCtrl: ModalController,
    private dateTimeEvents: DateTimeDialogEventsService
  ) {}

  async onClick(event) {
    if(this.isOpened) return;
    if (this.subscriptionEvents) this.subscriptionEvents.unsubscribe();

    const modal = await this.modalCtrl.create({
      component: DialogTestPage,
      animated: true,
      cssClass: 'sd modal-transparent',
      componentProps: {
        type: this.customDateTime ? this.customDateTime : "time",
        time: this.time,
        date: this.date,
        pickerTitle: this.pickerTitle,
        hideSeconds: this.hideSeconds,
        tickMinutes: this.tickMinutes
      },
    });
    
    modal.onDidDismiss().then(({ data }) => {
      this.dateTimeEvents.clearObservers();
      this.onSelect.emit(data);
      this.isOpened = false;
    });
    await modal.present();
    this.isOpened = true;
    this.subscriptionEvents = this.dateTimeEvents.channel.subscribe((data) => {
      if (data && data.type === "open-help") this.onHelpTap.emit();
    });
  }
}

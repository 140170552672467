import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class InAppBrowserService {
  constructor(private inAppBrowser: InAppBrowser) {}
  /**
   * Open the link passed as parameter on the device's browser.
   *
   * @param link
   */
  public openLinkOnBrowser(link: string) {
    this.openBrowser(link);
  }

  private openBrowser(link: string) {
    const options: InAppBrowserOptions = {
      location: 'no',
      clearsessioncache: 'yes',
      clearcache: 'yes'
    };

    this.inAppBrowser.create(link, '_system', options);
  }
}

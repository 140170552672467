import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, IonRouterOutlet, Platform } from '@ionic/angular';
import { TabIdentifier, TabService } from '@services/tab-service/tab.service';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {
  private alertIsShowing: boolean = false;
  private routerOutlet: IonRouterOutlet

  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private tabService: TabService,
    private router: Router
    ) {}

  init(routerOutlet: IonRouterOutlet) {
    this.routerOutlet = routerOutlet;
  }

 subscribeMain() {
     return this.platform.backButton.subscribeWithPriority(10, () => {
        this.execute();
      });
  }
  
  subscribeWithPriority(priority: number, cb: Function) {
    return this.platform.backButton.subscribeWithPriority(priority, (processNextHandler) => cb(processNextHandler));
  }

  private async execute() {
    const currentTab = this.tabService.getCurrentTab();
    
    if (currentTab !== TabIdentifier.CICLO) {
      await this.router.navigate(['tabs/cycle'], { replaceUrl: true });
    } else if (!this.routerOutlet.canGoBack()) {
      await this.presentAskToLeaveAlert();
    } else {
      this.routerOutlet.pop()
    }
  }

  private async presentAskToLeaveAlert() {
    const alert = await this.alertController.create({
      header: 'Sair do Aplicativo',
      message: 'Tem certeza que deseja sair?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Sim',
          handler: () => navigator['app'].exitApp()
        }
      ]
    });

    if (!this.alertIsShowing) {
      await alert.present();
      this.alertIsShowing = true;
      await alert.onDidDismiss();
      this.alertIsShowing = false;
    }
  }
}

import { Injectable } from "@angular/core";
import { StorageService } from "@app/@services/storage/storage.service";
import { Guid } from "../../../util/guid";

@Injectable({
  providedIn: "root",
})
export class OfflineStorageService {
  constructor(private storage: StorageService) {}
  private async clearLocalAfterMerge<T>(cachedData: T[]) {
    const elemsToRemove: T[] = cachedData.filter(
      (s) => s["hasPendingWrite"] || s["hasPendingDelete"]
    );
    for (const elem of elemsToRemove) {
      const index = cachedData
        .map((s) => s["localObjectId"])
        .indexOf(elem["localObjectId"]);
      cachedData.splice(index, 1);
    }
  }

  async get<T>(key: string): Promise<T> {
    return this.storage.get(key);
  }

  async set<T>(key: string, objToStore: T, promise?: Promise<T>): Promise<T> {
    await this.storage.set(key, objToStore);
    Promise.resolve(promise);
    return objToStore;
  }

  async remove<T>(key: string, promise?: Promise<T>): Promise<void> {
    await this.storage.remove(key);
    Promise.resolve(promise);
  }

  async removeDestroyedLocally<T>(key: string, destroyed: T[]) {
    const cachedData: T[] = await this.get<T[]>(key);
    destroyed.forEach((item: T, index: number) => {
      const cachedIndex = cachedData
        .map((cStatistic) => ({
          objectId: cStatistic["objectId"],
          localObjectId: cStatistic["localObjectId"],
        }))
        .findIndex(
          (obj) =>
            (item["localObjectId"] &&
              obj["localObjectId"] &&
              obj["localObjectId"] === item["localObjectId"]) ||
            obj["objectId"] === item["objectId"]
        );
      if (cachedIndex) cachedData.splice(cachedIndex, 1);
    });

    await this.set(key, cachedData);
  }

  async merge<T>(
    key: string,
    items: T[],
    refreshLastUpdate = true,
    hasPendingWrite = false,
    hasPendingDelete = false,
    shouldClear = true
  ): Promise<T[]> {
    let cachedData: T[] = await this.get<T[]>(key);
    if (!cachedData || cachedData.length === 0) cachedData = items;
    else {
      items.forEach((item) => {
        item["hasPendingWrite"] = hasPendingWrite;
        item["hasPendingDelete"] = hasPendingDelete;
        const cachedItem: T = cachedData.find(
          (s) =>
            (item["localObjectId"] &&
              s["localObjectId"] &&
              s["localObjectId"] === item["localObjectId"]) ||
            s["objectId"] == item["objectId"]
        );

        if (cachedItem) {
          const cachedIndex = cachedData
            .map((cStatistic) => ({
              objectId: cStatistic["objectId"],
              localObjectId: cStatistic["localObjectId"],
            }))
            .findIndex(
              (obj) =>
                (item["localObjectId"] &&
                  obj["localObjectId"] &&
                  obj["localObjectId"] === item["localObjectId"]) ||
                obj["objectId"] === item["objectId"]
            );
          const merged: T = Object.assign(cachedItem, item);
          cachedData[cachedIndex] = merged;
        } else {
          cachedData.unshift(item);
        }
      });
    }
    if (shouldClear) await this.clearLocalAfterMerge(cachedData);
    await this.set(key, cachedData);
    if (refreshLastUpdate) await this.set(`${key}LastUpdate`, new Date());
    return cachedData;
  }

  async create<T>(key: string, item: T, promise?: Promise<T>): Promise<T> {
    let items = (await this.storage.get(key)) as Array<any>;
    if (!items) items = [];
    item["localObjectId"] = !item["localObjectId"]
      ? Guid.generateLocalObjectId()
      : item["localObjectId"];
    item["hasPendingWrite"] = true;
    item["createdAt"] = new Date();
    item["updatedAt"] = new Date();
    items.push(item);
    await this.storage.set(key, items);
    Promise.resolve(promise);
    return item;
  }

  async delete<T>(key: string, item: T, promise?: Promise<T>): Promise<T> {
    const items = (await this.storage.get(key)) as Array<T>;
    const indexToRemove = items.findIndex(
      (obj) =>
        (obj["localObjectId"] &&
          obj["localObjectId"] === item["localObjectId"]) ||
        (obj["objectId"] && obj["objectId"] === item["objectId"])
    );
    if (indexToRemove >= 0) {
      const removedItem = items[indexToRemove];
      items[indexToRemove]["hasPendingDelete"] = true;
      await this.storage.set(key, items);
      Promise.resolve(promise);
      return removedItem;
    }

    return null;
  }

  async destroy<T>(key: string, item: T): Promise<T> {
    const items = (await this.storage.get(key)) as Array<T>;
    const indexToRemove = items.findIndex(
      (obj) => obj["localObjectId"] === item["localObjectId"]
    );
    if (indexToRemove >= 0) {
      const removedItem = items.splice(indexToRemove, 1)[0];
      await this.storage.set(key, items);
      return removedItem;
    }
  }

  async update<T>(
    key: string,
    item: T,
    hasPendingWrite = true,
    promise?: Promise<T>
  ): Promise<T> {
    const items = (await this.storage.get(key)) as Array<T>;
    const indexToUpdate = items.findIndex(
      (obj) =>
        (obj &&
          item &&
          obj["localObjectId"] &&
          obj["localObjectId"] === item["localObjectId"]) ||
        (obj && item && obj["objectId"] && obj["objectId"] === item["objectId"])
    );
    if (indexToUpdate >= 0) {
      item["hasPendingWrite"] = hasPendingWrite;
      items[indexToUpdate] = Object.assign(items[indexToUpdate], item);
      await this.storage.set(key, items);
      Promise.resolve(promise);
      return item;
    }
  }
}

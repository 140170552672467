<div class="message-container">  
  <div class="message-inner-container" *ngFor="let m of shownMessages; let i = index;">
    <div [id]="m.id" [ngClass]="(m.type === 'BOT') ? 'message-bot' : 'message-user'">
      <span *ngFor="let chunk of m.messages; let j = index">
        <span *ngIf="(j == 0) && (m.type === 'BOT')">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <b class="bold-chunk" *ngIf="chunk.isBold">
          {{chunk.message.trim()}}
        </b>
        <span class="regular-chunk" *ngIf="!chunk.isBold">
          {{chunk.message}}
        </span>
      </span>
    </div>
  </div>
</div>

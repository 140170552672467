import { Pipe, PipeTransform } from '@angular/core';
import { SubjectItem } from '@models/Subject';

@Pipe({
  name: 'filterTypeSubject'
})
export class FilterTypeSubject implements PipeTransform {

  transform(collection: Array<SubjectItem>, type: 'user-subject' | 'general-subject'): Array<SubjectItem> {
    
    return collection.filter((c)=> {
      if(type === 'user-subject') {
        return  c.userSubject && c.userSubject === true
      } else if(type === 'general-subject') {
        return  !c.userSubject;
      }
    })
  }

}

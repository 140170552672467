import { Injectable } from '@angular/core';
import { Meta } from '@models/Meta';
import { ParseService } from '@app/@services/api/parse/parse.service';
import { StorageService } from '@services/storage/storage.service';
import { User } from '@models/User';
import moment from 'moment';
import TimeUtils from '@app/util/TimeUtil';

@Injectable({
  providedIn: 'root'
})
export class MetasService {
  private metas: Meta | null;

  constructor(private parseService: ParseService, private storageService: StorageService) {}

  async initMetas(user: User) {
    this.metas = await this.getMetas();

    if (!this.metas) {
      this.metas = user.metaAtual;
      this.storeMetas(this.metas);
    }
  }

  hasMetas() {
    return !!this.metas;
  }

  public async createMeta(meta: Meta): Promise<any> {
    const metaResult = await this.parseService.run('createMeta', { meta });
    await this.storeMetas(metaResult);
    this.metas = metaResult;
    return metaResult;
  }

  public readMetas() {
    return this.metas;
  }

  public async refreshMetas() {
    try {
      const metas = await this.parseService.run('fetchMetas');
      await this.storeMetas(metas);
      this.metas = metas;
      return metas;
    } catch (e) {
      return;
    }
  }

  private storeMetas(metas) {
    return this.storageService.set('metas', metas);
  }

  async getMetas(): Promise<Meta | null> {
    return this.storageService.get('metas');
  }

  async getTotalHoursInMS(): Promise<number> {
    const DAYS: string[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    const metas = await this.getMetas();

    const totalHours = DAYS.reduce((acc, day) => {
      const value = metas[day.toLowerCase()];

      if (value) {
        acc = acc + value;
      }

      return acc;
    }, 0);

    return totalHours;
  }

  async getToltaHours(): Promise<number> {
    const totalMs = await this.getTotalHoursInMS();

    return totalMs / 1000 / 60 / 60;
  }

  async getTotalFormMMSS() {
    const DAYS: string[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const metas = await this.getMetas();

    const totalMs = DAYS.reduce((acc, day) => {
      const value = metas[day.toLowerCase()];

      if (value) {
        acc = acc +  moment.duration(value, 's').asMilliseconds();
      }

      return acc;
    }, 0);


    return TimeUtils.formatHHMM(totalMs);
  }

  async clear() {
    this.metas = null;
    return this.storageService.remove('metas');
  }
}

<app-simple-header></app-simple-header>

<ion-content fullscreen class="ion-text-center ion-padding">
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-icon size="large" name="{{store.icon}}"></ion-icon>
                <h1><b>Cancelar Plano {{store.platform}}</b></h1>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <div class="splash-info">
                    <div class="splash-intro">
                        <p> Como sua assinatura foi feita via <b>{{store.via}}</b>, todo o fluxo financeiro é controlado
                            exclusivamente pela {{store.company}}, então você
                            precisa fazer o procedimento via <b>{{store.via}}</b>. </p>
                        <p><a href="{{store.cancelLink}}">CLIQUE AQUI PARA CANCELAR</a></p>
                        <p *ngIf=" store.platform === 'Apple' ">
                            Se não funcionar por favor contate o <a href="https://support.apple.com/pt-br/billing">suporte Apple</a>.
                        </p>
                        <p *ngIf=" store.platform === 'Android' ">
                            Se não funcionar por favor <a
                                href="mailto:suporte@estudaqui.com?subject=Problema no cancelamento Google - Versão {{appVersion}} - {{userEmailEncoded}}">nos
                                avise aqui. </a>

                        </p>
                    </div>
                </div>
            </ion-col>
        </ion-row>
        <br />
        <ion-row>
            <ion-col>
                <ion-button expand="block" (click)="dismiss()">
                    Voltar
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

</ion-content>

<div>
  <div class="container-metas-total">
    <div class="metas-total">
      <div class="container-info">
        <div class="container-img item-col-1">
          <img src="assets/imgs/icon-ampulheta-2x.png" />
          <app-percentage-circle [percent]="100"></app-percentage-circle>
        </div>
      </div>
      <div class="container-time">
        <span>Total</span>
        <span>{{ tempoTotalHHMM }}</span>
      </div>
    </div>
    <div class="metas-total" *ngIf="tipo === 'semana'">
      <div class="container-info">
        <div class="container-img item-col-2">
         <ion-icon size="large" src="assets/svg/029-podcast.svg" style="color:white;"></ion-icon> 
          <app-percentage-circle [percent]="100"></app-percentage-circle>
        </div>
      </div>
      <div class="container-time">
        <span>Meta</span>
        <span>{{ tempoMetasHHMM }}</span>
      </div>
    </div>
  </div>
</div>
<div class="slides-time-chart">
  <ion-slides  [ngClass]="{ 'has-charts': hasStatistics }" pager (ionSlidesDidLoad)="slideChanged($event)"
    (ionSlideDidChange)="slideChanged($event)"  #slides>

    <ion-slide [ngClass]="{ 'has-chart': hasStatistics }" class="ion-no-padding ion-padding-top" >
      <div class="chart-container" style="width: 100%; height: 59vh; overflow: scroll;" *ngIf="hasStatistics">
        <div class="chart" id="chart-1"></div>
      </div>
      <div class="ion-padding empty-grafico" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve estudo nesse período.<br /><span class="small">(exemplo do gráfico abaixo)</span>
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/tempo-estudo-1.png" alt="" />
        </figure>
      </div>
    </ion-slide>

    <ion-slide [ngClass]="{ 'has-chart': hasStatistics }" class="ion-no-padding ion-padding-top">
      <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">
        <div class="chart" id="chart-2"></div>
      </div>
      <div class="empty-grafico" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve estudo nesse período.<br /><span class="small">(exemplo do gráfico abaixo)</span>
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/tempo-estudo-2.png" alt="" />
        </figure>
      </div>
    </ion-slide>

    <ion-slide [ngClass]="{ 'has-chart': hasStatistics }" class="ion-no-padding ion-padding-top">
      <div class="chart-container" style="width: 100%; height: 59vh; overflow: scroll;" *ngIf="hasStatistics">
        <div class="chart" id="chart-3" *ngIf="hasStatistics"></div>
      </div>

      <div class="empty-grafico" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve estudo nesse período.<br /><span class="small">(exemplo do gráfico abaixo)</span>
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/tempo-estudo-3.png" alt="" />
        </figure>
      </div>
    </ion-slide>

    <ion-slide [ngClass]="{ 'has-chart': hasStatistics }"  class="ion-no-padding ion-padding-top">
      <div class="chart-container" style="width: 100%; height: 58vh; overflow: scroll;" *ngIf="hasStatistics">
        <div class="chart" id="chart-4" *ngIf="hasStatistics"></div>
      </div>
      <div padding class="empty-grafico" *ngIf="!hasStatistics">
        <p class="sample-title">
          Não houve estudo nesse período.<br /><span class="small">(exemplo do gráfico abaixo)</span>
        </p>

        <figure class="sample-image">
          <img src="assets/imgs/statistics/tempo-estudo-4.png" alt="" />
        </figure>
      </div>
    </ion-slide>
  </ion-slides>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { AlarmModalPage } from './alarm-modal.page';
import { CustomDateTimeModule } from '@app/directives/custom-date-time/custom-date-time.module';

@NgModule({
  imports: [
    CustomDateTimeModule,
    CommonModule,
    FormsModule,
    IonicModule  ],

  declarations: [AlarmModalPage]
})
export class AlarmModalPageModule {}

<app-common-header
  (leftIconTap)="dismiss()"
  [title]="pageParams.title"
  [isDark]="true"
  [hasLogo]="false"
  [hasDrawer]="false"
  [hasFilter]="false"
  [hasTutorial]="false"
></app-common-header>
<ion-content fullscreen>


      <ion-searchbar #searchBar placeholder="Buscar ou criar disciplina" debounce="500" color="light" (ionChange)="getItems($event)"></ion-searchbar>
      <div class="add-subject-menu ion-padding" *ngIf="pageParams.canCreateDiscipline && isCreatingSubject">
        <span class="add-subject-label">Não encontramos esta disciplina, gostaria de adicionar?</span>
  
        <div class="flex-row ion-margin-top ion-margin-bottom" style="display: flex; flex-direction: row;" [formGroup]="formSubject">
          <ion-item color="light" class="subject-abbreviation" style="width: 100%">
            <ion-input
              type="text"
              max="10"
              #inputShortName
              (keyup)="onKeyDown($event, 'shortName')"
              formControlName="shortName"
              placeholder="Escolha uma Abreviatura (10 caracteres)*"
            ></ion-input>
          </ion-item>
          <img style="margin-top: 7px; margin-left:7px; width: 24px; height: 24px;" src="assets/svg/question-blue.svg" (click)="onClickHelpAbbreviation()" />
        </div>

        <div class="flex-row ion-margin-bottom" style="display: flex; flex-direction: row;" >
          <ion-item color="light" class="subject-type-selector" style="width: 100%">
            <ion-select
              [(ngModel)]="newSubjectType"
              cancelText="Cancelar"
              placeholder="Selecione o tipo de disciplina*"
              style="width: 100%"
            >
              <ion-select-option value="teorica">Teórica</ion-select-option>
              <ion-select-option value="raciocinio">Raciocínio</ion-select-option>
            </ion-select>
          </ion-item>
          <img style="margin-top: 7px; margin-left:7px; width: 24px; height: 24px;"  src="assets/svg/question-blue.svg" (click)="onClickHelpSubjectType()" />
        </div>
        <div class="flex-row ion-margin-bottom">
          <ion-label class="color-label">Cor</ion-label>
        </div>
        <div class="flex-row">
          <ion-item
            color="light"
            [ngStyle]="{
              'border-left-color': newColor.codigo,
              'border-left-style': 'solid',
              'border-left-width': '15px'
            }"
            class="color-picker"
            (click)="chooseColor()"
            style="width: 100%"
            lines="none"
          >
            <ion-label> {{newColor.nome}} </ion-label>
          </ion-item>
        </div>
      </div>

      <!-- empty -->
      <div class="empty-list" *ngIf="!pageParams.canCreateDiscipline && listedSubjects.length === 0">
        <ion-list>
          <ion-item class="ion-item-transparent white-text" *ngIf="listedSubjects.length === 0">
            <ion-label>Nenhum item correspondente a sua pesquisa</ion-label>
          </ion-item>
        </ion-list>
      </div>

    
        <ion-list *ngIf="!isCreatingSubject">
          <ion-item-divider *ngIf="(listedSubjects | filterTypeSubject:'user-subject').length > 0" sticky="true">
            <ion-label> Minhas Disciplinas </ion-label>
          </ion-item-divider>
          <ion-item lines="full" *ngFor="let subject of listedSubjects | filterTypeSubject:'user-subject'" (click)="onClickSubject(subject)">
            <ion-label>{{ subject.displayName }} </ion-label>
            <ion-checkbox slot="start" mode="ios"
              [checked]="selectedSubject?.objectId === subject.objectId && selectedSubject?.localObjectId === subject.localObjectId">
            </ion-checkbox>
          </ion-item>

          <ion-item-divider *ngIf="(listedSubjects | filterTypeSubject:'general-subject').length > 0" sticky="true">
            <ion-label> Disciplinas Gerais </ion-label>
          </ion-item-divider>
          <ion-item lines="full" *ngFor="let subject of listedSubjects | filterTypeSubject:'general-subject'" (click)="onClickSubject(subject)">
              <ion-label>{{ subject.displayName }} </ion-label>
              <ion-checkbox slot="start" mode="ios"   [checked]="selectedSubject?.objectId === subject.objectId && selectedSubject?.localObjectId === subject.localObjectId"></ion-checkbox>
          </ion-item>
        </ion-list>
    
  <ion-infinite-scroll threshold="20%" (ionInfinite)="loadMore($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Carregando mais dados...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

<ion-footer *ngIf="!isCreatingSubject"  class="ion-no-border" style="padding: 8px;">
  <ion-button color="primary" shape="round" expand="block" (click)="doDisciplineSelection()">Selecionar Disciplina</ion-button>
</ion-footer>


<ion-footer *ngIf="pageParams.canCreateDiscipline && isCreatingSubject" class="ion-no-border">
  <ion-toolbar>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="6">
          <ion-button color="primary" fill="outline" shape="round" expand="block"
            (click)="backToSubjectList()">Cancelar</ion-button>

        </ion-col>
        <ion-col size="6">
          <ion-button class="ion-text-uppercase" size="small" color="primary" shape="round" expand="block"
            (click)="handleCreateDisciplineTap()">
            Adicionar
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
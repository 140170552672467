import { Injectable } from "@angular/core";
import { CycleDto } from "@models/Ciclo";
import { CycleBlockDto } from "@models/CycleBlockDto";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ParseService } from "../parse/parse.service";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CycleService {
  public isEditing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private cycleTabEvents: Subject<any> = new Subject<any>();
  public automaticCycleCreation: Observable<any>;
  public cancelDialogSubject = new Subject<any>();

  constructor(private parseService: ParseService) {
    this.automaticCycleCreation = this.cycleTabEvents.pipe(
      filter((event) => event.type && event.type === "automatic")
    );
  }

  get isEditingCycle() {
    return this.isEditing.getValue();
  }

  public createCycle(data: any) {
    this.cycleTabEvents.next(data);
  }

  toggle() {
    this.isEditing.next(!this.isEditingCycle);
  }

  setEditing(isEditing: boolean) {
    this.isEditing.next(isEditing);
  }

  syncCycles(input: CycleDto) {
    return this.parseService.run("syncCycles_v2", input);
  }

  fetchCycleBlocksFromCycles(cycleIds: string[]) {
    return this.parseService.run("fetchCycleBlocksFromCycles", {
      cycleIds: cycleIds,
    });
  }

  syncCycleBlocks(input: CycleBlockDto) {
    return this.parseService.run("syncCycleBlocks_v2", input);
  }

  getLastUpdatedAt(email: string) {
    return this.parseService.run("getCycleLastUpdatedAt", { email });
  }

  getBlockLastUpdatedAt(email: string) {
    return this.parseService.run("getCycleBlockLastUpdatedAt", { email });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
import { MyExamService } from '../shared/my-exam.service';

@Component({
  selector: 'app-my-exam-edit-subject',
  templateUrl: './my-exam-edit-subject.page.html',
  styleUrls: ['./my-exam-edit-subject.page.scss']
})
export class MyExamEditSubjectPage extends BasePageController {
  @Input() subjectName: string;
  @Input() peso: number;
  @Input() quantidadeQuestoes: number;
  @Input() canEditPeso: boolean;

  constructor(public myExamService: MyExamService) {
    super();
  }

  onAdicionar() {
    this.modalCtrl.dismiss({subjectName: this.subjectName, peso: this.peso, quantidadeQuestoes: this.quantidadeQuestoes })
  }

  close() {
    this.modalCtrl.dismiss();
  }
}

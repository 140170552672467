<ion-footer class="footer">
    <ion-grid *ngIf="!isDesktop">

        <ion-row class="ion-no-padding" *ngIf="isPaywall">
            <ion-col class="ion-no-padding" offset="1" size="10">
                <ion-button style="height: 70px; " size="large" expand="block" (click)="openSubscribePlan.emit()">
                    <ion-label>
                        <strong> Assine </strong> a partir de <strong>R$6,67/mês </strong>
                    </ion-label>
                </ion-button>
            </ion-col>

        </ion-row>


        <ion-row class="ion-no-padding" *ngIf="!isPaywall && !isFreemium">
            <ion-col class="ion-no-padding" size="6">
                <ion-button style="height: 70px; " color="orange" size="large" expand="block"
                    (click)="handleAcceptFreemium()">
                    <ng-container>
                        Continuar na versão grátis
                    </ng-container>

                </ion-button>
            </ion-col>
            <ion-col class="ion-no-padding" size="6">
                <ion-button style="height: 70px; " size="large" expand="block" (click)="openSubscribePlan.emit()">
                    <ion-label>
                        <strong> Assine </strong> a partir de <strong>R$6,67/mês </strong>
                    </ion-label>
                </ion-button>
            </ion-col>

        </ion-row>

        <ion-row *ngIf="!isPaywall && isFreemium">
            <ion-col size="6">
                <ion-button *ngIf="!showFreemiumAdsText" color="orange" size="large" expand="block"
                    style="height: 70px;" (click)="handleAcceptFreemium()">
                    Continuar na versão grátis
                </ion-button>
                <ion-button #seeAdsBtn style="height: 70px; " color="orange" size="large" expand="block"
                    (click)="handleOpenAdsEmit()">
                    <ion-icon size="large" style="margin-right: 15px;" name="videocam-outline"></ion-icon>
                    <ion-label style="line-height: 23px;">
                        <ng-container>
                            Liberar <br /> <strong> Grátis</strong>
                        </ng-container>
                    </ion-label>
                </ion-button>

            </ion-col>

            <ion-col size="6">
                <ion-button size="large" expand="block" style="height: 70px;" (click)="openSubscribePlan.emit()">
                    <ion-label>
                        <strong> Assine </strong> a partir de <strong>R$6,67/mês </strong>
                    </ion-label>
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col size="12">
                <p (click)="presentPopover($event)">Problemas com a assinatura ?</p>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-grid *ngIf="isDesktop">

        <ion-row class="ion-no-padding">
            <ion-col class="ion-no-padding" offset="1" size="10">
                <ion-button style="height: 70px; " size="large" expand="block" (click)="openAppAssinatura()">
                    <ion-label>
                        <strong> Assine </strong> a partir de <strong>R$7,90/mês </strong>
                    </ion-label>
                </ion-button>
            </ion-col>

        </ion-row>

        <ion-row class="ion-no-padding  ion-no-margin">
            <div style="width: 100%; display: flex; justify-content: center;">
                <a style="margin-right: 5px;"
                    href="https://play.google.com/store/apps/details?loja=Google&id=br.estudaqui.com&hl=pt_BR"
                    target="_blank">
                    <img width="252px" src="../../../../assets/img/botao_baixar_na_playstore.png.webp">
                </a>
                <a style="margin-left: 5px;" href="https://apps.apple.com/br/app/estudaqui/id1445700738?loja=Apple"
                    target="_blank">
                    <img width="252px" src="../../../../assets/img/botao_baixar_na_apple.png.webp">
                </a>
            </div>

        </ion-row>

    </ion-grid>

</ion-footer>
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogSelectItemComponent } from '@app/shared/dialog-select-item/dialog-select-item.component';
import { ModalController } from '@ionic/angular';
import { DayOfWeek, DAYS_OF_WEEK_TO_SHORT_STRING, InterviewInputType, UserObjective, USER_OBJECTIVES, YesOrNoResponse } from '@models/InterviewBot';
import { PremiumService } from '@services/premium/premium.service';

@Component({
  selector: 'app-chat-actions',
  templateUrl: './chat-actions.component.html',
  styleUrls: ['./chat-actions.component.scss'],
})
export class ChatActionsComponent {
  @Input()
  interviewInputType: InterviewInputType;
  @Input()
  hasFinishedTyping: boolean;

  @Output()
  clickObjectiveSelector = new EventEmitter();
  @Output()
  finishInterviewWithCycleSelected = new EventEmitter();
  @Output()
  finishInterviewWithFreeStudySelected = new EventEmitter();
  @Output()
  next = new EventEmitter();
  @Output()
  onSelectDaysOfWeek = new EventEmitter();
  @Output()
  onSelectObjective = new EventEmitter();
  @Output()
  goToThirdStep = new EventEmitter();
  @Output()
  typeUserMessageDoKnowCycle = new EventEmitter();
  @Output()
  typeUserMessageHasSelectedCycle = new EventEmitter();
  @Output()
  tappedButton = new EventEmitter<number>();
  @Output()
  typeUserMessageStudiesEveryday = new EventEmitter();
  @Output()
  typeUserMessageRepeatMetas = new EventEmitter();
  @Output()
  handleAreWeightsDifferents = new EventEmitter();
  @Output()
  pickTime = new EventEmitter();

  // Array of options for template render
  daysOfWeek = [
    DayOfWeek.MONDAY,
    DayOfWeek.TUESDAY,
    DayOfWeek.WEDNESDAY,
    DayOfWeek.THURSDAY,
    DayOfWeek.FRIDAY,
    DayOfWeek.SATURDAY,
    DayOfWeek.SUNDAY,
  ];

  // Dictionary to hold selected days
  selectedDaysOfWeek = {
    [DayOfWeek.MONDAY]: false,
    [DayOfWeek.TUESDAY]: false,
    [DayOfWeek.WEDNESDAY]: false,
    [DayOfWeek.THURSDAY]: false,
    [DayOfWeek.FRIDAY]: false,
    [DayOfWeek.SATURDAY]: false,
    [DayOfWeek.SUNDAY]: false,
  };

  // Converts the DayOfWeek string to its enum
  dayOfWeekFromString = {
    [DayOfWeek.MONDAY]: DayOfWeek.MONDAY,
    [DayOfWeek.TUESDAY]: DayOfWeek.TUESDAY,
    [DayOfWeek.WEDNESDAY]: DayOfWeek.WEDNESDAY,
    [DayOfWeek.THURSDAY]: DayOfWeek.THURSDAY,
    [DayOfWeek.FRIDAY]: DayOfWeek.FRIDAY,
    [DayOfWeek.SATURDAY]: DayOfWeek.SATURDAY,
    [DayOfWeek.SUNDAY]: DayOfWeek.SUNDAY,
  };

  objectives: Array<UserObjective> = USER_OBJECTIVES;
  objective: UserObjective;
  time: string;

  constructor(private modalCtrl: ModalController, private premiumService: PremiumService) {}

  get displayTime() {
    if (this.time) return this.time;
    return 'Informe o tempo de estudo';
  }

  get YesOrNoResponseEnum() {
    return YesOrNoResponse;
  }

  async openModalChooseGoal() {
    const balance = await this.premiumService.getPointBalance();
    console.log({balance})

    const modalStudyTypeSelection = await this.modalCtrl.create({
      component: DialogSelectItemComponent,
      cssClass: 'sd modal-transparent',
      componentProps: {
        defaultValue: this.objective ? this.objective.objectId : null,
        items: this.objectives.map(goal => ({ label: goal.name, value: goal.objectId })),
        title: 'Objetivo:',
      }
    });

    await modalStudyTypeSelection.present();
    modalStudyTypeSelection.onDidDismiss().then(async result => {
      const { data } = result;
      if (data) this.objective = this.objectives.find(item => item.objectId === data.value);
      await this.premiumService.addPoint();
    });
  }

  _clickObjectiveSelector() {
    this.clickObjectiveSelector.emit();
  }

  _finishInterviewWithCycleSelected(response: YesOrNoResponse) {
    this.finishInterviewWithCycleSelected.emit(response);
  }

  _finishInterviewWithFreeStudySelected(response: YesOrNoResponse) {
    this.finishInterviewWithFreeStudySelected.emit(response);
  }

  _next() {
    this.next.emit();
  }

  _onSelectDaysOfWeek() {
    const selected: Array<DayOfWeek> = [];

    for (const day in this.selectedDaysOfWeek) {
      if (this.selectedDaysOfWeek[day] == true) {
        selected.push(this.dayOfWeekFromString[day]);
      }
    }

    this.onSelectDaysOfWeek.emit(selected);
  }

  _onSelectObjective() {
    if (this.hasPickedObjective) {
      this.onSelectObjective.emit(this.objective);
    }
  }

  _goToThirdStep() {
    this.goToThirdStep.emit();
  }

  _typeUserMessageDoKnowCycle(response: YesOrNoResponse) {
    this.typeUserMessageDoKnowCycle.emit(response);
  }

  _typeUserMessageHasSelectedCycle(response: any) {
    this.typeUserMessageHasSelectedCycle.emit(response);
  }

  _tapButton(indexButton: number) {
    this.tappedButton.emit(indexButton);
  }

  _typeUserMessageStudiesEveryday(response: YesOrNoResponse) {
    this.typeUserMessageStudiesEveryday.emit(response);
  }

  _typeUserMessageRepeatMetas(response: YesOrNoResponse) {
    this.typeUserMessageRepeatMetas.emit(response);
  }

  _handleAreWeightsDifferents(response: YesOrNoResponse) {
    this.handleAreWeightsDifferents.emit(response);
  }

  _pickTime() {
    if (!this.isTimePickerDisabled) {
      this.pickTime.emit(this.time);
    }
  }

  get inputType() {
    return this.interviewInputType;
  }

  get isTimePickerDisabled() {
    return !this.time || this.time.length == 0;
  }

  get hasDaysOfWeekSelected() {
    for (const day in this.selectedDaysOfWeek) {
      if (this.selectedDaysOfWeek[day] == true) {
        return true;
      }
    }

    return false;
  }

  get hasPickedObjective() {
    return this.objective && this.objective.objectId.length > 0 && this.objective.name.length > 0;
  }

  dayOfWeekShortString(day: DayOfWeek) {
    return DAYS_OF_WEEK_TO_SHORT_STRING[day];
  }

  toggleDayOfWeek(day: DayOfWeek) {
    this.selectedDaysOfWeek[day] = !this.selectedDaysOfWeek[day];
  }

  selectAllDays() {
    Object.keys(this.selectedDaysOfWeek).forEach(key => {
      this.selectedDaysOfWeek[key] = true;
    });
  }
}

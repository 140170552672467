import { Component, OnInit } from "@angular/core";
import { BasePageController } from "@app/pages/BasePage";
import ColorUtils from "@app/util/ColorUtils";
import { StatisticCachingService } from "@services/api/caching/statistic-caching/statistic-caching.service";
import { MetasService } from "@services/api/metas/metas.service";
import moment from "moment";
import * as _ from "underscore";
import { StatisticDayDetailDialogPage } from "./statistic-day-detail-dialog/statistic-day-detail-dialog.page";

@Component({
  selector: "app-statistic-tab-day",
  templateUrl: "./statistic-tab-day.page.html",
  styleUrls: ["./statistic-tab-day.page.scss"],
})
export class StatisticTabDayPage extends BasePageController implements OnInit {
  estatisticas: any = [];
  activeMonth: moment.Moment;
  lodadedMonths: Set<string> = new Set();

  eventos: any = {
    horarios: {},
    timezone: "America/Sao_Paulo",
  };

  tipo: any = "dia";
  metas: any = [0, 0, 0, 0, 0, 0, 0];
  subscription: any;
  constructor(
    private statisticCachingService: StatisticCachingService,
    private metasService: MetasService) {
    super();
  }

  ngOnInit() {
    const metaAtual = this.metasService.readMetas();

    if (metaAtual) {
      this.metas = [
        metaAtual.monday,
        metaAtual.tuesday,
        metaAtual.wednesday,
        metaAtual.thursday,
        metaAtual.friday,
        metaAtual.saturday,
        metaAtual.sunday,
      ];
    }
    this.activeMonth = moment();
    const firstDay = this.activeMonth
      .clone()
      .subtract(2, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const lastDay = this.activeMonth.endOf("month").format("YYYY-MM-DD");
    this.getEstatistica(firstDay, lastDay);
  }
  
  async abrirDetalhe(infoEvent) {
    console.log('abrirDetalhe')
    let tempoRevisao = 0;
    let tempoExercicio = 0;
    const tipoEstudo = _.groupBy(infoEvent, "tipo_estudo");

    _.each(tipoEstudo, (disciplinas, tipo) => {
      if (tipo === "Revisão") {
        _.each(disciplinas, (tipos, i) => {
          tempoRevisao += tipos.tempo;
        });
      } else if (tipo === "Exercícios") {
        _.each(disciplinas, (tipos, i) => {
          tempoExercicio += tipos.tempo;
        });
      }
    });

    const metaAtual = this.metasService.readMetas();
    let meta = 0;

    if (metaAtual) {
      this.metas = [
        metaAtual.monday,
        metaAtual.tuesday,
        metaAtual.wednesday,
        metaAtual.thursday,
        metaAtual.friday,
        metaAtual.saturday,
        metaAtual.sunday,
      ];

      const indexDia = moment(infoEvent.createAt).isoWeekday();
      meta = this.metas[indexDia - 1];
    }

    const dia = {
      status: infoEvent.status,
      dia: infoEvent.semana,
      data: infoEvent.data,
      createAt: infoEvent.createAt,
      estudado: infoEvent.hora,
      meta: meta,
      tempo_desperdicado: infoEvent.tempoParado,
      tempo_estudo: {
        estudo: infoEvent.hora,
        exercicio: tempoExercicio,
        revisao: tempoRevisao,
      },
      disciplinas: infoEvent.disciplinas,
    };

    const modal = await this.modalCtrl.create({
      component: StatisticDayDetailDialogPage,
      componentProps: { data: dia },
      cssClass: 'sd modal-transparent'
    });
    modal.present();
  }

  onSlidechanged(data) {
    const activeMonth: moment.Moment = data.activeMonth;
    this.activeMonth = activeMonth;
    const firstDay = this.activeMonth
      .clone()
      .subtract(2, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const lastDay = activeMonth.endOf("month").format("YYYY-MM-DD");
    this.getEstatistica(firstDay, lastDay);
  }

  onSelectDate(data: any) {
    if (data.event) {
      this.abrirDetalhe(data.event);
    }
  }

  async getEstatistica(startDate = "2020-07-01", endDate = "2020-07-31") {
    // check if the current month was already loaded
    const formattedMonth: string = endDate.substring(0, 7);
    if (this.lodadedMonths.has(formattedMonth)) {
      //await this.loading.dismiss();
      return;
    }

    const obj = {
      periodo: {
        email: this.userStoreService.user.username,
        start: startDate,
        end: endDate,
      },
    };

    return await this.statisticCachingService
      .read()
      .then((statistics) => {
        return statistics.filter((statisticItem) => {
          const createdAt = new Date(statisticItem.data);
          return (
            createdAt.getTime() >= new Date(obj.periodo.start).getTime() &&
            createdAt.getTime() <= new Date(obj.periodo.end).getTime()
          );
        });
      })
      .then(async (e) => {
        const groups = _.groupBy(e, "data");
        _.each(groups, (dia, i) => {
          const disciplinas = [];
          let totalHoras = 0;
          let totalParado = 0;
          let totalExercicos = 0;
          let tempoTotal = 0;

          _.each(dia, (e, z) => {
            totalHoras += e.tempo;
            totalParado += e.tempo_parado || 0;
            totalExercicos += parseFloat(e.total_exerc) || 0;
            const indexDia = moment(i).isoWeekday();
            const meta = this.metas[indexDia - 1];
            tempoTotal = meta;

            disciplinas.push({
              materia: e.curso,
              cor: ColorUtils.getColorFromStatistic(e),
              estudado: moment
                .utc(moment.duration(e.tempo, "s").asSeconds())
                .format("HH:mm:ss"),
              tempo: e.tempo,
              tempoParado: e.tempo_parado || 0,
              meta: moment
                .utc(moment.duration(tempoTotal, "s").asSeconds())
                .format("HH:mm:ss"),
            });
          });

          let percent;
          if (tempoTotal === 0 && totalHoras > 0) {
            percent = 100;
          } else {
            percent = (totalHoras * 100) / tempoTotal;
          }

          if (percent < 70) {
            dia.status = "ruim";
          } else if (percent >= 70 && percent < 100) {
            dia.status = "regular";
          } else if (percent >= 100) {
            dia.status = "bom";
          }

          dia.createAt = moment(i);
          dia.hora = moment
            .utc(moment.duration(totalHoras, "s").asSeconds())
            .format("HH:mm:ss");
          dia.data = moment(i).format("DD/MM/YYYY");
          dia.meta = moment
            .utc(moment.duration(tempoTotal, "s").asSeconds())
            .format("HH:mm:ss");
          dia.semana = moment(i).format("dddd");
          dia.tempoParado = moment
            .utc(moment.duration(totalParado, "s").asSeconds())
            .format("HH:mm:ss");
          dia.exercicios = totalExercicos;
          dia.disciplinas = disciplinas;
        });

       // Object.assign(this.eventos.horarios, groups);

        // this mechanism prevents to load an already loaded month
        this.lodadedMonths = new Set(
          Object.keys(groups).map((key: string) =>
            key.substring(0, 7)
          )
        );
        this.eventos.horarios = groups
      });
  }
}

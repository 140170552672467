import { AnalyticsEventEnum } from "@models/AnalyticsEventEnum";

export const EXTERNAL_LINKS: {
  [prop: string]: {
    link: string;
    amplitudeEvent: string;
  };
} = {
  youtube: {
    link: "https://www.youtube.com/channel/UCCLjGqRwsrZRfVfSZZmg6lQ",
    amplitudeEvent: AnalyticsEventEnum.BT_YOUTUBE,
  },
  facebook: {
    link: "https://www.facebook.com/estudaquioficial",
    amplitudeEvent: AnalyticsEventEnum.BT_FACEBOOK,
  },
  instagram: {
    link: 'https://www.instagram.com/estudaqui_oficial/',
    amplitudeEvent: AnalyticsEventEnum.BT_INSTAGRAM,
  },
  blog: {
    link: "https://www.estudaqui.com/blog",
    amplitudeEvent: AnalyticsEventEnum.BT_BLOG,
  },
  "app-details": {
    link: "https://www.estudaqui.com",
    amplitudeEvent: AnalyticsEventEnum.BT_APP_DETAILS,
  },
  "about-us": {
    link: "https://www.estudaqui.com/quem-somos",
    amplitudeEvent: AnalyticsEventEnum.BT_ABOUT_US,
  },
  tutorial: {
    link: "https://www.youtube.com/playlist?list=PLTcwQdvor55GIUDHpKr1ZFZLeeiKcs5MG",
    amplitudeEvent: AnalyticsEventEnum.BT_TUTORIAL,
  },
  FAQ: {
    link: "https://www.estudaqui.com/faq-perguntas-frequentes",
    amplitudeEvent: AnalyticsEventEnum.BT_FAQ,
  },
  "import-history": {
    link: "https://app.estudaqui.com/login",
    amplitudeEvent: AnalyticsEventEnum.BT_IMPORT_STUDY_DATA,
  },
};

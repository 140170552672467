import ChartUtils from '@app/util/ChartUtils';
import TimeUtils from '@app/util/TimeUtil';

export const getChart1 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-1',
      type: 'column'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Minutos por página',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif',
        },
      }
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: true,
        formatter: function () {
          return TimeUtils.formatMMSS(this.value);
        }
      },
      showLastLabel: false,
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          formatter: function () {
            return TimeUtils.formatMMSS(this.y);
          },
          style: {
            fontSize: '0.61rem',
            fontFamily: 'Comfortaa, sans-serif',
          },
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        }
      }
    },
    series: [
      {
        type: undefined,
        name: 'Min. por pág',
        colorByPoint: true,
        data: data
      }
    ]
  };
};

export const getChart2 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-2',
      type: 'column'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Número de páginas',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif',
        },
      }
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: true,
        format: '{value}'
      },
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '{point.y}',
          style: {
            fontSize: '0.61rem',
            fontFamily: 'Comfortaa, sans-serif',
          },
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        }
      }
    },
    series: [
      {
        type: undefined,
        name: 'Número de páginas',
        colorByPoint: true,
        data: data
      }
    ]
  };
};

export const getChart3 = (categories: Array<string | number>, data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      type: 'spline',
      renderTo: 'chart-3'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Tempo de leitura (min/pag) ',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'
      }
    },
    xAxis: {
      categories: categories,
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.62rem',
          fontFamily: 'Comfortaa, sans-serif'
        }
      }
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: true,
        format: '<b>{value} min/pag</b>'
      },
      title: null
    },
    plotOptions: {
      spline: {
        allowPointSelect: false,
        animation: false,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          format: '<b>{point.y:.2f}</b>',
          style: {
            fontSize: '0.61rem',
            fontFamily: 'Comfortaa, sans-serif'
          }
        }
      }
    },
    tooltip: {
      pointFormat: '<b>{point.y:.2f} min/pag</b>'
    },
    series: [
      {
        data: data
      }
    ]
  };
};

import { Injectable } from "@angular/core";
import { Tag } from "@models/Tags";
import { ParseService } from "@app/@services/api/parse/parse.service";

@Injectable({
  providedIn: "root",
})
export class TagsService {
  constructor(private parseService: ParseService) {}

  public addTagsToUser(tags: Array<Tag>, userId: string): Promise<any> {
    return this.parseService.run("addTagsToUser", { tags, userId });
  }
}

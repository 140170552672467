<app-common-header title="Meu Plano" (leftIconTap)="popActivity()" [isDark]="true" [hasFilter]="false"
  [hasTutorial]="false" [hasQuestionTutorial]="false"></app-common-header>
<ion-content class="ion-text-center ion-padding">
  <div class="container-data" *ngIf="!isFetchingData">
    <ion-list>
      <div class="plan-title">
        <h2>{{nomePlano}}</h2>
      </div>
      <div class="plan-image-container" *ngIf="!isFreemium">
        <img src="{{urlImagemPlano}}">
      </div>
      <div class="plan-info" *ngIf="!isFreemium">
        <p>Assinatura do Plano {{nomePlano}} começou em {{creationDate}} e será válida até {{expiryDate}}.</p>
        <p *ngIf="statusIsActive() && !isPagarmeSubscription">A renovação está programada para {{nextInvoiceDate}}.</p>
        <p class="plano-info-alert" *ngIf="statusIsSuspended() && !isPagarmeSubscription">A renovação automática do seu
          plano está cancelada, o que acarretará em suspensão dos serviços após {{expiryDate}}.</p>
        <p class="plano-info-alert" *ngIf="statusIsSuspended() && isPagarmeSubscription">Sua renovação automática foi
          cancelada. Após o vencimento do seu plano será necessária uma nova assinatura para voltar a usar o Estudaqui.
        </p>
      </div>
      <div class="plan-info" *ngIf="isFreemium">
        <p>Assinatura do Plano Grátis é valido indefinidamente.</p>
      </div>
    </ion-list>
  </div>
  <div class="skeleton" *ngIf="isFetchingData">
    <app-skeleton-item height="2em" width="50vw"></app-skeleton-item>
    <app-skeleton-item height="35vh" width="60vw"></app-skeleton-item>
    <div>
      <app-skeleton-item height="1.5em" width="90vw"></app-skeleton-item>
      <app-skeleton-item height="1.5em" width="90vw"></app-skeleton-item>
    </div>
    <app-skeleton-item height="3em" width="80vw"></app-skeleton-item>
  </div>
</ion-content>

<ion-footer>
  <div class="flex-center" *ngIf="statusIsActive() && !isPagarmeSubscription">
    <ion-button class="button-cancelar" expand="block" (click)="cancelarRenovacaoAutomatica()">
      Cancelar renovação automática
    </ion-button>
  </div>
  <div class="flex-center" *ngIf="statusIsActive() && isPagarmeSubscription">
    <ion-button class="button-cancelar" expand="block" (click)="cancelPagarmeSubscription()">
      CANCELAR RENOVAÇÃO
    </ion-button>
  </div>
  <div class="flex-center" *ngIf="statusIsSuspended() && isPagarmeSubscription">
    <ion-button class="button-cancelar" expand="block" (click)="reSubscribe()">
      NOVA ASSINATURA
    </ion-button>
  </div>
  <div class="flex-center" *ngIf="statusIsSuspended() && !isPagarmeSubscription">
    <ion-button class="button-cancelar" expand="block" (click)="retomarRenovacaoAutomatica()">
      Retomar renovação automática
    </ion-button>
    <div class="flex-center" *ngIf="enableRestore">
      <ion-button class="button-cancelar" expand="block" (click)="restoreGoogleSubscription()">
        Restaurar Google Inscrição
      </ion-button>
    </div>
  </div>
  <div class="flex-center" *ngIf="isGooglePaySubscription || isIOS">
    <ion-button class="button-cancelar" expand="block" (click)="handleHowToCancel()">Cancelar Assinatura</ion-button>
  </div>
</ion-footer>
import { Injectable } from '@angular/core';
import { ReviewUtils } from '@app/util/review-utils';
import { WeeklyscheduleService } from '@app/weekly-schedule/shared/weeklyschedule.service';
import { CycleBlocksCachingService } from '@services/api/caching/cycle-blocks-caching/cycle-blocks-caching.service';
import { CycleCachingService } from '@services/api/caching/cycle-caching/cycle-caching.service';
import { ReviewCachingService } from '@services/api/caching/review-caching/review-caching.service';
import { StatisticCachingService } from '@services/api/caching/statistic-caching/statistic-caching.service';
import { TopicCachingService } from '@services/api/caching/topic-caching/topic-caching.service';
import { UserSubjectCachingService } from '@services/api/caching/user-subject-caching/user-subject-caching.service';
import { MetasService } from '@services/api/metas/metas.service';
import { SyncService } from '@services/api/sync/sync.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { UserService } from '@services/api/user/user.service';
import { OneSignalService } from '@services/one-signal/one-signal.service';
import { KEY_LAST_REVIEW_NOTIFICATION_DATE } from '@services/review-notification/review-notification.service';
import { StorageService } from '@services/storage/storage.service';
import { TabIdentifier, TabService } from '@services/tab-service/tab.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private userStoreService: UserStoreService,
    private userService: UserService,
    private tabService: TabService,
    private weeklyScheduleService: WeeklyscheduleService,
    private syncService : SyncService,
    private reviewCachingService: ReviewCachingService,
    private statisticCachingService: StatisticCachingService,
    private cycleCachingService: CycleCachingService,
    private cycleBlocksCachingService: CycleBlocksCachingService,
    private userSubjectCachingService: UserSubjectCachingService,
    private topicCachingService: TopicCachingService,
    private metasService: MetasService,
    private storageService: StorageService,
    private oneSignalService: OneSignalService,
    private reviewUtils: ReviewUtils
    ) { }

  async logout() {
      this.syncService.setSyncStatus(false);
      this.weeklyScheduleService.clearReferences();
      this.tabService.tabParams.delete(TabIdentifier.CICLO);
      
      this.reviewCachingService.clear();
      this.reviewUtils.cancelAllNotifications();
      this.statisticCachingService.clear();
      this.cycleCachingService.clear();
      this.cycleBlocksCachingService.clear();
      this.userSubjectCachingService.clear();
      this.topicCachingService.clear();
      this.userStoreService.clear();
      this.syncService.clear();
      this.metasService.clear();
      this.storageService.remove('disableTypingEffect');
      this.storageService.remove('TAGS_LIST');
      this.storageService.remove('META_WEEK');
      this.storageService.remove('METAS_INTERVIEW_PROGRESS');
      this.storageService.remove('email-cadastrado');
      this.storageService.remove('user-has-seen-firstplan-dialog');
      this.storageService.remove(KEY_LAST_REVIEW_NOTIFICATION_DATE);

      this.oneSignalService.logout().catch(e => {});

      await this.userService.logOut().catch(e=> {});
  }
}

<section id="loved-by-students">
    <h2 class="text-center">Amado pelos estudantes</h2>
    <ul class="students-items" *ngFor="let talk of talkingAbout">
        <li [class]="talk.side">
            <ion-grid>
                <ion-row class="talk-row">
                    <ion-col class="talk-col" size="2" *ngIf="talk.side == 'left-image'">
                        <div class="table-cell">
                            <img src="{{talk.avatar}}">
                        </div>
                    </ion-col>
                    <ion-col class="talk-row">
                        <div class="text-card">
                            <p class="text-left">{{talk.text}}</p>
                            <p class="text-right">{{talk.author}}</p>
                        </div>
                    </ion-col>
                    <ion-col size="2" *ngIf="talk.side == 'right-image'">
                        <div class="table-cell">
                            <img src="{{talk.avatar}}">
                        </div>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </li>
    </ul>
    <ion-grid>
        <ion-row class="highlight-plus">
            <ion-col size="5">
                <p class="highlight-text --principal text-right">+ de</p>
            </ion-col>
            <ion-col>
                <p class="highlight-text">4.000 avaliações</p>
                <p class="highlight-text">500.000 downloads</p>
            </ion-col>
        </ion-row>
    </ion-grid>
</section>
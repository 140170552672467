<ion-content>
  <div class="container-dismiss" tappable (click)="dismiss()"></div>
  <div class="container-modal">
  <div class="heading">
    Parcelamento
  </div>
   <div class="content">
 

       <div *ngFor="let item of items" (click)="onClickItem( item )" class="dropdown-item">
         <img class="radio-icon" [src]="defaultValue == item.value ? checkedIcon : unCheckedIcon" />
         <span [innerHtml]="item.label"></span>
        </div>
      </div>



   <div class="footer">
    <div class="buttons">
      <ion-button color="primary" fill="clear" (click)="dismiss()">Voltar</ion-button>
      <ion-button color="primary" fill="clear" (click)="onItemSelect()">OK</ion-button>
    </div>
  </div>
  </div>
</ion-content>
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { EfficiencyChartsComponent } from "@app/components/statistics/efficiency-charts/efficiency-charts.component";
import { ExerciseChartsComponent } from "@app/components/statistics/exercise-charts/exercise-charts.component";
import { PageChartsComponent } from "@app/components/statistics/page-charts/page-charts.component";
import { TimeChartsComponent } from "@app/components/statistics/time-charts/time-charts.component";
import { BasePageController } from "@app/pages/BasePage";
import { IPeriod } from "@models/IPeriod";
import { Meta } from "@models/Meta";
import { Statistic, StatisticPageType } from "@models/Statistic";
import { MetasService } from "@services/api/metas/metas.service";

@Component({
  selector: "app-statistic-tab-week",
  templateUrl: "./statistic-tab-week.page.html",
  styleUrls: ["./statistic-tab-week.page.scss"],
})
export class StatisticTabWeekPage {
  @Input() statistics: Array<Statistic>;
  @Input() period: IPeriod;
  @Input() tipo: StatisticPageType;
  @Input() isTimeTabSelected: boolean;
  @Input() isExercisesTabSelected: boolean;
  @Input() isPagesTabSelected: boolean;
  @Input() isEfficiencyTabSelected: boolean;

  @ViewChild(TimeChartsComponent) timeCharts: TimeChartsComponent;
  @ViewChild(ExerciseChartsComponent) exerciseCharts: ExerciseChartsComponent;
  @ViewChild(PageChartsComponent) pageCharts: PageChartsComponent;
  @ViewChild(EfficiencyChartsComponent)
  efficiencyCharts: EfficiencyChartsComponent;

  metas: Meta;
  unregisterBackButtonAction: Function;
  isLoading: boolean = false;
  constructor(private metaService: MetasService) {
    this.metas = this.metaService.readMetas();  
  }

  ngOnDestroy(): void {
    if (this.unregisterBackButtonAction) this.unregisterBackButtonAction();
  }

  async plotCharts() {
    this.isLoading = true;
    setTimeout(() => {
      if (this.isTimeTabSelected) {
        this.timeCharts.plotAll();
      } else if (this.isExercisesTabSelected) {
        this.exerciseCharts.plotAll();
      } else if (this.isPagesTabSelected) {
        this.pageCharts.plotAll();
      } else {
        this.efficiencyCharts.plotAll();
      }
     this.isLoading = false;
    }, 1000);
  }
}

import { Injectable } from "@angular/core";
import { UserSubject } from "@models/Subject";
import { StatisticCachingService } from "@app/@services/api/caching/statistic-caching/statistic-caching.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StatisticService {
  private hasDrilldownOpened = false;
  //private drilldownComponentHolder: StatisticTabsHolder;
  private drilldownComponentHolder: any;
  backButtonSubject = new Subject<number>();

  constructor(private statisticCachingService: StatisticCachingService) {}

  backDrilldown() {
    if (this.drilldownComponentHolder) {
      this.hasDrilldownOpened = true;
      this.drilldownComponentHolder.chart.drillUp();
      this.drilldownComponentHolder = null;
      return true;
    }

    return false;
  }

  reset() {
    this.hasDrilldownOpened = false;
    this.drilldownComponentHolder = null;
  }

  hasActiveDrilldown() {
    return this.hasDrilldownOpened;
  }

  openDrilldown(component: any /*component: StatisticTabsHolder*/) {
    this.hasDrilldownOpened = true;
    this.drilldownComponentHolder = component;
  }

  async updateColorStatisticFromDisciplines(
    objectId: string,
    localObjectId: string,
    userSubjectsToUpdate: UserSubject[]
  ) {
    let statistics = await this.statisticCachingService.read();
    statistics = statistics ? statistics : [];

    const statisticsToUpdate = statistics.filter((s) => {
      const foundByLocalObjectId: boolean =
        localObjectId &&
        s.disciplina_usuario.localObjectId &&
        s.disciplina_usuario &&
        s.disciplina_usuario.localObjectId === localObjectId;
      const foundByObjectId: boolean =
        s.disciplina_usuario && s.disciplina_usuario.objectId === objectId;
      return (
        !!s.disciplina_usuario && (foundByLocalObjectId || foundByObjectId)
      );
    });

    statisticsToUpdate.forEach((statisticItem) => {
      const foundByLocalObjectId = !!(
        localObjectId && statisticItem.disciplina_usuario.localObjectId
      );
      if (foundByLocalObjectId) {
        statisticItem.disciplina_usuario = userSubjectsToUpdate.find(
          (subject) => subject.localObjectId === localObjectId
        );
      } else if (objectId) {
        statisticItem.disciplina_usuario = userSubjectsToUpdate.find(
          (subject) => subject.objectId === objectId
        );
      }
    });

    await this.statisticCachingService.set(statistics);
  }
}

import { Component, OnInit } from '@angular/core';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { DeviceService } from '@services/device/device.service';
import { SideMenuService } from '@services/side-menu/side-menu.service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
})
export class MenuFooterComponent implements OnInit{
  public deviceName: string = "";

  constructor(
    public sideMenuService: SideMenuService,
    public deviceService: DeviceService,
    public userStoreService: UserStoreService
    ) {}

  ngOnInit() {
    this.deviceService.getFormattedDeviceInfo().then((value)=> this.deviceName = value);
  }

  get hasUser() : boolean{
    return !!this.userStoreService.user;
  }

  get currentUserEmail() {
    return this.userStoreService.user
      ? this.userStoreService.user.username
      : "";
  }

  get currentUserExpiryDate() {
    return this.userStoreService.user
      ? this.userStoreService.user.validade : "";
  }

  get appVersion() : string {
    return environment.appVersion;
  }

  get currentUserPlan() {
    this.userStoreService.hasSubscription
    return this.userStoreService.user
      ? this.userStoreService.user.planoAtual.nome
      : "";
  }

  get isStaging() : boolean {
    return  !(environment.serverURL === 'https://api.estudaqui.com/')
  }

  get canTransfer() {
    return this.userStoreService && this.userStoreService.user && !this.userStoreService.user.expired && this.userStoreService.user.canTransfer;
  }

}

<app-common-header
  (leftIconTap)="dismiss()"
  (rightIconTap)="dismiss()"
  [title]="displayTitle"
  [isDark]="true"
  [hasLogo]="false"
  [hasDrawer]="false"
  [hasFilter]="false"
  [hasTutorial]="false"
></app-common-header>

<ion-content fullscreen class="ion-padding-horizontal">
  <div class="container-lista">
    <div *ngIf="!onlyColor">
      <p>
        Digite uma nova abreviatura para a disciplina
        <b>{{subject.displayName}}</b>
      </p>
      <div [formGroup]="formSubject">
        <ion-item class="subject-abbreviation" lines="none">
          <div class="subject-abbreviation-content">
            <ion-input
              #inputShortName
              type="text"
              maxlength="10"
              max-length="10"
              (keyup)="onKeyDown($event, 'shortName')"
              formControlName="shortName"
              placeholder="Escolha uma Abreviatura (10 caracteres)*"
            ></ion-input>
          </div>
        </ion-item>
      </div>

      <p *ngIf="subject">Selecione uma nova cor</p>
      <p *ngIf="!subject">Selecione uma cor para a nova disciplina</p>
    </div>

    <div class="scroll-list" [class.fullheight]="onlyColor">
      <ion-list class="list-itens">
        <ion-item
          lines="none"
          [ngStyle]="{
            'border-left-color': color.codigo,
            'border-left-style': 'solid',
            'border-left-width': '15px'
          }"
          class="item-list disc-select"
          [class.selected]="selectedColor && selectedColor.codigo == color.codigo"
          (click)="selectColor(color)"
          *ngFor="let color of colors"
        >
          <ion-label> {{color.nome}} </ion-label>
        </ion-item>
      </ion-list>
    </div>
  </div>
</ion-content>
<ion-footer class="ion-no-border ion-padding-horizontal">
  <ion-button *ngIf="!onlyColor" shape="round" expand="block" (click)="changeColor()"> Salvar </ion-button>
  <ion-button *ngIf="onlyColor" shape="round"  expand="block" (click)="chooseColor()">Confirmar</ion-button>
</ion-footer>


<app-close-popup></app-close-popup>

<ion-content class="ion-no-padding ion-no-border" fullscreen="true">
  <div class="container">
    <app-chat-progress avatar="assets/imgs/metas/help-bot.png" [title]="title"></app-chat-progress>
    <app-chat-messages></app-chat-messages>   
    
    <ng-container *ngIf="step === 0">
      <div class="create-cycle-buttons">
        <div class="create-cycle-manual" (click)="openAllExams()">
          <span class="title">Ver todas as provas</span>
          <span>Procurar provas cadastradas</span>
        </div>
        <div class="create-cycle-automatic" (click)="goToCreationStep()">
          <span class="title">Mapear nova prova</span>
          <span>Se não encontrou a sua</span>
        </div>
      </div>
    </ng-container>
       
    <div style="min-height: 100px;" *ngIf="step === 1">
      <ion-item lines="inset" color="lightblue" class="ion-margin-bottom ion-padding-horizontal">
        <ion-label position="floating">Nome da Prova</ion-label>
        <ion-input autofocus="true" [(ngModel)]="examTemporary.name" required="true"></ion-input>
      </ion-item>
      <ion-button  expand="block" (click)="onClickSaveExamName()" > Salvar </ion-button>
    </div>

    <ng-container *ngIf="step === 2">
      <app-chat-actions
      [interviewInputType]="inputType"s
      (handleAreWeightsDifferents)="goToEnd($event)"
    ></app-chat-actions>
      </ng-container>


  </div>
</ion-content>

import { Injectable } from '@angular/core';
import { UserService } from '@services/api/user/user.service';
import { LoadingService } from '@services/loading/loading.service';
import { ConnectionError } from '@services/utils/network.service';
import Swal from 'sweetalert2';

@Injectable({providedIn: 'root'})
export class LoginService {
  constructor(private userService: UserService, private loadingService: LoadingService) {}

  handleForgotPassword() {
    Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: 'CONTINUAR',
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      showCancelButton: false,
      showConfirmButton: false,
      title: 'Recuperar Senha',
      html:
        '<p class="sweet-alert-text indent">Para recuperar sua senha informe abaixo o seu e-mail cadastrado</p>' +
        `
                  <input class="swal2-input email-reset" style="margin-bottom: 5px;" placeholder="Informe o e-mail aqui" autofocus="true" />
                  <span class="email-error" style="visibility: hidden; display: block; text-align: left; font-weight: bold; color: #d33;">E-mail inválido</span>
        
                <div class="buttons" style="display: flex; padding-top: 7.5px; justify-content: space-between;">
                  <button class="swal2-confirm swal2-styled cancel" style="background-color: #d33; box-shadow: 2px 3px 4px #ccc; font-size: 13px; font-weight: bold; border-color: #d33; flex:1;">CANCELAR</button>
                  <button class="swal2-confirm swal2-styled reset-password" style="flex: 1; box-shadow: 2px 3px 4px #ccc; font-weight: bold; font-size: 13px;">CONTINUAR</button>
              </div>`,
      onOpen: () => {
        setTimeout(() => {
          const input: HTMLInputElement = document.querySelector('.email-reset');
          input.focus();
        }, 300);
      },
      preConfirm: async (data) => {
        Swal.close();
      }
    });

    document.querySelector('.cancel').addEventListener('click', () => {
      Swal.close();
    });

    document.querySelector('.reset-password').addEventListener('click', () => {
      const input: HTMLInputElement = document.querySelector('.email-reset');
      const span: HTMLSpanElement = document.querySelector('.email-error');
      input.value = this.prepareUserEmail(input.value);
      if (!this.validateEmail(input.value)) {
        span.style.visibility = 'visible';
        return;
      } else {
        span.style.visibility = 'hidden';
      }

      Swal.close();
      this.resetPassword({ email: input.value });
    });
  }

  private async resetPassword(data: { email: string }) {
    const email = data.email;
    await this.loadingService.show();

    try {
      const sendEmailMessage = `
          <p class="sweet-alert-text indent">Procure-o em sua caixa de entrada ou caixa spam, abra-o, e clique no link no corpo do e-mail para escolher uma nova senha.</p>
          <p class="sweet-alert-text indent">Caso não receba nosso e-mail em até 3 minutos <a href="mailto:suporte@estudaqui.com?subject=E-mail%20para%20recupera%C3%A7%C3%A3o%20de%20senha%20n%C3%A3o%20recebido - conta ${encodeURIComponent(email)}" class="msg-email">clique aqui para nos avisar.</a></a>`;

      await this.userService.requestPasswordReset(data.email);

      await Swal.fire({
        heightAuto: false,
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        cancelButtonText: 'CANCELAR',
        confirmButtonText: 'OK',
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        reverseButtons: true,
        showCancelButton: false,
        title: 'E-mail para trocar senha enviado',
        html: sendEmailMessage
      });
    } catch (error) {
      const emailEncoded = encodeURIComponent(email)
      if (error !== ConnectionError.CONNECTION_ERROR) {
        let errorMessage, title;
        if (error.code === 205 && error.message.startsWith('No user found with email')) {
          errorMessage = `<p class="sweet-alert-text indent">Por favor, verifique se o e-mail digitado está correto e tente novamente.</p>
              <p class="sweet-alert-text indent msg-contact">Se o problema persistir envie um e-mail para <a href="mailto:suporte@estudaqui.com?&subject=Erro%20ao%20recuperar%20senhar%3A%20e-mail%20n%C3%A3o%20cadastrado - conta ${emailEncoded}" class="msg-email">suporte@estudaqui.com</a></p>`;
          title = 'Email não cadastrado!';
        } else {
          errorMessage =
            '<p class="sweet-alert-text indent">Ocorreu algum problema ao enviar o email para recuperar senha. Por favor, Tente Novamente.</p>' +
            `<p class="class="sweet-alert-text indent">[DETAILS]: ${JSON.stringify(
              error,
              Object.getOwnPropertyNames(error)
            )}</p>`;
          title = 'Email não enviado';
        }

        Swal.fire({
          heightAuto: false,
          allowEnterKey: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          cancelButtonText: 'CANCELAR',
          confirmButtonText: 'OK',
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6',
          reverseButtons: true,
          showCancelButton: false,
          title: title,
          html: errorMessage
        }).then((result) => {});
      }
    }

    await this.loadingService.dismiss();
  }

  private validateEmail(email: string) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  private prepareUserEmail(email: string): string {
    return email.toLocaleLowerCase().trim();
  }
}

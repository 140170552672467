<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="close()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Meus Planos de Estudo</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  
  <div *ngIf="ciclos.length > 0">
    <ion-card *ngFor="let ciclo of ciclos" color="light">
      <ion-header>
        <ion-toolbar
          [ngClass]="{'ciclo-ativo': ciclo.objectId == cicloAtivoId}"
        >
          <ion-title size="small">
            <b> {{ ciclo.nome }} </b>
          </ion-title>
          <ion-buttons slot="end">
            <ion-button fill="clear" (click)="openDialogEditCycleName(ciclo)">
              <ion-icon slot="icon-only" name="pencil"></ion-icon>
            </ion-button>
            <ion-button fill="clear" (click)="delete(ciclo)">
              <ion-icon
                slot="icon-only"
                name="trash-outline"
                color="danger"
              ></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-card-content>
        <ion-row class="ion-text-center ion-no-padding">
          <ion-col size="4">
            <h3>Criado em</h3>
          </ion-col>
          <ion-col size="4">
            <h3>Tempo Estudado</h3>
          </ion-col>
          <ion-col size="4">
            <h3>Tempo Total</h3>
          </ion-col>
        </ion-row>
        <ion-row class="ion-text-center ion-no-padding">
          <ion-col size="4">
            <h2><b> {{getData(ciclo.createdAt)}} </b></h2>
          </ion-col>
          <ion-col size="4">
            <h2><b> {{getTempo(ciclo.tempoTotalCicloAtivo)}} </b></h2>
          </ion-col>
          <ion-col size="4">
            <h2><b> {{getTempo(ciclo.tempociclo)}} </b></h2>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>

  <app-empty-list title="Não encontramos planos de estudos" *ngIf="ciclos.length === 0"></app-empty-list>


  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="handleAddPlan()">
      <img src="assets/imgs/plans-compare/coroa_high.png" class="btn-crown-img">
      <ion-icon name="add" class="btn-add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

export enum ChartType {
  TEMPO = 'TEMPO',
  EXERCICIOS = 'EXERCICIOS',
  PAGINAS = 'PAGINAS',
  EFICIENCIA = 'EFICIENCIA',
}

export interface ChartSerie {
  color?: string;
  data?: Array<number>;
  name: string;
  y?: number;
  [key: string]: any;
}

export interface ChartSeries {
  [key: string]: ChartSerie;
}

export interface EffectivenessUnit {
  runningTime: number;
  stoppedTime: number;
}

export interface EffectivenessDict {
  [key: string]: EffectivenessUnit;
}

export interface DayDictionary {
  [key: string]: number;
}

export interface WeekDictionary {
  [key: string]: {
    start: string;
    end: string;
    total: number;
  };
}

export interface MonthDictionary {
  [key: string]: number;
}

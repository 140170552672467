import { Component, OnInit, ViewChild } from "@angular/core";
import { BasePageController } from "@app/pages/BasePage";
import { ChooseColorPage } from "@app/pages/choose-color/choose-color.page";
import { IonSearchbar, NavParams } from "@ionic/angular";
import { ChooseDisciplineParams } from "@models/choose-discipline-params";
import { Color } from "@models/Color";
import { UserSubject, SubjectItem, SubjectType } from "@models/Subject";
import { Topic } from "@models/topic.model";
import { ColorCachingService } from "@app/@services/api/caching/color-caching/color-caching.service";
import { UserSubjectCachingService } from "@app/@services/api/caching/user-subject-caching/user-subject-caching.service";
import { SubjectService } from "@app/@services/api/subject/subject.service";
import { TopicsService } from "@app/@services/api/topics/topics.service";
import { ColorService } from "@app/@services/color.service";
import { Severity } from "@sentry/types";

@Component({
  selector: 'app-choose-topic-by-discipline',
  templateUrl: './choose-topic-by-discipline.page.html',
  styleUrls: ['./choose-topic-by-discipline.page.scss']
})
export class ChooseTopicByDisciplinePage extends BasePageController implements OnInit {
  @ViewChild('searchBar') searchbar: IonSearchbar;

  discipline: UserSubject;
  filteredTopics: Topic[] = [];
  topics: Topic[] = [];
  selectedTopic: Topic;
  colors: Color[] = [];

  pageParams: ChooseDisciplineParams;
  listedSubjects: Array<SubjectItem> = [];
  generalSubjects: Array<SubjectItem> = [];
  selectedSubject: SubjectItem;

  // Information for adding a new subject
  newSubjectName: string;
  topicAbbreviation: string;
  newSubjectType: SubjectType;
  newSubjectTime = '01:00';
  newColor: Color = {
    codigo: '#00BFFF',
    nome: 'Azul céu profundo',
    createdAt: '2020-06-17T04:41:28.440Z',
    updatedAt: '2020-06-27T19:05:57.840Z',
    ordem: 38,
    objectId: 'ZO0MXEt3Eg'
  };
  constructor(
    public navParams: NavParams,
    private subjectService: SubjectService,
    private colorService: ColorService,
    private colorCachingService: ColorCachingService,
    private userSubjectCachingService: UserSubjectCachingService,
    private topicsService: TopicsService
  ) {
    super();
    this.pageParams = this.navParams.get('pageParams') as ChooseDisciplineParams;
    this.generalSubjects = this.subjectService.subjectItems.filter((subject) => !subject.excluida);
    if (!this.pageParams.canCreateDiscipline)
      this.generalSubjects = this.generalSubjects.filter((sub) => sub.userSubject);
    this.newColor = this.colorService.getRandomColor();
  }

  ngOnInit(): void {
    this.setSearchBarFocus();
  }

  get checkBoxIcon(): string {
    return 'assets/svg/white-circle.svg';
  }

  get isCreatingTopic() {
    return this.filteredTopics.length === 0;
  }

  private setSearchBarFocus() {
    setTimeout(() => {
      if (this.searchbar) this.searchbar.setFocus();
    }, 500);
  }

  async ionViewDidEnter() {
    this.listedSubjects = this.generalSubjects;
    this.discipline = this.navParams.get('discipline');
    this.selectedTopic = this.navParams.get('selectedTopic') as Topic;
    if (this.discipline) this.topics = await this.topicsService.findTopicsByDiscipline(this.discipline);
    this.topics.sort((a, b) => a.order - b.order);
    this.filteredTopics = this.topics.filter((topic) => !topic.flgDeleted);
    if (this.topics.length === 0) this.pageParams.title = 'Criar assunto';
  }

  async onTapTopicCreation() {
    const loading = await this.showLoading('Criando assunto', null);
    try {
      const description = this.searchbar.value;
      const newTopic: Topic = {
        color: this.newColor,
        description: description,
        abbreviation: this.topicAbbreviation ? this.topicAbbreviation : description.substring(0, 10),
        order: Date.now(),
        userDiscipline: this.discipline,
        user: this.userStoreService.user
      };

      const existingTopic = this.topics.find((topic) => {
        const disciplineByLocalObjectId =
          newTopic.userDiscipline.localObjectId &&
          topic.userDiscipline.localObjectId &&
          topic.userDiscipline.localObjectId === newTopic.userDiscipline.localObjectId;
        const disciplineByObjectId =
          newTopic.userDiscipline.objectId &&
          topic.userDiscipline.objectId &&
          topic.userDiscipline.objectId === newTopic.userDiscipline.objectId;
        return (
          topic.description.toLowerCase() === newTopic.description.toLowerCase() &&
          (disciplineByLocalObjectId || disciplineByObjectId)
        );
      });

      if (!existingTopic) {
        await this.topicsService.create(newTopic);
      } else {
        existingTopic.color = this.newColor;
        existingTopic.order = Date.now();
        existingTopic.flgDeleted = false;
        await this.topicsService.update(existingTopic);
      }

      this.topics = await this.topicsService.findTopicsByDiscipline(this.discipline);
      this.backToList();

      this.selectedTopic = this.topics[this.topics.length - 1];
      this.discipline.numTopics = this.topics.filter((topic) => !topic.flgDeleted).length;
      await this.userSubjectCachingService.update(this.discipline);

      await loading.dismiss();
      await this.doSelectTopic();
    } catch (e) {
      this.sentryService.withScope({ fn: 'onTapTopicCreation' }, e, Severity.Error);
      await loading.dismiss();
    }
  }

  backToList() {
    this.searchbar.value = null;
    this.filteredTopics = [...this.topics].filter((topic) => !topic.flgDeleted);
  }

  onTapTopicItem(topic: Topic) {
    this.selectedTopic = topic;
  }

  getItemCheckboxIcon(topic: Topic): string {
    if (
      this.selectedTopic &&
      topic.localObjectId == this.selectedTopic.localObjectId &&
      topic.description === this.selectedTopic.description
    )
      return 'assets/svg/blue-check-circle.svg';
    return 'assets/svg/white-circle.svg';
  }

  getLabelClass(topic: Topic) {
    if (this.selectedTopic && topic.localObjectId === this.selectedTopic.localObjectId) return 'subject-name blue';
    return 'subject-name';
  }

  async chooseColor() {
    if (this.colors.length === 0) this.colors = await this.colorCachingService.read();
    const modal = await this.modalCtrl.create({
      component: ChooseColorPage,
      componentProps: {
        colors: this.colors,
        subject: {
          color: this.newColor
        },
        onlyColor: true
      }
    });

    await modal.present();

    modal.onDidDismiss().then(({ data }) => {
      if (data && !data.reload) this.newColor = data.color as Color;
    });
  }

  async doSelectTopic() {
    if (!this.selectedTopic) {
      this.showToastMessage('Selecione um topico');
      return;
    }

    return await this.dismiss({
      selectedTopic: this.selectedTopic
    });
  }

  getItems(ev: any) {
    if (!ev) return;
    const val = ev.target.value as string;
    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      this.filteredTopics = this.topics.filter(
        (topic) => !topic.flgDeleted && topic.description.toLowerCase().includes(val.toLowerCase())
      );
    } else {
      this.filteredTopics = [...this.topics].filter((topic) => !topic.flgDeleted);
      this.listedSubjects = this.generalSubjects;
      this.topicAbbreviation = undefined;
      this.newSubjectType = undefined;
    }
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }
}

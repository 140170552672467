import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageService } from '@services/storage/storage.service';
const migrationCompletedKey = 'migration-completed';

@Injectable({
  providedIn: 'root'
})
export class MigrationService {
  constructor(
    private storage: StorageService,
    private platform: Platform
    ) {}

  async execute() {
    const migrationIsDone = await this.migrationIsDone();

    if (!migrationIsDone) {
      if(this.platform.is('android') || this.platform.is('ios')) {
        return this.migrateAndroidIndexedDb().then(()=> this.setCompleteMigration());
      }
    }

    return false;
  }

  private async migrateAndroidIndexedDb() {
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open('estudaqui_db');
      
      openRequest.onerror = (err) => {
        reject(err);
      };

      openRequest.onsuccess = async (event) => {
        const db = event.target['result'];
        const IONIC_3_OBJ_STORE_NAME = '_ionickv';

        db.onerror = (event) => {
          reject(event);
        };

        if (db.objectStoreNames.contains(IONIC_3_OBJ_STORE_NAME)) {
        
          const objectStore = db
            .transaction(IONIC_3_OBJ_STORE_NAME, 'readonly')
            .objectStore(IONIC_3_OBJ_STORE_NAME);

          objectStore.getAllKeys()
            .onsuccess = (allKeysEvent) => {
            const keys = allKeysEvent.target.result;

            objectStore.getAll().onsuccess = async (allValuesEvent) => {
              const values = allValuesEvent.target.result;
              // Reassemble everything that was in there.
              const reassembled = [];
              for (let i = 0; i < keys.length; ++i) {
                const obj = {key: keys[i], data: values[i]};
                reassembled.push(obj)
              }

              const jobs = reassembled.map((value)=> this.storage.set(value.key, value.data));
              await Promise.all(jobs).then(()=> resolve(true)).catch(e=> reject(e));
            
            };
          };
        } else {
          resolve(true)
        }
      };
    });
  }

  private async migrationIsDone(): Promise<boolean> {
    return this.storage.get(migrationCompletedKey) || false;
  }

  private async setCompleteMigration() {
    return this.storage.set(migrationCompletedKey, true);
  }
}

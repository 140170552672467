<section>
  <div class="objective-selector" (click)="_clickObjectiveSelector()" *ngIf="inputType === 'SELECT'">
    <div
          class="select-item"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 14px;
            padding-left: 14px;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
            color: #6D7D93;
          "
          (click)="openModalChooseGoal()"
        >
          <span class="ld-data"
            style="
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          ">
            {{ objective ? objective.name : 'Selecione seu objetivo' }}
          </span>
          <span>
            <ion-icon name="caret-down-sharp" class="icon-arrow" style="margin: 0 !important;"></ion-icon>
          </span>
        </div>
    <button
      [ngClass]="hasPickedObjective ? 'objective-button' : 'objective-button disabled'"
      (click)="_onSelectObjective()"
    >
      Ok
    </button>
  </div>
  
  <div style="padding: 15px 5px; color: #333;" class="time-picker"  *ngIf="inputType === 'TIME_PICKER'">
    <div
      customDateTime="time" [time]="time" [tickMinutes]="15"
      [hideSeconds]="true"
      (onSelect)="time = $event ? $event.time : time" 
    >{{ displayTime }}</div>
  
    <div [ngClass]="isTimePickerDisabled ? 'btn-time-picker disabled' : 'btn-time-picker'" (click)="_pickTime()">
      <ion-icon name="send-sharp"></ion-icon>
    </div>
  </div>
  
  <div class="weekdays" *ngIf="inputType === 'DAY_OF_WEEK_PICKER'">
    <div class="weekday-selector">
      <ul>
        <li
          (click)="toggleDayOfWeek(day)"
          [ngClass]="selectedDaysOfWeek[day] ? 'selected' : ''"
          *ngFor="let day of daysOfWeek;"
        >
          {{dayOfWeekShortString(day)}}
        </li>
      </ul>
    </div>
  
    <button (click)="selectAllDays()" class="btn-next">
      Marcar todos
    </button>
  
    <button [ngClass]="hasDaysOfWeekSelected ? 'btn-next' : 'btn-next disabled'" (click)="_onSelectDaysOfWeek()">
      Ok
    </button>
  </div>
  
  <button class="btn-round" (click)="_next()" *ngIf="inputType === 'LETS_GO_BUTTON'">Vamos lá!</button>
  <button class="btn-round" (click)="_tapButton(0)" *ngIf="inputType === 'LETS_GO_BUTTON_2'">Vamos lá!</button>
  <button class="btn-round" (click)="_tapButton(0)" *ngIf="inputType === 'GO_TO_STORE'">Ir atualizar o app</button>

  
  <button class="btn-round" (click)="_goToThirdStep()" *ngIf="inputType === 'OK_BUTTON'">Ok</button>
  
  <div class="btn-yes-no" *ngIf="inputType === 'YES_NO_BUTTON_KNOW_CYCLE'">
    <button (click)="_typeUserMessageDoKnowCycle(YesOrNoResponseEnum.YES)">Sim</button>
    <button (click)="_typeUserMessageDoKnowCycle(YesOrNoResponseEnum.NO)">Não</button>
  </div>
  
  <div class="btn-yes-no" *ngIf="inputType === 'YES_NO_BUTTON_STUDY_EVERYDAY'">
    <button (click)="_typeUserMessageStudiesEveryday(YesOrNoResponseEnum.YES)">Sim</button>
    <button (click)="_typeUserMessageStudiesEveryday(YesOrNoResponseEnum.NO)">Não</button>
  </div>
  
  <div class="btn-yes-no" *ngIf="inputType === 'YES_NO_BUTTON_REPEAT_METAS'">
    <button (click)="_typeUserMessageRepeatMetas(YesOrNoResponseEnum.YES)">Sim</button>
    <button (click)="_typeUserMessageRepeatMetas(YesOrNoResponseEnum.NO)">Não</button>
  </div>

  <div class="btn-yes-no" *ngIf="inputType === 'YES_NO_BUTTON_ARE_WEIGHTS_DIFFERENT'">
    <button (click)="_handleAreWeightsDifferents(YesOrNoResponseEnum.YES)">Sim</button>
    <button (click)="_handleAreWeightsDifferents(YesOrNoResponseEnum.NO)">Não</button>
  </div>
  
  <div class="select-study-type-horizontal" *ngIf="inputType === 'SELECT_STUDY_TYPE'">
    <div class="study-type" (click)="_typeUserMessageHasSelectedCycle('CYCLE')" style="flex: 1;">
      <img class="avatar" src="assets/imgs/cycle/ciclo.png" alt="Icone Ciclo" />
      <span>Ciclo de Estudo</span>
    </div>
    <div class="spacer" style="width: 10px;"></div>
    <div class="study-type" (click)="_typeUserMessageHasSelectedCycle('WEEKLY_SCHEDULE')" style="flex: 1;">
      <img class="avatar" src="assets/imgs/cycle/agenda.png" alt="Ícone agenda semanal" />
      <span>Agenda semanal</span>
    </div>
  </div>
  
  <div class="select-study-type-bottom" *ngIf="inputType === 'SELECT_STUDY_TYPE'">
    <div class="study-type" (click)="_typeUserMessageHasSelectedCycle('NO_PLAN')">
      <img class="avatar" src="assets/imgs/metas/metas-free.png" alt="Ícone estudo livre" />
      <span>Sem planejamento</span>
    </div>
  </div>
  
  <div class="select-study-type" *ngIf="inputType === 'FINISHED_WEEKLY_SCHEDULE'">
    <div class="study-type" (click)="_tapButton(0)">
      <img class="avatar" src="assets/weeklyschedule/icon_agenda-cadeado.png" alt="Icone: manter agenda" />
      <span class="text-center">Manter<br />Agenda</span>
    </div>
    <div class="study-type" (click)="_tapButton(1)">
      <img class="avatar" src="assets/weeklyschedule/icon_agenda-reiniciar.png" alt="Ícone: iniciar nova semana" />
      <span class="text-center">Iniciar Nova<br />Semana</span>
    </div>
  </div>
  
  <div class="select-study-type" *ngIf="inputType === 'FINISHED_CYCLE'">
    <div class="study-type" (click)="_tapButton(0)">
      <img class="avatar" src="assets/imgs/metas/metas-adicionar-ciclo.png" />
      <span>Modificar Ciclo</span>
    </div>
    <div class="study-type" (click)="_tapButton(1)">
      <img class="avatar" src="assets/imgs/metas/metas-reiniciar-ciclo.png" />
      <span>Reiniciar Ciclo</span>
    </div>
  </div>
  
  
  <div class="btn-yes-no general" *ngIf="inputType === 'GENERAL_YES_NO'">
    <button class="no" (click)="_tapButton(0)">NÃO</button>
    <button class="yes" (click)="_tapButton(1)">SIM</button>
  </div>

  <div class="btn-yes-no general" *ngIf="inputType === 'GENERAL_YES_NO_WITH_CROWN'">
    <button class="no" (click)="_tapButton(0)">NÃO</button>
    <button class="yes" (click)="_tapButton(1)">SIM 
      <img src="assets/imgs/plans-compare/coroa_high.png" class="crown-img">
    </button>
  </div>
  
  <div class="select-study-type" *ngIf="inputType === 'FINISHED_INTERVIEW_SELECTED_CYCLE'">
    <div class="study-type" (click)="_finishInterviewWithCycleSelected(YesOrNoResponseEnum.NO)">
      <img class="avatar" src="assets/imgs/metas/metas-youtube.png" />
      <span>Tutoriais</span>
    </div>
    <div class="study-type" (click)="_finishInterviewWithCycleSelected(YesOrNoResponseEnum.YES)">
      <img class="avatar" src="assets/imgs/metas/metas-cycle.png" />
      <span>Criar Ciclo</span>
    </div>
  </div>
  
  <div class="select-study-type" *ngIf="inputType === 'FINISHED_INTERVIEW_SELECTED_FREE_STUDY'">
    <div class="study-type" (click)="_finishInterviewWithFreeStudySelected(YesOrNoResponseEnum.NO)">
      <img class="avatar" src="assets/imgs/metas/metas-youtube.png" />
      <span>Tutoriais</span>
    </div>
    <div class="study-type" (click)="_finishInterviewWithFreeStudySelected(YesOrNoResponseEnum.YES)">
      <img class="avatar" src="assets/imgs/metas/metas-start.png" />
      <span>Iniciar Uso</span>
    </div>
  </div>
</section>
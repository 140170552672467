import { Component, Input, OnInit } from "@angular/core";
import { CreditCard } from "@models/CreditCard";
import { CreditCardService } from "@app/@services/credit-card/credit-card.service";

@Component({
  selector: "app-credit-card",
  templateUrl: "./credit-card.component.html",
  styleUrls: ["./credit-card.component.scss"]
})
export class CreditCardComponent {
  @Input() card: CreditCard;
  @Input() hasCard: boolean;

  /**
   * @see https://dev.wirecard.com.br/docs/cart%C3%A3o-de-cr%C3%A9dito
   *
   * A Wirecard, como facilitador de pagamento, disponibiliza aos parceiros a opção de receber pagamentos
   * por cartão de crédito, logo, é o lojista que decide até quantas vezes a compra pode ser parcelada, e
   * todas as outras condições de parcelamento.
   * bandeiras aceitas: Visa, MasterCard, Hiper, Hipercard, Dinners Club, American Express e Elo.
   *
   * No caso de Assinaturas não são aceitos os cartões Hiper e Hipercard.
   */
  constructor(private creditCardService: CreditCardService) {}


  highlightBrand() {
    if (!this.hasCard) {
      const brandObject = this.creditCardService.getBrand(this.card.cardNumber);
      this.card.brand = brandObject ? brandObject.brand : null;    }
  }

  setExpiration(value) {
    this.card.expiration = value;
  }

  setCVV(value) {
    this.card.cvv = value;
  }


  setHolderName(value) {
    this.card.holderName = value;
  }

  get urlBrandAmex() {
    return this.card && this.card.brand === "AMEX"
      ? "assets/svg/credit-card/amex.svg"
      : "assets/svg/credit-card-mono/amex.svg";
  }

  get urlBrandDiners() {
    return this.card && this.card.brand === "DINERS"
      ? "assets/svg/credit-card/diners.svg"
      : "assets/svg/credit-card-mono/diners.svg";
  }

  get urlBrandElo() {
    return this.card && this.card.brand === "ELO"
      ? "assets/svg/credit-card/elo.svg"
      : "assets/svg/credit-card-mono/elo.svg";
  }

  get urlBrandMastercard() {
    return this.card && this.card.brand === "MASTERCARD"
      ? "assets/svg/credit-card/mastercard.svg"
      : "assets/svg/credit-card-mono/mastercard.svg";
  }

  get urlBrandVisa() {
    return this.card && this.card.brand === "VISA"
      ? "assets/svg/credit-card/visa.svg"
      : "assets/svg/credit-card-mono/visa.svg";
  }
}

import { StatisticPageType } from '@models/Statistic';
import * as moment from 'moment';

/************************************************************************/
/********** Utilidades usadas pela entrevista de metas         **********/
/************************************************************************/
const weekDays = {
  SEG: { index: 0, responseId: 5, dayName: 'Segunda', dayFullName: 'Segunda-feira' },
  TER: { index: 1, responseId: 7, dayName: 'Terça', dayFullName: 'Terça-feira' },
  QUA: { index: 2, responseId: 8, dayName: 'Quarta', dayFullName: 'Quarta-feira' },
  QUI: { index: 3, responseId: 9, dayName: 'Quinta', dayFullName: 'Quinta-feira' },
  SEX: { index: 4, responseId: 10, dayName: 'Sexta', dayFullName: 'Sexta-feira' },
  SAB: { index: 5, responseId: 11, dayName: 'Sábado', dayFullName: 'Sábado' },
  DOM: { index: 6, responseId: 12, dayName: 'Domingo', dayFullName: 'Domingo' }
};

const weekDayDict = {
  '0': 'D',
  '1': 'S',
  '2': 'T',
  '3': 'Q',
  '4': 'Q',
  '5': 'S',
  '6': 'S'
};

const monthDict = {
  '0': 'jan',
  '1': 'fev',
  '2': 'mar',
  '3': 'abr',
  '4': 'mai',
  '5': 'jun',
  '6': 'jul',
  '7': 'ago',
  '8': 'set',
  '9': 'out',
  '10': 'nov',
  '11': 'dez'
};

export default class DateUtils {
  static convertToWeekDays(weekdaysString: Array<string>) {
    const days = weekdaysString.map((weekday) => weekDays[weekday]);

    days.sort((a, b) => {
      if (a.index > b.index) {
        return 1;
      }

      if (a.index < b.index) {
        return -1;
      }

      return 0;
    });

    return days;
  }

  /**
   * Convert a date in format YYYY-MM-DD to DD/MM
   * @param date
   *        date in format YYYY-MM-DD
   */
  static convertDatetoDDMM(date: string): string {
    return moment(date, 'YYYY-MM-DD').format('DD/MM');
  }

  /**
   * Convert a date in format YYYY-MM-DD to W DD/MM, where W is the initial of
   * the weekday.
   *
   * @param date
   *        date in format YYYY-MM-DD
   */
  static convertDatetoWDDMM(date: string): string {
    const day = moment(date, 'YYYY-MM-DD');
    return `${weekDayDict[day.day()]} ${day.format('DD/MM')}`;
  }

  /**
   * Convert a date range in format YYYY-MM-DD|YYYY-MM-DD to DD/MM - DD/MM
   * @param range
   *        date range in format YYYY-MM-DD|YYYY-MM-DD
   */
  static convertWeekRange(range: string): string {
    const rangeArr = range.split('|');
    const start = moment(rangeArr[0], 'YYYY-MM-DD').format('DD/MM');
    const end = moment(rangeArr[1], 'YYYY-MM-DD').format('DD/MM');
    return `${start} - ${end}`;
  }

  /**
   * @param day
   *        day in format YYYY-MM-DD
   */
  static getDayOfWeek(dayOfWeek: number): string {
    switch (dayOfWeek) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Segunda';
      case 2:
        return 'Terça';
      case 3:
        return 'Quarta';
      case 4:
        return 'Quinta';
      case 5:
        return 'Sexta';
      default:
        return 'Sábado';
    }
  }

  /**
   * Return the name of month based on it's index (Zero based!)
   *
   * @param index
   *        zero based
   */
  static getMonthNameByIndex(index: string) {
    return monthDict[index];
  }

  static formatByType(value: string, tipo: StatisticPageType) {
    let formatted: string;

    if (tipo === 'semana') {
      formatted = this.convertDatetoWDDMM(value);
    } else if (tipo === 'mes') {
      formatted = this.convertWeekRange(value);
    } else if (tipo === 'ano') {
      formatted = this.getMonthNameByIndex(value);
    }

    return formatted;
  }

  static isExpirationDateBeforeToday(expiration: string): boolean {
    return moment()
      .startOf('day')
      .isAfter(moment(expiration).startOf('day'));
  }
}

export { weekDays };

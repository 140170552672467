import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AnalyticsEventEnum } from '@models/AnalyticsEventEnum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {
  constructor(private angularFireAnalytics: AngularFireAnalytics) {
    this.angularFireAnalytics.logEvent('init');
  }

  setUserId(userId: string) {
    this.angularFireAnalytics.setUserId(userId);
  }

  setUserPlan(planId: string, expired:boolean, planExp: Date, planName: string) {

    let situacao = 'assinante';
    if(planId === environment.freemiumPlanId) {
      situacao = 'gratuito'
    } else if (planId === environment.trialPlanId) {
      situacao = 'trial'
    }

    this.angularFireAnalytics.setUserProperties({
      plan_id: planId,
      plan_exp: planExp,
      expired,
      plan_name: planName,
      Situacao: situacao
    })
  }

  setUserObjective(objective: string) {
    this.angularFireAnalytics.setUserProperties({ 'Objetivo': objective });
  }

  logEvent(eventName: AnalyticsEventEnum | string, eventParams?: {[key: string]: any}) {
    this.angularFireAnalytics.logEvent(eventName, eventParams)
  }
}

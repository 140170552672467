import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChatMessagesComponent } from '@app/bot/chat-messages/chat-messages.component';
import { BasePageController } from '@app/pages/BasePage';
import { InterviewInputType } from '@models/InterviewBot';
import { MyExamCreatePage } from '../my-exam-create/my-exam-create.page';
import { CreateAutomaticCyclePage } from '@app/cycle/create-automatic-cycle/create-automatic-cycle.page';
import { EventService } from '@services/event-service/event.service';

@Component({
  selector: 'app-my-exam-create-helper',
  templateUrl: './my-exam-create-helper.page.html',
  styleUrls: ['./my-exam-create-helper.page.scss']
})
export class MyExamCreateHelperPage extends BasePageController implements OnInit {
  @ViewChild(ChatMessagesComponent, { static: true }) chat: ChatMessagesComponent;
  @Input() nicho: string;
  @Input() startFromFirstStep: boolean = false;

  inputType: InterviewInputType;

  step: number = 1;
  title: string = 'Mapear nova prova';
  examTemporary = {
    name: '',
    areWeightsDifferent: false
  };

  constructor(private eventService: EventService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.step = this.startFromFirstStep ? 0 : 1;
    this.refreshChatText();
  }

  get hasFinishedTyping(): boolean {
    return this.chat && this.chat.hasFinishedTyping();
  }

  private refreshChatText() {
    if (this.step === 0) {
      this.chat.clearChatAndTypeBotMessage(`
      Nossa <Inteligência Artificial> cria planos de estudo <automaticamente> para as provas cadastradas.
      <1º busque> sua prova com diferentes <nomes e abreviações> que ela possa ter. Temos <+ de 1.500> provas <já mapeadas>, a sua pode ser uma delas.
      Caso não a encontre, <você mesmo pode adicioná-la> para que o app crie planos automáticos <para ela também.>`);
    } else {
      this.chat
        .clearChatAndTypeBotMessage(`Nossa <Inteligência Artificial> cria planos de estudo <automaticamente> para as provas cadastradas.
        Vamos criar sua prova!
        Informe o <nome oficial dessa prova,> aquele que aparece nos editais ou nos formulários de inscrições.
        <Caso não exista nome oficial>, escolha um que identifique sua prova da melhor maneira possível.`);
    }

    this.changeDetectorRef.detectChanges();
  }

  onClickSaveExamName() {

    if(!this.resolveFinishTyping()) {
      return;
    }
    const examNameInput = this.examTemporary.name;

    if (!examNameInput || examNameInput?.length === 0) {
      this.showToastWarningMessage('O nome da prova é obrigatório');
      return;
    } else if (examNameInput.length < 3) {
      this.showToastWarningMessage('O nome da prova deve ter mais que 3 caracteres.');
      return;
    } else if (examNameInput.length > 120) {
      this.showToastWarningMessage('O nome da prova deve ter menos que 120 caracteres.');
      return;
    }

    this.goToWeightQuestion();
  }

  async openAllExams() {
    if (this.resolveFinishTyping()) {
      this.modalCtrl.dismiss({ dismiss: true });
      this.eventService.publish('OPEN_CREATE_AUTOMATIC_PLAN');
    }
  }

  goToCreationStep() {
    if (this.resolveFinishTyping()) {
      this.step++;
      this.refreshChatText();
    }
  }

  private goToWeightQuestion() {
    const botText = `A prova <"${this.examTemporary.name}"> possui <pesos diferentes> para algumas disciplinas ?
      Se a prova contenha <oficialmente> pesos diferentes para as disciplinas, <clique em "SIM">
      Se o <peso for igual para todas as disciplinas> ou você estiver em dúvida, <clique "NÃO".>
      Você poderá alterar essa opção na próxima tela.
    `;
    this.setNewBoMessage(botText);
    this.inputType = InterviewInputType.YES_NO_BUTTON_ARE_WEIGHTS_DIFFERENT;

    this.step++;
  }

  async goToEnd(response: string) {
    if (!this.chat.hasFinishedTyping()) {
      this.chat.showCompleteText();
    } else {
      this.examTemporary.areWeightsDifferent = response === 'YES' ? true : false;

      const modal = await this.modalCtrl.create({
        component: MyExamCreatePage,
        cssClass: 'transparent sd',
        componentProps: {
          myExam: {
            cargo: this.examTemporary.name,
            pesosDiferentes: this.examTemporary.areWeightsDifferent,
            nicho: this.nicho
          }
        }
      });

      await modal.present();

      const { data } = await modal.onDidDismiss();

      await this.modalCtrl.dismiss(data);
    }
  }

  private setNewBoMessage(text: string) {
    this.chat.clearChatAndTypeBotMessage(text);
    this.changeDetectorRef.detectChanges();
  }

  private resolveFinishTyping() {
    if (!this.hasFinishedTyping) {
      this.chat.showCompleteText();
      return false;
    }

    return true;
  }
}

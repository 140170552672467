<ion-toolbar translucent="true">
    <ion-title size="small" class="ion-text-center">Informe a quantidade de questões e peso na prova</ion-title>
</ion-toolbar>
<ion-content class="ion-text-center">
    <h5>{{ subjectName }}</h5>
    <ion-grid fixed>

        <ion-row>
            <ion-col size="12">
                <ion-item lines="none">
                    <ion-label position="stacked"> Quantidade de questões </ion-label>
                    <ion-input [(ngModel)]="quantidadeQuestoes" type="number" placeholder="Ex.: 10"></ion-input>
                    <ion-button class="ion-margin-top" slot="end" fill="clear" (click)="myExamService.openHelpQtdQuestoes()">
                        <ion-icon style="font-size: 28px;" slot="icon-only" src="/assets/svg/help-circle.svg"></ion-icon>
                    </ion-button>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="canEditPeso">
            <ion-col size="12">
                <ion-item lines="none">
                    <ion-label position="stacked"> Peso da disciplina </ion-label>
                    <ion-input [(ngModel)]="peso" type="number"  placeholder="Ex.: 1"></ion-input>
                    <ion-button class="ion-margin-top" slot="end" fill="clear" (click)="myExamService.openHelpPeso()">
                        <ion-icon style="font-size: 28px;" slot="icon-only" src="/assets/svg/help-circle.svg"></ion-icon>
                    </ion-button>
                </ion-item>
            </ion-col>
        </ion-row>

    </ion-grid>
</ion-content>
<ion-footer>
    <ion-grid>
        <ion-row>
            <ion-col size="6">
                <ion-button color="danger" expand="block" shape="round" (click)="close()">Cancelar</ion-button>
            </ion-col>

            <ion-col size="6">
                <ion-button expand="block" shape="round" (click)="onAdicionar()">Adicionar</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>

</ion-footer>
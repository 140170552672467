import { Injectable } from '@angular/core';
import Phone from '@vtex/phone';
import('@vtex/phone/countries/BRA');

@Injectable({
   providedIn: 'root'
})
export class PhoneService {
   constructor() {}

   /**
    * Recupera o código de área do telefone (DDD), no formato brasileiro.
    *
    * @param phoneNumber Número de telefone
    */
   public getPhoneAreaCode(phoneNumber: string): string {
      if (this.isPhoneValid(phoneNumber)) {
         const phone = Phone.getPhoneInternational('55' + phoneNumber);
         return phone.nationalDestinationCode;
      }

      return undefined;
   }

   /**
    * Recupera o número de telefone no formato brasileiro.
    *
    * @param phoneNumber Número de telefone
    */
   public getPhoneNumber(phoneNumber: string): string {
      if (this.isPhoneValid(phoneNumber)) {
         const phone = Phone.getPhoneInternational('55' + phoneNumber);
         return phone.number;
      }

      return undefined;
   }

   /**
    * Recebe um número de telefone e verifica se é válido para o formato brasileiro.
    *
    * @param phoneNumber Número de telefone
    */
   public isPhoneValid(phoneNumber: string): boolean {
      if (!this.isEmpty(phoneNumber)) {
         const phonePrepared = phoneNumber.replace(/\D/g, '');
         return Phone.validate(phonePrepared, '55');
      }

      return false;
   }

   private isEmpty(field: string): boolean {
      return !field || field.length == 0;
   }
}

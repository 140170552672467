import { Component, ViewChild } from '@angular/core';
import { CustomDateTimeDirective } from '@app/directives/custom-date-time/custom-date-time';
import TimeUtils from '@app/util/TimeUtil';
import { ModalController } from '@ionic/angular';
import { Meta } from '@models/Meta';
import { MetasService } from '@app/@services/api/metas/metas.service';
import moment from 'moment';
import { BasePageController } from '../BasePage';
import { OfflineTutorialsPage } from '@app/offline-tutorials/offline-tutorials.page';

@Component({
  selector: 'app-metas',
  templateUrl: './metas.page.html',
  styleUrls: ['./metas.page.scss']
})
export class MetasPage extends BasePageController {
  segunda: string;
  terca: string;
  quarta: string;
  quinta: string;
  sexta: string;
  sabado: string;
  domingo: string;
  metas: Array<any> = [];
  totalMetasString = '00:00';
  isIphoneX = false;
  curWeekDayEditing: string;
  @ViewChild(CustomDateTimeDirective)
  timeComponent: CustomDateTimeDirective;

  constructor(private metasService: MetasService, private modalController: ModalController) {
    super();
  }

  async ionViewWillEnter() {
    const metas = this.metasService.readMetas();
    if (metas) {
      this.segunda = TimeUtils.millisToHHMM(metas.monday);
      this.terca = TimeUtils.millisToHHMM(metas.tuesday);
      this.quarta = TimeUtils.millisToHHMM(metas.wednesday);
      this.quinta = TimeUtils.millisToHHMM(metas.thursday);
      this.sexta = TimeUtils.millisToHHMM(metas.friday);
      this.sabado = TimeUtils.millisToHHMM(metas.saturday);
      this.domingo = TimeUtils.millisToHHMM(metas.sunday);
      this.refreshMetas();
    }
  }

  async openTimerDialog(weekdayName: string) {
    this.networkService.executeIfOnline(() => {
      this.curWeekDayEditing = weekdayName;
      if (this.timeComponent) {
        this.timeComponent.time = this[weekdayName];
        this.timeComponent.onClick(null);
      }
    });
  }

  async saveMetas() {
    if (!this.segunda || !this.terca || !this.quarta || !this.quinta || !this.sexta || !this.sabado || !this.domingo) {
      await this.showToastMessage('Você precisa escolher metas de toda semana..');
      return;
    }

    this.networkService.executeIfOnline(() => this.onFinish());
  }

  async onFinish() {
    const loading = await this.showLoading('Salvando...');
    this.refreshMetas();

    const meta: Meta = {
      monday: this.metas[0],
      tuesday: this.metas[1],
      wednesday: this.metas[2],
      thursday: this.metas[3],
      friday: this.metas[4],
      saturday: this.metas[5],
      sunday: this.metas[6],
      userId: this.userStoreService.user.objectId
    };

    try {
      await this.metasService.createMeta(meta);

      await this.showToastMessage('Metas salvas com sucesso..');
      this.close();
    } catch (err) {
      await this.showToastMessage('Ocorreu um erro ao salvar as metas. Por favor, tente novamente.');
      console.log('Erro ao atualizar metas no servidor.', err);
    }
    loading.dismiss();
  }

  onTimeSelect(data, property) {
    this.networkService.executeIfOnline(() => {
      if (data && data.time) {
        this[property] = data.time.split(':').slice(0, 2).join(':');
        this.refreshMetas();
      }
    });
  }

  refreshMetas() {
    this.metas = [
      moment.duration(this.segunda, 's').asMilliseconds(),
      moment.duration(this.terca, 's').asMilliseconds(),
      moment.duration(this.quarta, 's').asMilliseconds(),
      moment.duration(this.quinta, 's').asMilliseconds(),
      moment.duration(this.sexta, 's').asMilliseconds(),
      moment.duration(this.sabado, 's').asMilliseconds(),
      moment.duration(this.domingo, 's').asMilliseconds()
    ];

    const totalMetas = this.metas.reduce((accumulator, current) => accumulator + current);
    this.totalMetasString = TimeUtils.formatHHMM(totalMetas);
  }

  async openTutorialTab() {
    const tutorialModal = await this.modalCtrl.create({
      component: OfflineTutorialsPage,
      animated: false,
      componentProps: {
        title: 'Metas de Estudo',
        youtubeURL: 'https://youtube.com/shorts/9CHWq-mj0bY?feature=share', //mudar link
        message: `Dicas sobre as metas de estudo: 
        1. Suas metas de estudo são <importantes> para as <estatísticas>. Há diversos comparativos entre as metas e o tempo real de estudo.
        2. Para <editar> as metas, entre no dia desejado, escolha a nova quantidade de horas e clique em <salvar>.`
      },
      cssClass: 'sd modal-transparent'
    });
    tutorialModal.present();
  }

  close() {
    this.modalController.dismiss();
  }
}

import { Injectable } from "@angular/core";
import { StorageService } from "@app/@services/storage/storage.service";
import { ICachingRead } from "../icaching-read";

@Injectable({
  providedIn: "root",
})
export class ClearCachingService<T> {
  constructor(private storage: StorageService) {}

  async clear(caching: ICachingRead<T>) {
    try {
      await this.storage.remove(caching.entityName);
      await this.storage.remove(`${caching.entityName}LastUpdate`);
      caching.lastUpdate = null;
      caching.cachedData = [];
    } catch (e) {
      console.log("error on clear", e);
    }
  }
}

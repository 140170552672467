<app-common-header
  title="Metas"
  [isDark]="true"
  (leftIconTap)="close()"
  (rightIconTap)="openTutorialTab()"
  [hasFilter]="false"
  [hasTutorial]="true"
></app-common-header>

<ion-content fullscreen class="ion-padding">
  <ion-list>
    <div class="container-list-metas">
      <div class="item-list" (click)="openTimerDialog('segunda')">
        <span>Segunda</span>
        <span>{{ segunda }}</span>
      </div>

      <div class="item-list" (click)="openTimerDialog('terca')">
        <span>Terça</span>
        <span>{{ terca }}</span>
      </div>

      <div class="item-list" (click)="openTimerDialog('quarta')">
        <span>Quarta</span>
        <span>{{ quarta }}</span>
      </div>

      <div class="item-list" (click)="openTimerDialog('quinta')">
        <span>Quinta</span>
        <span>{{ quinta }}</span>
      </div>

      <div class="item-list" (click)="openTimerDialog('sexta')">
        <span>Sexta</span>
        <span>{{ sexta }}</span>
      </div>

      <div class="item-list" (click)="openTimerDialog('sabado')">
        <span>Sábado</span>
        <span>{{ sabado }}</span>
      </div>

      <div class="item-list" (click)="openTimerDialog('domingo')">
        <span>Domingo</span>
        <span>{{ domingo }}</span>
      </div>
    </div>


    <div class="item-list amount">
      <span>Total da semana</span>
      <span>{{ totalMetasString }}</span>
    </div>
  </ion-list>

  <div
    #divCustomTime
    customDateTime
    [hideSeconds]="true"
    [tickMinutes]="15"
    (onSelect)="onTimeSelect($event, curWeekDayEditing)"
    class="content"
    style="padding: 10px"
  ></div>
</ion-content>
<ion-footer class="ion-padding">
  <ion-button color="primary" shape="round" expand="block" (click)="saveMetas()">
    Salvar
  </ion-button>
</ion-footer>



import { Injectable } from '@angular/core';
import { Review } from '@models/Review';
import { UserSubject } from '@models/Subject';
import { OfflineStorageService } from '@app/@services/api/offline-storage/offline-storage.service';
import { ReviewService } from '@app/@services/api/review/review.service';
import { SyncService } from '@app/@services/api/sync/sync.service';
import { UserStoreService } from '@app/@services/api/user-store/user-store.service';
import { interval, BehaviorSubject } from 'rxjs';
import { CachingUtils } from '../../../../util/caching-utils';
import { ClearCachingService } from '@app/@services/api/caching/clear-caching/clear-caching.service';
import { TopicCachingService } from '@app/@services/api/caching/topic-caching/topic-caching.service';
import { UserSubjectCachingService } from '@app/@services/api/caching/user-subject-caching/user-subject-caching.service';
import { ErrorReportService, IErrorReport } from '@services/error-report/error-report.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewCachingService {
  entityName = 'cachedReview';
  cachedData: Array<Review> = [];
  lastUpdate: Date = null;
  syncTimer = interval(60000);
  syncUpdate: BehaviorSubject<Review[]> = new BehaviorSubject<Review[]>([]);
  reviewData$: BehaviorSubject<Review[]> = new BehaviorSubject<Review[]>([]);

  constructor(
    private offlineStorage: OfflineStorageService,
    private reviewService: ReviewService,
    private userStore: UserStoreService,
    private clearCaching: ClearCachingService<Review>,
    private userSubjectCachingService: UserSubjectCachingService,
    private syncService: SyncService,
    private topicCachingService: TopicCachingService,
    private errorReportService: ErrorReportService
  ) {}

  async read(includeDelete?: boolean): Promise<Review[]> {
    const subjectsMap: Map<string, UserSubject> = new Map<string, UserSubject>();
    const allSubjects = await this.userSubjectCachingService.read();
    allSubjects.forEach((subject) => {
      const key = CachingUtils.generateItemKey(subject);
      subjectsMap.set(key, subject);
    });

    let reviews = await this.offlineStorage.get<Review[]>(this.entityName);
    reviews = reviews ? reviews : [];

    return reviews.filter((review) => {
      if (review.userSubject) {
        const key = CachingUtils.generateItemKey(review.userSubject);
        if (subjectsMap.has(key)) review.userSubject = subjectsMap.get(key);
        if (!includeDelete) {
          return !review.hasPendingDelete;
        }
        return review;
      }
    });
  }

  refreshReviews() {
    this.syncUpdate.next([]);
  }

  async clear() {
    this.syncUpdate.next([]);
    this.clearCaching.clear(this);
  }

  private async getAll(): Promise<Array<Review>> {
    const reviews = await this.offlineStorage.get<Array<Review>>(this.entityName);
    return reviews ? reviews : [];
  }

  async set(items: Review[]): Promise<void> {
    await this.offlineStorage.set(this.entityName, items);
    this.reviewData$.next(items);
    this.syncService.setSyncStatus(true);
  }

  async create(item: Review): Promise<void> {
    await this.offlineStorage.create(this.entityName, item);
    this.syncService.setSyncStatus(true);
  }

  async delete(object: Partial<Review>) {
    await this.offlineStorage.delete(this.entityName, object);
    this.syncService.setSyncStatus(true);
  }

  async update(object: Partial<Review>) {
    await this.offlineStorage.update(this.entityName, object);
    this.syncService.setSyncStatus(true);
  }

  async save() {
    throw new Error('Method not implemented.');
  }

  private async updateSubjectOfReview(reviews: Review[], subjectIds: string[], subjectLocalIds: string[]) {
    const allSubjects = await this.userSubjectCachingService.read();
    const userSubjects = allSubjects.filter(
      (subject) => subjectIds.includes(subject.objectId) || subjectLocalIds.includes(subject.localObjectId)
    );

    reviews.forEach((review) => {
      if (
        subjectIds.includes(review.userSubject.objectId) ||
        subjectLocalIds.includes(review.userSubject.localObjectId)
      ) {
        const newSubject = userSubjects.find((subject) => {
          if (subject.objectId && review.userSubject.objectId) return subject.objectId === review.userSubject.objectId;
          return subject.localObjectId === review.userSubject.localObjectId;
        });
        if (newSubject) review.userSubject = newSubject;
      }
    });
  }

  private async fetchReviewsToWrite() {
    const reviewsToWrite: Review[] = this.cachedData.filter((r) => r.hasPendingWrite && !r.hasPendingDelete && r.userSubject);

    const subjectIds: string[] = reviewsToWrite
      .filter((r) => r.userSubject && r.userSubject.objectId)
      .map((r) => r.userSubject.objectId);

    const subjectLocalIds: string[] = reviewsToWrite
      .filter((r) => r.userSubject && r.userSubject.localObjectId)
      .map((r) => r.userSubject.localObjectId);

    await this.updateSubjectOfReview(reviewsToWrite, subjectIds, subjectLocalIds);

    const topics = await this.topicCachingService.read(true);

    reviewsToWrite.forEach((review) => {
      const { topic } = review;
      if (topic) {
        review.topic = topics.find((t) => t.localObjectId === topic.localObjectId);
      } else {
        const error = {
          msg: 'review missing topic',
          data: { review, topics }
        };

        const report: IErrorReport = {
          sentry: {
            tags: { fn: 'elseTopicReviewsToWrite' },
            err: JSON.stringify(error)
          }
        };

        this.errorReportService.sendReport(report);
      }
    });

    return reviewsToWrite;
  }

  async sync23(): Promise<Review[]> {
    let allReviews = await this.offlineStorage.get<Review[]>(this.entityName);
    allReviews = allReviews ? allReviews : [];
    this.cachedData = allReviews;

    const lastDate = await this.offlineStorage.get(`${this.entityName}LastUpdate`);

    const toWrite = await this.fetchReviewsToWrite();
    const toDelete: Review[] = allReviews.filter((r) => r.hasPendingDelete);

    const syncResult = await this.reviewService.syncReviews(
      toWrite,
      toDelete,
      lastDate ? (lastDate as Date) : null,
      this.userStore.user,
      CachingUtils.buildTrackingToSync(this.cachedData)
    );

    allReviews = CachingUtils.mergeWithSync(allReviews, syncResult);

    await this.offlineStorage.set(this.entityName, allReviews);
    this.syncUpdate.next(allReviews);

    return allReviews;
  }

  async sync() : Promise<Review[]> {
    const lastUpdate = await this.offlineStorage.get(`${this.entityName}LastUpdate`) as Date;
    let allReviews = await this.offlineStorage.get<Review[]>(this.entityName);
    allReviews = allReviews ? allReviews : [];
    this.cachedData = allReviews.filter((r) => !r.hasPendingDelete);

    const toWrite = await this.fetchReviewsToWrite();
    const toDelete: string[] = allReviews.filter((r) => r.hasPendingDelete && r.objectId).map((r)=> r.objectId);

    const toDeleteLocal: Review[] = allReviews.filter((r)=> r.hasPendingWrite && !r.objectId);

    const syncResult = await this.reviewService.syncReviewsV3({
      lastUpdate, toWrite, deleted: toDelete
    });
    syncResult.doDelete.concat(toDeleteLocal);

    const syncDate = syncResult.syncDate;
    await this.offlineStorage.set(`${this.entityName}LastUpdate`, syncDate);

    syncResult.doUpdateOrCreate.forEach((r)=> {
      delete r.user;
    })


    allReviews = CachingUtils.mergeWithSync(allReviews, syncResult);
    await this.offlineStorage.set(this.entityName, allReviews);
    this.syncUpdate.next(allReviews);

    return allReviews
  }

  async needSync(): Promise<boolean> {
    try {
      const data = await this.getAll();
      const lastUpdatedLocal_ = this.getLastUpdated(data).then((result) => (result ? result.updatedAt : null));
      const lastUpdatedAtServer_ = this.reviewService.getLastUpdatedAt(this.userStore.user.objectId);

      const hasPending_ = Promise.resolve(data).then((values: any[]) => {
        if (values && values.length > 0) {
          const value = values.find((v) => {
            const { hasPendingDelete, hasPendingWrite } = v;

            if (hasPendingDelete || hasPendingWrite) {
              return true;
            }
            return false;
          });
          if (value) {
            return true;
          }
          return false;
        }
        return false;
      });

      const [lastUpdatedLocal, lastUpdatedAtServer, hasPending] = await Promise.all([
        lastUpdatedLocal_,
        lastUpdatedAtServer_,
        hasPending_
      ]);
      return lastUpdatedLocal !== lastUpdatedAtServer || hasPending;
    } catch (e) {
      console.error({ e });
      return false;
    }
  }

  async saveReviewPattern(data) {
    await this.offlineStorage.set('___review_pattern', data);
  }

  async getReviewPattern() {
    return this.offlineStorage.get('___review_pattern');
  }

  private async getLastUpdated(data): Promise<Review | null> {
    const dataSorted = CachingUtils.orderByUpdatedAt<Review>(data);

    if (dataSorted.length > 0) {
      return dataSorted[0];
    }

    return null;
  }
}

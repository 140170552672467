<app-close-popup></app-close-popup>

<ion-content class="ion-no-padding ion-no-border" fullscreen="true">

  <div class="rate-container">
    <div class="rate-title">
      <div class="rate-title-content">
        <img src="{{isFiveStars ? 'assets/imgs/rate/logo_heart.png' : 'assets/imgs/rate/logo_sad.png'}}">
        <h3 *ngIf="isFiveStars">Oba! Pode nos ajudar?</h3>
        <h3 *ngIf="!isFiveStars">Ops. Temos que melhorar</h3>

      </div>
    </div>

    <div class="rate-content" *ngIf="isFiveStars">
      <ionic-rating-component #rating
      activeIcon = "star"
      defaultIcon = "star-outline"
      activeColor = "#FCC002" 
      defaultColor = "#B8B5B5"
      readonly="true"
      [rating]="rateValue"
      fontSize = "40px">
      </ionic-rating-component>
    </div>

    <div class="rate-text" *ngIf="isFiveStars">
      <p>Muito obrigado pela avaliação!</p>
      <p>A nota na loja de apps é <span>MUITO importante</span> para nós.</p>
      <p><span>Nos ajude, valorize o nosso trabalho</span> nos dando 5 estrelas lá também ☺.</p>
      <p>Basta <span>clicar no botão abaixo</span> e escolher as <span>5 estrelas</span>:</p>
    </div>

    <div class="rate-text" *ngIf="!isFiveStars">
      <p>Muito obrigado pela avaliação!</p>
      <p> <span>O que precisamos fazer</span> para merecer 5 estrelas?</p>   
      <p><span>Nos ajude </span>respondendo 3 perguntas, são só <span>30 segundos </span>☺</p>
      <p><span>Isso vai nos ajudar MUITO!</span></p>
    </div>

    <div class="rate-btn">
      <ion-button (click)="isFiveStars ? openRateAPP() : onImprovementsSurvey()">
        <span>Sim, posso ajudar</span>
      </ion-button>
      <div class="no-help">
        <a (click)="close()">Obrigado, mas não<br />quero ajudar</a>
      </div>
    </div>
    </div>

  
</ion-content>

import { Injectable } from '@angular/core';
import { Color } from '@models/Color';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  constructor() {}

  public getRandomColor(): Color {
    return colors()[Math.floor(Math.random() * colors().length)];
  }
}

function colors(): Array<Color> {
  return [
    {
      codigo: '#FFA07A',
      nome: 'Salmão claro',
      createdAt: '2020-06-17T04:41:29.234Z',
      updatedAt: '2020-06-27T19:05:57.273Z',
      ordem: 1,
      objectId: 'rlrAhF5eAT'
    },
    {
      codigo: '#F08080',
      nome: 'Coral claro',
      createdAt: '2020-06-17T04:41:29.522Z',
      updatedAt: '2020-06-27T19:05:57.429Z',
      ordem: 2,
      objectId: '0HtABdbdfT'
    },
    {
      codigo: '#DC143C',
      nome: 'Carmesim',
      createdAt: '2020-06-17T04:41:28.275Z',
      updatedAt: '2020-06-27T19:05:57.429Z',
      ordem: 3,
      objectId: '06bKprmNCB'
    },
    {
      codigo: '#B22222',
      nome: 'Tijolo refratário',
      createdAt: '2020-06-17T04:41:29.518Z',
      updatedAt: '2020-06-27T19:05:57.493Z',
      ordem: 4,
      objectId: 'eBVI7tWhJm'
    },
    {
      codigo: '#FF0000',
      nome: 'Vermelho',
      createdAt: '2020-06-17T04:41:29.519Z',
      updatedAt: '2020-06-27T19:05:57.493Z',
      ordem: 5,
      objectId: 'rayH2DbCnQ'
    },
    {
      codigo: '#8B0000',
      nome: 'Castanho avermelhado',
      createdAt: '2020-06-17T04:41:29.519Z',
      updatedAt: '2020-06-27T19:05:57.205Z',
      ordem: 6,
      objectId: 'WXLPXFQgfy'
    },
    {
      codigo: '#FF7F50',
      nome: 'Coral',
      createdAt: '2020-06-17T04:41:29.233Z',
      updatedAt: '2020-06-27T19:05:57.606Z',
      ordem: 7,
      objectId: '3Ek7QFdT5H'
    },
    {
      codigo: '#FF6347',
      nome: 'Tomate',
      createdAt: '2020-06-17T04:41:30.176Z',
      updatedAt: '2020-06-27T19:05:57.429Z',
      ordem: 8,
      objectId: '5ujjVVEQg7'
    },
    {
      codigo: '#FF4500',
      nome: 'Jambo',
      createdAt: '2020-06-17T04:41:30.176Z',
      updatedAt: '2020-06-27T19:05:57.555Z',
      ordem: 9,
      objectId: 'MkwkHfKMWh'
    },
    {
      codigo: '#FFD700',
      nome: 'Ouro',
      createdAt: '2020-06-17T04:41:30.158Z',
      updatedAt: '2020-06-27T19:05:57.190Z',
      ordem: 10,
      objectId: 'z9oNQVYSui'
    },
    {
      codigo: '#FFA500',
      nome: 'Laranja',
      createdAt: '2020-06-17T04:41:30.160Z',
      updatedAt: '2020-06-27T19:05:57.463Z',
      ordem: 11,
      objectId: 'TL8xGZkcK5'
    },
    {
      codigo: '#FF8C00',
      nome: 'Laranja escuro',
      createdAt: '2020-06-17T04:41:30.163Z',
      updatedAt: '2020-06-27T19:05:57.690Z',
      ordem: 12,
      objectId: '0aBBlnFL1D'
    },
    {
      codigo: '#FAFAD2',
      nome: 'Amarelo ouro claro',
      createdAt: '2020-06-17T04:41:30.157Z',
      updatedAt: '2020-06-27T19:05:57.256Z',
      ordem: 13,
      objectId: '1OndWmrbaY'
    },
    {
      codigo: '#FFE4B5',
      nome: 'Mocassim',
      createdAt: '2020-06-17T04:41:30.170Z',
      updatedAt: '2020-06-27T19:05:57.673Z',
      ordem: 14,
      objectId: 'wkTtdScRtx'
    },
    {
      codigo: '#FFDAB9',
      nome: 'Pêssego',
      createdAt: '2020-06-17T04:41:30.167Z',
      updatedAt: '2020-06-27T19:05:57.429Z',
      ordem: 15,
      objectId: '1VEwMQjpkb'
    },
    {
      codigo: '#EEE8AA',
      nome: 'Dourado pálido',
      createdAt: '2020-06-17T04:41:29.517Z',
      updatedAt: '2020-06-27T19:05:57.221Z',
      ordem: 16,
      objectId: 'Gbr1WUmj7F'
    },
    {
      codigo: '#BDB76B',
      nome: 'Caqui escuro',
      createdAt: '2020-06-17T04:41:30.164Z',
      updatedAt: '2020-06-27T19:05:57.539Z',
      ordem: 17,
      objectId: 'jtjkMQWyNw'
    },
    {
      codigo: '#FFFF00',
      nome: 'Amarelo',
      createdAt: '2020-06-17T04:41:30.159Z',
      updatedAt: '2020-06-27T19:05:57.429Z',
      ordem: 18,
      objectId: '8V60AU1eAE'
    },
    {
      codigo: '#7CFC00',
      nome: 'Verde grama',
      createdAt: '2020-06-17T04:41:29.240Z',
      updatedAt: '2020-06-27T19:05:57.305Z',
      ordem: 19,
      objectId: 'YQWPikE5fS'
    },
    {
      codigo: '#228B22',
      nome: 'Verde floresta',
      createdAt: '2020-06-17T04:41:29.239Z',
      updatedAt: '2020-06-27T19:05:57.429Z',
      ordem: 20,
      objectId: 'MMWn0b8ClE'
    },
    {
      codigo: '#006400',
      nome: 'Verde escuro',
      createdAt: '2020-06-17T04:41:29.230Z',
      updatedAt: '2020-06-27T19:05:57.441Z',
      ordem: 21,
      objectId: 'iV4EjXJZhu'
    },
    {
      codigo: '#9ACD32',
      nome: 'Verde amarelado',
      createdAt: '2020-06-17T04:41:29.516Z',
      updatedAt: '2020-06-27T19:05:57.239Z',
      ordem: 22,
      objectId: 'aR1hxEA08i'
    },
    {
      codigo: '#90EE90',
      nome: 'Verde claro',
      createdAt: '2020-06-17T04:41:29.232Z',
      updatedAt: '2020-06-27T19:05:57.429Z',
      ordem: 23,
      objectId: '9BuzFJtAGI'
    },
    {
      codigo: '#8FBC8F',
      nome: 'Verde mar escuro',
      createdAt: '2020-06-17T04:41:29.229Z',
      updatedAt: '2020-06-27T19:05:57.523Z',
      ordem: 24,
      objectId: 'eEWFONG1qj'
    },
    {
      codigo: '#3CB371',
      nome: 'Verde mar médio',
      createdAt: '2020-06-17T04:41:29.235Z',
      updatedAt: '2020-06-27T19:05:57.640Z',
      ordem: 25,
      objectId: 'zFBwxVTZXB'
    },
    {
      codigo: '#2E8B57',
      nome: 'Herbal',
      createdAt: '2020-06-17T04:41:29.227Z',
      updatedAt: '2020-06-27T19:05:57.289Z',
      ordem: 26,
      objectId: '0rqliFJAGa'
    },
    {
      codigo: '#808000',
      nome: 'Oliva',
      createdAt: '2020-06-17T04:41:30.156Z',
      updatedAt: '2020-06-27T19:05:57.505Z',
      ordem: 27,
      objectId: 'Sn4NSuY9dL'
    },
    {
      codigo: '#556B2F',
      nome: 'Oliva escura',
      createdAt: '2020-06-17T04:41:29.233Z',
      updatedAt: '2020-06-27T19:05:57.657Z',
      ordem: 28,
      objectId: 'R0ciWRgHCC'
    },
    {
      codigo: '#6B8E23',
      nome: 'Oliva parda',
      createdAt: '2020-06-17T04:41:29.511Z',
      updatedAt: '2020-06-27T19:05:58.055Z',
      ordem: 29,
      objectId: 'RiPyjrxfTn'
    },
    {
      codigo: '#E0FFFF',
      nome: 'Ciano claro',
      createdAt: '2020-06-17T04:41:29.221Z',
      updatedAt: '2020-06-27T19:05:57.955Z',
      ordem: 30,
      objectId: 'xukwCT2Wu5'
    },
    {
      codigo: '#00FFFF',
      nome: 'Água',
      createdAt: '2020-06-17T04:41:29.223Z',
      updatedAt: '2020-06-27T19:05:57.705Z',
      ordem: 31,
      objectId: 'OhsUCrStGj'
    },
    {
      codigo: '#7FFFD4',
      nome: 'Água-marinha',
      createdAt: '2020-06-17T04:41:29.226Z',
      updatedAt: '2020-06-27T19:05:57.889Z',
      ordem: 32,
      objectId: 'YhFYvdRI2p'
    },
    {
      codigo: '#AFEEEE',
      createdAt: '2020-06-17T04:41:30.347Z',
      updatedAt: '2020-06-27T19:05:57.572Z',
      nome: 'Turquesa pálida',
      ordem: 33,
      objectId: 'QLD584heP4'
    },
    {
      codigo: '#48D1CC',
      nome: 'Turquesa média',
      createdAt: '2020-06-17T04:41:29.231Z',
      updatedAt: '2020-06-27T19:05:57.773Z',
      ordem: 34,
      objectId: 'dBuXBKNsh2'
    },
    {
      codigo: '#5F9EA0',
      nome: 'Azul cadete',
      createdAt: '2020-06-17T04:41:29.224Z',
      updatedAt: '2020-06-27T19:05:57.589Z',
      ordem: 35,
      objectId: 'cbDbQIUUSr'
    },
    {
      codigo: '#008080',
      nome: 'Verde-azulado',
      createdAt: '2020-06-17T04:41:29.223Z',
      updatedAt: '2020-06-27T19:05:57.722Z',
      ordem: 36,
      objectId: 'c7tG18Ifax'
    },
    {
      codigo: '#B0E0E6',
      nome: 'Azul pólvora',
      createdAt: '2020-06-17T04:41:29.227Z',
      updatedAt: '2020-06-27T19:05:57.806Z',
      ordem: 37,
      objectId: '5dSACNcpkd'
    },
    {
      codigo: '#00BFFF',
      nome: 'Azul céu profundo',
      createdAt: '2020-06-17T04:41:28.440Z',
      updatedAt: '2020-06-27T19:05:57.840Z',
      ordem: 38,
      objectId: 'ZO0MXEt3Eg'
    },
    {
      codigo: '#B0C4DE',
      nome: 'Azul aço claro',
      createdAt: '2020-06-17T04:41:28.299Z',
      updatedAt: '2020-06-27T19:05:57.756Z',
      ordem: 39,
      objectId: 'jc5L1QtfSB'
    },
    {
      codigo: '#1E90FF',
      nome: 'Azul furtivo',
      createdAt: '2020-06-17T04:41:28.284Z',
      updatedAt: '2020-06-27T19:05:57.622Z',
      ordem: 40,
      objectId: 'ev6pfCGRuj'
    },
    {
      codigo: '#4682B4',
      nome: 'Azul aço',
      createdAt: '2020-06-17T04:41:28.305Z',
      updatedAt: '2020-06-27T19:05:57.790Z',
      ordem: 41,
      objectId: 'Dl53WciHYc'
    },
    {
      codigo: '#0000CD',
      nome: 'Azul médio',
      createdAt: '2020-06-17T04:41:28.296Z',
      updatedAt: '2020-06-27T19:05:57.989Z',
      ordem: 42,
      objectId: 'cNgBXsEHjH'
    },
    {
      codigo: '#000080',
      nome: 'Naval',
      createdAt: '2020-06-17T04:41:28.283Z',
      updatedAt: '2020-06-27T19:05:57.972Z',
      ordem: 43,
      objectId: 'jOVZAIoiOy'
    },
    {
      codigo: '#7B68EE',
      nome: 'Azul ardósia médio',
      createdAt: '2020-06-17T04:41:28.287Z',
      updatedAt: '2020-06-27T19:05:57.823Z',
      ordem: 44,
      objectId: 'b4LCQiGBlL'
    },
    {
      codigo: '#E6E6FA',
      nome: 'Lavanda',
      createdAt: '2020-06-17T04:41:28.304Z',
      updatedAt: '2020-06-27T19:05:57.874Z',
      ordem: 45,
      objectId: 'j7DMwcTIzF'
    },
    {
      codigo: '#D8BFD8',
      nome: 'Cardo',
      createdAt: '2020-06-17T04:41:28.297Z',
      updatedAt: '2020-06-27T19:05:57.856Z',
      ordem: 46,
      objectId: 'ZAnqW6S1jF'
    },
    {
      codigo: '#EE82EE',
      nome: 'Violeta',
      createdAt: '2020-06-17T04:41:28.298Z',
      updatedAt: '2020-06-27T19:05:57.922Z',
      ordem: 47,
      objectId: 'voAFUonVYb'
    },
    {
      codigo: '#DA70D6',
      nome: 'Orquídea',
      createdAt: '2020-06-17T04:41:28.297Z',
      updatedAt: '2020-06-27T19:05:57.739Z',
      ordem: 48,
      objectId: '6lJq54qFR0'
    },
    {
      codigo: '#FF00FF',
      nome: 'Fúchsia',
      createdAt: '2020-06-17T04:41:28.282Z',
      updatedAt: '2020-06-27T19:05:57.939Z',
      ordem: 49,
      objectId: 'glBDPMDaSI'
    },
    {
      codigo: '#BA55D3',
      nome: 'Orquídea média',
      createdAt: '2020-06-17T04:41:28.300Z',
      updatedAt: '2020-06-27T19:05:58.039Z',
      ordem: 50,
      objectId: 'M9CW0J5cHu'
    },
    {
      codigo: '#9370DB',
      nome: 'Púrpura média',
      createdAt: '2020-06-17T04:41:28.298Z',
      updatedAt: '2020-06-27T19:05:58.141Z',
      ordem: 51,
      objectId: 'LkSNs1ImDe'
    },
    {
      codigo: '#8A2BE2',
      nome: 'Azul violeta',
      createdAt: '2020-06-17T04:41:28.298Z',
      updatedAt: '2020-06-27T19:05:58.322Z',
      ordem: 52,
      objectId: 'XKHWTmMCbd'
    },
    {
      codigo: '#9932CC',
      nome: 'Orquídea escura',
      createdAt: '2020-06-17T04:41:28.303Z',
      updatedAt: '2020-06-27T19:05:58.273Z',
      ordem: 53,
      objectId: '3bDXNyWFKR'
    },
    {
      codigo: '#800080',
      nome: 'Púrpura',
      createdAt: '2020-06-17T04:41:28.286Z',
      updatedAt: '2020-06-27T19:05:58.207Z',
      ordem: 54,
      objectId: 'PsetuDSB6M'
    },
    {
      codigo: '#4B0082',
      nome: 'Índigo',
      createdAt: '2020-06-17T04:41:28.303Z',
      updatedAt: '2020-06-27T19:05:58.106Z',
      ordem: 55,
      objectId: 'XXWV8n0Pcd'
    },
    {
      codigo: '#FFB6C1',
      nome: 'Rosa claro',
      createdAt: '2020-06-17T04:41:28.277Z',
      updatedAt: '2020-06-27T19:05:58.156Z',
      ordem: 56,
      objectId: 'QLBRyntroW'
    },
    {
      codigo: '#FF69B4',
      nome: 'Rosa forte',
      createdAt: '2020-06-17T04:41:28.278Z',
      updatedAt: '2020-06-27T19:05:58.223Z',
      ordem: 57,
      objectId: 'piZv3qviFC'
    },
    {
      codigo: '#FF1493',
      nome: 'Rosa profundo',
      createdAt: '2020-06-17T04:41:28.278Z',
      updatedAt: '2020-06-27T19:05:58.122Z',
      ordem: 58,
      objectId: 'zrKojQAjCH'
    },
    {
      codigo: '#DB7093',
      nome: 'Vermelho violeta pálido',
      createdAt: '2020-06-17T04:41:28.281Z',
      updatedAt: '2020-06-27T19:05:58.022Z',
      ordem: 59,
      objectId: 'AA9mhOJzrU'
    },
    {
      codigo: '#FFFFFF',
      nome: 'Branco',
      createdAt: '2020-06-17T04:41:29.525Z',
      updatedAt: '2020-06-27T19:05:58.172Z',
      ordem: 60,
      objectId: 'dMb7vQntdk'
    },
    {
      codigo: '#F0FFFF',
      nome: 'Azul celeste',
      createdAt: '2020-06-17T04:41:29.221Z',
      updatedAt: '2020-06-27T19:05:58.441Z',
      ordem: 61,
      objectId: 'aYu1p3HMj0'
    },
    {
      codigo: '#F5F5DC',
      nome: 'Bege',
      createdAt: '2020-06-17T04:41:30.158Z',
      updatedAt: '2020-06-27T19:05:58.190Z',
      ordem: 62,
      objectId: 'H0y4tDni1h'
    },
    {
      codigo: '#FFFFF0',
      nome: 'Marfim',
      createdAt: '2020-06-17T04:41:29.517Z',
      updatedAt: '2020-06-27T19:05:58.089Z',
      ordem: 63,
      objectId: 'ihPUx69CKR'
    },
    {
      codigo: '#FFE4E1',
      nome: 'Rosa embaçado',
      createdAt: '2020-06-17T04:41:29.518Z',
      updatedAt: '2020-06-27T19:05:58.390Z',
      ordem: 64,
      objectId: 'pmIce19GZH'
    },
    {
      codigo: '#DCDCDC',
      nome: 'Gainsboro',
      createdAt: '2020-06-17T04:41:29.526Z',
      updatedAt: '2020-06-27T19:05:57.905Z',
      ordem: 65,
      objectId: 'sgeH2bhPHU'
    },
    {
      codigo: '#C0C0C0',
      nome: 'Prata',
      createdAt: '2020-06-17T04:41:29.528Z',
      updatedAt: '2020-06-27T19:05:58.372Z',
      ordem: 66,
      objectId: 'MmNUhjFGD3'
    },
    {
      codigo: '#808080',
      nome: 'Cinza',
      createdAt: '2020-06-17T04:41:29.528Z',
      updatedAt: '2020-06-27T19:05:58.072Z',
      ordem: 67,
      objectId: 'xKyxtlpshL'
    },
    {
      codigo: '#778899',
      nome: 'Cinza ardósia claro',
      createdAt: '2020-06-17T04:41:28.299Z',
      updatedAt: '2020-06-27T19:05:58.239Z',
      ordem: 68,
      objectId: 'A1zRr4uqZO'
    },
    {
      codigo: '#2F4F4F',
      nome: 'Cinza ardósia escuro',
      createdAt: '2020-06-17T04:41:29.234Z',
      updatedAt: '2020-06-27T19:05:58.340Z',
      ordem: 69,
      objectId: '7kS4220lki'
    },
    {
      codigo: '#000000',
      nome: 'Preto',
      createdAt: '2020-06-17T04:41:29.528Z',
      updatedAt: '2020-06-27T19:05:58.305Z',
      ordem: 70,
      objectId: 'FYmTCje9mB'
    },
    {
      codigo: '#FFEBCD',
      nome: 'Amêndoa',
      createdAt: '2020-06-17T04:41:30.169Z',
      updatedAt: '2020-06-27T19:05:58.425Z',
      ordem: 71,
      objectId: 'My7xwvWYfb'
    },
    {
      codigo: '#DEB887',
      nome: 'Madeira',
      createdAt: '2020-06-17T04:41:30.168Z',
      updatedAt: '2020-06-27T19:05:58.289Z',
      ordem: 72,
      objectId: 'QwfpS7zGfT'
    },
    {
      codigo: '#BC8F8F',
      nome: 'Marrom rosado',
      createdAt: '2020-06-17T04:41:29.521Z',
      updatedAt: '2020-06-27T19:05:58.408Z',
      ordem: 73,
      objectId: 'pWWuSimfIP'
    },
    {
      codigo: '#F4A460',
      nome: 'Marrom amarelado',
      createdAt: '2020-06-17T04:41:30.172Z',
      updatedAt: '2020-06-27T19:05:58.006Z',
      ordem: 74,
      objectId: 'EUqwIZbghD'
    },
    {
      codigo: '#DAA520',
      nome: 'Dourado',
      createdAt: '2020-06-17T04:41:30.159Z',
      updatedAt: '2020-06-27T19:05:58.355Z',
      ordem: 75,
      objectId: '5hYxtwpacn'
    },
    {
      codigo: '#D2691E',
      nome: 'Chocolate',
      createdAt: '2020-06-17T04:41:30.169Z',
      updatedAt: '2020-06-27T19:05:58.455Z',
      ordem: 76,
      objectId: 'OciIEi5EE5'
    },
    {
      codigo: '#8B4513',
      nome: 'Marrom sela',
      createdAt: '2020-06-17T04:41:30.172Z',
      updatedAt: '2020-06-27T19:05:58.257Z',
      ordem: 77,
      objectId: 'j9P50OJ5B3'
    },
    {
      codigo: '#800000',
      nome: 'Bordô',
      createdAt: '2020-06-17T04:41:29.521Z',
      updatedAt: '2020-06-27T19:05:58.473Z',
      ordem: 78,
      objectId: 'aF6OIe3QWI'
    }
  ];
}


<ion-content class="ion-padding ion-text-center">
  <img src="../../assets/imgs/estudaqui-white.png" height="50" class="ion-margin" />

  <ion-grid>
    <ion-row>
      <ion-col>
        <h4><b>Ops, sua sessão expirou</b></h4>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-no-padding">
        <div>
          <div class="ion-text-center ion-text-start">
           <h1>  Para continuar, refaça o login:</h1>
            
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <form [formGroup]="form" (ngSubmit)="handleLoginForm()" autocomplete="off">
    <ion-list>
      <ion-item lines="none">
        <ion-input disable="true" autocomplete="email" formControlName="email" type="email" placeholder="Email">
        </ion-input>
      </ion-item>
      <div class="ion-text-start ion-margin-top" lines="none" *ngIf="submitted && form.controls.email.errors">
        <ion-label color="danger" *ngIf="form.controls.email.errors.required">* Email é obrigatório</ion-label>
        <ion-label color="danger" *ngIf="form.controls.email.errors.pattern">* Formato de email inválido</ion-label>
      </div>

      <ion-item lines="none" class="ion-margin-top">
        <ion-input
          (keyup.enter)="handleLoginForm()"
          formControlName="password"
          required
          [type]="passwordInputType"
          placeholder="Senha"
        >
        </ion-input>
        <ion-icon
          *ngIf="passwordInputType === 'password'"
          name="eye"
          slot="end"
          (click)="onShowPassword()"
          class="ion-margin-top"
        >
        </ion-icon>
        <ion-icon
          *ngIf="passwordInputType === 'text'"
          name="eye-off"
          slot="end"
          (click)="onShowPassword()"
          class="ion-margin-top"
        ></ion-icon>
      </ion-item>
      <div class="ion-text-start ion-margin-top" lines="none" *ngIf="submitted && form.controls.password.errors">
        <ion-label color="danger" *ngIf="form.controls.password.errors.required">* Senha é obrigatória</ion-label>
      </div>
      <ion-button
        class="ion-item ion-float-right ion-no-padding"
        size="small"
        color="dark"
        fill="clear"
        (click)="this.loginService.handleForgotPassword()"
        mode="ios"
      >
        Esqueci minha senha
      </ion-button>
    </ion-list>
  
    <ion-grid fixed>
      <ion-row>
        <ion-col class="ion-no-padding" size="12">
          <ion-button type="submit" expand="full">Entrar</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>

  <div class="container-social-buttons" *ngIf="!isIOS">
    <div class="container-separator">
      <div class="separator">
        <div class="line"></div>
        <h2>ou, entre com</h2>
        <div class="line"></div>
      </div>
    </div>
    <div *ngIf="!isIOS" class="container-a">
      <app-facebook-button
        style="margin-right: 15px"
        (click)="handleLoginWithSocialProvider('Facebook')"
      ></app-facebook-button>
      <app-google-button (click)="handleLoginWithSocialProvider('Google')"></app-google-button>
    </div>
  </div>
</ion-content>

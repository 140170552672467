import normalize from 'normalize-strings';
import _ from 'lodash';

export default class StringUtils {
  static normalizeToUpperCase(rawString: string): string {
    const normalized = normalize(rawString);
    const trimmed: string = normalized.trim();
    return trimmed.toLocaleUpperCase();
  }

  static normalize(rawString: string): string {
    rawString = _.deburr(decodeURIComponent(rawString))
      .replace(/\s{2,}/g, ' ')
      .toLowerCase();

    return _.trim(rawString);
  }

  static isJsonString(str: string): boolean {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}

<ion-content>
  <div class="container-dismiss" tappable (tap)="dismiss()"></div>
  <div class="container-modal">
    <div>
      <div class="heading">
        {{ title }}
      </div>
      <div class="content">
        <div class="items">
          <div *ngFor="let item of items" (click)="onClickItem( item )" class="dropdown-item">
            <img class="radio-icon" [src]="defaultValue == item.value ? checkedIcon : unCheckedIcon" />
            <span [innerHtml]="item.label"></span>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="buttons centered">
          <ion-button class="button" (click)="dismiss()" clear>Voltar</ion-button>
        </div>
      </div>
    </div>
  </div>
</ion-content>
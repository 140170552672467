import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BasePageController } from "@app/pages/BasePage";
import { UserSubjectUtils } from "@app/util/UserSubjectUtils";
import { IonInput, NavParams } from "@ionic/angular";
import { Color } from "@models/Color";
import { UserSubject } from "@models/Subject";
import { Topic } from "@models/topic.model";
import { ColorCachingService } from "@app/@services/api/caching/color-caching/color-caching.service";
import { TopicCachingService } from "@app/@services/api/caching/topic-caching/topic-caching.service";
import { SubjectService } from "@app/@services/api/subject/subject.service";
import { UserStoreService } from "@app/@services/api/user-store/user-store.service";
import { EventService } from "@app/@services/event-service/event.service";
import { ChooseDisciplineModalPage } from "@app/disciplines/choose-discipline-modal/choose-discipline-modal.page";

@Component({
  selector: "app-create-topic",
  templateUrl: "./create-topic.page.html",
  styleUrls: ["./create-topic.page.scss"],
})
export class CreateTopicPage extends BasePageController implements OnInit {
  colors: Array<Color> = [];
  selectedColor: Color;
  onlyColor = false;
  formTopic: FormGroup;
  discipline: UserSubject;
  pageParams = {
    canSelectDiscipline: false,
  };
  topic: Topic;

  @ViewChild("inputDescription") inputShortName: IonInput;

  constructor(
    public navParams: NavParams,
    private colorCachingService: ColorCachingService,
    private topicCachingService: TopicCachingService,
    private subjectService: SubjectService,
    private userStore: UserStoreService,
    private events: EventService
  ) {
    super();
  }

  ngOnInit() {
    this.formTopic = new FormGroup({
      description: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
      ]),
    });
  }

  get description() {
    return this.formTopic.get("description");
  }

  async ionViewWillEnter() {
    const pageParams = this.navParams.get("pageParams");

    this.colors = await this.colorCachingService.read();
    this.selectedColor =
      this.colors[Math.floor(Math.random() * this.colors.length)];
    this.discipline = this.navParams.get("discipline") as UserSubject;
    this.topic = this.navParams.get("topic") as Topic;

    if (this.topic) {
      this.description.setValue(this.topic.description);
      this.selectedColor = this.topic.color;
    }

    if (pageParams) this.pageParams = pageParams;
  }

  async save() {
    if (this.topic) await this.doUpdateTopic();
    else await this.doCreateTopic();
  }

  private async doUpdateTopic() {
    const value = this.description.value as string;
    await this.topicCachingService.update({
      color: this.selectedColor,
      description: value,
      abbreviation: value.substring(0, 10),
      userDiscipline: this.discipline,
      objectId: this.topic.objectId,
      localObjectId: this.topic.localObjectId,
    });

    await this.dismiss({ shouldReloadTopics: true });
  }

  private async doCreateTopic() {
    const value = this.description.value as string;
    const topics = (await this.topicCachingService.read()).filter((topic) => {
      if (topic.userDiscipline.localObjectId && this.discipline.localObjectId)
        return (
          topic.userDiscipline.localObjectId === this.discipline.localObjectId
        );
      return topic.userDiscipline.objectId === this.discipline.objectId;
    });
    let totalTopics: number = topics.length;
    const newTopic: Topic = {
      color: this.selectedColor,
      description: value,
      abbreviation: value.substring(0, 10),
      order: Date.now(),
      userDiscipline: this.discipline,
      user: this.userStore.user,
      flgDeleted: false,
    };
    let createdTopic: Topic;

    const existingTopic = topics.find((topic) => {
      const disciplineByLocalObjectId =
        newTopic.userDiscipline.localObjectId &&
        topic.userDiscipline.localObjectId &&
        topic.userDiscipline.localObjectId ===
          newTopic.userDiscipline.localObjectId;
      const disciplineByObjectId =
        newTopic.userDiscipline.objectId &&
        topic.userDiscipline.objectId &&
        topic.userDiscipline.objectId === newTopic.userDiscipline.objectId;
      return (
        topic.description.toLowerCase() ===
          newTopic.description.toLowerCase() &&
        (disciplineByLocalObjectId || disciplineByObjectId)
      );
    });

    if (!existingTopic) {
      createdTopic = await this.topicCachingService.create(newTopic);
      totalTopics += 1;
    } else {
      existingTopic.color = this.selectedColor;
      existingTopic.order = Date.now();
      existingTopic.flgDeleted = false;
      await this.topicCachingService.update(existingTopic);
      createdTopic = { ...existingTopic };
    }

    this.discipline.numTopics = totalTopics;

    await this.dismiss({
      shouldReloadTopics: true,
      discipline: this.discipline,
      createdTopic,
    });
  }

  async openChooseDisciplineDialog() {
    const loading = await this.showLoading();
    // Load subjects list before opening subject's list page
    if (
      !this.subjectService.subjectItems ||
      this.subjectService.subjectItems.length === 0 ||
      !this.subjectService.userSubjectItems ||
      this.subjectService.userSubjectItems.length === 0
    ) {
      await this.subjectService.loadSubjectItems();
    }
    const modal = await this.modalCtrl.create({
      component: ChooseDisciplineModalPage,
      componentProps: {
        pageParams: {
          title: "Escolha a disciplina",
          canCreateDiscipline: false,
        },
      },
    });
    await modal.present();
    modal.onDidDismiss().then(async (response) => {
      const { data } = response;
      if (data && data.selectedSubjectItem) {
        this.discipline = UserSubjectUtils.fromSubjectItemToUserSubject(
          data.selectedSubjectItem
        );
      }
    });
    await loading.dismiss();
  }

  async chooseColor() {
    const modal = await this.modalCtrl.create({
      component: "ChooseColorPage",
      componentProps: {
        colors: this.colors,
        subject: {
          color: this.selectedColor,
        },
        onlyColor: true,
      },
    });
    await modal.present();

    modal.onDidDismiss().then((response) => {
      const { data } = response;
      if (data && !data.reload) this.selectedColor = data.color as Color;
    });
  }

  async dismiss(data?: any) {
    if (data) this.events.publish("refresh:topics");
    await this.modalCtrl.dismiss(data);
  }
}

import { Injectable } from '@angular/core';
//import { Device } from "@awesome-cordova-plugins/device/ngx";
import { Device, DeviceInfo } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private deviceInfo : DeviceInfo; 
  constructor() {
    this.getInfo();
  }

  private async getInfo() {
    if(!this.deviceInfo) {
      this.deviceInfo = await Device.getInfo();
    }

    return this.deviceInfo;
  }

  async getFormattedDeviceInfo(): Promise<string> {
    const info = await this.getInfo();
    const model = info.model;
    const osVersion = info.osVersion;
    return `${model} / ${osVersion}`;
  }

  async getPlatform() {
    return this.getInfo().then(info => info.platform);
  }

  async getVersion() {
    return this.getInfo().then(info => info.osVersion);
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Platform } from "@ionic/angular";
import { Network } from "@capacitor/network";
import { ToastService } from "@services/toast/toast.service";

export enum ConnectionStatus {
  ONLINE,
  OFFLINE,
}

export enum ConnectionError {
  CONNECTION_ERROR,
}

@Injectable({
  providedIn: "root",
})
export class NetworkService {
  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(
    ConnectionStatus.OFFLINE
  );
  private platforms: string[];
  private events = new Subject<any>();

  constructor(
    private platform: Platform,
    private toastService: ToastService
  ) {
    this.platform.ready().then(async () => {
      this.platforms = this.platform.platforms();
      this.initializeNetworkEvents();
      const isConnected = (await Network.getStatus()).connected;
      const status =
        isConnected === true
          ? ConnectionStatus.ONLINE
          : ConnectionStatus.OFFLINE;
      this.status.next(status);
    });
  }

  private initializeNetworkEvents() {
    Network.addListener(
      "networkStatusChange",
      async (netWorkStatus) => {
        const isConnected = netWorkStatus.connected;
        const status =
          isConnected === true
            ? ConnectionStatus.ONLINE
            : ConnectionStatus.OFFLINE;
        this.updateNetworkStatus(status);
      }
    );
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);
    const connectionMessage =
      status === ConnectionStatus.OFFLINE
        ? "Dispositivo desconectado da internet"
        : "Dispositivo conectado à internet";

    if (status === ConnectionStatus.ONLINE) {
      this.events.next("network:reconnected");
    }

    await this.toastService.create(connectionMessage, 2000, 'top', 'dimgrey');

 

  }

  /**
   * Verify if device has connection and execute the function. Otherwise, shows an alert.
   *
   * @param callback
   *        function to be executed if online
   */
  public async executeIfOnline(callback: Function) {
    if (this.isConnected()) {
      await callback();
    } else {
      this.showOfflineAlert();
    }
  }

  private getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  public onNetworkChange(): Observable<ConnectionStatus> {
    return this.status.asObservable();
  }

  public isConnected(): boolean {
    return this.getCurrentNetworkStatus() === ConnectionStatus.ONLINE;
  }

  public async showOfflineAlert() {
    const message = 'Offline. Verifique sua internet e tente novamente.'
    await this.toastService.create(message, 2000, 'top', 'danger');
  }

  public async connectionType() {
    return Network.getStatus();
  }

  public getPlatforms() {
    return this.platforms;
  }

  getObservable(): Subject<any> {
    return this.events;
  }
}

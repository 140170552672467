<app-common-header
  title="Minha Assinatura"
  [isDark]="true"
  [hasFilter]="false"
  [hasTutorial]="false"
  [hasQuestionTutorial]="false"
  (leftIconTap)="dismiss()"
></app-common-header>
<ion-content>
  <ion-list>
    <div class="item" (click)="openPlanList()">
      <div class="item-info">
        <ion-icon name="cart"></ion-icon>
        <div class="flex-column">
          <span>Todos os Planos</span>
          <span class="smaller">Todos os Planos oferecidos</span>
        </div>
      </div>
      <ion-icon name="chevron-forward-circle-outline"></ion-icon>
    </div>
    <hr>

    <div class="item" (click)="openCheckoutPersonalData()">
      <div class="item-info">
        <ion-icon name="person"></ion-icon>
        <div class="flex-column">
          <span>Dados Pessoais</span>
          <span class="smaller">Meus dados pessoais</span>
        </div>
      </div>
      <ion-icon name="chevron-forward-circle-outline"></ion-icon>
    </div>
    <hr>
  </ion-list>
</ion-content>
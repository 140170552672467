import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UserSubjectUtils } from "@app/util/UserSubjectUtils";
import { IonInput, NavParams } from "@ionic/angular";
import { Color } from "@models/Color";
import { SubjectItem } from "@models/Subject";
import { UserSubjectCachingService } from "@app/@services/api/caching/user-subject-caching/user-subject-caching.service";
import { BasePageController } from "../BasePage";

@Component({
  selector: "app-choose-color",
  templateUrl: "./choose-color.page.html",
  styleUrls: ["./choose-color.page.scss"],
})
export class ChooseColorPage extends BasePageController implements OnInit {
  @ViewChild("inputShortName") inputShortName: IonInput;
  
  @Input() colors: Array<Color> = [];
  @Input() subject: SubjectItem;
  @Input() onlyColor = false;
  selectedColor: Color;
  formSubject: FormGroup;

  constructor(
    private navParams: NavParams,
    private userSubjectCachingService: UserSubjectCachingService,
    private userSubjectUtils: UserSubjectUtils
  ) {
    super();
  }

  ngOnInit() {
    this.selectedColor = this.subject.color;
    this.formSubject = new FormGroup({
      shortName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(10),
      ]),
    });

    this.formSubject.setValue({
      shortName: this.subject.shortName ? this.subject.shortName : "",
    });

    this.formSubject.get("shortName").valueChanges.subscribe((v: string) => {
      this.subject.shortName = v.substring(0, 10);
    });
  }

  get displayTitle() {
    return this.onlyColor ? "Selecionar cor" : "Editar disciplina";
  }

  get shortName() {
    return this.formSubject.get("shortName");
  }

  selectColor(color: Color) {
    this.selectedColor = color;
  }

  onKeyDown(event: KeyboardEvent, field: string) {
    const value: string = (event.target as HTMLInputElement).value;
    this.formSubject.get(field).setValue(value.substring(0, 10));
    (event.target as HTMLInputElement).value = value.substring(0, 10);
    //this.inputShortName._native.nativeElement.value = value.substring(0, 10);
    if (value.length >= 10)
      this.showToastMessage("Limite de 10 caracteres atingido.");
  }

  async changeColor() {
    this.subject.shortName = this.shortName.value as string;
    if (this.subject.shortName.trim().length === 0) {
      this.showToastMessage("Informe a abreviatura da disciplina.");
      return;
    }

    const alert = await this.alertCtrl.create({
      header: 'Alterar disciplina ?',
      subHeader: 'Confirmar alteração da cor e da abreviatura ? ',
      message: 'Isso irá atualizar a cor e a abreviatura dessa disciplina.',
      buttons: [{text:'Cancelar', role: 'cancel'}, {text: 'Sim', handler: ()=> this.confirmChangeColor(this.selectedColor)}]
    })

    await alert.present();
  }

  async confirmChangeColor(color: Color) {
    if (!this.subject) this.modalCtrl.dismiss({ color: color, reload: false });
    const loading = await this.showLoading();
    const updatedDscipline = await this.updateSubjectAfterChooseColor(
      color,
      this.subject.shortName
    );
    await loading.dismiss();
    this.modalCtrl.dismiss({ reload: true, discipline: updatedDscipline });
  }

  chooseColor() {
    this.modalCtrl.dismiss({
      color: this.selectedColor,
      reload: false,
    });
  }

  private async updateSubjectAfterChooseColor(color: Color, shortName: string) {
    const userSubject = await this.userSubjectUtils.find(this.subject);
    userSubject.abreviatura = shortName;
    userSubject.cor = color;
    return this.userSubjectCachingService.update(userSubject);
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

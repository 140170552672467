export interface EstudaquiPlan {
  objectId: string;
  mostrar: boolean;
  texto: string;
  color: string;
  valor: number;
  duracaoDias: number;
  descricao: string;
  nome: string;
  valorMes: string;
  createdAt: any;
  updatedAt: any;
  id_pagarme: number;
  descontoPorcentagem: number;
}

export enum PlanType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  PREPAID = 'PREPAID',
}

export enum PlanPeriod {
  QUARTERLY = 'QUARTERLY',
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}

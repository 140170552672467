<app-common-header
  (leftIconTap)="dismiss()"
  [title]="pageParams.title"
  [isDark]="true"
  [hasLogo]="false"
  [hasDrawer]="false"
  [hasFilter]="false"
  [hasTutorial]="false"
></app-common-header>

<ion-content>
  <div class="content" *ngIf="topics.length === 0" style="padding: 0 1em">
    <form-create-topic
      [discipline]="discipline"
      [topic]="selectedTopic"
    ></form-create-topic>
  </div>
  <div class="content" *ngIf="topics.length > 0">
    <div class="search-container">
      <ion-searchbar
        color="light"
        #searchBar
        (ionInput)="getItems($event)"
        placeholder="Buscar ou criar assunto"
      ></ion-searchbar>
      <div class="add-subject-menu" *ngIf="isCreatingTopic">
        <span class="add-subject-label"
          >Não encontramos este assunto, gostaria de adicionar?</span
        >
        <div class="flex-row">
          <ion-label class="color-label">Selecione uma cor</ion-label>
        </div>
        <div class="flex-row">
          <ion-item
            [ngStyle]="{
              'border-left-color': newColor.codigo,
              'border-left-style': 'solid',
              'border-left-width': '15px'
            }"
            class="color-picker"
            (click)="chooseColor()"
            lines="none"
            style="width:100%"
          >
            <ion-label> {{newColor.nome}} </ion-label>
          </ion-item>
        </div>
      </div>

      <div class="search-list" *ngIf="!isCreatingTopic">
        <ion-list lines="none">
          <ion-item
            *ngFor="let topic of filteredTopics"
            (click)="onTapTopicItem( topic )"
          >
            <img
              class="has-finished-icon"
              [src]="getItemCheckboxIcon( topic )"
              alt="check icon"
            />
            <div class="item-subject-name" [class.last-user-subject]="false" style="margin-left: 7px;">
              <span [ngClass]="getLabelClass(topic)">
                {{ topic.description }}
              </span>
            </div>
          </ion-item>
        </ion-list>

        <br />
        <br />
        <br />
        <br />
      </div>
    </div>

    <div class="footer" *ngIf="isCreatingTopic">
      <button class="button-cancel" (click)="backToList()">Cancelar</button>
      <button class="button-adicionar" (click)="onTapTopicCreation()">
        Adicionar Assunto
      </button>
    </div>

    <div class="footer" *ngIf="!isCreatingTopic">
      <button class="button-adicionar" (click)="doSelectTopic()">
        Selecionar Assunto
      </button>
    </div>
  </div>
</ion-content>

import { Statistic } from '@models/Statistic';
import { CycleBlock } from '../models/Ciclo';
import { Color } from '../models/Color';

export default class ColorUtils {
  static getColorFromStatistic(statistic: Statistic): string {
    try {
      if (statistic && statistic.disciplina_usuario) {
        if (statistic.disciplina_usuario.excluida && statistic.disciplina_usuario.disciplina_geral) {
          return statistic.disciplina_usuario.disciplina_geral.color.codigo;
        }

        return statistic.disciplina_usuario.cor.codigo;
      }
      return '#4a90e2';
    } catch (e) {
      return '#4a90e2';
    }
  }

  static getColorFromBlock(block: CycleBlock): Color {
    try {
      if (block && block.disciplina_usuario) {
        if (block.disciplina_usuario.excluida && block.disciplina_usuario.disciplina_geral)
          return block.disciplina_usuario.disciplina_geral.color;
        return block.disciplina_usuario.cor;
      }
    } catch (e) {
      return {
        codigo: '#4a90e2'
      } as Color;
    }
  }

  static getColorFromCycleBlock(block: CycleBlock): string {
    try {
      if (block && block.disciplina_usuario) {
        if (block.disciplina_usuario.excluida && block.disciplina_usuario.disciplina_geral) {
          return block.disciplina_usuario.disciplina_geral.color.codigo;
        }

        return block.disciplina_usuario.cor.codigo;
      }
    } catch (e) {
      return '#4a90e2';
    }
  }

  static getContrast(hexcolor) {
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }

    // Convert to RGB value
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    return yiq >= 128 ? 'black' : 'white';
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { BasePageController } from '@app/pages/BasePage';
import { ChooseDisciplineModalPage } from '@app/disciplines/choose-discipline-modal/choose-discipline-modal.page';

import { MyExamEditSubjectPage } from '../my-exam-edit-subject/my-exam-edit-subject.page';
import { MyExamService } from '../shared/my-exam.service';
import { SubjectService } from '@services/api/subject/subject.service';
import { UserSubjectCachingService } from '@services/api/caching/user-subject-caching/user-subject-caching.service';
import { SubjectItem, UserSubject } from '@models/Subject';
import { ExamService } from '@services/api/exam/exam.service';
import { UserSubjectUtils } from '@app/util/UserSubjectUtils';
import { DisciplineService } from '@services/api/discipline/discipline.service';
import { AnalyticsEventEnum } from '@models/AnalyticsEventEnum';

@Component({
  selector: 'app-my-exam-create',
  templateUrl: './my-exam-create.page.html',
  styleUrls: ['./my-exam-create.page.scss']
})
export class MyExamCreatePage extends BasePageController implements OnInit {
  @Input() myExam: any;

  examNameInput: string = '';
  goalSelectedValue: string;
  subjects: ISubjectView[] = [];

  examObjectId: string;
  userSubjects: UserSubject[];

  pesosDiferentesToggle: boolean = false;

  constructor(
    public examService: ExamService,
    public myExamService: MyExamService,
    public discplineService: DisciplineService,
    public userSubjectCachingService: UserSubjectCachingService,
    public subjectService: SubjectService,
    public userSubjectUtils: UserSubjectUtils
  ) {
    super();
  }

  async ngOnInit() {
    this.userSubjects = await this.userSubjectCachingService.getAll();
    if (this.myExam) {
      this.examNameInput = this.myExam.cargo || '';
      this.goalSelectedValue = this.myExam.nicho || 'Concursos Públicos';
      this.subjects = this.myExam.subjects || [];
      this.pesosDiferentesToggle = this.myExam.pesosDiferentes || false;
    } else {
      this.goalSelectedValue = await this.userService
        .getLastGoal(this.userStoreService.user.objectId)
        .then((goal) => goal?.name?.toLowerCase())
        .catch(() => 'concursos públicos');
      console.log({ g: this.goalSelectedValue });
    }
  }

  async onPesoDiferenteToggle(event: any) {
    this.pesosDiferentesToggle = event.detail.checked;
    if (!this.pesosDiferentesToggle) {
      this.showAlert({
        header: 'Atenção',
        message: 'Isso irá ajustar todos os pesos das disciplinas para 1',
        subHeader: 'Deseja continuar ?',
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            handler: () => {
              this.pesosDiferentesToggle = true;
            }
          },
          {
            text: 'Continuar',
            role: 'confirm',
            handler: () => {
              this.subjects = this.subjects.map((s) => {
                s.peso = 1;
                return s;
              });
            }
          }
        ]
      });
    }
  }

  async onAddSubject() {
    const loading = await this.showLoading();

    if (await this.userSubjectCachingService.needSync()) {
      await this.userSubjectCachingService.sync();
    }

    await this.subjectService.loadSubjectItems();

    const modal = await this.modalCtrl.create({
      component: ChooseDisciplineModalPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        pageParams: {
          title: 'Escolha a disciplina',
          canCreateDiscipline: true
        }
      }
    });

    await modal.present();
    await loading.dismiss();

    const { data } = await modal.onDidDismiss();
    if (!data || !data.selectedSubjectItem) {
      return;
    }

    await this.showLoading();
    let { selectedSubjectItem } = data;

    try {
      const needToSync = !selectedSubjectItem.objectId;
      const isUserSubject = selectedSubjectItem.userSubject;

      if (!isUserSubject) {
        const newSubject = await this.createUserSubjectFromGeneral(selectedSubjectItem);
        selectedSubjectItem = UserSubjectUtils.toSubjectItem(newSubject);
      } else if (needToSync) {
        await this.userSubjectCachingService.sync();
        const subject = await this.resolveUserSubjectSync(selectedSubjectItem);
        selectedSubjectItem = UserSubjectUtils.toSubjectItem(subject);
      }
    } catch (e) {
      console.error(e);
    } finally {
      await this.dismissLoading();

      const { displayName, name, descricao, color, objectId, localObjectId } = selectedSubjectItem;

      if (!objectId) {
        this.showToastWarningMessage('Erro ao adicionar a disciplina na lista. Tente novamente!');
        return;
      }

      this.doPushSubject({
        objectId,
        localObjectId,
        descricao: displayName ?? name ?? descricao,
        cor: color,
        quantidadeQuestoes: 10,
        peso: 1
      });
    }
  }

  private async doPushSubject(data: {
    objectId: string;
    localObjectId: string;
    descricao: string;
    cor: any;
    quantidadeQuestoes: number;
    peso: number;
  }) {
    const alreadyAddedIndex = this.subjects.findIndex((s) => s.objectId === data.objectId);

    if (alreadyAddedIndex !== -1) {
      this.showToastWarningMessage(`Disciplina já adicionada. Posição ${alreadyAddedIndex + 1}`);
      return;
    }

    this.subjects.push(data);
    //open modal to edit subject after added
    this.onEditSubject(this.subjects[this.subjects.length - 1], this.subjects.length - 1);
  }

  async onSave() {
    if (this.goalSelectedValue.length < 1) {
      this.showToastWarningMessage('Um objetivo deve ser selecionado para a prova');
      return;
    }

    if (!this.examNameInput || this.examNameInput.length < 3) {
      this.showToastWarningMessage('Nome da prova deve ter pelo menos 3 caracteres.');
      return;
    }

    if (this.subjects.length < 2 || this.subjects.length > 25) {
      this.showToastWarningMessage('A prova deve ter pelo menos 2 e no máximo 25 disciplinas');
      return;
    }

    this.Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      cancelButtonText: 'NÃO',
      confirmButtonText: 'SIM',
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      customClass: {
        actions: 'actions-custom'
      },
      title: 'Deseja continuar?',
      html: `
        <p class="sweet-alert-text">
          Tem certeza que deseja salvar?
        </p>
      `
    }).then((result) => {
      if (result.value) {
        this.doCreateOrUpdate();
      } 
      this.Swal.close();
    });
  }

  private async doCreateOrUpdate() {
    const createExamInput = {
      objectId: this.myExam?.objectId,
      cargo: this.examNameInput,
      nicho: this.goalSelectedValue,
      pesosDiferentes: this.pesosDiferentesToggle,
      subjects: this.subjects.map(({ objectId, localObjectId, peso, quantidadeQuestoes }) => ({
        localObjectId: localObjectId || null,
        objectId,
        peso,
        quantidadeQuestoes
      }))
    };

    await this.showLoading('Processando...');

    try {
      const result = await this.examService.createOrUpdateMyExam(createExamInput);

      const dataEvent = {
        cargo: this.examNameInput,
        nicho: this.goalSelectedValue,
        pesosDiferentes: this.pesosDiferentesToggle
      };

      this.myExam?.objectId
        ? this.analyticsService.logEvent(AnalyticsEventEnum.EV_MY_EXAM_UPDATE, dataEvent)
        : this.analyticsService.logEvent(AnalyticsEventEnum.EV_MY_EXAM_CREATE, dataEvent);

      
      this.myExam?.objectId ? this.close(true) : this.askToCreateCycleBeforeClose(true, result);
    } catch (e) {
      this.showToastWarningMessage('Um erro ocorreu ao criar sua prova. Tente novamente!');
      this.close(true);
    } finally {
      await this.dismissLoading();
    }
  }

  async onEditSubject(subject: any, index: number) {
    const modal = await this.modalCtrl.create({
      component: MyExamEditSubjectPage,
      cssClass: 'modal-sd-small',
      componentProps: {
        subjectName: subject.descricao,
        quantidadeQuestoes: subject.quantidadeQuestoes,
        peso: subject.peso,
        canEditPeso: this.pesosDiferentesToggle
      }
    });

    modal.present();

    const { data } = await modal.onDidDismiss();

    if (data) {
      subject.peso = data.peso;
      subject.quantidadeQuestoes = data.quantidadeQuestoes;
      this.subjects[index] = subject;
    }
  }

  private async resolveUserSubjectSync(subject: SubjectItem): Promise<UserSubject> {
    await this.userSubjectCachingService.sync();
    await this.subjectService.reset();
    this.userSubjects = await this.userSubjectCachingService.read();

    const existentSubject = this.subjectService.findUserSubjectByName(subject);
    return UserSubjectUtils.fromSubjectItemToUserSubject(existentSubject);
  }

  private async createUserSubjectFromGeneral(subject: SubjectItem): Promise<UserSubject> {
    const newDiscipline: UserSubject = {
      cor: subject.color,
      abreviatura: subject.shortName,
      descricao: subject.name,
      tipo: subject.type,
      user: this.userStoreService.user,
      disciplinaGeral: subject.userSubject ? undefined : { objectId: subject.objectId },
      localObjectId: subject.localObjectId
    };

    let existentSubject: SubjectItem = this.subjectService.findUserSubjectByDescription(subject);

    if (existentSubject) {
      newDiscipline.localObjectId = existentSubject.localObjectId;
      newDiscipline.objectId = existentSubject.objectId;
      newDiscipline.cor = existentSubject.cor;
      newDiscipline.excluida = false;
      await this.userSubjectCachingService.update(newDiscipline);
    } else {
      await this.userSubjectCachingService.create(newDiscipline);
    }

    await this.userSubjectCachingService.sync();
    await this.subjectService.loadSubjectItems();

    const existentSubject2 = this.subjectService.findUserSubjectByDescription(subject);
    if (existentSubject2) {
      newDiscipline.localObjectId = existentSubject2.localObjectId;
      newDiscipline.objectId = existentSubject2.objectId;
      newDiscipline.cor = existentSubject2.cor;
      newDiscipline.excluida = false;
    }

    this.userSubjects = await this.userSubjectCachingService.read();
    return newDiscipline;
  }

  async onRemoveSubject(index: number) {
    const alert = await this.alertCtrl.create({
      header: 'Remover disciplina',
      message: 'Tem certeza que deseja remover esta disciplina da lista?',
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {}
        },
        {
          text: 'Sim',
          handler: () => {
            this.subjects.splice(index, 1);
          }
        }
      ]
    });

    alert.present();
  }

  compareWith(o1: string, o2: string) {
    return o1.toLocaleLowerCase() === o2.toLocaleLowerCase();
  }

  get calculateTotalQuestoes(): number {
    if (this.subjects.length > 0) {
      return this.subjects.reduce((acc, subject) => {
        acc = acc + subject?.quantidadeQuestoes;
        return acc;
      }, 0);
    }

    return 0;
  }

  async askToCreateCycleBeforeClose(reload?: boolean, examResult?: any) {
    this.Swal.fire({
      heightAuto: false,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      cancelButtonText: 'NÃO',
      confirmButtonText: 'SIM',
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      showCancelButton: true,
      showConfirmButton: true,
      showCloseButton: true,
      customClass: {
        actions: 'actions-custom'
      },
      title: 'Nova prova criada com sucesso',
      html: `
        <p class="sweet-alert-text">
          Deseja criar um novo plano de estudo para ela agora?
        </p>
      `
    }).then((result) => {
      if (result.value) {
        this.close(reload, examResult)
        
      } else {
        this.close(reload)
      }

      this.Swal.close();
    });
  }

  async close(reload?: boolean, examResult?: any) {
    this.modalCtrl.dismiss({ reload, examResult });
  }
}

interface ISubjectView {
  objectId: string;
  localObjectId: string;
  descricao: string;
  quantidadeQuestoes: number;
  peso: number;
  cor: {
    codigo: string;
  };
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { BasePageController } from "@app/pages/BasePage";
import { ChooseColorPage } from "@app/pages/choose-color/choose-color.page";
import { Guid } from "@app/util/guid";
import { UserSubjectUtils } from "@app/util/UserSubjectUtils";
import { IonSearchbar, NavParams } from "@ionic/angular";
import { ChooseDisciplineParams } from "@models/choose-discipline-params";
import { Color } from "@models/Color";
import { SubjectItem, SubjectType, UserSubject } from "@models/Subject";
import { ColorCachingService } from "@app/@services/api/caching/color-caching/color-caching.service";
import { UserSubjectCachingService } from "@app/@services/api/caching/user-subject-caching/user-subject-caching.service";
import { SubjectService } from "@app/@services/api/subject/subject.service";
import { ColorService } from "@app/@services/color.service";
import { HelpBotPage } from "@app/shared/help-bot/help-bot.page";
import { EasyPagination } from "@app/util/EasyPagination";

@Component({
  selector: 'app-choose-discipline-modal',
  templateUrl: './choose-discipline-modal.page.html',
  styleUrls: ['./choose-discipline-modal.page.scss']
})
export class ChooseDisciplineModalPage extends BasePageController implements OnInit {
  @ViewChild('searchBar') searchbar: IonSearchbar;
  @ViewChild('inputShortName') inputShortName: any;

  pageParams: ChooseDisciplineParams;
  listedSubjects: Array<SubjectItem> = [];
  generalSubjects: Array<SubjectItem> = [];
  selectedSubject: SubjectItem;

  // Information for adding a new subject
  newSubjectName: string;
  newSubjectShortName: string;
  newSubjectType: SubjectType;
  newSubjectTime = '01:00';
  newColor: Color = {
    codigo: '#00BFFF',
    nome: 'Azul céu profundo',
    createdAt: '2020-06-17T04:41:28.440Z',
    updatedAt: '2020-06-27T19:05:57.840Z',
    ordem: 38,
    objectId: 'ZO0MXEt3Eg'
  };

  formSubject: FormGroup;

  easyPagination: EasyPagination<SubjectItem>;
  pageSize = 20;
  constructor(
    public navParams: NavParams,
    private subjectService: SubjectService,
    private colorService: ColorService,
    private colorCachingService: ColorCachingService,
    private userSubjectCachingService: UserSubjectCachingService
  ) {
    super();
    this.pageParams = this.navParams.get('pageParams') as ChooseDisciplineParams;
  }

  ngOnInit() {
    this.formSubject = new FormGroup({
      shortName: new FormControl(null, [Validators.required, Validators.maxLength(10)])
    });

    const sub = this.formSubject.get('shortName').valueChanges.subscribe((v: string) => {
      this.newSubjectShortName = v.substring(0, 10);
    });

    this.pushSubscription(sub);

    this.generalSubjects = this.subjectService.subjectItems.filter((subject) => !subject.excluida);
    if (this.pageParams.loadOnly) {
      this.handleLoadOnly();
    }

    if (!this.pageParams.canCreateDiscipline) {
      this.generalSubjects = this.generalSubjects.filter((sub) => sub.userSubject);
    }
    this.newColor = this.colorService.getRandomColor();

    //this.searchbar.placeholder = 'Buscar';
    this.easyPagination = new EasyPagination<SubjectItem>(this.generalSubjects, 1, this.pageSize);
    this.listedSubjects = this.easyPagination.currentPage().items;
  }

  async ionViewWillLeave() {
    this.unsubscribeAllSubscriptions();
  }

  get shortName() {
    return this.formSubject.get('shortName');
  }

  get isCreatingSubject() {
    return this.listedSubjects.length === 0;
  }

  private isSubjectEqualToSelected(subject: SubjectItem): boolean {
    return (
      (subject.objectId && this.selectedSubject.objectId && subject.objectId === this.selectedSubject.objectId) ||
      (subject.localObjectId &&
        this.selectedSubject.localObjectId &&
        subject.localObjectId === this.selectedSubject.localObjectId)
    );
  }

  private async createUserSubject(newDiscipline: UserSubject): Promise<UserSubject> {

    const subjectItem : SubjectItem = UserSubjectUtils.toSubjectItem(newDiscipline);
    const existentSubject: SubjectItem = this.subjectService.findUserSubjectByName(subjectItem);

    if(existentSubject) {
      newDiscipline.localObjectId = existentSubject.localObjectId;
      newDiscipline.objectId = existentSubject.objectId;
      newDiscipline.cor = existentSubject.cor;
      newDiscipline.excluida = false;
      await this.userSubjectCachingService.update(newDiscipline);
      await this.subjectService.reset();

      return newDiscipline;
    } else {
      const created = await this.userSubjectCachingService.create(newDiscipline);
      await this.subjectService.reset();
      return created;
    }
  }

  private handleLoadOnly() {
    if (this.pageParams.loadOnly === 'GENERAL')
      this.generalSubjects = this.generalSubjects.filter((discipline) => !discipline.userSubject);
    else if (this.pageParams.loadOnly === 'USER')
      this.generalSubjects = this.generalSubjects.filter((discipline) => discipline.userSubject);
  }

  onKeyDown(event: KeyboardEvent, field: string) {
    const value: string = (event.target as HTMLInputElement).value;
    this.formSubject.get(field).setValue(value.substring(0, 10));
    (event.target as HTMLInputElement).value = value.substring(0, 10);
    //this.inputShortName._native.nativeElement.value = value.substring(0, 10);
    if (value.length >= 10) this.showToastMessage('Limite de 10 caracteres atingido.');
  }

  async onClickHelpAbbreviation() {
    // this.showToastMessage("Não implementado");
    const modal = await this.modalCtrl.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        avatar: 'assets/imgs/metas/help-bot.png',
        message:
          'As abreviaturas são utilizadas nos gráficos e no ciclo de estudo, por isso há uma limitação de 10 caracteres.',
        title: 'Abreviatura'
      }
    });

    await modal.present();
  }

  async handleCreateDisciplineTap() {
    this.newSubjectName = this.searchbar.value.trim();

    if(!this.newSubjectType) {
      this.showToastWarningMessage('Tipo deve ser selecionado!');
      return;
    }


    if(!this.newSubjectShortName || this.newSubjectShortName.length < 1) {
      this.showToastWarningMessage('Campo abreviatura deve ser preenchido!');
      return;
    }
 

    if(!this.newSubjectName || this.newSubjectName.length < 1) {
      this.showToastWarningMessage('Nome da disciplina deve ser preenchido!');
      return;
    }
 
    const newDiscipline: UserSubject = {
      cor: this.newColor,
      abreviatura: this.newSubjectShortName,
      descricao: this.newSubjectName,
      tipo: this.newSubjectType,
      user: this.userStoreService.user,
      disciplinaGeral: undefined,
      excluida: false,
      localObjectId: Guid.generateLocalObjectId()
    };
    const loading = await this.showLoading();

    await this.createUserSubject(newDiscipline);
 
    const addedSubject: SubjectItem = UserSubjectUtils.toSubjectItem(newDiscipline);
    this.generalSubjects = this.subjectService.userSubjectItems;
    this.backToSubjectList();
    this.selectedSubject = addedSubject;
    this.doDisciplineSelection();

    await loading.dismiss();
  }

  async backToSubjectList() {
    this.searchbar.value = null;
    this.selectedSubject = undefined;
    this.listedSubjects = this.generalSubjects;
    this.newSubjectShortName = undefined;
    this.newSubjectType = undefined;
  }

  onClickSubject(subject: SubjectItem) {
    this.selectedSubject = subject;
  }


  async chooseColor() {
    const newSubject: Partial<SubjectItem> = {
      shortName: this.newSubjectShortName,
      name: this.newSubjectName,
      color: this.newColor,
      displayName: this.newSubjectName
    };

    const loading = await this.showLoading();
    // Load colors list before opening the page
    const colors: Array<Color> = await this.colorCachingService.read();
    await loading.dismiss();

    const modal = await this.modalCtrl.create({
      component: ChooseColorPage,
      animated: false,

      componentProps: { colors, subject: newSubject, onlyColor: true }
    });
    await modal.present();

    modal.onDidDismiss().then(async (response) => {
      const { data } = response;
      if (data && !data.reload) {
        this.newColor = data.color;
      }
    });
  }

  doDisciplineSelection() {
    if (!this.selectedSubject) {
      this.showToastMessage('Selecione uma disciplina');
      return;
    }
    this.dismiss({
      selectedSubjectItem: this.selectedSubject
    });
  }

  getItems(ev: any) {
    if (!ev) return;
    const val = ev.target.value;
    // if the value is an empty string don't filter the items
    if (val && val.trim() != '') {
      const result = this.generalSubjects.filter((item) => {
        return item.displayName.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
      this.easyPagination.reset(result, 1, this.pageSize);

    } else {
      this.easyPagination.reset(this.generalSubjects, 1, this.pageSize);
      this.newSubjectShortName = undefined;
      this.newSubjectType = undefined;
    }
    
    this.listedSubjects = this.easyPagination.currentPage().items;
  }

  dismiss(data?: any) {
    this.modalCtrl.dismiss(data);
  }

  async onClickHelpSubjectType() {
    // this.showToastMessage("Missing");
    const modal = await this.modalCtrl.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        avatar: 'assets/imgs/metas/help-bot.png',
        message: `Essa informação é importante para o sistema otimizar os planejamentos de estudo.
        <Teórica:> disciplinas que normalmente exigem mais leitura e memorização de regras e textos (p.ex. português, direitos, história etc).
        <Raciocínio:> disciplinas que exigem cálculos, raciocínio ou contas (p.ex. matemática, raciocínio lógico, contabilidade etc).`,
        title: 'Tipo de Disciplina'
      }
    });
    await modal.present();
  }

  async loadMore(infiniteScroll) {
      const items = this.easyPagination.nextPage().items;
      this.listedSubjects = this.listedSubjects.concat(items);
      infiniteScroll.target.complete();

  }
}

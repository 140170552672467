import { Router } from '@angular/router';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { MigrationService } from '@services/migration-service/migration-service.service';
import { StorageService } from '@services/storage/storage.service';

export function initAppFactory(
  storageService: StorageService,
  migrationService: MigrationService,
  userStoreService: UserStoreService,
  router: Router
): () => Promise<any> {
  return async () => {
    await storageService.init();

    await migrationService.execute().catch((e) => {});
    await userStoreService.init();
    
    return Promise.resolve(true);
  };
}

export interface CreditCard {
  brand?: string;
  cardNumber: string;
  cvv: string;
  expiration: string;
  expiryMonth: string;
  expiryYear: string;
  holderName: string;
}

export interface WirecardCreditCard {
  brand: string;
  expiration_month: string;
  expiration_year: string;
  first_six_digits: string;
  holder_name: string;
  last_four_digits: string;
  vault?: string;
}

export enum PaymentMethod {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_PAYMENT_SLIP = 'BANK_PAYMENT_SLIP',
}

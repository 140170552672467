import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-dialog-select-item',
  templateUrl: './dialog-select-item.component.html',
  styleUrls: ['./dialog-select-item.component.scss'],
})
export class DialogSelectItemComponent {
  checkedIcon = 'assets/svg/blue-check-circle.svg';
  unCheckedIcon = 'assets/svg/light-blue-circle.svg';

  @Input()
  items: { label: string; value: string }[] = [];

  @Input()
  defaultValue: string;

  @Input()
  title: string = 'Selecione:';

  constructor(
    private modalCtrl: ModalController
  ) {}


  onClickItem(item) {
    this.defaultValue = item.value;
    this.dismiss(item);
  }

  dismiss(data?: any) {
    return this.modalCtrl.dismiss(data);
  }
}

import * as moment from 'moment';

export default class ChartUtils {
  static sortAscending(a, b): number {
    if (a.y < b.y) {
      return 1;
    }

    if (a.y > b.y) {
      return -1;
    }

    return 0;
  }

  static sortAscendingByDate(a, b): number {
    const dateA = moment(a.date, 'YYYY-MM-DD');
    const dateB = moment(b.date, 'YYYY-MM-DD');

    if (dateA.isAfter(dateB)) {
      return 1;
    }

    if (dateA.isBefore(dateB)) {
      return -1;
    }
    return 0;
  }

  static calculateHeight(numberDataPoints: number, heightDataPoint = 35, minHeight = 450) : number {
    const heightResult = numberDataPoints * heightDataPoint;
    return heightResult <= minHeight ? minHeight : heightResult;
}
}

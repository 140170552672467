import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChatMessagesComponent } from '@app/bot/chat-messages/chat-messages.component';
import { BasePageController } from '@app/pages/BasePage';
import { InterviewInputType } from '@models/InterviewBot';

@Component({
  selector: 'app-help-bot',
  templateUrl: './help-bot.page.html',
  styleUrls: ['./help-bot.page.scss'],
})
export class HelpBotPage extends BasePageController implements OnInit {
  @ViewChild(ChatMessagesComponent, {static: true}) chat: ChatMessagesComponent;

  @Input()
  message: string;

  @Input()
  avatar: string = 'assets/imgs/metas/help-bot.png';
  
  @Input()
  title: string;

  @Input()
  useInterviewActions: boolean;
  
  @Input()
  actionsType: InterviewInputType;
  interviewActionsButtonHandler: Function;

  @Input()
  canClose: boolean = true;

  constructor() {
    super();
  }

  ngOnInit() {
    this.chat.clearChatAndTypeBotMessage(this.message);
  }

  async buttonTapped(evt) {

    const hasFinishedTyping = this.chat.hasFinishedTyping();
    if (!hasFinishedTyping) return this.chat.showCompleteText();

    if (this.interviewActionsButtonHandler) {
      this.interviewActionsButtonHandler.call(this, evt);
      if(this.canClose) {
        await this.finish();
      }
    }
  }

  async ok() {
    const hasFinishedTyping = this.chat.hasFinishedTyping();

    if (hasFinishedTyping) {
      await this.finish();
    } else {
      this.chat.showCompleteText();
    }
  }

  async finish() {
    await this.modalCtrl.dismiss();
  }

}

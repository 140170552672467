import ChartUtils from '@app/util/ChartUtils';
import DateUtils from '@app/util/DateUtils';
import TimeUtils from '@app/util/TimeUtil';
import { ChartSeries } from '@models/ChartModel';
import { StatisticPageType } from '@models/Statistic';

export const getChart1 = (
  categories: Array<string | number>,
  series: ChartSeries,
  height: number,
  type: StatisticPageType
) => {
  const seriesQty = Object.keys(series).length;

  return {
    chart: {
      animation: false,
      inverted: true,
      height: height,
      renderTo: 'chart-1',
      type: 'column'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: seriesQty > 1 ? true : false
    },
    title: {
      text: 'Tempo de estudo',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      categories: categories,
      labels: {
        enabled: true,
        formatter: function () {
          return DateUtils.formatByType(this.value, type);
        },
        rotation: 0,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: true,
        formatter: function () {
          return TimeUtils.convertMillisToHHMMExcludingZero(this.value);
        }
      },
      showLastLabel: false,
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          formatter: function () {
            return TimeUtils.convertMillisToHHMMExcludingZero(this.y);
          },
          style: {
            fontSize: '11px',
            fontFamily: 'Verdana, sans-serif'
          }
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        }
      },
      spline: {
        color: '#4A90E2',
        marker: {
          states: {
            hover: 'none',
            select: {
              animation: {
                enabled: false
              },
              enabled: false
            }
          },
          fillColor: 'white',
          lineColor: 'orange',
          lineWidth: 2
        }
      }
    },
    series: Object.keys(series).map((key) => series[key])
  };
};

export const getChart2 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-2',
      type: 'column',
      height: 500,
      width: '100%'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Tempo por disciplina/assunto',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      } 
    },
    subtitle: {
      text: `
      <div class="img-detail-click">
        <img style="width:14px; height:14px;"  src="assets/imgs/icon-detail-click.png" />
        <span>Clique para detalhar</span>
      </div>
      `,
      useHTML: true
    },
    tooltip: {
      enabled: false,
      formatter: function (e) {
        return `${this.key}: ${TimeUtils.convertMillisToHHMMExcludingZero(this.y)}`;
      }
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.72rem',
          fontFamily: 'Comfortaa, sans-serif',

        }
      }
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: true,
        formatter: function () {
          return TimeUtils.convertMillisToHHMMExcludingZero(this.value);
        }
      },
      showLastLabel: false,
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          formatter: function () {
            return TimeUtils.convertMillisToHHMMExcludingZero(this.y);
          },
          style: {
            fontSize: '0.7rem',
            fontFamily: 'Clean, sans-serif'
          }
        },
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        },
        pointPadding: 0.15
      }
    },
    series: [
      {
        type: undefined,
        name: 'Diciplinas',
        colorByPoint: true,
        data: data,
        marker: {
          // enabled: false
        },
        states: {
          hover: {
            enabled: false
          }
        }
      }
    ]
  };
};

export const getChart3 = (data: Array<any>) => {
  return {
    chart: {
      animation: false,
      height: 420,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      renderTo: 'chart-3',
      type: 'pie'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Distribuição por disciplina',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    subtitle: {
      text: `
      <div class="img-detail-click">
        <img style="width:14px; height:14px;" src="assets/imgs/icon-detail-click.png" />
        <span>Clique para detalhar</span>
      </div>
      `,
      useHTML: true
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      animation: false,
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f} %',
          style: {
            fontSize: '0.6rem',
            fontFamily: 'Clean, sans-serif'
          }
        },
        size: '60%'
      }
    },
    series: [
      {
        name: 'Porcentagem',
        colorByPoint: false,
        data: data
      }
    ]
  };
};

export const getChart4 = (data: Array<any>) => {
  data.sort(ChartUtils.sortAscending);

  return {
    chart: {
      animation: false,
      inverted: true,
      renderTo: 'chart-4',
      type: 'column',
      height: 400,
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    title: {
      text: 'Tipo de estudo',
      style: {
        fontSize: '1rem',
        fontWeight: 'bold'     
      }
    },
    tooltip: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: true,
        rotation: 0,
        style: {
          fontSize: '0.7rem',
          fontFamily: 'Clean, sans-serif'
        }
      }
    },
    yAxis: {
      min: 0,
      labels: {
        enabled: true,
        formatter: function () {
          return TimeUtils.convertMillisToHHMMExcludingZero(this.value);
        }
      },
      showLastLabel: false,
      title: null
    },
    plotOptions: {
      series: {
        allowPointSelect: false,
        animation: false,
        pointWidth: 15,
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: '#FFFFFF',
          formatter: function () {
            return TimeUtils.convertMillisToHHMMExcludingZero(this.y);
          },
          style: {
            fontSize: '0.7rem',
            fontFamily: 'Clean, sans-serif'
          }
        },
        pointPadding: 0.1,
        states: {
          hover: 'none',
          inactive: {
            enabled: false
          },
          select: {
            animation: {
              enabled: false
            },
            enabled: false
          }
        }
      }
    },
    series: [
      {
        data: data
      }
    ]
  };
};

import { Component } from '@angular/core';
import { DeleteAccountModalComponent } from '@app/pages/delete-account-modal/delete-account-modal.component';
import { AlertController, LoadingController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { ApplePurchaseService } from '@services/api/apple-purchase/apple-purchase.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { EventService } from '@services/event-service/event.service';
import { GooglePurchaseService } from '@services/google-purchase/google-purchase.service';
import { InAppPurchaseService } from '@services/in-app-purchase/in-app-purchase.service';
import { LoadingService } from '@services/loading/loading.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popover-list',
  templateUrl: './popover-list.component.html',
  styleUrls: ['./popover-list.component.scss']
})
export class PopoverListComponent {
  constructor(
    private popOverController: PopoverController,
    private modalCtrl: ModalController,
    private userStoreService: UserStoreService,
    private applePurchaseService: ApplePurchaseService,
    private inAppPurchaseService: InAppPurchaseService,
    private platform: Platform,
    private loadingService: LoadingService,
    private eventService: EventService,
    private googlePurchaseService: GooglePurchaseService,
    private alertService: AlertController
  ) {}

  get isIOS() {
    return this.platform.is('ios');
  }

  get appVersion() {
    return environment.appVersion;
  }

  get userEmailEncoded() {
    return encodeURIComponent(this.userStoreService.user?.email ?? '');
  }

  async restoreAndroidSubscribe() {
    await this.loadingService.show();
    try {
    const userObjectId = this.userStoreService.user.objectId;

    const receipts = this.inAppPurchaseService.getReceipts();

    if(receipts.length > 0) {
      const { transactions } = receipts[0];
      transactions.sort((a: any,b: any)=> new Date(b.purchaseDate).getTime() - new Date(a.purchaseDate).getTime())
      const transaction = transactions[0];
      const { nativePurchase } = transaction;
      const { purchaseToken} = nativePurchase

      await this.googlePurchaseService.restoreSubscription(purchaseToken, userObjectId);    
      //emetir dismiss com mensagem de sucesso  
    } 
  } catch(e) {
    const userEmail = this.userStoreService.user.email;
    const messageAux = `<br/> <p>Se o problema persistir envie um e-mail para <a href="mailto:suporte@estudaqui.com?subject=Ops, algo deu errado! - Versão ${environment.appVersion} - ${encodeURIComponent(userEmail)}" class="msg-email">suporte@estudaqui.com</a></p>`;

    const alert = await this.alertService.create({
      message: "Não conseguimos recuperar sua compra, tente novamente ou entre em contato com o suporte." + messageAux,
      buttons: ['OK'],
      mode: 'ios'

    });

    await alert.present();

  } finally {
    await this.loadingService.dismiss();
  }

  }

  async restoreIOSSubscribe() {
    await this.loadingService.show();

    const userObjectId = this.userStoreService.user.objectId;
    const status = await this.applePurchaseService.checkStatus(userObjectId);
    this.loadingService.dismiss();

    if (status) {
      this.eventService.publish('subscription:inAppPurchase');
      this.dismiss(true);
    }
  }

  async sendEmail() {
    await this.dismiss();
  }

  async deleteAccount() {
    await this.dismiss();

    const modal = await this.modalCtrl.create({
      component: DeleteAccountModalComponent
    });

    await modal.present();
  }

  dismiss(closeModal?: boolean) {
    return this.popOverController.dismiss({ dismiss: closeModal });
  }
}

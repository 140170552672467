import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { UserStoreService } from "@app/@services/api/user-store/user-store.service";

@Injectable({
  providedIn: "root",
})
export class WelcomeGuard implements CanActivate {
  constructor(
    private userStoreService: UserStoreService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const user = this.userStoreService.user;

    if (user) {
      this.router.navigate(["tabs/cycle"], { replaceUrl: true });
      return false;
    }
    
    return true;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CheckoutPersonalDataPage } from '../checkout-personal-data/checkout-personal-data.page';
import { PlanIosListPage } from '../plan-ios-list/plan-ios-list.page';
import { PremiumService } from '@services/premium/premium.service';

@Component({
  selector: 'app-current-subscription-ios',
  templateUrl: './current-subscription-ios.page.html',
  styleUrls: ['./current-subscription-ios.page.scss'],
})
export class CurrentSubscriptionIosPage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    private premiumService: PremiumService) {
      

     }

  ngOnInit() {
  }

  async openPlanList() {

    this.premiumService.showBenefitsPlaModal();


    // const modal = await this.modalCtrl.create({
    //   component: PlanIosListPage,
    //   cssClass: 'sd modal-transparent',
    // });

    // modal.present();
  }

  async openCheckoutPersonalData() {
    const modal = await this.modalCtrl.create({
      component: CheckoutPersonalDataPage,
      cssClass: 'sd modal-transparent',
    });

    modal.present();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

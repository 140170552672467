<div class="calendar">
  <ion-slides
    #slider
    [options]="slideOpts"
    (ionSlideDidChange)="onSlideChanged()"
  >
    <ion-slide
      style="display: flex; flex-direction: column"
      *ngFor="let monthObj of months"
    >
      <!-- meses -->
      <ion-item lines="none" class="month-row ion-margin ion-text-center">
        <ion-icon
          size="large"
          slot="start"
          name="chevron-back-outline"
          (click)="prev()"
        ></ion-icon>
        <ion-label class="lb-mes ion-text-center">
          {{ monthObj.selectedMonth.format("MMM YYYY") }}
        </ion-label>
        <ion-icon
          size="large"
          slot="end"
          name="chevron-forward-outline"
          (click)="next()"
        ></ion-icon>
      </ion-item>

      <div class="day-grid">
        <!-- labels semana-->
        <ion-row class="text-center week-row">
          <ion-col *ngFor="let weekName of weekNames">
            <p class="lb-semana">{{ weekName }}</p>
          </ion-col>
        </ion-row>

        <!-- dias -->
        <ion-row
          class="text-center day-row"
          *ngFor="let week of monthObj.weeks; let rowIndex = index"
        >
          <div class="month-col" *ngIf="week.days">
            <ion-col
              class="day-col"
              *ngFor="let day of week.days; let colIndex = index"
            >
              <div
                tappable
                class="day-cell"
                (click)="select(monthObj, day, rowIndex)"
                [ngStyle]="{ color: events.horarios[day.format('YYYY-MM-DD')] | getColorEventDay }"
                [ngClass]="{
                  'non-current-month': !(
                    day.month() === monthObj.selectedMonth.month()
                  ),
                  'is-event-day': temEvento(day, monthObj, rowIndex) == true,
                  weekend: day.days() % 6 === 0
                }"
              >
                <div
                  class="lb-day"
                  [ngClass]="{
                    selected: day.isSame(monthObj.selectedDate),
                    today: isToday(day)
                  }"
                >
                  <img
                    class="icon-day"
                    [src]="events.horarios[day.format('YYYY-MM-DD')] | getIconDayEvent"
                    *ngIf="temEvento(day, monthObj, rowIndex) == true"
                  />

                  <span>{{ day.date() }}</span>

                  <div
                    class="lb-hora-evento"
                    *ngIf="temEvento(day, monthObj, rowIndex) == true"
                  >
                    {{  events.horarios[day.format('YYYY-MM-DD')].hora }}
                  </div>
                </div>

                <div
                  class="container-markers"
                  [ngClass]="{ 'width-container': temEvento(day) }"
                >
                  <em class="marker-evento" *ngIf="temEvento(day)"></em>
                </div>
              </div>
            </ion-col>
          </div>
        </ion-row>
      </div>
    </ion-slide>
  </ion-slides>
</div>

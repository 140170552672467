import { Component } from '@angular/core';

@Component({
  selector: 'app-talking-about',
  templateUrl: './talking-about.component.html',
  styleUrls: ['./talking-about.component.scss']
})
export class TalkingAboutComponent {
  talkingAbout = [
    {
      text: 'Ótimo app! Os lembretes para revisão, definição de tempo de estudo, organização das disciplinas, timer pomodoro, acompanhamento estatístico de desempenho contribuem muito para o melhor aproveitamento do tempo de estudo.',
      author: '- Valescka Alves',
      avatar: 'assets/imgs/plans-compare/person-valescka.png',
      side: 'left-image'
    },
    {
      text: '... a forma como o APP é trabalhado para facilitar nossa vida é impressionante... Raramente eu escrevo em avaliações de App, mas esse está de parabéns.',
      author: '- Saulo Catunda',
      avatar: 'assets/imgs/plans-compare/person-saulo.png',
      side: 'right-image'
    },
    {
      text: 'O APP é sensacional !! Estou tendo uma experiência incrível. O Estudaqui disponibiliza diversas ferramentas, que ajudam na organização dos estudos. Isso tem sido um auxílio fundamental na minha preparação. Quero parabenizar toda a equipe, que tem se empenhado para trazer um aplicativo diferenciado.',
      author: '- Arlan Nunes',
      avatar: 'assets/imgs/plans-compare/person-arlan.png',
      side: 'left-image'
    }
  ];
}

<app-close-popup *ngIf="canClose"></app-close-popup>

<ion-content class="ion-no-padding ion-no-border" fullscreen="true">
  <div class="container">
    <app-chat-progress [avatar]="avatar" [title]="title"></app-chat-progress>
    <app-chat-messages></app-chat-messages>
    <app-chat-actions
      [interviewInputType]="actionsType"
      (tappedButton)="buttonTapped($event)"  
    >
    </app-chat-actions>
    <div *ngIf="!useInterviewActions" class="footer-buttons">
      <button class="ok-button" (click)="ok()">
        Entendi
      </button>
    </div>
  </div>
</ion-content>
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastCtrl: ToastController) {}

  async create(message: string, duration?: number, position?: ToastPositionType, color?: string) {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: duration || 2000,
      position: position || 'bottom',
      animated: false,
      color
    });

    await toast.present();
  }

  async showAlert(message: string, duration?: number, position?: ToastPositionType) {
    return this.create(message, duration, position, 'danger')
  }

  async showWarning(message: string, duration?: number, position?: ToastPositionType) {
    return this.create(message, duration, position, 'warning')
  }
}

export type ToastPositionType = 'bottom' | 'top' | 'middle';
<div class="credit-cards">
  <img src="{{ urlBrandAmex }}" />
  <img src="{{ urlBrandDiners }}" />
  <img src="{{ urlBrandElo }}" />
  <img src="{{ urlBrandMastercard }}" />
  <img src="{{ urlBrandVisa }}" />
</div>
<ion-grid>
  <ion-row>
    <ion-col>
      <ion-item color="light" class="ion-margin-bottom">
        <ion-label position="floating">Número do Cartão</ion-label>
        <ion-input
          type="tel"
          maxlength="19"
          [disabled]="hasCard"
          [(ngModel)]="card.cardNumber"
          (keyup)="highlightBrand()"
          simpleMask="9999 9999 9999 9999"
        ></ion-input>
        <ion-icon color="medium" slot="end" name="card"></ion-icon>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item color="light" class="ion-margin-bottom">
        <ion-label position="floating">Validade (MM/  AA)</ion-label>
        <ion-input
          type="tel"
          simpleMask="99/99"
          maxlength="5"
          [disabled]="hasCard"
          [(ngModel)]="card.expiration"
        ></ion-input>
        <ion-icon color="medium" slot="end" name="calendar"></ion-icon>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item color="light" class="ion-margin-bottom">
        <ion-label position="floating">CVV</ion-label>
        <ion-input type="tel" maxlength="3" [disabled]="hasCard"  [(ngModel)]="card.cvv"></ion-input>
        <ion-icon color="medium" slot="end" name="key"></ion-icon>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-item color="light" class="ion-margin-bottom">
        <ion-label position="floating">Nome do titular</ion-label>
        <ion-input type="text" [disabled]="hasCard" [(ngModel)]="card.holderName"></ion-input>
        <ion-icon color="medium" slot="end" name="person"></ion-icon>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>

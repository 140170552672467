import { Injectable } from '@angular/core';
import { HelpBotPage } from '@app/shared/help-bot/help-bot.page';
import { ModalController } from '@ionic/angular';
import { CachedItem } from '@models/cached-item.interface';
import { UserSubject } from '@models/Subject';
import { SyncResult } from '@models/sync-result.interface';
import { Topic } from '@models/topic.model';
import { TopicCachingService } from '../caching/topic-caching/topic-caching.service';
import { ParseService } from '../parse/parse.service';

@Injectable({
  providedIn: 'root'
})
export class TopicsService {
  constructor(
    private topicCachingService: TopicCachingService,
    private parseService: ParseService,
    private modalCtrl: ModalController
  ) {}

  subscriberRefresh() {
    return this.topicCachingService.subscribeRefresh();
  }

  async create(topic: Topic) {
    await this.topicCachingService.create(topic);
  }

  async update(partialTopic: Partial<Topic>) {
    await this.topicCachingService.update(partialTopic);
  }

  async updateBatch(partialTopics: Partial<Topic>[]) {
    for (const topic of partialTopics) {
      await this.topicCachingService.update(topic);
    }
  }

  async getAll(): Promise<Topic[]> {
    return this.topicCachingService.read();
  }

  async saveAll(topics: Topic[]) {
    await this.topicCachingService.set(topics);
  }

  async delete(topic: Topic) {
    await this.topicCachingService.delete(topic);
  }

  async deleteBatch(topics: Topic[]) {
    for (const topic of topics) {
      await this.topicCachingService.delete(topic);
    }
  }

  async getTopicsAsMapWithObjectIdKey() {
    const map = new Map<string, Topic>();
    this.topicCachingService.cachedData.forEach((topic) => {
      const key: string = topic.objectId;
      map.set(key, topic);
    });
    return map;
  }

  async getTopicsAsMap(): Promise<Map<string, Topic>> {
    const topics = this.topicCachingService.cachedData;
    const map = new Map<string, Topic>();
    topics.forEach((topic) => {
      let key: string = topic.objectId;
      if (topic.localObjectId) key = topic.localObjectId;
      map.set(key, topic);
    });
    return map;
  }

  async syncTopics(payload: {
    toWrite: Topic[];
    toDelete: Topic[];
    userId: string;
    data: CachedItem[];
  }): Promise<SyncResult<Topic>> {
    return this.parseService.run('syncTopics', payload);
  }

  async findTopicsByDiscipline(discipline: UserSubject) {
    const topics = await this.topicCachingService.read();
    return topics.filter((topic) => {
      return (
        topic.userDiscipline &&
        ((discipline.localObjectId && discipline.localObjectId === topic.userDiscipline.localObjectId) ||
          discipline.objectId === topic.userDiscipline.objectId)
      );
    });
  }

  async showTopicsAndDisciplinesInfoDialog() {
    const modal = await this.modalCtrl.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      animated: false,
      componentProps: {
        avatar: 'assets/imgs/metas/help-bot.png',
        message: `As provas são subdivididas em <disciplinas> (p.ex. Português, Matemática etc) e as disciplinas podem se subdividir em <assuntos> (p.ex. Pontuação, Concordância etc).
          <Cadastre os assuntos> que fazem parte de cada disciplina para que você tenha uma <melhor visão do que você deve estudar e a ordem de estudo.>`,
        title: 'Disciplinas e Assuntos',
        buttons: [
          {
            name: 'ENTENDI',
            className: 'entendi',
            shouldClose: true,
            handler: () => {}
          }
        ]
      }
    });
    await modal.present();
  }
}

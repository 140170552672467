import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from "@angular/core";
import * as _ from "underscore";
import * as moment from "moment";
import { IPeriod } from "@models/IPeriod";
import { Statistic, StatisticPageType } from "@models/Statistic";
import { TopicsService } from "@app/@services/api/topics/topics.service";
import { StatisticService } from "@app/@services/statistic/statistic.service";
import ColorUtils from "@app/util/ColorUtils";
import { getChart1, getChart2, getChart3 } from "./chart.config";
import { AnalyticsEventEnum } from "@models/AnalyticsEventEnum";
import ChartUtils from "@app/util/ChartUtils";

import * as Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
import Drilldown from "highcharts/modules/drilldown";
import Exporting from "highcharts/modules/exporting";
import { ModalController } from "@ionic/angular";
import { ExpandComponent } from "./expand/expand.component";
import { AnalyticsService } from "@services/analytics/analytics.service";
More(Highcharts);
Drilldown(Highcharts);
Exporting(Highcharts);

const SlideName = {
  0: "Minutos por pagina",
  1: "Numero de paginas",
  2: "Evolucao tempo por pagina",
};

@Component({
  selector: 'app-page-charts',
  templateUrl: './page-charts.component.html',
  styleUrls: ['./page-charts.component.scss']
})
export class PageChartsComponent implements AfterViewInit {
  @Input() statistics: Array<Statistic>;
  @Input() period: IPeriod;
  @Input() tipo: StatisticPageType;

  private statisticsGroupedByDiscipline: Array<Statistic> = [];
  private statisticsWithPages: Array<Statistic> = [];
  totalPages = 0;
  pagesRatio = '00:00';
  chart: any;

  readonly CHART_HEIGHT = 400;

  constructor(
    private cdr: ChangeDetectorRef,
    private topicsService: TopicsService,
    private statisticService: StatisticService,
    private analyticsService: AnalyticsService,
    private modalCtrl: ModalController
  ) {}

  ngAfterViewInit() {
    this.plotAll();
    this.cdr.detectChanges();
  }

  get hasStatistics(): boolean {
    this.statisticsWithPages = this.statistics.filter((item) => item.pag_estudadas);
    return this.statisticsWithPages.length > 0;
  }

  plotAll() {
    if (this.hasStatistics) {
      this.statisticsGroupedByDiscipline = _.groupBy(this.statisticsWithPages, 'curso');
      this.totalPages = this.statisticsWithPages.map(this.mapTotal).reduce(this.reduceTotal);
      const totalTime = this.statisticsWithPages.map((item) => item.tempo).reduce(this.reduceTotal);
      const ratio = Math.round(totalTime / this.totalPages);
      this.pagesRatio = moment.utc(ratio).format('mm:ss');
      this.plotChart1();
      this.plotChart2();
      this.plotChart3();
    } else {
      this.totalPages = 0;
      this.pagesRatio = '00:00';
    }
  }

  private plotChart1() {
    const disciplinesData: Array<any> = [];

    _.each(this.statisticsGroupedByDiscipline, (group: Array<Statistic>, discipline: string) => {
      const totalPages = group.map(this.mapTotal).reduce(this.reduceTotal);
      const totalMilisseconds = group.map((item) => item.tempo).reduce(this.reduceTotal);
      disciplinesData.push({
        name: discipline,
        y: totalMilisseconds / totalPages,
        color: ColorUtils.getColorFromStatistic(group[0]),
        extraData: group.length === 0 ? null : { discipline: group[0].disciplina_usuario, group: group }
      });
    });

    const chart = getChart1(
      disciplinesData.map((item) => {
        item.drilldown = true;
        return item;
      })
    );

    const chartHeight = ChartUtils.calculateHeight(chart.series[0].data.length, 45, this.CHART_HEIGHT);

    const exerciseChart1 = new Highcharts.Chart({
      lang: {
        drillUpText: '◁ Voltar'
      },
      chart: {
        type: 'column',
        renderTo: chart.chart.renderTo,
        inverted: true,
        animation: false,
        height: chartHeight,
        events: {
          drillup: (e) => {
            this.logEvent(SlideName[0], 'drillup');
            this.statisticService.reset();

            exerciseChart1.setSize(null, chartHeight);
            exerciseChart1.setSubtitle({
              text: `
                <div class="img-detail-click">
                  <img style="width: 14px; height:14px" src="assets/imgs/icon-detail-click.png" />
                  <span>Clique na disciplina para detalhar Assuntos</span>
                </div>
              `,
              useHTML: true
            });
          },

          drilldown: async (e) => {
            this.logEvent(SlideName[0], 'drilldown');
            if (!e.seriesOptions) {
              const extraData = (e.point as any).extraData;
              const series: any = {
                name: 'Minutos por assunto',
                colorByPoint: true,
                data: []
              };
              exerciseChart1.showLoading('Aguarde...');
              const topics = await this.topicsService.findTopicsByDiscipline(extraData.discipline);
              const grpuppedStatistics = extraData.group as Statistic[];
              const topicLocalIds = topics.map((topic) => topic.localObjectId);
              const statisticsWithNoTopics: Statistic[] = [];
              const filteredStatistics = grpuppedStatistics.filter((statistic) => {
                if (!statistic.topic || !statistic.topic.localObjectId) statisticsWithNoTopics.push(statistic);
                return (
                  statistic.topic &&
                  statistic.topic.localObjectId &&
                  topicLocalIds.includes(statistic.topic.localObjectId)
                );
              });
              series.data = topics
                .map((topic) => {
                  const topicStatistics = filteredStatistics.filter(
                    (statistic) => statistic.topic.localObjectId === topic.localObjectId
                  );

                  if (topicStatistics.length == 0) return null;

                  const totalPages = topicStatistics.map(this.mapTotal).reduce(this.reduceTotal);
                  const totalMilisseconds = topicStatistics.map((item) => item.tempo).reduce(this.reduceTotal);

                  return {
                    name: topic.description,
                    y: totalMilisseconds / totalPages,
                    color: topic.color.codigo
                  };
                })
                .filter((item) => !!item);

              if (statisticsWithNoTopics.length != 0) {
                const totalPages = statisticsWithNoTopics.map(this.mapTotal).reduce(this.reduceTotal);
                const totalMilisseconds = statisticsWithNoTopics.map((item) => item.tempo).reduce(this.reduceTotal);
                series.data.push({
                  color: '#dddddd',
                  name: 'Não definido *',
                  y: totalMilisseconds / totalPages
                });
              }
              const chartHeightDrillDown = ChartUtils.calculateHeight(series.data.length, 35, this.CHART_HEIGHT);

              exerciseChart1.setSize(null, chartHeightDrillDown);
              exerciseChart1.setSubtitle({
                text: extraData.discipline.descricao
              });
              exerciseChart1.hideLoading();
              exerciseChart1.addSeriesAsDrilldown(e.point, series);
              this.chart = exerciseChart1;
              this.statisticService.openDrilldown(this);
            }
          }
        }
      },
      credits: { enabled: false },
      exporting: { enabled: false },
      tooltip: chart.tooltip,
      title: chart.title,
      subtitle: {
        text: `
        <div class="img-detail-click">
          <img style="width: 14px; height:14px" src="assets/imgs/icon-detail-click.png" />
          <span>Clique na disciplina para detalhar Assuntos</span>
        </div>
        `,
        useHTML: true
      },
      xAxis: chart.xAxis as any,
      yAxis: chart.yAxis as any,
      legend: {
        enabled: false
      },
      plotOptions: chart.plotOptions as any,
      series: chart.series as any,
      drilldown: {
        activeAxisLabelStyle: {
          fontWeight: 'normal'
        },
        activeDataLabelStyle: {
          fontWeight: 'normal'
        },
        drillUpButton: {
          position: {
            x: 0,
            y: -15
          },
          theme: {
            fill: '#007aff',
            'stroke-width': 1,
            stroke: 'silver',
            style: {
              color: 'white',
              fontWeight: 'bold'
            }
          }
        },
        series: []
      }
    });

    exerciseChart1.reflow();
  }

  private plotChart2() {
    const disciplinesData: Array<any> = [];

    _.each(this.statisticsGroupedByDiscipline, (group: Array<Statistic>, discipline: string) => {
      const totalPages = group.map(this.mapTotal).reduce(this.reduceTotal);
      disciplinesData.push({
        name: discipline,
        y: totalPages,
        color: ColorUtils.getColorFromStatistic(group[0]),
        extraData: group.length === 0 ? null : { discipline: group[0].disciplina_usuario, group: group }
      });
    });

    const chart = getChart2(
      disciplinesData.map((item) => {
        item.drilldown = true;
        return item;
      })
    );

    const chartHeight = ChartUtils.calculateHeight(chart.series[0].data.length, 35, this.CHART_HEIGHT);

    const exerciseChart1 = new Highcharts.Chart({
      lang: {
        drillUpText: '◁ Voltar'
      },
      chart: {
        type: 'column',
        renderTo: chart.chart.renderTo,
        inverted: true,
        animation: false,
        height: chartHeight,
        events: {
          drillup: (e) => {
            this.logEvent(SlideName[1], 'drillup');
            this.statisticService.reset();
            exerciseChart1.setSize(null, chartHeight);
            exerciseChart1.setSubtitle({
              text: `
              <div class="img-detail-click">
                <img style="width: 14px; height:14px" src="assets/imgs/icon-detail-click.png" />
                <span>Clique na disciplina para detalhar Assuntos</span>
              </div>
              `,
              useHTML: true
            });
          },

          drilldown: async (e) => {
            this.logEvent(SlideName[1], 'drilldown');

            if (!e.seriesOptions) {
              const extraData = (e.point as any).extraData;
              const series: any = {
                name: 'Minutos por assunto',
                colorByPoint: true,
                data: []
              };
              exerciseChart1.showLoading('Aguarde...');
              const topics = await this.topicsService.findTopicsByDiscipline(extraData.discipline);
              const topicLocalIds = topics.map((topic) => topic.localObjectId);
              const grpuppedStatistics = extraData.group as Statistic[];
              const statisticsWithNoTopics: Statistic[] = [];
              const filteredStatistics = grpuppedStatistics.filter((statistic) => {
                if (!statistic.topic || !statistic.topic.localObjectId) statisticsWithNoTopics.push(statistic);
                return (
                  statistic.topic &&
                  statistic.topic.localObjectId &&
                  topicLocalIds.includes(statistic.topic.localObjectId)
                );
              });
              series.data = topics
                .map((topic) => {
                  const topicStatistics = filteredStatistics.filter(
                    (statistic) => statistic.topic.localObjectId === topic.localObjectId
                  );
                  if (topicStatistics.length == 0) return null;
                  const totalPages = topicStatistics.map(this.mapTotal).reduce(this.reduceTotal, 0);

                  return {
                    name: topic.description,
                    y: totalPages,
                    color: topic.color.codigo
                  };
                })
                .filter((item) => !!item);

              if (statisticsWithNoTopics.length != 0)
                series.data.push({
                  color: '#dddddd',
                  name: 'Não definido *',
                  y: statisticsWithNoTopics.map(this.mapTotal).reduce(this.reduceTotal, 0)
                });

              const chartHeightDrillDown = ChartUtils.calculateHeight(series.data.length, 35, this.CHART_HEIGHT);

              exerciseChart1.setSize(null, chartHeightDrillDown);
              exerciseChart1.setSubtitle({
                text: extraData.discipline.descricao
              });
              exerciseChart1.hideLoading();
              exerciseChart1.addSeriesAsDrilldown(e.point, series);
              this.chart = exerciseChart1;
              this.statisticService.openDrilldown(this);
            }
          }
        }
      },
      tooltip: chart.tooltip,
      credits: { enabled: false },
      exporting: { enabled: false },
      title: chart.title,
      subtitle: {
        text: `
          <div class="img-detail-click">
            <img style="width: 14px; height:14px" src="assets/imgs/icon-detail-click.png" />
            <span>Clique na disciplina para detalhar Assuntos</span>
          </div>
        `,
        useHTML: true
      },
      xAxis: chart.xAxis as any,
      yAxis: chart.yAxis as any,
      legend: {
        enabled: false
      },
      plotOptions: chart.plotOptions as any,
      series: chart.series as any,
      drilldown: {
        activeAxisLabelStyle: {
          fontWeight: 'normal'
        },
        activeDataLabelStyle: {
          fontWeight: 'normal'
        },
        drillUpButton: {
          position: {
            x: 0,
            y: -15
          },
          theme: {
            fill: '#007aff',
            'stroke-width': 1,
            stroke: 'silver',
            style: {
              color: 'white',
              fontWeight: 'bold'
            }
          }
        },
        series: []
      }
    });

    exerciseChart1.reflow();
  }

  private plotChart3() {
    const disciplinesData: Array<any> = [];
    const start = this.period.start.clone();
    const end = this.period.end.clone();
    const weekDays: Array<string> = [];
    const day = start.clone();

    while (day.isBefore(end) || day.isSame(end)) {
      weekDays.push(day.format('DD/MM'));
      day.add(1, 'day');
    }

    const groupByDate = _.groupBy(this.statisticsWithPages, 'data');

    _.each(groupByDate, (group: Array<Statistic>, date: string) => {
      const pagesPerMinute = group.map(this.mapMinutePerPage).reduce(this.reduceTotal);
      disciplinesData.push(pagesPerMinute);
    });

    let chart = getChart3(weekDays, disciplinesData);
    //const chartHeight = chart.series[0].data.length * 35;
    chart.chart['height'] = 'auto';
      
    this.chart = {...chart};
    chart.chart['page'] = this;
    const highChart = new Highcharts.Chart(chart as any);
    highChart.reflow();
  }

  private mapMinutePerPage(item: Statistic): number {
    const minutes = item.tempo / 1000 / 60;
    const pages = Number(item.pag_estudadas);

    if (minutes && pages) {
      return minutes / pages;
    }

    return 0;
  }

  private mapMillisPerPage(item: Statistic): number {
    const minutes = item.tempo;
    const pages = Number(item.pag_estudadas);

    if (minutes && pages) {
      return minutes / pages;
    }

    return 0;
  }

  private mapTotal(item: Statistic): number {
    return item.pag_estudadas ? Number(item.pag_estudadas) : 0;
  }

  private reduceTotal(acc: number, curr: number) {
    return curr + acc;
  }

  async slideChanged(event) {
    const { srcElement } = event;
    const { activeIndex } = srcElement.swiper;
    const realIndex = activeIndex;
    const slideName = SlideName[realIndex];
    this.logEvent(slideName);
  }

  logEvent(slideName: string, action?: string) {
    this.analyticsService.logEvent(AnalyticsEventEnum.SC_STATISTICS_SLIDE, {
      chart: 'page-chart',
      period: this.tipo,
      slide_name: slideName,
      action
    });
  }

  async expand() {
    delete this.chart.chart.renderTo;

    this.chart.chart.exporting ={
        sourceWidth: 400,
        sourceHeight: 360,
         scale: 4,
        chartOptions: {
            subtitle: null
        }
    }

    const modal = await  this.modalCtrl.create({
      component: ExpandComponent,
      backdropDismiss: false,
      componentProps: {options: this.chart},
      cssClass: 'fullscreen modal-transparent'
    });
    modal.present();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getColorEventDay'
})
export class GetColorEventDayPipe implements PipeTransform {

  transform(value): string {

    let response;

    if(value) {
      const status = value.status;
      if(status === 'bom') {
        response = "#1CD37A";
      } else if (status === 'regular') {
        response = "#FA8B0C";
      } else if (status === 'ruim') {
        response = "#F54C61"
      }
    } 


    return response;
  }

}

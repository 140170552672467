import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { CreditCard } from '@models/CreditCard';
import jsencrypt from 'jsencrypt';
import { MoipCreditCard, MoipValidator } from 'moip-sdk-js';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
  constructor(private toastController: ToastController) {}

  // Encrypt in v2.0 - Waiting API update...
  /**
   * Criptografia para os dados sensíveis do cartão de crédito.
   */
  public async encryptCard(card: any) {
    // Sandbox public key
    const pubKey = `-----BEGIN PUBLIC KEY-----
      MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoTiTC7YFAQleyou/tjEN
      dv6AydVFXSrTJIVENvbCc9cLGtCGr9/tYzQu+3UiDlbtInEcBgw94LWPxekuTnus
      XMh5wFyu/B2j/dDshnIQDHfTpIbQlJV4h50GJTXEvj82bxtkBhbmj0bgqw8b0hgt
      bKDcbf+zdVYMUx9/00qGBWAOmVZaSfMbXKHex4RwJsss93Lkihw+VBeZyxgHqEwG
      yPIvYHJ2NC496f6SUZX3ZhYEdIsE/oZ9xwj2C0Mcu5eh/s43U9V3eoVZQj8Jod/z
      oQOae5wBorkkdvIzM1aoQh0U7zZCKLZmnqCOEIPMhu/hFqsfqemUv6sztUfAMMX5
      AwIDAQAB
      -----END PUBLIC KEY-----`;

    const hash = await MoipCreditCard.setEncrypter(jsencrypt, 'ionic')
      .setPubKey(pubKey)
      .setCreditCard({
        number: card.cardNumber,
        cvc: card.cvv,
        expirationMonth: card.expiryMonth,
        expirationYear: card.expiryYear
      })
      .hash();

    return hash;
  }

  public getBrand(cardNumber: string) {
    return MoipValidator.cardType(cardNumber);
  }

  public isCreditCardValid(card: CreditCard): boolean {
    if (this.isEmpty(card.cardNumber)) {
      this.toast('O número do cartão de crédito é obrigatório.');
      return false;
    }

    // Validar número do cartão
    const isValidNumber: boolean = MoipValidator.isValidNumber(card.cardNumber);
    if (!isValidNumber) {
      this.toast('O número do cartão é inválido.');
      return false;
    }

    if (this.isEmpty(card.expiration)) {
      this.toast('A validade do cartão é obrigatória.');
      return false;
    }

    if (card.expiration.length < 5) {
      this.toast('A validade do cartão é inválida.');
      return false;
    }

    const expirations: string[] = card.expiration.split('/');
    card.expiryMonth = expirations[0];
    card.expiryYear = expirations[1];

    // Validar data de expiração
    const isExpiryDateValid: boolean = MoipValidator.isExpiryDateValid(card.expiryMonth, card.expiryYear);
    if (!isExpiryDateValid) {
      this.toast('A validade do cartão é inválida.');
      return false;
    }

    if (this.isEmpty(card.holderName)) {
      this.toast('O nome do titular é obrigatório.');
      return false;
    }

    return true;
  }

  private isEmpty(field: string): boolean {
    return !field || field.length == 0;
  }

  private async toast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'bottom',
      color: 'warning'
    });

    await toast.present();
  }
}

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BasePageController } from '@app/pages/BasePage';
import { SyncService } from '@services/api/sync/sync.service';
import { EventService } from '@services/event-service/event.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss']
})
export class ChangePasswordPage extends BasePageController implements OnInit {
  submitted: boolean = false;
  form: FormGroup;
  passwordInputType: string = 'password';

  constructor(
   private synService: SyncService,
   private eventService: EventService
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required]),
      passwordRepeat: new FormControl('', [Validators.required])
    });
  }

  async onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const loading = await this.showLoading();
    try {
      const { password, passwordRepeat } = this.form.value;

      if (password === passwordRepeat) {
       
        await this.userService.changePassword({ userId: this.userStoreService.user.objectId, password });

        await loading.dismiss();
        const alert = await this.showAlert({
          header: 'Senha alterada',
          message: 'Sua senha foi alterada com sucesso'
        });
        await alert.onDidDismiss();
        this.dismiss();
      } else {
        await loading.dismiss();
        this.showToast({
          message: 'As senhas não são iguais. Por favor, digite novamente.'
        });
      }
    } catch (e) {
      await loading.dismiss();
      this.showToast({
        message: 'Ops. Um erro desconhecido ocorreu.'
      });
      console.log({ e });
    }
  }

  async handleSyncProcess() {}

  async onShowPassword() {
    this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DateTimeDialogEventsService {
  channel: Subject<any> = new Subject<any>();

  constructor() {}

  clearObservers() {
    this.channel = new Subject<any>();
  }

  add(data: any) {
    this.channel.next(data);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent {
  @Input()
  displayText = 'Selecione a quantidade de parcelas *';
  @Input()
  displayColor = '#639bd0';
  @Input()
  items = [];

  @Input()
  centered: boolean = false;

  @Output()
  onTap = new EventEmitter();

  iconColor = '#777';
  constructor(){}

  onTapDropdown() {
    this.onTap.emit();
  }
}

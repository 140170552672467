import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { UserStoreService } from '@app/@services/api/user-store/user-store.service';
import { PremiumService } from '@services/premium/premium.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private userStoreService: UserStoreService,
    private router: Router,
    private premiumService: PremiumService
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const user = this.userStoreService.user;

    if (!user) {
      this.router.navigate(['welcome-onboarding'], { replaceUrl: true });
      return false;
    } else if (this.userStoreService.userIsExpired() && !this.userStoreService.userIsFreemium()) {
      this.premiumService.waitResolveExpired();
      return true;
    }

    return true;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { ChronometerPage } from './chronometer.page';
import { SharedComponentsModule } from '@app/components/shared-components.module';
import { AlarmModalPageModule } from '../alarm-modal/alarm-modal.module';

@NgModule({
  imports: [
    AlarmModalPageModule,
    SharedComponentsModule,
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [ChronometerPage]
})
export class ChronometerPageModule {}

import { Injectable } from '@angular/core';
import amplitude, { AmplitudeClient, Callback, Identify } from 'amplitude-js';
import { environment } from '../../../environments/environment';
import { AnalyticsEventEnum } from '../../models/AnalyticsEventEnum';
import { WebUtils } from '@app/util/WebUtils';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  private client: AmplitudeClient;

  constructor() {
    this.client = amplitude.getInstance();
    this.client.init(environment.amplitudeApiKey, undefined, { includeReferrer: true, includeUtm: true });
    this.client.setVersionName(environment.appVersion);
    this.client.setUserProperties({ isWebVersion:  WebUtils.isWeb() });
  }
  setUserId(userId: string) {
    this.client.setUserId(userId);
  }

  setUserObjective(objective: string) {
    this.client.setUserProperties({ objective });
  }

  setUserPlan(planId: string, expired: boolean, planExp: Date, planName: string) {
    const identify: Identify = new this.client.Identify();
    identify.set('planId', planId);
    identify.set('planName', planName);
    this.client.setUserProperties({
      plan_id: planId,
      plan_exp: planExp,
      expired,
      plan_name: planName
    });
  }

  logEvent(event: AnalyticsEventEnum | string, data?: any, cb?: Callback) {
    return this.client.logEvent(event, data, cb);
  }
}

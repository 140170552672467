import { Injectable } from '@angular/core';
import { ParseService } from '@services/api/parse/parse.service';

@Injectable({
  providedIn: 'root'
})
export class GooglePurchaseService {

  constructor(private parseService: ParseService) { }

  public purchase(receipt: PurchaseReceipt): Promise<{ wasCreated: boolean; subscription: any }> {
    return this.parseService.run('handleInAppPurchaseGoogle', { receipt });
  }

  public restoreSubscription(purchaseToken: string, userId: string): Promise<string> {
    return this.parseService.run('restoreGooogleSubscription', { purchaseToken, userId });
  }
}

interface PurchaseReceipt {
  orderId: string;
  packageName: string;
  productId: string;
  purchaseTime: number;
  purchaseState: number;
  purchaseToken: string;
  autoRenewing: boolean;
  acknowledged: boolean;
  userId: string;
}
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MenuController, ModalController } from "@ionic/angular";
import { SyncService } from "@app/@services/api/sync/sync.service";
import { BehaviorSubject } from "rxjs";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Screenshot } from '@ionic-native/screenshot/ngx';

@Component({
  selector: "app-common-header",
  templateUrl: "./common-header.component.html",
  styleUrls: ["./common-header.component.scss"],
})
export class CommonHeaderComponent {
  @Output()
  filterIconTap = new EventEmitter();

  @Output()
  leftIconTap = new EventEmitter();

  @Output()
  rightIconTap = new EventEmitter();

  @Input()
  title: string = "";

  @Input()
  hasFilter = false;

  @Input()
  hasLogo = false;

  @Input()
  hasDrawer = false;

  @Input()
  hasTutorial = false;

  @Input()
  hasQuestionTutorial = false;

  @Input() disableDrawerButton = false;

  @Input()
  isLight = false;

  // this variable is deprecated
  @Input()
  isDark = false;

  @Input() enableScreenshot = false;

  syncStatus: BehaviorSubject<boolean>;

  constructor(
    private menuCtrl: MenuController,
    private modalController: ModalController,
    private syncService: SyncService,
    private socialSharing: SocialSharing,
    private screenshot: Screenshot
  ) {
    this.syncStatus = this.syncService.getSyncStatus();
  }

  _filterIconTap() {
    this.filterIconTap.emit();
  }

  _leftIconTap() {
    this.leftIconTap.emit();
  }

  _rightIconTap() {
    this.rightIconTap.emit();
  }

  menuToggle() {
    this.menuCtrl.toggle();
  }

  close() {
    this.modalController.dismiss();
  }

  onBackButton() {
    alert("show back button");
  }

  async shareScreenShot() {
    try {
      const screenshotResult = await this.screenshot.URI(80);
      await this.socialSharing.share('', null, screenshotResult.URI, null);
    } catch (error) {
      console.log('screenshot error', error);
    }
  }
}

import { Injectable } from '@angular/core';
import { OfflineTutorialsPage } from '@app/offline-tutorials/offline-tutorials.page';
import { HelpBotPage } from '@app/shared/help-bot/help-bot.page';
import { ModalController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class MyExamService {
  constructor(private modalCtrl: ModalController) {}
  

  async openHelpPeso() {
    const modal = await this.modalCtrl.create({
      component: HelpBotPage,
      cssClass: 'sd modal-transparent',
      componentProps: {
        avatar: 'assets/imgs/metas/help-bot.png',
        message: `Caso a prova contenha <oficialmente> pesos diferentes para diferentes disciplinas, <informe os pesos definidos para cada uma.>
              <Caso não haja diferentes pesos, utilize peso "1"> para todas as disciplinas ou <desative o toggle de pesos diferentes>.
              `,
        title: 'Peso da Disciplina',
        buttons: [
          {
            name: 'ENTENDI',
            className: 'entendi',
            shouldClose: true,
            handler: () => {}
          }
        ]
      }
    });
    modal.present();
  }

  async openHelpQtdQuestoes() {
      const modal = await this.modalCtrl.create({
        component: OfflineTutorialsPage,
        componentProps: {
            avatar: 'assets/imgs/metas/help-bot.png',
            title: 'Quantidade de Questões',
            youtubeURL: 'https://youtu.be/ZfFjaFiEj6U',
            message: `Informe a <quantidade de questões dessa disciplina> prevista para a prova.
            <Caso não saiba,> informe uma <quantidade> que <reflita a importância> da disciplina na prova.
            <Por exemplo,> se a prova possuir 100 questões, as divida dentre as disciplinas conforme a importância de cada uma (Português 30, Matemática 20 etc).
            <Se ainda tiver duvídas, assista ao vídeo abaixo:>
                  `,
        },
        cssClass: 'sd modal-transparent'
      });
  
      modal.present();
  }
}

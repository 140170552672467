import { Injectable } from '@angular/core';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '@services/storage/storage.service';

@Injectable({ providedIn: "root" })
export class TransferringContextService {
  constructor(
    private storage: StorageService,
    private file: File,
    private socialSharing: SocialSharing,
    private http: HttpClient,
  ) {}

  replacerFunc = () => {
    const visited = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (visited.has(value)) {
          return;
        }
        visited.add(value);
      }
      return value;
    };
  };

  async restoreContextFromAssets(): Promise<any> {
    const backupResult: any = await this.http.get('assets/dump/backup.json').toPromise();
    const statisticsResult: any = await this.http.get('assets/dump/backup-statistics.json').toPromise();
    backupResult.cachedStatistic = statisticsResult;

    return backupResult;
  }

  async createContextFiles(): Promise<string[]> {
    const allData: any = {};
    //await this.storage.ready();
    const keys = [
      'accessTag',
      'cachedUserSubjects',
      'cachedCycle',
      'cachedCycleBlocks',
      'cachedReview',
      'cached_topics',
      'ciclo',
      'email-user-estudo',
      'ratingData',
      'user-estudo',
      'user-estudo-logado',
    ];
    for (const key of keys) {
      const item = await this.storage.get(key);
      item && item.cicloAtivo ? delete item.cicloAtivo : '';
      allData[key] = item;
    }

    const statistics = await this.storage.get('cachedStatistic');
    const content = JSON.stringify(allData, this.replacerFunc());
    const contentStatistics = JSON.stringify(statistics, this.replacerFunc());

    const backupFileEntry = (await this.file.writeFile(this.file.dataDirectory, 'backup.json', content, {
      append: false,
      replace: true,
    })) as FileEntry;

    const statisticFileEntry = (await this.file.writeFile(
      this.file.dataDirectory,
      'backup-statistics.json',
      contentStatistics,
      {
        append: false,
        replace: true,
      },
    )) as FileEntry;

    return [backupFileEntry.nativeURL, statisticFileEntry.nativeURL];
  }

  async shareFiles() {
    await this.socialSharing.shareWithOptions({
      files: await this.createContextFiles(),
      chooserTitle: 'Share Estudaqui files (2)',
      message: 'Compartilhar arquivos',
    });
  }
}

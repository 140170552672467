import { Injectable } from '@angular/core';
import { InAppPurchase } from '@ionic-native/in-app-purchase/ngx';
import { AlertController } from '@ionic/angular';
import { ErrorReportService } from '@services/error-report/error-report.service';
import { ParseService } from '../parse/parse.service';

@Injectable({
  providedIn: 'root'
})
export class ApplePurchaseService {
  constructor(private alertCtrl: AlertController, private iap: InAppPurchase, private parseService: ParseService) {}

  // Check if the user subscription is still active. Don't call this every app open, since it requires iTunes login
  public async checkValidationOfReceipt(userId: string) {
    const receipt = await this.iap.getReceipt();
    const receiptValidationStatus = await this.validateReceipt(userId, receipt);

    return receiptValidationStatus;
  }

  public async checkStatus(userObjectId: string) {
    try {
      // First, ask Apple for the receipt. We get back an encrypted string
      const receipt = await this.iap.getReceipt();
      return await this.validateReceipt(userObjectId, receipt);
    } catch (err) {
      if (err.code === 141) {
        const title = 'Erro ao restaurar assinatura';
        const subTitle = 'Sua assinatura Apple não é mais válida, por favor assine algum dos planos novamente.';

        const alert = await this.alertCtrl.create({
          header: title,
          subHeader: subTitle,
          buttons: [
            {
              text: 'Ok',
              handler: () => {}
            }
          ]
        });

        await alert.present();
      }

      return null;
    }
  }

  public async purchase(assinatura): Promise<any> {
    //return this.parseService.run('handleInAppPurchaseSandbox', assinatura);
    const response= await this.parseService.run('handleInAppPurchase', assinatura);
  }

  public restore(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.iap
        .restorePurchases()
        .then((purchases) => {
          resolve(purchases);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  private validateReceipt(objectId: string, receipt: string): Promise<any> {
    return this.parseService.run('validateReceipt', {
      customer_id: objectId,
      receipt
    });
  }

  public fetchReceipt() {
    return this.iap.getReceipt();
  }
}

import { commonEnv } from './common-env';

export const environment = {
  production: true,
  appVersion: commonEnv.appVersion,
  appVersionCode: commonEnv.appVersionCode,
  parseAppId: 'Ib2MzUBmsRuQuaeMvskVXkwwJzso0iUG2TV7h4Tk',
  parseJsId: '3YMlWXblOfDRT7VRW6YlYRWvsFRs8unKxPI8E8JE',
  serverURL: 'https://api.estudaqui.com/',
  amplitudeApiKey: '2fd233eae76be059ad04fe26e1d79b76',
  moipPubKey: `-----BEGIN PUBLIC KEY-----
   MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoTiTC7YFAQleyou/tjEN
   dv6AydVFXSrTJIVENvbCc9cLGtCGr9/tYzQu+3UiDlbtInEcBgw94LWPxekuTnus
   XMh5wFyu/B2j/dDshnIQDHfTpIbQlJV4h50GJTXEvj82bxtkBhbmj0bgqw8b0hgt
   bKDcbf+zdVYMUx9/00qGBWAOmVZaSfMbXKHex4RwJsss93Lkihw+VBeZyxgHqEwG
   yPIvYHJ2NC496f6SUZX3ZhYEdIsE/oZ9xwj2C0Mcu5eh/s43U9V3eoVZQj8Jod/z
   oQOae5wBorkkdvIzM1aoQh0U7zZCKLZmnqCOEIPMhu/hFqsfqemUv6sztUfAMMX5
   AwIDAQAB
   -----END PUBLIC KEY-----`,
  freemiumPlanId: 'FRvSmcyhZD',
  trialPlanId: 'TEBTLKF3UO',
  adUnitId: 'ca-app-pub-7905300897087175/9661921651',
  interstitialAd: 'ca-app-pub-7905300897087175/9257588789',
  adUnitIdIOS: 'ca-app-pub-7905300897087175/6657208119',
  interstitialAdIOS: 'ca-app-pub-7905300897087175/3959974152',
  firebase: commonEnv.firebase,
  googleClientId: commonEnv.googleClientId,
  facebookAppId: commonEnv.facebookAppId
};

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { DELETED_ACCOUNT } from '@models/Tags';
import { TagsService } from '@services/api/tags/tags.service';
import { UserStoreService } from '@services/api/user-store/user-store.service';
import { UserService } from '@services/api/user/user.service';
import { LoadingService } from '@services/loading/loading.service';
import { LogoutService } from '@services/logout/logout.service';
import { StorageService } from '@services/storage/storage.service';
import { ToastService } from '@services/toast/toast.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss']
})
export class DeleteAccountModalComponent {
  deleteFieldValue: string;

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private userStoreService: UserStoreService,
    private userService: UserService,
    private tagsService: TagsService,
    private logoutService: LogoutService,
    private router: Router
  ) {}

  async clickDelete() {
    if (this.deleteFieldValue !== 'EXCLUIR TUDO') {
     await this.toastService.showAlert('Digite EXCLUIR TUDO', null, null);
    return;
    }

    this.handelDelete();

  }

  private async handelDelete() {
    await this.loadingService.show(null, false);
    const user = this.userStoreService.user;

    try {
     
     this.tagsService.addTagsToUser([{ tag: DELETED_ACCOUNT }], this.userStoreService.user.objectId);

      await this.userService.deleteAccount({
        userId: user.objectId,
        email: user.username
      });
      
      const alert = await this.alertController.create({
        header: 'Conta apagada com sucesso',
        message: 'Seu cadastro foi removido com sucesso do sistema Estudaqui.'
      });
      
      await this.loadingService.dismiss();
      
      await this.logoutService.logout();
      this.router.navigate(['welcome-onboarding'], { replaceUrl: true });
      await alert.present();

      this.modalController.dismiss();

      
    } catch (err) {
      await this.loadingService.dismiss();

      const alert = await this.alertController.create({
        header: 'Erro ao Apagar Usuário',
        message: 'Ocorreu um erro inesperado ao apagar sua conta. Por favor, tente novamente.'
      });

      alert.present();
    }
  }

  clickCancel() {
    this.modalController.dismiss();
  }
}

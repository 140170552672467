import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

import { BotModule } from "@app/bot/bot.module";
import { SharedModule } from "@app/shared/shared.module";
import { MyExamCreateHelperPage } from "./my-exam-create-helper.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    BotModule,
    SharedModule
  ],
  declarations: [MyExamCreateHelperPage],
})
export class MyExamCreateHelperPageModule {}

import { Component } from '@angular/core';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent {
  benefits = [
    {
      title: 'Gerenciador de Revisões',
      text: 'Utilize nossa tecnologia para programar quando você irá revisar seus estudos',
      icon: 'calendar'
    },
    {
      title: 'Uso offline',
      text: 'Utilização das funcionalidades do aplicativo sem conexão ou no modo avião',
      icon: 'cloud-offline'
    },
    {
      title: 'Sem anúncios',
      text: 'Fique livre de anúncios dentro do aplicativo',
      icon: 'eye-off'
    },
    {
      title: 'Planos Otimizados',
      text: 'Planos 100% personalizados e otimizados utilizando inteligência artificial',
      icon: 'sync-circle'
    },
    {
      title: 'Estatísticas Avançadas',
      text: '+ de 16 gráficos, como tempo desperdiçado, evolução de acertos e eficiência',
      icon: 'analytics'
    },
    {
      title: 'Gatilhos de Motivação',
      text: 'Incluindo fácil acompanhamento visual do cumprimento de metas',
      icon: 'happy'
    }
  ];
}

import { Injectable } from "@angular/core";
import { ParseService } from "@app/@services/api/parse/parse.service";

@Injectable({
  providedIn: "root",
})
export class MoipService {
  constructor(private parseService: ParseService) {}

  alterarCartao(cartao): Promise<any> {
    return this.parseService.run("moipAlterarCartao", cartao);
  }

  private consultarAssinanteMoip(code): Promise<any> {
    return this.parseService.run("moipConsultarAssinante", code);
  }

  public async consultarAssinante(userId: string) {
    let assinante;

    try {
      assinante = await this.consultarAssinanteMoip({ code: userId });
    } catch (err) {
      assinante = null;
    }

    return assinante;
  }

  private consultarAssinaturaMoip(code): Promise<any> {
    return this.parseService.run("moipConsultarAssinatura", code);
  }

  public async consultarAssinatura(userId: string) {
    let assinatura;

    try {
      assinatura = await this.consultarAssinaturaMoip({ code: userId });
    } catch (err) {
      assinatura = null;
    }

    return assinatura;
  }

  private consultarCupomMoip(code): Promise<any> {
    return this.parseService.run("moipConsultarCupom", code);
  }

  public async consultarCupom(code: string) {
    let cupom;

    try {
      cupom = await this.consultarCupomMoip({ code: code });
    } catch (err) {
      cupom = null;
    }

    return cupom;
  }

  criarAssinante(assinante): Promise<any> {
    return this.parseService.run("moipCriarAssinante", assinante);
  }

  criarAssinatura(assinatura): Promise<any> {
    return this.parseService.run("moipCriarAssinatura", assinatura);
  }

  obterDatasValidade(): Promise<any> {
    return this.parseService.run("getExpiryDates");
  }

  retomarAssinatura(code): Promise<any> {
    return this.parseService.run("moipRetomarAssinatura", code);
  }

  suspenderAssinatura(code): Promise<any> {
    return this.parseService.run("moipSuspenderAssinatura", code);
  }

  upgradeAssinatura(upgrade): Promise<any> {
    return this.parseService.run("moipUpgradeAssinatura", upgrade);
  }
}

import { Component, OnInit } from "@angular/core";
import { BasePageController } from "@app/pages/BasePage";
import { ModalController } from "@ionic/angular";
import { Ciclo } from "@models/Ciclo";
import { CycleCachingService } from "@app/@services/api/caching/cycle-caching/cycle-caching.service";
import { EstudoService } from "@app/@services/api/estudo/estudo.service";
import { EventService } from "@app/@services/event-service/event.service";
import moment from "moment";
import { PremiumService } from "@services/premium/premium.service";

@Component({
  selector: "app-cycle-list",
  templateUrl: "./cycle-list.page.html",
  styleUrls: ["./cycle-list.page.scss"],
})
export class CycleListPage extends BasePageController {
  ciclos: Ciclo[] = [];
  cicloAtivoId: string;
  empty = false;

  constructor(
    private estudoService: EstudoService,
    private eventsService: EventService,
    private cycleCachingService: CycleCachingService,
    public modalController: ModalController,
    public premiumService: PremiumService
  ) {
    super();
  }

  async ionViewWillEnter() {
    const user = this.userStoreService.user;
    const ciclo = user.cicloAtivo;
    this.cicloAtivoId = ciclo ? ciclo.objectId : undefined;
    this.fetchData();
  }

  async fetchData() {
    const loading = await this.showLoading();
    try {
      this.ciclos = await this.cycleCachingService.read();
    } catch (e) {
      this.ciclos = [];
    }
    loading.dismiss();
  }

  async openDialogEditCycleName(cycle: Ciclo) {
    await this.modalController.dismiss();
    this.eventsService.publish('cycle:edit', cycle);
  }

  async delete(ciclo) {
    this.networkService.executeIfOnline(async () => {
      const cicloToRemoveId = ciclo.objectId;
      const isCicloAtivo = cicloToRemoveId == this.cicloAtivoId;
      const message = isCicloAtivo
        ? "Este é o seu plano ativo, será necessário selecionar outro plano após a remoção! Deseja excluir o plano?"
        : "Deseja excluir o plano?";


      const confirm = await this.alertCtrl.create({
        header: "Excluir plano",
        message: message,
        buttons: [
          {
            text: "Não",
            role: "cancel",
          },
          {
            text: "Sim",
            handler: () => this.handleDelete(ciclo),
          },
        ],
      });

      confirm.present();
    });
  }

  async handleDelete(ciclo) {
    const loading = await this.showLoading("Aguarde. Deletando ciclo");
    const cicloToRemoveId = ciclo.objectId;
    const isCicloAtivo = cicloToRemoveId == this.cicloAtivoId;
    const obj = {
      ciclo: {
        objectId: ciclo.objectId,
      },
    };

    await this.estudoService.deleteCiclo(obj);
    await this.cycleCachingService.sync();
    this.ciclos = [];
    this.fetchData();

    if (isCicloAtivo) {
      this.eventsService.publish("remove:cicloAtivo");
    }

    await loading.dismiss();
  }

  duas_casas(numero) {
    if (numero <= 9) {
      numero = "0" + numero;
    }
    return numero;
  }

  convertToHHMM(info) {
    const hrs = this.duas_casas(parseInt(info));
    const min = this.duas_casas(Math.round((info - hrs) * 60));
    return hrs + ":" + min;
  }

  getTempo(tempo) {
    return this.convertToHHMM(
      moment.duration(tempo, "ms").asHours().toFixed(2)
    );
  }

  getHora(hora) {
    return moment.utc(hora).format("HH:mm:ss");
  }

  getNameDay(data) {
    return moment(data).format("dddd");
  }

  getData(data) {
    return moment(data).format("DD/MM/YYYY");
  }

  close() {
    this.modalController.dismiss();
  }

  async handleAddPlan() {

    const reward = await this.premiumService.waitResolvePremiumModal();

    if(reward) {
      await this.modalController.dismiss();
      this.eventsService.publish('OPEN_SELECT_PLAN');
    }
  }
}

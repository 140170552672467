import { Injectable } from "@angular/core";
import { EstudoService } from "@app/@services/api/estudo/estudo.service";
import { StorageService } from "@app/@services/storage/storage.service";
import { interval } from "rxjs";
import { ClearCachingService } from "../clear-caching/clear-caching.service";
import { ICachingRead } from "../icaching-read";

interface StudyType {
  Ordem: number;
  Tipo_estudo: string;
  className: string;
  createdAt: string;
  objectId: string;
  updatedAt: string;
}

@Injectable({
  providedIn: "root",
})
export class StudyTypeCachingService implements ICachingRead<StudyType> {
  entityName = "cachedStudyTypes";
  lastUpdate: Date = null;
  cachedData: Array<StudyType> = [];
  syncTimer = interval(60000);

  constructor(
    private clearCaching: ClearCachingService<StudyType>,
    private storage: StorageService,
    private studyService: EstudoService
  ) {}

  async clear() {
    return this.clearCaching.clear(this);
  }

  async sync(): Promise<void> {
    try {
      await this.read();
      const result = (await this.studyService.syncStudyTypes(
        this.lastUpdate
      )) as Array<StudyType>;
      this.lastUpdate = new Date();

      if (!this.cachedData || this.cachedData.length === 0) {
        this.cachedData = result;
      } else {
        result.forEach((studyType) => {
          const cachedStudyType: StudyType = this.cachedData.filter(
            (s) => s.objectId == studyType.objectId
          )[0];
          if (cachedStudyType) {
            const cachedIndex = this.cachedData
              .map((cStudyType) => cStudyType.objectId)
              .indexOf(studyType.objectId);
            const mergedStudyType: StudyType = Object.assign(
              cachedStudyType,
              studyType
            );
            this.cachedData[cachedIndex] = mergedStudyType;
          } else {
            this.cachedData.push(studyType);
          }
        });
      }

      await this.storage.set(this.entityName, this.cachedData);
      await this.storage.set(`${this.entityName}LastUpdate`, this.lastUpdate);
    } catch (error) {
      console.log(error);
    }
  }

  async read(): Promise<Array<StudyType>> {
    const _lastUpdate = await this.storage.get(`${this.entityName}LastUpdate`);
    this.lastUpdate = _lastUpdate ? new Date(_lastUpdate) : null;
    if (this.cachedData.length === 0)
      this.cachedData = (await this.storage.get(
        this.entityName
      )) as Array<StudyType>;
    return this.cachedData;
  }
}

<app-close-popup></app-close-popup>

<ion-content class="ion-no-padding ion-no-border" fullscreen="true">


  <div class="rate-container">
    <div class="rate-title">
      <div class="rate-title-content">
        <img src="assets/imgs/rate/logo_glass.png" alt="" srcset="">
        <h2>Está gostando?</h2>
      </div>
    </div>

    <div class="rate-content">
      <p>Quantas estrelas você daria para o Estudaqui?</p>
    </div>

    <div class="rate-stars">
      <form [formGroup]="customForm">
        <ionic-rating-component #rating
        activeIcon = "star"
        defaultIcon = "star-outline"
        activeColor = "#FCC002" 
        defaultColor = "#B8B5B5"
        readonly="false"
        fontSize = "40px"
        formControlName="starRating">
        </ionic-rating-component>
      </form>
    </div>

    <div class="rate-btn">
      <ion-button [disabled] = "startRating === 0" (click)="open()">
        <span>Avaliar</span>
      </ion-button>
    </div>
  </div>
</ion-content>
<app-common-header title="Minhas Provas" [isDark]="true" (leftIconTap)="dismiss()" [hasFilter]="false"
  [hasQuestionTutorial]="false"></app-common-header>
<ion-content>
  <ng-container *ngIf="examListView?.length > 0">
    <p class="ion-margin ion-text-center">Clique para gerenciar suas provas</p>
    <ion-list>
      <ion-item *ngFor="let exam of examListView">
        <ion-label class="ion-text-wrap">
          {{ exam.cargo }}
        </ion-label>
        <ion-button color="light" class="ion-no-margin" slot="end" fill="clear" (click)="doDisable(exam)">
          <ion-icon slot="icon-only" name="trash-outline"></ion-icon>
        </ion-button>
        <ion-button color="light" class="ion-no-margin" slot="end" fill="clear" (click)="openCreateOrUpdateExamModal(exam)">
          <ion-icon slot="icon-only" name="pencil"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-list>
  </ng-container>

  <app-empty-list *ngIf="examListView?.length === 0" title="Não encontramos provas personalizadas"></app-empty-list>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button (click)="openCreateExamHelper()">
      <img src="assets/imgs/plans-compare/coroa_high.png" class="btn-crown-img">
      <ion-icon name="add" class="btn-add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
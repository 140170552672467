import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getIconDayEvent'
})
export class GetIconDayEvent implements PipeTransform {

  transform(value): string {

    let response;

    if (value) {
      const status = value.status;
      if ( status === "bom") {
        return "assets/imgs/icon-smile-bom.png";
      } else if (status === "regular") {
        return "assets/imgs/icon-smile-regular.png";
      } else if (status === "ruim") {
        return "assets/imgs/icon-smile-ruim.png";
      } else {
        return "assets/imgs/icon-smile-regular.png";
      }
    }

    return response;
  }

}

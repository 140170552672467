import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeeklyScheduleCreateComponent } from './weekly-schedule-create/weekly-schedule-create.component';
import { SharedComponentsModule } from '@app/components/shared-components.module';
import { StudyPlanWeeklyDisplayComponent } from './shared/study-plan-weekly-display/study-plan-weekly-display.component';
import { SharedModule } from '@app/shared/shared.module';
import { CustomDateTimeModule } from '@app/directives/custom-date-time/custom-date-time.module';
import { FormsModule } from '@angular/forms';
import { BotModule } from '@app/bot/bot.module';
import { WeeklyScheduleCreationOption } from './weekly-schedule-creation-option/weekly-schedule-creation-option.component';

@NgModule({
  declarations: [WeeklyScheduleCreateComponent, StudyPlanWeeklyDisplayComponent, WeeklyScheduleCreationOption],
  exports: [StudyPlanWeeklyDisplayComponent, WeeklyScheduleCreationOption],
  imports: [CommonModule, SharedModule, SharedComponentsModule, BotModule, FormsModule, CustomDateTimeModule]
})
export class WeeklyScheduleModule {}

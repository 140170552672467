import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatActionsComponent } from './chat-actions/chat-actions.component';
import { ChatMessagesComponent } from './chat-messages/chat-messages.component';
import { ChatProgressComponent } from './chat-progress/chat-progress.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { CustomDateTimeModule } from '@app/directives/custom-date-time/custom-date-time.module';



@NgModule({
  declarations: [
    ChatActionsComponent,
    ChatMessagesComponent,
    ChatProgressComponent
  ],
  exports: [
    ChatActionsComponent,
    ChatMessagesComponent,
    ChatProgressComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    CustomDateTimeModule
  ]
})
export class BotModule { }

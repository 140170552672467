import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  modalInst : HTMLIonModalElement[] = [];


  constructor() {}

  storeModal(x: HTMLIonModalElement) {
    this.modalInst.push(x);
  }

  closeAll() {
    this.modalInst.forEach((modal)=> modal.dismiss());
    this.modalInst = [];
  }
 
}

import { Animation, createAnimation } from "@ionic/core";

export function appEnterAnimation(baseEl: HTMLElement): Animation {
  const baseAnimation = createAnimation().addElement(baseEl);

  const backdropAnimation = createAnimation().addElement(baseEl.querySelector("ion-backdrop"));

  const wrapperAnimation = createAnimation().addElement(baseEl.querySelector(".modal-wrapper"));

  wrapperAnimation
    .fromTo(
      "transform",
      "scaleX(0.1) scaleY(0.1)",
      "translateX(0%) scaleX(1) scaleY(1)"
    )
    .fromTo("opacity", 0, 1);

  backdropAnimation.fromTo("opacity", 0.01, 0.4);

  return baseAnimation
      .easing("cubic-bezier(0.36,0.66,0.04,1)")
      .duration(400)
      .beforeAddClass("show-modal")
      .addAnimation(backdropAnimation)
      .addAnimation(wrapperAnimation)

}


  <div class="content-page">
    <div class="picker">
      <div class="header">
        <section class="title" *ngIf="pickerTitle">
          <h4 style="font-size: 0.9rem; margin: 0;margin-right: 2px; margin-top: 10px; margin-bottom: 20px">
            {{ pickerTitle }}
            <img
              style="margin-left: 2px;"
              src="../../assets/svg/question-blue.svg"
              alt="help"
              class="help-icon"
              (click)="openHelpBot()"
            />
          </h4>

          <!-- <span (tap)="openHelpBot()" class="help-element">?</span> -->
        </section>
        <div
          [innerHtml]="formattedTime"
          style="
            display: flex;
            font-weight: bold;
            justify-content: space-evenly;
            font-size: 1.8rem;
          "
        ></div>
      </div>

      <div
        [formGroup]="formTime"
        class="content"
        [class.hide-seconds]="hideSeconds"
        *ngIf="pickerType == 'time'"
      >
        <div class="field">
          <div>
            <ion-button
              (click)="upButtonTime('hours')"
              ion-long-press
              (pressEnded)="longPressEnd('hours')"
              (longPressed)="longPressStart('hours')"
              #upButton
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-up"></ion-icon>
            </ion-button>
          </div>
          <div>
            <input
              type="tel"
              (input)="onInput('hours')"
              formControlName="hours"
              #inputHours
              (keydown)="onKeyDown($event, 'hours')"
              (focus)="focusField('hours', $event)"
              (blur)="blurField('hours')"
              maxlength="2"
            />
            <span class="label">h</span>
          </div>
          <div>
            <ion-button
              ion-long-press
              (click)="downButtonTime('hours')"
              (pressEnded)="longPressEnd('hours', 'down')"
              (longPressed)="longPressStart('hours', 'down')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-down"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="field">
          <div>
            <ion-button
              ion-long-press
              (pressEnded)="longPressEnd('minutes')"
              (longPressed)="longPressStart('minutes')"
              (click)="upButtonTime('minutes')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-up"></ion-icon>
            </ion-button>
          </div>
          <div>
            <input
              [readonly]="tickMinutes > 1"
              type="tel"
              (input)="onInput('minutes')"
              formControlName="minutes"
              #inputMinutes
              (keydown)="onKeyDown($event, 'minutes')"
              (focus)="focusField('minutes', $event)"
              (blur)="blurField('minutes')"
              maxlength="2"
            />
            <span class="label">min</span>
          </div>
          <div>
            <ion-button
              ion-long-press
              (pressEnded)="longPressEnd('minutes', 'down')"
              (longPressed)="longPressStart('minutes', 'down')"
              (click)="downButtonTime('minutes')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-down"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="field" *ngIf="!hideSeconds">
          <div>
            <ion-button
              ion-long-press
              (pressEnded)="longPressEnd('seconds')"
              (longPressed)="longPressStart('seconds')"
              (click)="upButtonTime('seconds')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-up"></ion-icon>
            </ion-button>
          </div>
          <div>
            <input
              type="tel"
              (input)="onInput('seconds')"
              #inputSeconds
              formControlName="seconds"
              (keydown)="onKeyDown($event, 'seconds')"
              (focus)="focusField('seconds', $event)"
              (blur)="blurField('seconds')"
              maxlength="2"
            />
            <span class="label">seg</span>
          </div>
          <div>
            <ion-button
              ion-long-press
              (pressEnded)="longPressEnd('seconds', 'down')"
              (longPressed)="longPressStart('seconds', 'down')"
              (click)="downButtonTime('seconds')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-down"></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>

      <div [formGroup]="formDate" class="content" *ngIf="pickerType == 'date'">
        <div class="field">
          <div>
            <ion-button
              ion-long-press
              (click)="upButtonDate('day')"
              (pressEnded)="longPressEnd('day')"
              (longPressed)="longPressStart('day')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-up"></ion-icon>
            </ion-button>
          </div>
          <div>
            <input
              type="tel"
              (input)="onInput('day')"
              formControlName="day"
              #inputDay
              (keydown)="onKeyDown($event, 'day')"
              (focus)="focusField('day', $event)"
              (blur)="blurField('day')"
              maxlength="2"
            />
          </div>
          <div>
            <ion-button
              ion-long-press
              (click)="downButtonDate('day')"
              (pressEnded)="longPressEnd('day', 'down')"
              (longPressed)="longPressStart('day', 'down')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-down"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="field month">
          <div>
            <ion-button
              ion-long-press
              fill="clear"
              (click)="upButtonDate('month')"
              (pressEnded)="longPressEnd('month')"
              (longPressed)="longPressStart('month')"
              tabindex="-1"
            >
              <ion-icon slot="icon-only" name="arrow-up"></ion-icon>
            </ion-button>
          </div>
          <div>
            <select
              name="month"
              formControlName="month"
              #selectMonth
              (change)="onSelectChange()"
            >
              <option [value]="i" *ngFor="let month of months; let i = index;">
                {{ month }}
              </option>
            </select>
          </div>
          <div>
            <ion-button
              ion-long-press
              (click)="downButtonDate('month', 'down')"
              (pressEnded)="longPressEnd('month')"
              (longPressed)="longPressStart('month', 'down')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-down"></ion-icon>
            </ion-button>
          </div>
        </div>

        <div class="field year">
          <div>
            <ion-button
              ion-long-press
              (click)="upButtonDate('year')"
              (pressEnded)="longPressEnd('year')"
              (longPressed)="longPressStart('year')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-up"></ion-icon>
            </ion-button>
          </div>
          <div>
            <input
              type="tel"
              (input)="onInput('year')"
              formControlName="year"
              #inputYear
              (keydown)="onKeyDown($event, 'year')"
              (focus)="focusField('year', $event)"
              (blur)="blurField('year')"
              maxlength="4"
            />
          </div>
          <div>
            <ion-button
              ion-long-press
              (click)="downButtonDate('year')"
              (pressUp)="longPressEnd('year', 'down')"
              (longPressed)="longPressStart('year', 'down')"
              tabindex="-1"
              fill="clear"
            >
              <ion-icon slot="icon-only" name="arrow-down"></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>
      <div class="footer">
        <ion-button
          class="btn-resetar-ciclo"
          (click)="dismiss()"
          style="border-bottom-left-radius: 5px"
          expand="block"
        >
          CANCELAR
        </ion-button>
        <span class="spacer"></span>
        <ion-button
          [disabled]="(pickerType === 'time' && formTime.invalid) || pickerType === 'date' && formDate.invalid"
          class="btn-resetar-ciclo"
          (click)="selectedDate()"
          style="border-bottom-right-radius: 5px"
          expand="block"
        >
          OK
        </ion-button>
      </div>
    </div>
  </div>


import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-intallments-dialog-dropdown',
  templateUrl: './intallments-dialog-dropdown.page.html',
  styleUrls: ['./intallments-dialog-dropdown.page.scss'],
})
export class IntallmentsDialogDropdownPage implements OnInit {
  defaultValue = 1;
  items = [
    { label: 'R$ 79,90 à vista <span class="blue">(economia de R$ 14,90)</span>', value: 1 },
    { label: '12 x R$ 7,90 <span class="blue">(economia de R$ 0,00)</span>', value: 12 },
  ];
  checkedIcon = 'assets/svg/blue-check-circle.svg';
  unCheckedIcon = 'assets/svg/light-blue-circle.svg';
  constructor(
    private modalController: ModalController, private navParams: NavParams
  ) {
    this.items = this.navParams.get('items');
    this.defaultValue = this.navParams.get('defaultValue');
   }

  ngOnInit() {
  }

  onClickItem(item) {
    this.dismiss(item);
  }

  onItemSelect() {
    if (this.defaultValue) {
      this.dismiss(this.items.find(item => item.value === this.defaultValue));
    }
  }

  async dismiss(data?: any) {
    await this.modalController.dismiss(data);
  }

}

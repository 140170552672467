import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';


import { CheckoutSelectPlanPage } from './checkout-select-plan.page';
import { SharedComponentsModule } from '@app/components/shared-components.module';

@NgModule({
  imports: [SharedComponentsModule, CommonModule, FormsModule, IonicModule],
  declarations: [CheckoutSelectPlanPage]
})
export class CheckoutSelectPlanPageModule {}

import { Component } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";

import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Drilldown from 'highcharts/modules/drilldown';
import Exporting from 'highcharts/modules/exporting';
import { getgid } from "process";
More(Highcharts);
Drilldown(Highcharts);
Exporting(Highcharts);

@Component({
  selector: 'app-expand',
  templateUrl: './expand.component.html',
  styleUrls: ['./expand.component.scss']
})
export class ExpandComponent {
  options: any;
  constructor(private platform: Platform, private modalCtrl: ModalController) {}

  ionViewDidEnter() {
    //console.log({ chart: this.chart });
    const options = { ...this.options };
    //options.yAxis = this.options.xAxis;
    //options.xAxis = this.options.yAxis;
    const minHeight = options.xAxis.categories.reduce((acc) => acc + 10, 0);
    options.chart.height = this.platform.height();
    options.chart.width = this.platform.width();
    options.chart.inverted = true;
    const minHeightAux = minHeight < this.platform.height() ? (this.platform.height() + 100) : minHeight;

    options.chart.scrollablePlotArea = {
      minHeight: minHeightAux
    };
  
       options.chart['events'] = {
         load: (chartThis) =>
           chartThis.target.renderer
             .image('assets/imgs/close-outline.svg', 10, 5, 40, 40)
             .on('click', () => this.dismiss())
             .add()
       };

    const hg = new Highcharts.Chart('container', options);
  }

  dismiss(){
    this.modalCtrl.dismiss();
  }
}   
import _ from "lodash";

export class EasyPagination<T> {
  private pagination: IPaginationResponse<T> | null;

  constructor(
    private collection: T[],
    private page = 1,
    private pageSize = 20
  ) {
    this.pagination = pagination<T>(this.collection, this.page, this.pageSize);
  }

  get totalOfPages() : number {
    return this.pagination.totalOfPages;
  }

  get currentPageNumber() : number {
    return this.pagination.page;
  }

  get hasMorePage() : boolean {
    return this.currentPageNumber < this.totalOfPages;
  }

  reset(collection: T[], page = 1, pageSize = 20) {
    this.collection = collection;
    this.page = page;
    this.pageSize = pageSize;

    this.pagination = pagination<T>(this.collection, this.page, this.pageSize);
  }

  currentPage(): { page: number; items: T[] } {
    return { page: this.pagination.page, items: this.pagination.items };
  }

  nextPage(): { page: number; items: T[] }  {
    this.page++;
    this.pagination = pagination<T>(this.collection, this.page, this.pageSize);
    return this.currentPage();
  }

  previousPage():{ page: number; items: T[] } {
    this.page = this.page === 1 ? this.page : this.page - 1;
    this.pagination = pagination<T>(this.collection, this.page, this.pageSize);
    return this.currentPage();
  }
}

function pagination<T>(
  collection: T[],
  page = 1,
  pageSize = 20
): IPaginationResponse<T> {
  const offset = (page - 1) * pageSize;
  const pagedItems = _.drop(collection, offset).slice(0, pageSize);

  const totalOfPages = Math.ceil(collection.length / pageSize);

  return {
    page,
    pageSize,
    total: collection.length,
    totalOfPages,
    items: pagedItems,
  };
}

interface IPaginationResponse<T> {
  page: number;
  pageSize: number;
  total: number;
  totalOfPages: number;
  items: T[];
}
